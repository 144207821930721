import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { trackPatientAsViewedRequest } from 'containers/PatientDetails/actions';

import './styles.scss';
import '../../assets/stylesheets/_variables.scss';

import SubjectInfoCard from './SubjectInfoCard';
import StudyInfoCard from './StudyInfoCard';
import TeamInfo from './TeamInfoCard';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PatientInfo = props => {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      trackPatientAsViewedRequest(
        parseInt(params.patientId, 10) || -1,
        parseInt(params.procedureId, 10) || -1
      )
    );
  }, []);

  return (
    <div className='patient-info-container hide-in-print'>
      <div className='patient-cards'>
        <SubjectInfoCard />
        <StudyInfoCard />
        <TeamInfo procedureId={parseInt(params.procedureId, 10) || -1} />
      </div>
    </div>
  );
};

export default PatientInfo;

import {
  USER_TYPE_PATIENT_ADVOCATE,
  USER_TYPE_PROVIDER,
  USER_TYPES,
} from 'constants';

const isProvider = type => type === USER_TYPE_PROVIDER;

const isPatientAdvocate = type => type === USER_TYPE_PATIENT_ADVOCATE;

const isSupportUser = userType =>
  userType?.toUpperCase() === USER_TYPES.SUPPORT;

export const isDeidentifiedAccessUserRole = (
  deidentifiedAccessRoles,
  selectedRole
) => {
  return deidentifiedAccessRoles.includes(selectedRole);
};

export const canHavePrimaryPhysicians = (
  type,
  userType,
  deidentifiedAccessRoles,
  userRole
) => {
  if (!isDeidentifiedAccessUserRole(deidentifiedAccessRoles, userRole)) {
    return isProvider(type) && isSupportUser(userType);
  }
  return false;
};

export const canHavePrimaryAdvocates = (
  type,
  userType,
  deidentifiedAccessRoles,
  userRole
) => {
  if (!isDeidentifiedAccessUserRole(deidentifiedAccessRoles, userRole)) {
    return isPatientAdvocate(type) && isSupportUser(userType);
  }
  return false;
};

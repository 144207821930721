/* eslint-disable react/forbid-prop-types */
import React from 'react';
import DataTable from 'components/StyleGuideComponents/DataTable';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const AuditTrailTable = props => {
  const currentUser = useSelector(store => store.currentUser);
  const { data, headers } = props;
  return data?.length ? (
    <div className='audit-trail-table'>
      <span className='show-only-in-print printed-by-text'>
        Generated by{' '}
        {`${`${currentUser.attributes.firstName} ${currentUser.attributes.lastName}`}`}
      </span>
      <DataTable
        tableHeaders={headers}
        tableData={data}
        style={{ margin: '0px' }}
        headerStyle={{ textAlign: 'left' }}
        dataStyle={{ textAlign: 'left' }}
      />
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};

AuditTrailTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.arrayOf(PropTypes.object),
};

AuditTrailTable.defaultProps = {
  data: [],
  headers: [],
};

export default AuditTrailTable;

import {
  downloadCSV,
  fetchConsentsForAuditTrail,
  fetchAuditTrails,
  downloadConsentAuditTrailData,
} from 'services/patientDetails';
import * as Constants from './constants';

export const fetchAuditTrailsRequestSucceed = data => ({
  type: Constants.FETCH_AUDIT_TRAILS_REQUEST_SUCCEED,
  data,
});

export const fetchConsentsForAuditTrailRequestSucceed = data => ({
  type: Constants.FETCH_CONSENT_FOR_AUDIT_TRAIL_REQUEST_SUCCEED,
  data,
});

export const fetchAuditTrailsRequest = params => dispatch => {
  fetchAuditTrails(params)
    .then(response => {
      dispatch(fetchAuditTrailsRequestSucceed(response.data));
    })
    .catch(() => {});
};

export const fetchAuditTrailsCsvRequest = params => dispatch => {
  downloadCSV(params)
    .then(response => {
      window.open(response.data.fileUrl);
    })
    .catch(() => {});
};

export const fetchConsentsForAuditTrailRequest = params => dispatch => {
  fetchConsentsForAuditTrail(params)
    .then(response => {
      dispatch(fetchConsentsForAuditTrailRequestSucceed(response.data));
    })
    .catch(() => {});
};

export const downloadConsentAuditTrailDataRequest = params => dispatch => {
  downloadConsentAuditTrailData(params)
    .then(response => {
      window.open(response.data.downloadLink);
    })
    .catch(() => {});
};

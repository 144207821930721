import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import EmptyView from 'components/Emptyview';
import { getPatientTasks } from 'containers/Tasks/actions';
import './tasks.scss';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import { PrimaryModal } from 'components/StyleGuideComponents/common/modals';
import TabContentWrapper from 'containers/PatientDetails/Components/TabContentWrapper';
import { sortData } from 'containers/PatientDetails/configs/services';
import PropTypes from 'prop-types';
import TaskBucket from './Components/TaskBucket';
import NoTasks from '../../assets/images/notasks.png';

const Tasks = props => {
  const [reminderSuccessModalShow, setReminderSuccessModalShow] =
    useState(false);

  const dispatch = useDispatch();
  const params = useParams();

  const selectedPhaseId = useSelector(
    store => store.currentPatientInfo.selectedPhaseId
  );
  const total = useSelector(store => store.tasks.total);
  const tasks = useSelector(store => store.tasks.tasks);

  const { isActive } = props;

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  useEffect(() => {
    if (isActive)
      dispatch(getPatientTasks(patientId, procedureId, selectedPhaseId, true));
  }, [isActive]);

  const sortedTasks = useMemo(
    () => sortData(dispatch, tasks, 'date', 'tasks'),
    [tasks]
  );

  return (
    <TabContentWrapper
      header={
        <div className='header'>
          <span className='header-left-section'>
            {total > 0 ? `${total} Tasks` : ''}
          </span>
        </div>
      }
      body={
        <>
          <div className='tasks-list'>
            {Object.keys(sortedTasks).length ? (
              Object.keys(sortedTasks).map(key => (
                <TaskBucket key={key} date={key} tasks={sortedTasks[key]} />
              ))
            ) : (
              <EmptyView imgSrc={NoTasks} className='' message='No Tasks' />
            )}
          </div>

          <PrimaryModal
            show={reminderSuccessModalShow}
            onHide={() => setReminderSuccessModalShow(false)}
            bodyHeader={{
              text: 'Alert!',
            }}
            bodyContent={{
              text: 'This patient has not yet logged into the app, so your reminder will only be viewed by the patient if they choose to log in. You may want check in with the patient outside of the application to make sure they are on-track.',
            }}
            buttons={[
              <PrimaryButton
                onClick={() => setReminderSuccessModalShow(false)}
                key='first'
              >
                Close
              </PrimaryButton>,
            ]}
          />
        </>
      }
    />
  );
};

Tasks.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default Tasks;

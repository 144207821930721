/*
 *
 * ediary actions
 *
 */
import { showToaster } from 'common/toasterActions';
import {
  getAllDiaryEntries,
  markDiariesAsRead,
  updateDiaryEntry,
} from 'services/patientDetails';
import {
  getPatientInfo,
  resetUnreadDiaryCount,
} from 'containers/PatientDetails/actions';
import { resetDataForDoubleAuthetication } from 'components/MultiAuthenticatedRequest/actions';
import {
  GET_DIARY_ENTRIES_REQUEST_SENT,
  GET_DIARY_ENTRIES_REQUEST_SUCCEED,
  GET_DIARY_ENTRIES_REQUEST_FAILED,
} from './constants';

export const getPatientDiariesRequestSent = () => ({
  type: GET_DIARY_ENTRIES_REQUEST_SENT,
});
export const getPatientDiariesRequestSucceeded = diaryLogs => ({
  type: GET_DIARY_ENTRIES_REQUEST_SUCCEED,
  diaryLogs,
});
export const getPatientDiariesRequestFailed = () => ({
  type: GET_DIARY_ENTRIES_REQUEST_FAILED,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getAllDiaryEntriesRequest = patientId => dispatch => {
  getAllDiaryEntries(patientId)
    .then(response => {
      dispatch(getPatientDiariesRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const markAsReadPatientAllDiariesRequest =
  (patientId, procedureId) => dispatch => {
    markDiariesAsRead(patientId)
      .then(response => {
        dispatch(
          showToaster({ type: 'success', message: response.data.message })
        );
        dispatch(getAllDiaryEntriesRequest(patientId));
        dispatch(resetUnreadDiaryCount(procedureId));
      })
      .catch(() => {});
  };

export const updateDiaryRequest = params => dispatch => {
  updateDiaryEntry(params)
    .then(response => {
      dispatch(
        showToaster({ type: 'success', message: response.data.message })
      );
      dispatch(resetDataForDoubleAuthetication());
      dispatch(getAllDiaryEntriesRequest(params.patient_id));
      dispatch(getPatientInfo(params.patient_id, params.procedure_id));
    })
    .catch(() => {});
};

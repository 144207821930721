import React, { useState } from 'react';
import Strings from '../../strings';
import { TertiaryButton } from '../StyleGuideComponents/common/buttons';
import { PrimaryModal } from '../StyleGuideComponents/common/modals';
import './preloginFooter.scss';

const PreloginFooter = () => {
  const [showContactviewModal, setShowContactviewModal] = useState(false);

  return (
    <div className='pre-login-footer-container'>
      <div
        className='contact-us cursor-pointer'
        onClick={() => setShowContactviewModal(true)}
      >
        <TertiaryButton>Need Help?</TertiaryButton>
      </div>
      <div className='copy-right-container'>
        <div className='copy-right-label'>
          &copy; {Strings.ORGANIZATION_COPY_RIGHT_STRING}
        </div>
      </div>

      <PrimaryModal
        show={showContactviewModal}
        onHide={() => setShowContactviewModal(false)}
        bodyHeader={{
          text: 'Contact Us',
        }}
        bodyContent={{
          text: (
            <div className='footer-content-container'>
              <div className='text'>{Strings.CONTACT_US_INFO_STRING1}</div>
              <div className='text'>{Strings.CONTACT_US_INFO_STRING2}</div>
              <div className='phone-container'>
                <i className='icon icon-font-a-contact' />
                <span className='phone'>
                  {Strings.SUPPORT_CONTACT_NUMBER_STRING}
                </span>
              </div>
              <div className='email-container'>
                <i className='icon icon-font-a-email' />
                <span className='email'>{Strings.SUPPORT_EMAIL}</span>
              </div>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default PreloginFooter;

import axios, { nonMasqueradingInstance, defaultAxios } from './base';
import {
  BASE_AUTH_URL,
  DEFAULT_URL,
  UPDATE_EMAIL_PREFERENCE_ON_MESSAGES,
} from './constants';

export const registerUserService = userRegistrationDetails => {
  const { email, password, passwordConfirmation, firstName, lastName } =
    userRegistrationDetails;

  const data = {
    email,
    password,
    password_confirmation: passwordConfirmation,
    first_name: firstName,
    last_name: lastName,
  };

  return defaultAxios.post(BASE_AUTH_URL, data);
};

export const signInUserService = userCredentials =>
  defaultAxios.post(`${BASE_AUTH_URL}/sign_in`, userCredentials);

export const generateOtpUserService = userCredentials =>
  defaultAxios.post(`${DEFAULT_URL}/user_verifications`, userCredentials);

export const signOutService = () =>
  nonMasqueradingInstance.delete(`${BASE_AUTH_URL}/sign_out`);

export const getTermsAndConditionUrlService = params =>
  defaultAxios.get(`${DEFAULT_URL}/logins/terms_and_condition_url`, { params });

export const getPrivacyNoticeUrlService = () =>
  defaultAxios.get(`${DEFAULT_URL}/patient/sign_ups/privacy_notice_url`);

export const updateIsEmailMessagesEnabled = preference =>
  axios.put(UPDATE_EMAIL_PREFERENCE_ON_MESSAGES, {
    email_preference: preference.toString(),
  });

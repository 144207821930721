/** \n 
 * @author Dilip Joshi
 * @desc This component is a collection of common Modals used in the Style Guide. The component imports `Modal`, `ModalForm` and `_variables.scss` from the file system. The `_variables.scss` file contains variables used in styling the component.
 
`loaderModalStyle` is an object that contains styling properties for a loader modal. 

There are six exported modals in this component: 
1. `PrimaryModal` is a modal with a close icon. Props can be passed to this modal.
2. `SecondaryModal` is a modal with a close icon and has added properties to handle flex-box alignment.
3. `TertiaryModal` is a modal with a close icon and has added properties to handle flex-box alignment, body direction, and button direction.
4. `DeleteModal` is a `PrimaryModal` with a close icon, used for deletion confirmation.
5. `LoaderModal` is a modal used to show a loading spinner. LoaderModal also imports the `loaderModalStyle` object to define its styling.
6. `FormModal` is a modal used specifically for displaying a form. It contains a close icon and can receive props.

All exported modals are written as arrow functions that return a `Modal` or `ModalForm` component with props passed to them.

To use this component, import the desired modals and render the modal with the appropriate props. Example:
```
import React from 'react';
import { PrimaryModal, SecondaryModal } from './StyleGuideModalComponent';

const MyComponent = () => {
  return (
    <div>
      <PrimaryModal 
        title={text: "Example Modal"  }
        body={text: "Hello World!" }
      />
      <SecondaryModal 
        title={title: "Second Example Modal" }
        body={title: "This modal is aligned to the left." }
      />
    </div>
  );
};
 */
import React from 'react';
import Modal from 'components/StyleGuideComponents/Modal';
import ModalForm from 'components/StyleGuideComponents/ModalForm';
import variables from '../../../assets/stylesheets/_variables.scss';

const loaderModalStyle = {
  background: variables.lightBoxModalBackgroundColor,
  background: 'transparent',
  boxShadow: 'none',
};

export const PrimaryModal = props => <Modal closeIcon {...props} />;
export const SecondaryModal = props => (
  <Modal
    closeIcon
    bodyPosition='flex-start'
    {...props}
    bodyContent={{ ...props.bodyContent, align: 'left' }}
    bodyHeader={{ ...props.bodyHeader, align: 'left' }}
  />
);
export const TertiaryModal = props => (
  <Modal
    closeIcon
    {...props}
    bodyContent={{ ...props.bodyContent, align: 'center' }}
    bodyHeader={{ ...props.bodyHeader, align: 'center' }}
    bodyDirection='column'
    bodyPosition='center'
    buttonsDirection='column'
  />
);
export const DeleteModal = props => <PrimaryModal closeIcon {...props} />;
export const LoaderModal = props => (
  <Modal {...props} loaderModal modalStyle={loaderModalStyle} />
);
export const FormModal = props => <ModalForm closeIcon {...props} />;

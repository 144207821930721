import { ImportantDevices } from '@mui/icons-material';
import '../../assets/stylesheets/_variables.scss';
// import variables from "./styleGuideComponents.scss";

import { colors } from '../StyleGuideComponents/StyleGuideCSS';

export default {
  control: base => ({
    ...base,
    minHeight: 34,
  }),

  option: (base, state) => ({
    ...base,
    ':active': {
      color: state.isSelected ? colors.colorPrimaryDark : null,
      backgroundColor: state.isSelected ? colors.colorSecondaryDark : null,
    },
    background: state.isFocused
      ? colors.colorSecondary
      : state.isSelected
      ? colors.colorSecondaryDark
      : undefined,
    color: state.isSelected ? colors.colorSecondaryDark : undefined,
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: base => ({
    ...base,
    padding: 4,
  }),
  valueContainer: base => ({
    ...base,
    padding: '0px 6px',
  }),
  multiValue: base => ({
    ...base,
    border: `1px solid ${colors.colorPrimary}`,

    backgroundColor: '#fff',
    borderRadius: '20px',
    color: `${colors.colorPrimary}`,
  }),
  input: base => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import SpinnerImage from 'assets/images/spinner.gif';
import { LoaderModal } from '../StyleGuideComponents/common/modals';
import './lightbox_modal.scss';

const LightboxModal = props => (
  <LoaderModal
    show={props.show}
    img={{
      image: (
        <img src={SpinnerImage} alt='Loading...' className='spinner-image' />
      ),
    }}
  />
);

LightboxModal.propTypes = {
  show: PropTypes.bool,
};

export default LightboxModal;

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { BackButton } from 'components/UtilityComponents';
import { useSelector } from 'react-redux';
import TabContentWrapper from 'containers/PatientDetails/Components/TabContentWrapper';
import MessageResponse from '../MessageResponse';
import MessagesList from '../MessagesList';

const ConversationDetails = props => {
  const { selectedConversationId } = props;
  const history = useHistory();
  const conversation = useSelector(store =>
    store.conversations.conversations.find(
      con => con.id === parseInt(selectedConversationId, 10)
    )
  );

  return (
    <TabContentWrapper
      header={
        <div className='header'>
          <span className='header-left-section'>
            <BackButton
              className='app-link'
              goBack={() =>
                history.replace(`${window.location.pathname}#messages`)
              }
            />
          </span>
        </div>
      }
      body={
        <>
          <div
            className='row'
            style={{ marginBottom: '20px', marginTop: '10px' }}
          >
            <div className='col-xs-10'>
              Subject:&nbsp;
              {conversation ? conversation.subject : ''}
              {conversation && conversation.startedWith && (
                <span>
                  &nbsp;(Started With: {conversation.startedWith.fullName})
                </span>
              )}
            </div>
          </div>
          <MessageResponse conversationId={selectedConversationId} />
          <MessagesList conversationId={selectedConversationId} />
        </>
      }
    />
  );
};

ConversationDetails.propTypes = {
  selectedConversationId: PropTypes.string.isRequired,
};

export default ConversationDetails;

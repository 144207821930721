import React, { ReactNode } from "react";
import "./styles.scss";

enum DISPLAY_MODES {
  DISPLAY_MODE_MINI = "0",
  DISPLAY_MODE_FULL_SCREEN = "1",
}

type MobileLoaderProps = {
  displayMode: "0" | "1";
  loaderWrapperClass: string;
  loaderImgStyle: object;
  childrenContent: ReactNode;
};

const MobileLoader: React.FC<MobileLoaderProps> = (props) => {
  const { displayMode, loaderWrapperClass, loaderImgStyle, childrenContent } =
    props;
  return displayMode === DISPLAY_MODES.DISPLAY_MODE_MINI ? (
    <div className={`${loaderWrapperClass} mobile-loader`}>
      <img
        src="/images/mobile_loader.gif"
        className="mini-spinner-image"
        style={loaderImgStyle}
        alt="Loading..."
      />
    </div>
  ) : (
    <div className={loaderWrapperClass}>
      <div className="full-screen-loader-wrapper">
        <div className="full-screen-loader">
          <img
            className="full-spinner-image"
            src="/images/mobile_loader.gif"
            alt="Loading..."
          />
        </div>
      </div>
      {childrenContent}
    </div>
  );
};

export default MobileLoader;

/**
@author Dilip Joshi
@desc A stateless functional component displaying a preview of form data
@component
@param {Props} props - The props object holding the data to be displayed
@param {Array} props.dataToShow - An array holding the data to be displayed
@param {Function} props.onStepChange - A function to change the current step
@param {number} props.step - The current step in the form
@param {Function} props.onSubmit - A function to submit form data
@returns {JSX.Element} - A preview of form data along with Go Back and Save buttons
 */

import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import React from 'react';
import { IconButton } from '@material-ui/core';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import variables from 'assets/stylesheets/_variables.scss';
import { PrimaryModal } from 'components/StyleGuideComponents/common/modals';

const FormDataPreview = props => {
  return (
    <div className='preview-wrapper'>
      <div className='add-user-form-preview' style={{ overflowX: 'auto' }}>
        {props.dataToShow.map(data => (
          <div className='details'>
            <div className='title'>
              <span className='title-text'>{data.title}</span>
              <span>
                <IconButton onClick={() => props.onStepChange(data.editStep)}>
                  <EditOutlinedIcon
                    fontSize='small'
                    sx={{
                      color: variables.colorPrimary,
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </IconButton>
              </span>
            </div>
            <div className='columns'>
              {data.columnsData.map(column => (
                <div className='info'>
                  <div className='info-key'>{column.label}</div>
                  {column.visible ? (
                    <div className='info-value'>{column.value}</div>
                  ) : (
                    <>NA</>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className='buttons'>
        <SecondaryButton
          onClick={() => props.onStepChange(props.step - 1)}
          style={{ width: '200px' }}
        >
          Go Back
        </SecondaryButton>
        <PrimaryButton style={{ width: '200px' }} onClick={props.onSubmit}>
          Save
        </PrimaryButton>
      </div>
    </div>
  );
};

export default FormDataPreview;

import authSamlCallback from 'services/samlCallback';
import { setAuthHeaders } from 'services/base';
import * as StorageService from 'services/storage';
import camelcaseKeys from 'change-case-object';
import * as Constants from './constants';

export const getSamlVerificationSuccess = () => ({
  type: Constants.GET_SAML_VERIFICATION_SUCCESS,
});

export const getSamlVerificationFailed = data => ({
  type: Constants.GET_SAML_VERIFICATION_FAILED,
  data,
});

export const verifySamlAssertion = code => dispatch => {
  authSamlCallback(code)
    .then(response => {
      setAuthHeaders(response.headers, response.data.access_token);
      StorageService.persistAuthHeadersInDeviceStorage(
        response.headers,
        response.data.access_token
      );
      const currentUser = camelcaseKeys.camelCase(response.data.data);
      StorageService.setItemToStorage(
        'currentUser',
        JSON.stringify(currentUser)
      );
      window.location.href = '/';
      dispatch(getSamlVerificationSuccess());
      return null;
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/unauthorized-access';
      dispatch(getSamlVerificationFailed(null));
    });
};

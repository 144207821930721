import {
  FETCH_NOTIFICATIONS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
  SAVE_ADD_NOTIFICATION_FORM_DATA,
  UPDATE_CREATE_NOTIFICATION_ADMIN_VALUE_IN_STORE,
  SET_SELECTED_NOTIFICATION_ID,
  RESET_ADD_NOTIFICATION_DATA,
} from './constants';

const paginationIntialData = {
  total: 0,
  page: 1,
  total_pages: 1,
  last_page: false,
  first_page: true,
  prev_page: null,
  next_page: 2,
  start_number: 1,
  end_number: 10,
};

const notificationsAdminFormData = {
  page: 0,
  filterName: '',
  title: '',
  body: '',
  offsetInDays: 0,
  timings: null,
  dueOffsetHours: 0,
  department: null,
  procedure: null,
  eventKind: null,
  type: null,
  uniqueIdentifier: '',
  showAddNotificationsInfoModal: false,
};

const notificationsAdminInitialData = {
  notificationsData: [],
  notificationHeaders: [],
  pagination: paginationIntialData,
  ...notificationsAdminFormData,
};

export const notificationAdminReducer = (
  state = notificationsAdminInitialData,
  action
) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED:
      return {
        ...state,
        notificationHeaders: action.data.notificationHeaders,
        notificationsData: action.data.notificationsData,
        pagination: action.data.pagination,
      };

    case SAVE_ADD_NOTIFICATION_FORM_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }

    case UPDATE_CREATE_NOTIFICATION_ADMIN_VALUE_IN_STORE: {
      return {
        ...state,
        [action?.key]: action?.value,
      };
    }

    case SET_SELECTED_NOTIFICATION_ID:
      return {
        ...state,
        ...action.data.notificationData,
        selectedNotificationId: action.data.id,
      };

    case RESET_ADD_NOTIFICATION_DATA: {
      return {
        ...state,
        ...notificationsAdminFormData,
        ...action.data,
      };
    }

    default:
      return state;
  }
};

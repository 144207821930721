import React, { useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { SecondaryButton } from 'components/StyleGuideComponents/common/buttons';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import EmptyView from 'components/Emptyview';
import NoConnect from 'assets/images/noconnect.png';
import TabContentWrapper from 'containers/PatientDetails/Components/TabContentWrapper';
import { sortData } from 'containers/PatientDetails/configs/services';
import { ACCESS_CONTROL_KEYS } from 'constants';
import { EMPTY_COMMUNICATION_LOG_MESSAGE } from '../constants';
import CreateNote from './CreateNote';
import '../notes.scss';
import NoteDateBucket from './NoteDateBucket';
import { markAsReadPatientAllNotesRequest } from '../action';

const NotesList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const notes = useSelector(store => store.communicationLog.notes);
  const currentUserId = useSelector(
    store => store.currentUser.attributes && store.currentUser.attributes.id
  );

  const appFeatures = useSelector(store => store.currentUser.appFeatures);
  const canCreateNote = appFeatures[ACCESS_CONTROL_KEYS.NOTES].canCreate;

  const [showModal, setShowModal] = useState(false);

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  const getNotesCount = notesInArgs => {
    let count = 0;
    Object.keys(notesInArgs).map(key => {
      count += notesInArgs[key].dateNotes.length;
      return key;
    });
    return count;
  };

  const checkIfThereIsAnyUnreadNote = notesInArgs => {
    let isThereAnyUnreadNote = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < notesInArgs.length; i++) {
      if (isThereAnyUnreadNote) break;
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < notesInArgs[i].dateNotes.length; j++) {
        if (notesInArgs[i].dateNotes[j].readOn === null) {
          isThereAnyUnreadNote = true;
          break;
        }
      }
    }
    return isThereAnyUnreadNote;
  };

  const sortedNotes = useMemo(
    () => sortData(dispatch, notes, 'date', 'dateNotes'),
    [notes]
  );

  const onMarkAllReadClick = () => {
    dispatch(markAsReadPatientAllNotesRequest(patientId, procedureId));
  };

  const onNoteClick = noteId => {
    history.replace(`${window.location.pathname}?note_id=${noteId}#patientlog`);
  };

  return (
    <TabContentWrapper
      header={
        <div className='header'>
          {notes.length > 0 ? (
            <>
              <div className='header-left-section'>
                <div className='notes-count notes-header-left-text'>
                  {getNotesCount(notes) === 1
                    ? '1 Note'
                    : `${getNotesCount(notes)} Notes`}
                </div>
              </div>
              <div className='header-right-section'>
                <div className='note-create-buttons'>
                  <SecondaryButton
                    style={{ width: '138px' }}
                    onClick={onMarkAllReadClick}
                    disabled={!checkIfThereIsAnyUnreadNote(notes)}
                  >
                    Mark all as read
                  </SecondaryButton>
                  {canCreateNote ? (
                    <SecondaryButton
                      style={{ width: '138px' }}
                      onClick={() => setShowModal(true)}
                    >
                      <ModeEditOutlinedIcon sx={{ fontSize: '2rem' }} />
                      &nbsp; Create Note
                    </SecondaryButton>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='header-left-section' />
              <div className='header-right-section'>
                {canCreateNote ? (
                  <SecondaryButton
                    style={{ width: '138px' }}
                    onClick={() => setShowModal(true)}
                  >
                    <ModeEditOutlinedIcon sx={{ fontSize: '2rem' }} />
                    &nbsp; Create Note
                  </SecondaryButton>
                ) : (
                  ''
                )}
              </div>
            </>
          )}
        </div>
      }
      body={
        <>
          <div className='communication-log-list'>
            {notes.length > 0 ? (
              Object.keys(sortedNotes).map(key => (
                <NoteDateBucket
                  onNoteClick={id => onNoteClick(id)}
                  currentUserId={currentUserId}
                  notes={sortedNotes[key]}
                  date={key}
                  key={key}
                />
              ))
            ) : (
              <EmptyView
                imgSrc={NoConnect}
                className=''
                message={EMPTY_COMMUNICATION_LOG_MESSAGE}
              />
            )}
          </div>
          <CreateNote
            showModal={showModal}
            onModalClose={() => setShowModal(false)}
          />
        </>
      }
    />
  );
};
export default NotesList;

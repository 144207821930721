import React from 'react';
import InputMask from 'react-input-mask';
import { PHONE_INPUT_MASK } from 'constants';

const PhoneInput = props => {
  return (
    <InputMask
      {...props}
      mask={props.mask || PHONE_INPUT_MASK}
      maskChar={null}
      type='tel'
    />
  );
};

export default PhoneInput;

import { getNativeAuthTokens } from 'utils/nativeAuthTokens';
import { PLATFORM } from 'enums';

// searchParams is used for running components locally.
export const getAuthValues = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const tokens = getNativeAuthTokens();
  const bearerToken = tokens?.["Authorization"] || searchParams.get("Authorization") || null;
  const version = searchParams.get('X-APP-VERSION') || '0';
  const platform = searchParams.get('platform') || searchParams.get('Platform') || PLATFORM.IOS;

  const invalidAuthValues = ['null', 'undefined', null, undefined, '']

  if(invalidAuthValues.includes(bearerToken)) {
    throw new Error(`Auth Values are blank, undefined or null with Auth Values as => bearerToken: ${bearerToken}`);
  }

  return {
    'token-type': 'Bearer',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-APP-VERSION': version,
    platform: platform,
    Authorization: `Bearer ${bearerToken}`,
  };
};

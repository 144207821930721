import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import EmptyView from 'components/Emptyview';
import NavNotificationCard from 'containers/NavNotificationMenu/NavNotificationCard';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  getNotifications,
  getUnreadNotificationsCount,
} from 'containers/NavNotificationMenu/actions';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import './NotificationDropdown.scss';
import NoNotifications from '../../assets/images/nonotifications.png';

const NotificationDropdown = () => {
  const history = useHistory();
  const ref = React.createRef();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const open = Boolean(anchorEl);
  const notifications = useSelector(
    store => store.notificationsReducer.notifications
  );
  const isLast = useSelector(store => store.notificationsReducer.isLast);

  const isLoading = useSelector(store => store.notificationsReducer.isLoading);
  const unreadCount = useSelector(
    store => store.notificationsReducer.unreadCount
  );

  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setPage(1);
    if (unreadCount > 0) {
      dispatch(getNotifications({ page, limit: 10 }, true));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const queryNotifications = () => {
    dispatch(getNotifications({ page, limit: 10 }, true));
  };

  useEffect(() => {
    queryNotifications();
  }, [page]);

  let refreshInterval = null;

  useEffect(() => {
    refreshInterval = setInterval(() => {
      dispatch(getUnreadNotificationsCount());
    }, 30000);
  }, []);

  useLayoutEffect(() => {
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  const handleNotificationsScroll = () => {
    if (!isLast && !isLoading) {
      const { scrollTop, scrollHeight, offsetHeight } = ref.current;
      if (scrollTop === scrollHeight - offsetHeight) {
        setPage(page + 1);
      }
    }
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        className='notification-dropdown'
      >
        <IconButton
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          className='notification-icon'
          disableRipple
        >
          <NotificationsOutlinedIcon id='notification-icon' />

          {unreadCount > 0 ? (
            <span className='pending-notifications-badge'>{unreadCount}</span>
          ) : (
            ''
          )}
        </IconButton>
      </Box>
      <Menu
        className='dropdown-ul'
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          paddingTop: '0px !important',
          paddingBottom: '0',
          width: '100%',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            width: '380px',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            paddingTop: '0px !important',
            paddingBottom: '0',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem className='notification-dropdown-menu'>
          <div className='notifications-container'>
            <MenuItem
              className='notfications-dropdown-header'
              sx={{ width: '100%' }}
            >
              <div className='notifications-dropdown-title'>Notifications</div>
              <TertiaryButton
                style={{ width: 'auto' }}
                onClick={() => history.replace('/notifications')}
                disabled={notifications.length === 0}
              >
                See all
              </TertiaryButton>
            </MenuItem>

            {isLoading ? (
              <div className='notifications-dropdown-body loader-body'>
                <LoadingIndicator show={isLoading} />
              </div>
            ) : (
              <div
                className='notifications-dropdown-body'
                onScroll={() => handleNotificationsScroll()}
                ref={ref}
              >
                {notifications.length === 0 && (
                  <EmptyView
                    imgSrc={NoNotifications}
                    className=''
                    message='No Notifications'
                  />
                )}
                {notifications.map((notification, index) => (
                  <NavNotificationCard
                    key={`key_${index.toString()}`}
                    notification={notification}
                  />
                ))}
              </div>
            )}
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default NotificationDropdown;

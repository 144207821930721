import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validate, { clearErrorsForField } from 'common/validator';
import HelpBlock from 'components/HelpBlock';
import AddAttachmentModal from 'containers/AddAttachmentModal/index';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { COMMUNICATION_LOG_SUPPORTED_FILE_FORMATS } from 'constants';
import {
  PrimaryButton,
  TertiaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { useParams } from 'react-router';
import { postCommentsRequest } from './NoteDetails/action';
import { resetAddCommentOnNoteStarted } from 'common/actions';

const validationConfig = {
  fields: ['comment'],
  rules: {
    comment: [{ rule: 'isRequired', message: 'Comment is required' }],
  },
};

const CommentRespond = props => {
  const dispatch = useDispatch();
  const params = useParams();

  const [errors, setErrors] = useState({});
  const [comment, setComment] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  const addCommentOnNoteStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.addCommentOnNoteStarted
  );

  const resetState = () => {
    setComment('');
    setAttachments([]);
    setShowModal(false);
    setErrors({});
  };
  
  useEffect(() => {
    if (addCommentOnNoteStarted) {
      resetState();
      dispatch(resetAddCommentOnNoteStarted());
    }
  }, [addCommentOnNoteStarted]);

  const onFormValidationSuccess = () => {
    const params = {
      id: props.noteId,
      text: comment.trim(),
      media_resources: attachments,
      secure_action_code: SecureCodes.NOTE_ADD_COMMENT,
      needs_reason: true,
      patientId,
      procedureId,
    };
    dispatch(secureActionWithDoubleAuthentication(postCommentsRequest, params));
  };

  const onSubmitComment = () => {
    validate(
      validationConfig,
      { comment },
      errors => setErrors(errors),
      onFormValidationSuccess
    );
  };

  return (
    <div className='response-container'>
      <div className='response-label'>
        <span className='icon-addchatbubbleoutline note-details-page-icons' />
        <span className='response-label-title'>Add a Comment</span>
      </div>
      <div
        className={`form-group ${
          errors.comment !== undefined ? 'has-error' : ''
        }`}
      >
        <textarea
          className='form-control comment-area'
          cols='100'
          name='answer'
          placeholder='Type your comment here...'
          value={comment}
          onChange={e => {
            setErrors(clearErrorsForField(errors, 'comment'));
            setComment(e.target.value);
          }}
        />
        <HelpBlock value={errors.comment} />
      </div>
      <div className='attachment-section'>
        <div className='response-add-attachment-modal'>
          <AddAttachmentModal
            onUpdateAttachments={attachments => {
              setAttachments(attachments);
            }}
            showModal={showModal}
            onModalClose={() => setShowModal(false)}
            showAddedAttachments
            isBindedWithModal={false}
            fileFormats={COMMUNICATION_LOG_SUPPORTED_FILE_FORMATS}
            mediaResources={attachments}
          />
        </div>
      </div>
      <div>
        <span className='float-right btn-attachment'>
          <PrimaryButton onClick={onSubmitComment}>Add Comment</PrimaryButton>
        </span>
        <span className='float-left btn-attachment add-attachment-button'>
          <TertiaryButton onClick={() => setShowModal(true)}>
            <AttachmentOutlinedIcon />
            &nbsp; Add Attachment
          </TertiaryButton>
        </span>
      </div>
    </div>
  );
};

export default CommentRespond;

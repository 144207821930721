import { DATE_FORMAT } from 'constants';

export const validationConfig = {
  fields: ['appointmentDate'],
  rules: {
    appointmentDate: [
      { rule: 'isValidDate', message: 'Invalid date provided' },
      {
        rule: 'isDependentRequired',
        dependOnKey: 'appointmentTime',
        message: 'Date is required when time is entered',
      },
    ],
  },
};

export const validateAppointmentConfig = {
  fields: ['appointmentDate', 'appointmentTitle'],
  rules: {
    appointmentTitle: [{ rule: 'isRequired', message: 'Title is required' }],
    appointmentDate: [
      {
        rule: 'isValidDate',
        message: `Invalid date provided. Accepted Format is ${DATE_FORMAT}`,
      },
      {
        rule: 'isDependentRequired',
        dependOnKey: 'appointmentTime',
        message: 'Date is required when time is entered',
      },
    ],
  },
};

export const createAppointmentValidationConfig = {
  fields: ['createAppointmentTitle', 'createAppointmentDate'],
  rules: {
    createAppointmentTitle: [
      { rule: 'isRequired', message: 'Title is required' },
    ],
    createAppointmentDate: [
      {
        rule: 'isValidDate',
        message: `Invalid date provided. Accepted Format is ${DATE_FORMAT}`,
      },
    ],
  },
};

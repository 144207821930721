import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import { clearErrorsForField } from 'common/validator';
import { updateResourceAdminValue } from '../action';

const ResourceFilter = props => {
  const dispatch = useDispatch();

  const [resourcesData, setResourcesData] = useState({
    filterName: '',
  });

  const resourceAdminReducer = useSelector(store => store.resourceAdminReducer);

  useEffect(() => {
    setResourcesData({
      filterName: resourceAdminReducer.filterName,
    });
  }, [resourceAdminReducer.filterName]);

  const [errors, setErrors] = useState({});
  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'filterName',
      label: 'Title',
      type: 'text',
      placeholder: 'Type Title',
      value: resourcesData.filterName,
      visible: true,
    },
  ];

  const onSubmit = () => {
    dispatch(updateResourceAdminValue('filterName', resourcesData.filterName));
    dispatch(updateResourceAdminValue('page', 1));
  };

  return (
    <div className='filters-container'>
      <div className='filters-label'>Filters</div>
      <FormGenerator
        className='add-edit-form'
        id='resourceFilter'
        formConfig={config}
        errors={errors}
        onChange={(key, value) => {
          clearErrorsForField(errors, key);
          setResourcesData({ filterName: value });
        }}
        buttons={[
          <PrimaryButton
            style={{ maxWidth: 'none' }}
            disabled={!resourcesData.filterName}
            className={resourcesData.filterName ? 'cursor-pointer' : ''}
            onClick={e => {
              setResourcesData({ filterName: '' });
              dispatch(updateResourceAdminValue('filterName', ''));
            }}
          >
            Clear All
          </PrimaryButton>,
          <SecondaryButton
            form='resourceFilter'
            type='submit'
            style={{ maxWidth: 'none' }}
            onClick={() => onSubmit()}
          >
            Apply Filters
          </SecondaryButton>,
        ]}
        {...props}
      />
    </div>
  );
};

export default ResourceFilter;

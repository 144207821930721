import React, { useState } from 'react';

const Tooltip = ({
  content,
  children,
  toolTipStyle,
  tipStyle,
  tipPosition,
}) => {
  const [show, setShow] = useState(false);
  return (
    <span
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      {show ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span
            className={`tooltip-content ${
              tipPosition ? `tip-${tipPosition}` : 'tip-top'
            }`}
            style={{ ...toolTipStyle }}
          >
            {content}
          </span>
        </div>
      ) : (
        <></>
      )}
    </span>
  );
};

export default Tooltip;

/*
 *
 * PatientDetailsPage reducer
 *
 */
import * as Constants from './constants';

const initialState = {
  isLoading: false,
  appointments: [],
  facets: [],
  patients: [],
  dates: [],
  ehrActivityCount: 0,
  selectedFilterId: null,
};

export const patientDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.GET_PROVIDER_SEARCH_REQUEST_SENT:
    case Constants.GET_PROVIDER_FILTERS_REQUEST_SENT:
    case Constants.GET_PROVIDER_FILTERS_REQUEST_FAILED:
    case Constants.GET_PROVIDER_SEARCH_REQUEST_FAILED:
    case Constants.GET_PROVIDER_PATIENTS_RANGE_REQUEST_FAILED:
    case Constants.GET_PROVIDER_PATIENTS_RANGE_REQUEST_SENT:
    case Constants.GET_EHR_ACTIVITY_REQUEST_SENT:
    case Constants.GET_EHR_ACTIVITY_REQUEST_FAILED:
    case Constants.GET_APPOINTMENTS_SEARCH_WITHIN_RANGE_REQUEST_SENT:
    case Constants.GET_APPOINTMENTS_SEARCH_WITHIN_RANGE_REQUEST_FAILED:
    case Constants.GET_APPOINTMENTS_SEARCH_REQUEST_SENT:
    case Constants.GET_APPOINTMENTS_SEARCH_REQUEST_FAILED:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_PROVIDER_FILTERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        facets: [...action.payload.facets],
      };
    case Constants.GET_APPOINTMENTS_SEARCH_WITHIN_RANGE_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        dates: [...action.dates.dates],
      };
    case Constants.GET_APPOINTMENTS_SEARCH_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        appointments: [...action.payload.appointments],
      };
    case Constants.GET_EHR_ACTIVITY_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        ehrActivityCount: action.data.count,
      };
    case Constants.SET_SELECTED_FILTER_ID:
      return {
        ...state,
        selectedFilterId: action.filterId,
      };
    case Constants.RESET_STATE:
      return {
        ...state,
        selectedFilterId: null,
      };
    default:
      return state;
  }
};

const addNewPatientInitialState = {
  // isLoading: false,
  show: false,
  isLoading: false,
  isRequestInProgress: false,
};

export const addNewPatientReducer = (
  state = addNewPatientInitialState,
  action
) => {
  switch (action.type) {
    case Constants.ADD_NEW_PATIENT_REQUEST_SENT:
      return {
        show: true,
        isLoading: true,
        isRequestInProgress: true,
      };
    case Constants.ADD_NEW_PATIENT_REQUEST_SUCCEED:
      return {
        show: false,
        isLoading: false,
        isRequestInProgress: false,
      };
    case Constants.ADD_NEW_PATIENT_REQUEST_FAILED:
      return {
        show: true,
        isLoading: false,
        isRequestInProgress: false,
      };
    default:
      return state;
  }
};

import { getSurveys, clearSurveyResponse } from 'services/surveys';
import { showToaster } from 'common/toasterActions';
import { resetDataForDoubleAuthetication } from 'components/MultiAuthenticatedRequest/actions';
import {
  GET_SURVEYS_REQUEST_SENT,
  GET_SURVEYS_REQUEST_SUCCEED,
  GET_SURVEYS_REQUEST_FAILED,
  CLEAR_SURVEYS_REQUEST_SENT,
  CLEAR_SURVEYS_REQUEST_SUCCEED,
  CLEAR_SURVEYS_REQUEST_FAILED,
} from './constants';

export const getSurveysRequestSent = () => ({
  type: GET_SURVEYS_REQUEST_SENT,
});

export const getSurveysRequestSucceeded = data => ({
  type: GET_SURVEYS_REQUEST_SUCCEED,
  surveys: data,
});

export const getSurveysRequestFailed = () => ({
  type: GET_SURVEYS_REQUEST_FAILED,
});

export const clearSurveysRequestSent = () => ({
  type: CLEAR_SURVEYS_REQUEST_SENT,
});

export const clearSurveysRequestSucceeded = () => ({
  type: CLEAR_SURVEYS_REQUEST_SUCCEED,
});

export const clearSurveysRequestFailed = () => ({
  type: CLEAR_SURVEYS_REQUEST_FAILED,
});

// Redux Thunk actions:

export const getSurvey =
  (id, userProcedureId, procedurePhaseId) => dispatch => {
    dispatch(getSurveysRequestSent());
    getSurveys(id, userProcedureId, procedurePhaseId)
      .then(response => {
        dispatch(getSurveysRequestSucceeded(response.data));
      })
      .catch(() => {
        dispatch(getSurveysRequestFailed());
      });
  };

export const clearSurvey = data => dispatch => {
  dispatch(clearSurveysRequestSent());
  clearSurveyResponse(data)
    .then(response => {
      dispatch(getSurvey(data.patient_id, data.user_procedure_id));
      dispatch(showToaster({ message: response.data.message }));
      dispatch(resetDataForDoubleAuthetication());
    })
    .catch(() => {
      dispatch(clearSurveysRequestFailed());
    });
};

import {
  FETCH_PROCEDURES_REQUEST_SUCCEED,
  FETCH_ALL_PROVIDERS_SUCCEED,
  FETCH_DEPARTMENTS_SUCCEED,
  FETCH_LOCATIONS_SUCCEED,
  FETCH_CREATE_USER_DROPDOWN_OPTIONS,
  FETCH_TIMEZONES_SUCCEED,
  FETCH_PROVIDERS_NOT_PI_SUCCEED,
  FETCH_ADVOCATES_SUCCEED,
  GET_LOCATION_CODES_REQUEST_SUCCEED,
  GET_EVENT_KINDS_REQUEST_SUCCEED,
  GET_TASK_IDENTIFIERS_REQUEST_SUCCEED,
  FETCH_RESOURCE_CATEGORIES_SUCCEED,
  FETCH_RESOURCE_ACTIONS_SUCCEED,
} from './constants';

const adminInitialData = {
  types: [],
  userTypes: [],
  userRoleOptions: [],
  allProviders: [],
  departments: [],
  locations: [],
  timezones: [],
  providersNotPi: [],
  advocates: [],
  importanceLevels: [],
  locationCodes: [],
  procedures: [],
  eventKinds: [],
  reminderTypes: [],
  taskTypes: [],
  deidentifiedAccessUserRoles: [],
  taskIdentifiers: [],
  categories: [],
  actions: [],
};

// eslint-disable-next-line import/prefer-default-export
export const AdminReducer = (state = adminInitialData, action) => {
  switch (action.type) {
    case FETCH_CREATE_USER_DROPDOWN_OPTIONS:
      return {
        ...state,
        types: action.data.types,
        userTypes: action.data.userTypes,
        userRoleOptions: action.data.userRoleOptions,
        importanceLevels: action.data.importanceLevels,
        reminderTypes: action.data.reminderTypes,
        taskTypes: action.data.taskTypes,
        deidentifiedAccessUserRoles: action.data.deidentifiedAccessUserRoles,
      };
    case FETCH_PROCEDURES_REQUEST_SUCCEED: {
      return {
        ...state,
        procedures: action.data.procedures,
      };
    }

    case FETCH_ALL_PROVIDERS_SUCCEED: {
      return {
        ...state,
        allProviders: action.data.providers,
      };
    }

    case FETCH_DEPARTMENTS_SUCCEED: {
      return {
        ...state,
        departments: action.data.departments,
      };
    }

    case FETCH_LOCATIONS_SUCCEED: {
      return {
        ...state,
        locations: action.data.locations,
      };
    }

    case FETCH_TIMEZONES_SUCCEED: {
      return {
        ...state,
        timezones: action.data.timezones,
      };
    }

    case FETCH_PROVIDERS_NOT_PI_SUCCEED: {
      return {
        ...state,
        providersNotPi: action.data,
      };
    }

    case FETCH_ADVOCATES_SUCCEED: {
      return {
        ...state,
        advocates: action.data,
      };
    }

    case GET_LOCATION_CODES_REQUEST_SUCCEED:
      return {
        ...state,
        locationCodes: action.data,
      };

    case GET_EVENT_KINDS_REQUEST_SUCCEED:
      return {
        ...state,
        eventKinds: action.data,
      };

    case GET_TASK_IDENTIFIERS_REQUEST_SUCCEED:
      return {
        ...state,
        taskIdentifiers: action.data,
      };
    case FETCH_RESOURCE_CATEGORIES_SUCCEED:
      return {
        ...state,
        categories: action.data,
      };
    case FETCH_RESOURCE_ACTIONS_SUCCEED:
      return {
        ...state,
        actions: action.data,
      };

    default:
      return state;
  }
};

import {
  FETCH_USERS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
  UPDATE_CREATE_USER_ADMIN_VALUE_IN_STORE,
  SAVE_ADD_USER_FORM_DATA,
  RESET_ADD_USER_DATA,
  SET_SELECTED_USER_ID,
  UPDATE_USERS_DATA_AFTER_SORTING,
} from './constants';

const paginationIntialData = {
  total: 0,
  page: 1,
  total_pages: 1,
  last_page: false,
  first_page: true,
  prev_page: null,
  next_page: 2,
  start_number: 1,
  end_number: 10,
};

const userAdminFormData = {
  firstName: null,
  lastName: null,
  email: null,
  contactNumber: null,
  userDepartment: null,
  type: null,
  userType: null,
  userRole: null,
  userLocations: [],
  primaryPhysicians: [],
  primaryAdvocates: [],
  userAdminData: [],
  isSuperUser: false,
  attachPatients: false,
  shouldInvite: true,
  allowSsoLogin: false,
  selectedUserId: null,
  page: 1,
  filterNameOrEmail: null,
  step: 1,
  previousStep: 0,
};

const userAdminInitialData = {
  usersData: [],
  usersHeaders: [],
  pagination: paginationIntialData,
  ...userAdminFormData,
};

export const userAdminReducer = (state = userAdminInitialData, action) => {
  switch (action.type) {
    case FETCH_USERS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED:
      return {
        ...state,
        usersData: action.data.usersData,
        usersHeaders: action.data.usersHeaders,
        pagination: action.data.pagination,
      };

    case SAVE_ADD_USER_FORM_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }

    case UPDATE_CREATE_USER_ADMIN_VALUE_IN_STORE: {
      return {
        ...state,
        [action?.key]: action?.value,
      };
    }

    case SET_SELECTED_USER_ID:
      return {
        ...state,
        ...action.data.userData,
        userDepartment: action.data.userData.department,
        userRole: action.data.userData.userRole,
        selectedUserId: action.data.id,
      };

    case RESET_ADD_USER_DATA: {
      return {
        ...state,
        ...userAdminFormData,
        ...action.data,
      };
    }

    case UPDATE_USERS_DATA_AFTER_SORTING:
      return {
        ...state,
        usersData: action.data,
      };
    default:
      return state;
  }
};

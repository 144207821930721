import axios from './base';
import {
  POST_CREATE_NOTE,
  PUT_UPDATE_NOTE,
  PUT_UPDATE_COMMENTS,
} from './constants';

export const getPatientNotes = (patientId, userProcedureId) =>
  axios.get(
    `connect_patients/${patientId}/user_procedures/${userProcedureId}/notes`
  );

export const getPatientNoteDetails = params =>
  axios.get(`connect/notes/${params.id}`);

export const markAsReadPatientAllNotes = (patientId, userProcedureId) =>
  axios.get(
    `connect_patients/${patientId}/user_procedures/${userProcedureId}/mark_notes_as_read`
  );

export const postComments = params =>
  axios.post(`connect/notes/${params.id}/comments`, params);

export const postCreateNotes = params => axios.post(POST_CREATE_NOTE, params);

export const editCommunicationLogNotes = (noteId, params) =>
  axios.put(`${PUT_UPDATE_NOTE}${noteId}`, params);

export const editCommunicationLogComments = (commentId, params) =>
  axios.put(`${PUT_UPDATE_COMMENTS}${commentId}`, params);

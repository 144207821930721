import React from 'react';
import PropTypes from 'prop-types';
import { changeSearchParams } from 'utils/changeSearchParams';
import { STATE } from 'enums';
import './index.scss';
import MobileLoader from 'components/MobileComponents/MobileLoader';

class MobileErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    const searchParams = new URLSearchParams(window.location.search);

    this.state = {
      hasError: false,
      displayMode: searchParams.get('displayMode'),
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.log('Caught exception', error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      changeSearchParams(STATE.ERROR);
      return this.props.fallback;
    } else if (this.props.isLoading) {
      return (
        <>
          <MobileLoader show={this.props.isLoading} loaderWrapperClass={this.props.loaderWrapperClass} displayMode={this.state.displayMode}
            loaderImgStyle={this.props.loaderImgStyle}
            childrenContent={this.props.children}
          />
        </>
      );
    } else {
      return this.props.children;
    }
  }
}

MobileErrorBoundary.propTypes = {
  children: PropTypes.any,
  fallback: PropTypes.element,
};

export default MobileErrorBoundary;

/*
 *
 * PatientDetailsPage actions
 *
 */
import { resetDataForDoubleAuthetication } from 'components/MultiAuthenticatedRequest/actions';

import { showToaster } from 'common/toasterActions';
import {
  getPatientNoteDetails,
  postComments,
  editCommunicationLogNotes,
  editCommunicationLogComments,
} from 'services/connect';
import { getPatientInfo } from 'containers/PatientDetails/actions';
import {
  GET_NOTES_DETAILS_REQUEST_SENT,
  GET_NOTES_DETAILS_REQUEST_SUCCEED,
  GET_NOTES_DETAILS_REQUEST_FAILED,
  POST_RESPOND_TO_NOTES_REQUEST_SENT,
  POST_RESPOND_TO_NOTES_REQUEST_SUCCEED,
  POST_RESPOND_TO_NOTES_REQUEST_FAILED,
  PUT_EDIT_NOTES_REQUEST_SENT,
  PUT_EDIT_NOTES_REQUEST_SUCCEED,
  PUT_EDIT_NOTES_REQUEST_FAILED,
  PUT_EDIT_COMMENTS_REQUEST_SENT,
  PUT_EDIT_COMMENTS_REQUEST_SUCCEED,
  PUT_EDIT_COMMENTS_REQUEST_FAILED,
} from './constants';

import { setAddCommentOnNoteStarted } from 'common/actions';

export const getPatientNotesDetailsRequestSent = () => ({
  type: GET_NOTES_DETAILS_REQUEST_SENT,
});

export const getPatientNotesDetailsRequestSucceeded = data => ({
  type: GET_NOTES_DETAILS_REQUEST_SUCCEED,
  data,
});

export const getPatientNotesDetailsRequestFailed = () => ({
  type: GET_NOTES_DETAILS_REQUEST_FAILED,
});

export const postCommentsRequestSent = () => ({
  type: POST_RESPOND_TO_NOTES_REQUEST_SENT,
});

export const postCommentsRequestSucceeded = data => ({
  type: POST_RESPOND_TO_NOTES_REQUEST_SUCCEED,
  data,
});

export const postCommentsRequestFailed = () => ({
  type: POST_RESPOND_TO_NOTES_REQUEST_FAILED,
});

export const editNotesRequestSent = () => ({
  type: PUT_EDIT_NOTES_REQUEST_SENT,
});

export const editNotesRequestSucceeded = data => ({
  type: PUT_EDIT_NOTES_REQUEST_SUCCEED,
  data,
});

export const editNotesRequestFailed = () => ({
  type: PUT_EDIT_NOTES_REQUEST_FAILED,
});

export const editCommentsRequestSent = () => ({
  type: PUT_EDIT_COMMENTS_REQUEST_SENT,
});

export const editCommentsRequestSucceeded = data => ({
  type: PUT_EDIT_COMMENTS_REQUEST_SUCCEED,
  data,
});

export const editCommentsRequestFailed = () => ({
  type: PUT_EDIT_COMMENTS_REQUEST_FAILED,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getPatientNoteDetailsRequest = params => dispatch => {
  dispatch(getPatientNotesDetailsRequestSent());
  getPatientNoteDetails(params)
    .then(response => {
      dispatch(getPatientNotesDetailsRequestSucceeded(response.data.data));
      dispatch(getPatientInfo(params.patientId, params.procedureId));
    })
    .catch(() => {
      dispatch(getPatientNotesDetailsRequestFailed());
    });
};

export const postCommentsRequest = params => dispatch => {
  dispatch(postCommentsRequestSent());
  postComments(params)
    .then(response => {
      dispatch(postCommentsRequestSucceeded(response.data.data));
      dispatch(getPatientNoteDetailsRequest(params));
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: response.data.message }));
      dispatch(setAddCommentOnNoteStarted());
    })
    .catch(error => {
      dispatch(postCommentsRequestFailed());
      if (error.response.data.error)
        dispatch(
          showToaster({ message: error.response.data.error, type: 'error' })
        );
    });
};

export const doEditNotesRequest = (noteId, params) => dispatch => {
  dispatch(editNotesRequestSent());
  editCommunicationLogNotes(noteId, params)
    .then(response => {
      dispatch(editNotesRequestSucceeded(response.data.data));
      dispatch(getPatientNoteDetailsRequest(noteId));
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(error => {
      dispatch(editNotesRequestFailed());
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

export const doEditCommentsRequest = (commentId, params) => dispatch => {
  dispatch(editCommentsRequestSent());
  editCommunicationLogComments(commentId, params)
    .then(response => {
      dispatch(editCommentsRequestSucceeded(response.data.data));
      dispatch(
        getPatientNoteDetailsRequest(response.data.data.communicationNoteId)
      );
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(error => {
      dispatch(editCommentsRequestFailed());
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddEditTaskReminderModal from './Components/AddEditTaskReminderModal';
import { updateTaskAdminValue } from '../TaskAdmin/action';
import {
  resetAddTasksReminderData,
  updateTaskReminderAdminValue,
} from './action';

const TaskReminderAdmin = props => {
  const dispatch = useDispatch();

  const show = useSelector(store => store.taskReminderAdminReducer.show);

  const [selectedTaskReminderId, setSelectedTaskReminderId] = useState(null);

  return (
    <AddEditTaskReminderModal
      show={show}
      onHide={() => {
        dispatch(resetAddTasksReminderData());
        dispatch(updateTaskReminderAdminValue('show', false));
      }}
      updateSelectedTaskReminderId={id => setSelectedTaskReminderId(id)}
    />
  );
};

export default TaskReminderAdmin;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import validate, { clearErrorsForField } from 'common/validator';
import HelpBlock from 'components/HelpBlock';
import DateTime from 'react-datetime';
import TimePicker from 'components/TimePicker';
import moment from 'moment-timezone';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { createAppointment } from 'containers/Appointments/action';
import { resetAddVisitStarted } from 'common/actions';
import {
  NOTES_EDIT_CHARACTERS_LIMIT,
  DATE_FORMAT,
  APPOINTMENT_TIME_FORMAT,
} from 'constants';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import PropTypes from 'prop-types';
import { dateStringToDateWithDateFormat } from 'utils/dateUtils';
import { createAppointmentValidationConfig } from '../validators';

const CreateAppointment = props => {
  const dispatch = useDispatch();

  const {
    patientId,
    procedureId,
    compositeProcedure,
    showCreateAppointmentModal,
  } = props;
  const addVisitStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.addVisitStarted
  );

  const [errors, setErrors] = useState({});
  const [createAppointmentDate, setCreateAppointmentDate] = useState(null);
  const [createAppointmentTime, setCreateAppointmentTime] = useState(null);
  const [browserTimezone, setBrowserTimezone] = useState(null);
  const [additionalNote, setAdditionalNote] = useState('');
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [createAppointmentTitle, setCreateAppointmentTitle] = useState('');

  const getBrowserTimezone = inAbbreviation => {
    const timeZone = moment.tz.guess();
    const time = new Date();
    const timeZoneOffset = time.getTimezoneOffset();
    return inAbbreviation
      ? moment.tz.zone(timeZone).abbr(timeZoneOffset)
      : moment.tz.guess();
  };

  const getTrimmedAppointmentDate = date => {
    if (typeof date === 'object') return date;
    return date.trim();
  };

  const onCreateAppointmentModalClose = () => {
    const { setShowCreateAppointmentModal } = props;
    setErrors({});
    setCreateAppointmentTitle('');
    setCreateAppointmentDate(null);
    setCreateAppointmentTime(null);
    setAdditionalNote('');
    setShowCreateAppointmentModal();
  };

  useEffect(() => {
    if (addVisitStarted) {
      onCreateAppointmentModalClose();
      dispatch(resetAddVisitStarted());
    }
  }, [addVisitStarted]);

  useEffect(() => {
    setBrowserTimezone(getBrowserTimezone());
  }, []);

  const onCreateApptFormValidationFailure = errorsInForm => {
    setErrors(errorsInForm);
  };

  useEffect(() => {
    setCreateAppointmentTime(null);
  }, [isDateSelected]);

  const onCreateApptFormValidationSuccess = () => {
    const params = {
      patientId,
      user_procedure_id: procedureId,
      title: createAppointmentTitle.trim(),
      user_timezone: browserTimezone,
      additional_note: additionalNote,
      compositeProcedureId: compositeProcedure?.compositeProcedureId,
    };

    if (isDateSelected) {
      params.date_known = '1';
      params.date = createAppointmentDate.format('MMM DD, YYYY');
      params.date = dateStringToDateWithDateFormat(
        getTrimmedAppointmentDate(createAppointmentDate)
      );
      if (createAppointmentTime) {
        params.time_known = '1';
        params.time = createAppointmentTime.format(APPOINTMENT_TIME_FORMAT);
      }
    }

    params.secure_action_code = SecureCodes.CREATE_APPOINTMENT;
    params.needs_reason = true;

    dispatch(secureActionWithDoubleAuthentication(createAppointment, params));
  };

  const onCreateAppointment = e => {
    e.preventDefault();
    validate(
      createAppointmentValidationConfig,
      { createAppointmentTitle, createAppointmentDate },
      onCreateApptFormValidationFailure,
      onCreateApptFormValidationSuccess
    );
  };

  const renderCreateAppointmentModal = (
    <form className='visits-form' id='add-visit-form'>
      <div
        className={`form-group visits-name ${
          errors.createAppointmentTitle !== undefined ? 'has-error' : ''
        }`}
      >
        <label htmlFor='visit-name' className='form-label required'>
          VISIT NAME
        </label>
        <input
          name='visit-name'
          className='visits-form-inputs'
          value={createAppointmentTitle}
          placeholder=''
          required
          maxLength='250'
          onChange={e => {
            setCreateAppointmentTitle(e.target.value);
            setErrors(clearErrorsForField(errors, 'createAppointmentTitle'));
          }}
        />
        <HelpBlock value={errors.createAppointmentTitle} />
      </div>
      <div className='visits-form-row'>
        <div
          className={`form-group rowelement ${
            errors.createAppointmentDate !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='date'>Date</label>
          <DateTime
            timeFormat={false}
            className='date-picker-field float-left date-field form-control visits-form-inputs'
            closeOnSelect
            dateFormat={DATE_FORMAT}
            closeOnTab
            popperPlacement='top'
            popperModifiers={{
              flip: {
                behavior: ['top'], // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false, // tell it not to try to stay within the view (prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false, // turn off since needs preventOverflow to be enabled
              },
            }}
            inputProps={{ placeholder: 'Select Date', readOnly: false }}
            onChange={date => {
              setCreateAppointmentDate(
                date.toString().trim().length ? date : null
              );
              // eslint-disable-next-line no-unneeded-ternary
              setIsDateSelected(date?.toString().trim().length ? true : false);
              setErrors(clearErrorsForField(errors, 'createAppointmentDate'));
            }}
          />
          <HelpBlock value={errors.createAppointmentDate} />
        </div>

        <div
          className={`form-group rowelement ${
            errors.createAppointmentTime !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='time'>time</label>
          <TimePicker
            value={createAppointmentTime}
            className='visits-form-inputs'
            disabled={!isDateSelected}
            placeholder='Select Time'
            onChange={date => {
              setCreateAppointmentTime(date);
              setErrors(clearErrorsForField(errors, 'createAppointmentTime'));
            }}
          />
          <HelpBlock value={errors.createAppointmentTime} />
        </div>
      </div>

      <div className='visit-note-form'>
        <div className='form-group'>
          <label htmlFor='notes'>Notes</label>
          <textarea
            type='text'
            className='visit-note-input'
            placeholder='Type here ...'
            maxLength={NOTES_EDIT_CHARACTERS_LIMIT}
            onChange={e => {
              setAdditionalNote(e.target.value);
            }}
            name='note'
          />
        </div>
        <span className='character-limit'>
          {additionalNote ? additionalNote.length : 0} /{' '}
          {NOTES_EDIT_CHARACTERS_LIMIT}
        </span>
      </div>
    </form>
  );

  return (
    <FormModal
      show={showCreateAppointmentModal}
      onHide={onCreateAppointmentModalClose}
      title={{
        text: 'Add Visit',
      }}
      modalStyle={{
        overflowY: 'inherit',
      }}
      form={{
        form: renderCreateAppointmentModal,
      }}
      buttons={[
        <PrimaryButton
          type='submit'
          form='add-visit-form'
          style={{ width: '260px', margin: '32px 0px 16px 0px' }}
          onClick={e => onCreateAppointment(e)}
          key='primaryButton'
        >
          Save
        </PrimaryButton>,
      ]}
    />
  );
};

CreateAppointment.propTypes = {
  patientId: PropTypes.number.isRequired,
  procedureId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  compositeProcedure: PropTypes.object.isRequired,
  setShowCreateAppointmentModal: PropTypes.func.isRequired,
  showCreateAppointmentModal: PropTypes.bool.isRequired,
};

export default CreateAppointment;

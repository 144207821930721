import CheckBoxIcon from '@mui/icons-material/CheckBox';
import './styles.scss';

export default function PatientGoals(props) {
  const { patient_goals } = props;

  return (
    <div className='patient-goals-wrapper'>
      <p className='patient-goals-title title-font-regular'>Goals</p>
      {patient_goals?.length > 0 ? (
        patient_goals?.map((goal, index) => {
          return (
            <p className='patient-goal' key={index}>
              <CheckBoxIcon className='patient-goal-checkbox'/>
              <span className='text-font-small'>{goal}</span>
            </p>
          );
        })
      ) : (
        <p className='text-font-small'>No Goals Reported</p>
      )}
    </div>
  );
}

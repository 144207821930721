import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  dateTimeStringToMMSDateFormatWithTimzoneAbbreviation,
  isTBD,
} from 'utils/dateUtils';
import { DISPLAY_TO_BE_DECIDED_STRING, ACCESS_CONTROL_KEYS } from 'constants';
import { clearSurvey } from 'containers/Surveys/action';
import './surveyCard.scss';
import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import variables from 'assets/stylesheets/_variables.scss';
import strings from 'strings';
import { PropTypes } from 'prop-types';

const SurveyCard = props => {
  const { survey } = props;
  const params = useParams();
  const dispatch = useDispatch();

  const currentUser = useSelector(store => store.currentUser.attributes);

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];
  const appFeatures = currentUser && currentUser?.appFeatures;
  const canClear =
    appFeatures && appFeatures[ACCESS_CONTROL_KEYS.SURVEYS]?.canClear;

  const onClearSurvey = () => {
    const data = {
      survey_id: survey.id,
      patient_id: patientId,
      user_procedure_id: procedureId,
      secure_action_code: SecureCodes.CLEAR_SURVEY,
      needs_reason: true,
      re_auth_header: (
        <span
          style={{
            color: variables.colorBlack,
            fontSize: '16px',
            fontWeight: '400',
            paddingLeft: '32px',
          }}
        >
          {' '}
          Clear Survey Responses{' '}
        </span>
      ),
      re_auth_information: (
        <div style={{ textAlign: 'justify' }}>
          {strings.CLEAR_SURVEY_INFO}
          <ul>
            <li style={{ textAlign: 'justify' }}>
              The cleared survey responses will be <b>DELETED</b> and will no
              longer be viewable from the clinical dashboard
            </li>
            <li style={{ textAlign: 'justify' }}>
              Cleared survey responses <b>CANNOT BE RETRIEVED</b>
            </li>
            <li style={{ textAlign: 'justify' }}>
              The survey will be resent to the subject at the new appropriate
              time to be filled out and submitted as applicable
            </li>
          </ul>
        </div>
      ),
    };
    dispatch(secureActionWithDoubleAuthentication(clearSurvey, data));
  };

  return (
    <div>
      <div className='survey-header pos-rel'>
        <div className='survey-name'>
          <p>{survey.surveyName}</p>
          <TertiaryButton
            className='clear-survey-button'
            onClick={onClearSurvey}
            style={{
              width: 'fit-content',
              display: `${
                survey.canClearSurvey && canClear ? 'block' : 'none'
              }`,
            }}
          >
            Clear Survey
          </TertiaryButton>
        </div>
        <div>
          <span>Sent on: </span>
          <span className='inline-block'>
            {' '}
            {isTBD(survey.sentOn)
              ? DISPLAY_TO_BE_DECIDED_STRING
              : dateTimeStringToMMSDateFormatWithTimzoneAbbreviation(
                  survey.sentOn
                )}
          </span>
        </div>
        {survey.submittedOn && (
          <div className='completed'>
            <span className='on-date'>
              <span>Answered on:&nbsp;</span>
              <span className='inline-block'>
                {dateTimeStringToMMSDateFormatWithTimzoneAbbreviation(
                  survey.submittedOn
                )}
              </span>
            </span>
            &nbsp;
            <i className='icon icon-font-a-tick-unfilled' />
          </div>
        )}
      </div>
    </div>
  );
};

SurveyCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  survey: PropTypes.object.isRequired,
};
export default SurveyCard;

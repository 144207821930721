import {
  GET_CONVERSATIONS_REQUEST_FAILED,
  GET_CONVERSATIONS_REQUEST_SENT,
  GET_CONVERSATIONS_REQUEST_SUCCEED,
  SHOW_CONVERSATION_DETAILS,
  SHOW_CONVERSATIONS_LIST,
  START_CONVERSATION_REQUEST_FAILED,
  START_CONVERSATION_REQUEST_SENT,
  START_CONVERSATION_REQUEST_SUCCEED,
  RESOLVE_CONVERSATION_REQUEST_FAILED,
  RESOLVE_CONVERSATION_REQUEST_SENT,
  RESOLVE_CONVERSATION_REQUEST_SUCCEED,
  RESET_DATA_REQUEST,
} from 'containers/Conversations/constants';

const initialState = {
  isLoading: false,
  isListView: true,
  selectedConversationId: null,
  pagination: {
    end_number: null,
    first_page: null,
    last_page: null,
    page: null,
    prev_page: null,
    start_number: null,
    total: null,
    total_pages: null,
  },
  conversations: [],
};

function patientConversationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONVERSATIONS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CONVERSATIONS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        pagination: action.pagination,
        conversations: [...action.conversations],
      };
    case GET_CONVERSATIONS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SHOW_CONVERSATION_DETAILS:
      return {
        ...state,
        isListView: false,
        selectedConversationId: action.conversationId,
      };
    case SHOW_CONVERSATIONS_LIST:
      return {
        ...state,
        isLoading: false,
        selectedConversationId: null,
        isListView: true,
      };
    case START_CONVERSATION_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case START_CONVERSATION_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        conversations: [action.conversation].concat(state.conversations),
      };
    case START_CONVERSATION_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESOLVE_CONVERSATION_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case RESOLVE_CONVERSATION_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        conversations: state.conversations.map(conversation =>
          conversation.id === action.conversation.id
            ? action.conversation
            : conversation
        ),
      };
    case RESOLVE_CONVERSATION_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_DATA_REQUEST:
      return {
        ...state,
        isListView: true,
      };
    default:
      return state;
  }
}

export default patientConversationsReducer;

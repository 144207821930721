import React from 'react';
import { withRouter } from 'react-router';
import NavigationBar from 'containers/NavigationBar';
import './header.scss';

const Header = props => {
  const { history } = props;
  switch (history.location.pathname) {
    case '/not-found':
      return null;
    case '/logout':
      return null;
    case '/unauthorized-access':
      return null;
    case '/patient-signup':
      return null;
    default:
      return (
        <div className='outer-header'>
          <div className='header-container'>
            <NavigationBar />
          </div>
        </div>
      );
  }
};

export default withRouter(Header);

import React from 'react';
import InputMask from 'react-input-mask';
import { ZIP_INPUT_REGEX } from 'constants';

const ZipInput = props => {
  const formatChars = {
    d: ZIP_INPUT_REGEX,
  };
  return (
    <InputMask
      {...props}
      formatChars={formatChars}
      mask='dddddddddd'
      maskChar={null}
      type='tel'
    />
  );
};

export default ZipInput;

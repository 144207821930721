import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { saveAddTaskFormData } from '../action';

const AddTaskStepTwoForm = props => {
  const dispatch = useDispatch();

  const data = useSelector(store => store.taskAdminReducer);
  const adminData = useSelector(store => store.AdminReducer);

  const [errors, setErrors] = useState({});
  const [taskData, setTaskData] = useState({
    importanceLevel: null,
    department: null,
    procedure: null,
    eventKind: null,
    expiryEventKind: null,
  });

  useEffect(() => {
    const updatedData = {};
    Object.keys(taskData).forEach(key => (updatedData[key] = data[key]));
    setTaskData({ ...taskData, ...updatedData });
  }, [data]);

  const config = [
    {
      id: 1,
      input_type: 'single_select',
      name: 'importanceLevel',
      label: 'Importance Level',
      placeholder: 'Importance Level',
      value: taskData.importanceLevel,
      options: adminData.importanceLevels,
      validation: [
        { rule: 'isRequired', message: 'Importance Level is required' },
      ],
      visible: true,
    },
    {
      id: 2,
      input_type: 'single_select',
      name: 'department',
      label: 'Department',
      placeholder: 'Department',
      value: taskData.department,
      options: adminData.departments,
      validation: [{ rule: 'isRequired', message: 'Department is required' }],
      visible: true,
    },
    {
      id: 2,
      input_type: 'single_select',
      name: 'procedure',
      label: 'Procedure',
      placeholder: 'Procedure',
      value: taskData.procedure,
      options: adminData.procedures,
      validation: [{ rule: 'isRequired', message: 'Procedure is required' }],
      visible: true,
    },
    {
      id: 2,
      input_type: 'single_select',
      name: 'eventKind',
      label: 'Event Kind',
      placeholder: 'Event Kind',
      value: taskData.eventKind,
      options: adminData.eventKinds,
      validation: [{ rule: 'isRequired', message: 'Event Kind is required' }],
      visible: true,
    },
    {
      id: 2,
      input_type: 'single_select',
      name: 'expiryEventKind',
      label: 'Expiry Event Kind',
      placeholder: 'Expiry Event Kind',
      value: taskData.expiryEventKind,
      options: adminData.eventKinds,
      validation: [
        { rule: 'isRequired', message: 'Expiry Event Kind is required' },
      ],
      visible: true,
    },
  ];

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      taskData,
      errors => {
        setErrors(errors);
      },
      () => {
        dispatch(saveAddTaskFormData(taskData));
        props.onStepChange(props.step + 1);
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        setTaskData({ ...taskData, [key]: value });
      }}
      buttons={[
        <SecondaryButton
          style={{ width: '176px' }}
          onClick={() => props.onStepChange(props.step - 1)}
        >
          <span>Back</span>
        </SecondaryButton>,
        <PrimaryButton style={{ width: '176px' }} onClick={() => onSubmit()}>
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

export default AddTaskStepTwoForm;

/*
 *
 * Common Actions
 *
 */
import { v4 as uuidv4 } from 'uuid';
import { showToaster } from 'common/toasterActions';
import {
  getDocumentAuthorizedURL,
  uploadDocumentToS3,
} from 'services/documents';

import {
  getProcedureLocations,
  getProcedureProviders,
  getProcedureModifiers,
  updateProcedureModifiers,
} from 'services/dashboard';
import {
  getDepartmentProcedures,
  saveFilterSelection,
  updateFilterSelection,
  deleteCustomFilter,
  getDepartmentProviders,
  getDepartmentOnlyProviders,
  addNewPatientProcedure,
  getLocationTimezone,
  getDepartmentSupportedKeys,
  getDepartmentOnlyGeneralCardiologists,
  isEmailUnique,
  isPhoneUnique,
  getSearchCategoryList,
  getProcedureMasterProviders,
  getCounterSigne,
  getStatesList,
  fetchCohortsList,
} from 'services/common';
import {
  getVerificationCode,
  verifyCode,
} from 'services/patient/authentication';
import { getPatientInfo } from 'containers/PatientDetails/actions';
import * as Constants from './constants';
import { getUserInfo } from './authThunkActions';

export const getDocAuthorizedUrlRequestSent = () => ({
  type: Constants.GET_DOC_AUTHORIZED_URL_REQUEST_SENT,
});

export const getDocAuthorizedUrlRequestSucceeded = data => ({
  type: Constants.GET_DOC_AUTHORIZED_URL_REQUEST_SUCCEED,
  data,
});

export const getDocAuthorizedUrlRequestFailed = () => ({
  type: Constants.GET_DOC_AUTHORIZED_URL_REQUEST_FAILED,
});

export const uploadDocumentRequestSent = () => ({
  type: Constants.DOCUMENT_UPLOAD_REQUEST_SENT,
});

export const uploadDocumentRequestSucceeded = data => ({
  type: Constants.DOCUMENT_UPLOAD_REQUEST_SUCCEED,
  data,
});

export const uploadDocumentRequestFailed = () => ({
  type: Constants.DOCUMENT_UPLOAD_REQUEST_FAILED,
});

export const uploadDocumentClearState = () => ({
  type: Constants.DOCUMENT_UPLOAD_CLEAR_STATE,
});

export const uploadDocumentRemoveDocument = id => ({
  type: Constants.DOCUMENT_UPLOAD_REMOVE_DOCUMENT,
  id,
});

export const getProcedurelocationsRequestSent = () => ({
  type: Constants.GET_PROCEDURE_LOCATIONS_REQUEST_SENT,
});

export const getProcedurelocationsRequestSucceeded = data => ({
  type: Constants.GET_PROCEDURE_LOCATIONS_REQUEST_SUCCEED,
  data,
});

export const getProcedurelocationsRequestFailed = () => ({
  type: Constants.GET_PROCEDURE_LOCATIONS_REQUEST_FAILED,
});

export const getProcedureProvidersRequestSent = () => ({
  type: Constants.GET_PROCEDURE_PROVIDERS_REQUEST_SENT,
});

export const getProcedureProvidersRequestSucceeded = data => ({
  type: Constants.GET_PROCEDURE_PROVIDERS_REQUEST_SUCCEED,
  data,
});

export const getProcedureMasterProvidersRequestFailed = () => ({
  type: Constants.GET_PROCEDURE_MASTER_PROVIDERS_REQUEST_FAILED,
});

export const getProcedureMasterProvidersRequestSent = () => ({
  type: Constants.GET_PROCEDURE_MASTER_PROVIDERS_REQUEST_SENT,
});

export const getProcedureMasterProvidersRequestSucceeded = data => ({
  type: Constants.GET_PROCEDURE_MASTER_PROVIDERS_REQUEST_SUCCEED,
  data,
});

export const getCounterSigneRequestFailed = () => ({
  type: Constants.GET_COUNTER_SIGNE_REQUEST_FAILED,
});

export const getCounterSigneRequestSent = () => ({
  type: Constants.GET_COUNTER_SIGNE_REQUEST_SENT,
});

export const getCounterSigneRequestSucceeded = data => ({
  type: Constants.GET_COUNTER_SIGNE_REQUEST_SUCCEED,
  data,
});

export const getProcedureProvidersRequestFailed = () => ({
  type: Constants.GET_PROCEDURE_PROVIDERS_REQUEST_FAILED,
});

export const getProcedureModifiersRequestSent = () => ({
  type: Constants.GET_PROCEDURE_MODIFIERS_REQUEST_SENT,
});

export const getProcedureModifiersRequestSucceeded = data => ({
  type: Constants.GET_PROCEDURE_MODIFIERS_REQUEST_SUCCEED,
  data,
});

export const getProcedureModifiersRequestFailed = () => ({
  type: Constants.GET_PROCEDURE_MODIFIERS_REQUEST_FAILED,
});

export const updateProcedureModifiersRequestSent = () => ({
  type: Constants.UPDATE_PROCEDURE_MODIFIERS_REQUEST_SENT,
});

export const updateProcedureModifiersRequestSucceeded = data => ({
  type: Constants.UPDATE_PROCEDURE_MODIFIERS_REQUEST_SUCCEED,
  data,
});

export const updateProcedureModifiersRequestFailed = () => ({
  type: Constants.UPDATE_PROCEDURE_MODIFIERS_REQUEST_FAILED,
});

export const getDepartmentProcedureRequestSent = () => ({
  type: Constants.GET_DEPARTMENT_PROCEDURES_REQUEST_SENT,
});

export const getDepartmentProcedureRequestSucceeded = data => ({
  type: Constants.GET_DEPARTMENT_PROCEDURES_REQUEST_SUCCEED,
  data,
});

export const getDepartmentProcedureRequestFailed = () => ({
  type: Constants.GET_DEPARTMENT_PROCEDURES_REQUEST_FAILED,
});

export const getDepartmentProvidersRequestSent = () => ({
  type: Constants.GET_DEPARTMENT_PROVIDERS_REQUEST_SENT,
});

export const getDepartmentProvidersRequestSucceeded = data => ({
  type: Constants.GET_DEPARTMENT_PROVIDERS_REQUEST_SUCCEED,
  data,
});

export const getDepartmentProvidersRequestFailed = () => ({
  type: Constants.GET_DEPARTMENT_PROVIDERS_REQUEST_FAILED,
});

export const getDepartmentOnlyProvidersRequestSent = () => ({
  type: Constants.GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SENT,
});

export const getDepartmentOnlyProvidersRequestSucceeded = data => ({
  type: Constants.GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SUCCEED,
  data,
});

export const getDepartmentOnlyProvidersRequestFailed = () => ({
  type: Constants.GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_FAILED,
});

export const addNewProcedureRequestSent = () => ({
  type: Constants.ADD_NEW_PROCEDURE_REQUEST_SENT,
});

export const addNewProcedureRequestSucceeded = () => ({
  type: Constants.ADD_NEW_PROCEDURE_REQUEST_SUCCEED,
});

export const addNewProceduRequestFailed = () => ({
  type: Constants.ADD_NEW_PROCEDURE_REQUEST_FAILED,
});

export const getDepartmentSupportedKeysRequestFailed = () => ({
  type: Constants.GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_FAILED,
});

export const getDepartmentSupportedKeysRequestSent = () => ({
  type: Constants.GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SENT,
});

export const getDepartmentSupportedKeysRequestSucceeded = data => ({
  type: Constants.GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SUCCEED,
  data,
});

export const getDepartmentOnlyGeneralCardiologistsRequestSent = () => ({
  type: Constants.GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SENT,
});

export const getDepartmentOnlyGeneralCardiologistsRequestSucceeded = data => ({
  type: Constants.GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SUCCEED,
  data,
});

export const getDepartmentOnlyGeneralCardiologistsRequestFailed = () => ({
  type: Constants.GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_FAILED,
});
export const getSearchCategoryListRequestFailed = () => ({
  type: Constants.GET_SEARCH_CATEGORY_LIST_REQUEST_FAILED,
});

export const getSearchCategoryListRequestSent = () => ({
  type: Constants.GET_SEARCH_CATEGORY_LIST_REQUEST_SENT,
});

export const getSearchCategoryListRequestSucceeded = data => ({
  type: Constants.GET_SEARCH_CATEGORY_LIST_REQUEST_SUCCEED,
  data,
});

export const saveSubjectInformation = data => ({
  type: Constants.SAVE_SUBJECT_INFORMATION,
  data,
});

export const showLoaderForAddPatient = () => ({
  type: Constants.SHOW_LOADER_FOR_ADD_PATIENT,
});

export const stopLoaderForAddPatient = () => ({
  type: Constants.STOP_LOADER_FOR_ADD_PATIENT,
});

export const clearSubjectInformation = () => ({
  type: Constants.CLEAR_SUBJECT_INFORMATION,
});

export const saveStudyInformation = data => ({
  type: Constants.SAVE_STUDY_INFORMATION,
  data,
});

export const clearStudyInformation = () => ({
  type: Constants.CLEAR_STUDY_INFORMATION,
});

export const setProfilePhotoUpdateStarted = () => ({
  type: Constants.SET_PROFILE_PHOTO_UPLOAD_STARTED,
});

export const resetProfilePhotoUpdateStarted = () => ({
  type: Constants.RESET_PROFILE_PHOTO_UPLOAD_STARTED,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const uploadDocument =
  (
    url,
    fields,
    file,
    mediaResource,
    onUploadProgress = null,
    isExtra = false
  ) =>
  dispatch => {
    dispatch(uploadDocumentRequestSent());
    uploadDocumentToS3(url, fields, file, onUploadProgress)
      .then(() => {
        const data = {
          url: fields.key,
          mediaResource,
          isExtra,
        };
        dispatch(uploadDocumentRequestSucceeded(data));
        dispatch(setProfilePhotoUpdateStarted());
      })
      .catch(() => {
        dispatch(uploadDocumentRequestFailed());
      });
  };

export const uploadDocumentWithAuthUrl =
  (
    fileName,
    fileSize,
    fromType,
    mediaType,
    duration,
    file,
    useOriginalHeaders,
    onUploadProgress = null,
    isExtra = false
  ) =>
  dispatch => {
    dispatch(getDocAuthorizedUrlRequestSent());
    getDocumentAuthorizedURL(
      fileName,
      fileSize,
      fromType,
      mediaType,
      duration,
      useOriginalHeaders
    )
      .then(response => {
        const { url, fields, mediaResource } = response.data;
        if (url) {
          dispatch(
            uploadDocument(
              url,
              fields,
              file,
              mediaResource,
              onUploadProgress,
              isExtra
            )
          );
        }
        dispatch(getDocAuthorizedUrlRequestSucceeded(response.data));
      })
      .catch(() => {
        dispatch(getDocAuthorizedUrlRequestFailed());
      });
  };

export const clearDocumentStates = () => {
  dispatchEvent(uploadDocumentClearState());
};

export const removeDocument = id => {
  dispatchEvent(uploadDocumentRemoveDocument(id));
};

export const getProcedureLocationsRequest = params => dispatch => {
  dispatch(getProcedurelocationsRequestSent());
  getProcedureLocations(params)
    .then(response => {
      dispatch(getProcedurelocationsRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getProcedurelocationsRequestFailed());
    });
};

export const getProcedureProvidersRequest =
  (params, userType = 'Provider') =>
  dispatch => {
    dispatch(getProcedureProvidersRequestSent());
    getProcedureProviders(params, userType)
      .then(response => {
        dispatch(getProcedureProvidersRequestSucceeded(response.data));
      })
      .catch(() => {
        dispatch(getProcedureProvidersRequestFailed());
      });
  };

export const getProcedureMasterProvidersRequest = params => dispatch => {
  dispatch(getProcedureMasterProvidersRequestSent());
  getProcedureMasterProviders(params)
    .then(response => {
      dispatch(getProcedureMasterProvidersRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getProcedureMasterProvidersRequestFailed());
    });
};

export const getCounterSigneRequest = params => dispatch => {
  dispatch(getCounterSigneRequestSent());
  getCounterSigne(params)
    .then(response => {
      dispatch(getCounterSigneRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getCounterSigneRequestFailed());
    });
};

export const getProcedureModifiersRequest = params => dispatch => {
  dispatch(getProcedureModifiersRequestSent());
  getProcedureModifiers(params)
    .then(response => {
      dispatch(getProcedureModifiersRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getProcedureModifiersRequestFailed());
    });
};

export const updateProcedureModifiersRequest = params => dispatch => {
  dispatch(updateProcedureModifiersRequestSent());
  updateProcedureModifiers(params)
    .then(() => {
      dispatch(updateProcedureModifiersRequestSucceeded());
      dispatch(getPatientInfo(params.patient_id, params.user_procedure_id));
      dispatch(showToaster({ message: 'Procedure Updated Successfully' }));
    })
    .catch(() => {
      dispatch(updateProcedureModifiersRequestFailed());
    });
};

export const getDepartmentProceduresRequest = () => dispatch => {
  dispatch(getDepartmentProcedureRequestSent());
  getDepartmentProcedures()
    .then(response => {
      dispatch(getDepartmentProcedureRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getDepartmentProcedureRequestFailed());
    });
};

export const addNewPatientProcedureRequest = params => dispatch => {
  dispatch(addNewProcedureRequestSent());
  addNewPatientProcedure(params)
    .then(() => {
      dispatch(addNewProcedureRequestSucceeded());
      dispatch(showToaster({ message: 'Procedure added successfully.' }));
    })
    .catch(() => {
      dispatch(addNewProceduRequestFailed());
      // dispatch(showToaster({ message: error.response.data.error, type: 'error' }));
    });
};

export const getDepartmentProvidersRequest = () => dispatch => {
  dispatch(getDepartmentProvidersRequestSent());
  getDepartmentProviders()
    .then(response => {
      dispatch(getDepartmentProvidersRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getDepartmentProvidersRequestFailed());
    });
};

export const getDepartmentOnlyProvidersRequest = () => dispatch => {
  dispatch(getDepartmentOnlyProvidersRequestSent());
  getDepartmentOnlyProviders()
    .then(response => {
      dispatch(getDepartmentOnlyProvidersRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getDepartmentOnlyProvidersRequestFailed());
    });
};

export const getDepartmentOnlyGeneralCardiologistsRequest = () => dispatch => {
  dispatch(getDepartmentOnlyGeneralCardiologistsRequestSent());
  getDepartmentOnlyGeneralCardiologists()
    .then(response => {
      dispatch(
        getDepartmentOnlyGeneralCardiologistsRequestSucceeded(response.data)
      );
    })
    .catch(() => {
      dispatch(getDepartmentOnlyGeneralCardiologistsRequestFailed());
    });
};

// Save Search Filter
export const saveFilterSelectionRequestSent = () => ({
  type: Constants.SAVE_SEARCH_FILTERS_SENT,
});

export const saveFilterSelectionRequestSucceeded = data => ({
  type: Constants.SAVE_SEARCH_FILTERS_SUCCEED,
  data,
});

export const saveFilterSelectionRequestFailed = data => ({
  type: Constants.SAVE_SEARCH_FILTERS_FAILED,
  data,
});

export const saveSearchFilterSelectionRequest = params => dispatch => {
  dispatch(saveFilterSelectionRequestSent());
  saveFilterSelection(params)
    .then(response => {
      dispatch(saveFilterSelectionRequestSucceeded(response.data));
      dispatch(showToaster({ message: 'Custom filter created successfully.' }));
    })
    .catch(error => {
      dispatch(saveFilterSelectionRequestFailed(error.response.data));
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

// UPDATE Search Filter
export const updateFilterSelectionRequestSent = () => ({
  type: Constants.UPDATE_SEARCH_FILTERS_SENT,
});

export const updateFilterSelectionRequestSucceeded = data => ({
  type: Constants.UPDATE_SEARCH_FILTERS_SUCCEED,
  data,
});

export const updateFilterSelectionRequestFailed = data => ({
  type: Constants.UPDATE_SEARCH_FILTERS_FAILED,
  data,
});

export const updateSearchFilterSelectionRequest = params => dispatch => {
  dispatch(updateFilterSelectionRequestSent());
  updateFilterSelection(params)
    .then(response => {
      dispatch(updateFilterSelectionRequestSucceeded(response.data));
      dispatch(showToaster({ message: 'Custom filter updated successfully.' }));
    })
    .catch(error => {
      dispatch(updateFilterSelectionRequestFailed(error.response.data));
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

// UPDATE Search Filter
export const deleteCustomFilterRequestSent = () => ({
  type: Constants.DELETE_SEARCH_FILTERS_SENT,
});

export const deleteCustomFilterRequestSucceeded = data => ({
  type: Constants.DELETE_SEARCH_FILTERS_SUCCEED,
  data,
});

export const deleteCustomFilterRequestFailed = data => ({
  type: Constants.DELETE_SEARCH_FILTERS_FAILED,
  data,
});

export const clearAddProcedureStatesRequest = () => ({
  type: Constants.CLEAR_ADD_PROCEDURE_STATES_REQUEST,
});

export const deleteCustomFilterRequest = id => dispatch => {
  dispatch(deleteCustomFilterRequestSent());
  deleteCustomFilter(id)
    .then(response => {
      dispatch(deleteCustomFilterRequestSucceeded(response.data));
      dispatch(showToaster({ message: 'Custom filter deleted successfully.' }));
    })
    .catch(error => {
      dispatch(deleteCustomFilterRequestFailed(error.response.data));
    });
};

export const clearAddProcedureStates = () => dispatch => {
  dispatch(clearAddProcedureStatesRequest());
};

// Mobile Number Verification
export const sendVerificationCodeRequestSent = () => ({
  type: Constants.GET_VERIFICATION_CODE_REQUEST_SENT,
});

export const sendVerificationCodeSucceeded = () => ({
  type: Constants.GET_VERIFICATION_CODE_REQUEST_SUCCEED,
});

export const sendVerificationCodeRequestFailed = data => ({
  type: Constants.GET_VERIFICATION_CODE_REQUEST_FAILED,
  data,
});

export const clearVerificationCode = () => ({
  type: Constants.CLEAR_VERIFICATION_CODE,
});

export const getStatesListRequestSent = () => ({
  type: Constants.GET_STATES_LIST_REQUEST_SENT,
});

export const getStatesListRequestSucceed = data => ({
  type: Constants.GET_STATES_LIST_REQUEST_SUCCEED,
  data,
});

export const getStatesListRequestFailed = () => ({
  type: Constants.GET_STATES_LIST_REQUEST_FAILED,
});

// Geo Code and Timezone

export const getLocationRequestSent = () => ({
  type: Constants.GET_LOCATION_REQUEST_SENT,
});

export const getLocationRequestSucceeded = data => ({
  type: Constants.GET_LOCATION_REQUEST_SUCCEED,
  data,
});

export const getLocationRequestFailed = data => ({
  type: Constants.GET_LOCATION_REQUEST_FAILED,
  data,
});

export const validateEmailUniqunessRequestSent = () => ({
  type: Constants.VALIDATE_EMAIL_UNIQUENESS_REQUEST_SENT,
});

export const validateEmailUniqunessRequestSucceeded = data => ({
  type: Constants.VALIDATE_EMAIL_UNIQUENESS_REQUEST_SUCCEED,
  data,
});

export const validateEmailUniqunessRequestFailed = data => ({
  type: Constants.VALIDATE_EMAIL_UNIQUENESS_REQUEST_FAILED,
  data,
});

export const validatePhoneUniqunessRequestSent = () => ({
  type: Constants.VALIDATE_PHONE_UNIQUENESS_REQUEST_SENT,
});

export const validatePhoneUniqunessRequestSucceeded = data => ({
  type: Constants.VALIDATE_PHONE_UNIQUENESS_REQUEST_SUCCEED,
  data,
});

export const validatePhoneUniqunessRequestFailed = data => ({
  type: Constants.VALIDATE_PHONE_UNIQUENESS_REQUEST_FAILED,
  data,
});

export const setUserProfileNameUpdated = () => ({
  type: Constants.SET_USER_PROFILE_NAME_UPDATED,
});

export const resetUserProfileNameUpdated = () => ({
  type: Constants.RESET_USER_PROFILE_NAME_UPDATED,
});

export const setUserProfileEmailUpdated = () => ({
  type: Constants.SET_USER_PROFILE_EMAIL_UPDATED,
});

export const resetUserProfileEmailUpdated = () => ({
  type: Constants.RESET_USER_PROFILE_EMAIL_UPDATED,
});

export const setUserProfileContactNumberUpdated = () => ({
  type: Constants.SET_USER_PROFILE_CONTACT_NUMBER_UPDATED,
});

export const resetUserProfileContactNumberUpdated = () => ({
  type: Constants.RESET_USER_PROFILE_CONTACT_NUMBER_UPDATED,
});

export const setUserProfileVerifyOtpUpdated = () => ({
  type: Constants.SET_USER_PROFILE_VERIFY_OTP_UPDATED,
});

export const resetUserProfileVerifyOtpUpdated = () => ({
  type: Constants.RESET_USER_PROFILE_VERIFY_OTP_UPDATED,
});

export const setConfirmPasswordDone = () => ({
  type: Constants.SET_CONFIRM_PASSWORD_DONE,
});

export const resetConfirmPasswordDone = () => ({
  type: Constants.RESET_CONFIRM_PASSWORD_DONE,
});

export const setConversationStartDone = () => ({
  type: Constants.SET_CONVERSATION_START_DONE,
});

export const resetConversationStartDone = () => ({
  type: Constants.RESET_CONVERSATION_START_DONE,
});

export const setEditPatientStarted = () => ({
  type: Constants.SET_EDIT_PATIENT_STARTED,
});

export const resetEditPatientStarted = () => ({
  type: Constants.RESET_EDIT_PATIENT_STARTED,
});

export const setAddVisitStarted = () => ({
  type: Constants.SET_ADD_VISIT_STARTED,
});

export const resetAddVisitStarted = () => ({
  type: Constants.RESET_ADD_VISIT_STARTED,
});

export const setEditVisitStarted = () => ({
  type: Constants.SET_EDIT_VISIT_STARTED,
});

export const resetEditVisitStarted = () => ({
  type: Constants.RESET_EDIT_VISIT_STARTED,
});

export const setEditVisitNoteStarted = () => ({
  type: Constants.SET_EDIT_VISIT_NOTE_STARTED,
});

export const resetEditVisitNoteStarted = () => ({
  type: Constants.RESET_EDIT_VISIT_NOTE_STARTED,
});

export const setEditNotesStarted = () => ({
  type: Constants.SET_EDIT_NOTE_STARTED,
});

export const resetEditNotesStarted = () => ({
  type: Constants.RESET_EDIT_NOTE_STARTED,
});

export const setPhaseChangeStarted = () => ({
  type: Constants.SET_PHASE_CHANGE_STARTED,
});

export const resetPhaseChangeStarted = () => ({
  type: Constants.RESET_PHASE_CHANGE_STARTED,
});

export const setExitStudyStarted = () => ({
  type: Constants.SET_EXIT_STUDY_STARTED,
});

export const resetExitStudyStarted = () => ({
  type: Constants.RESET_EXIT_STUDY_STARTED,
});

export const setResolveConversationStarted = () => ({
  type: Constants.SET_RESOLVE_CONVERSATION_STARTED,
});

export const resetResolveConversationStarted = () => ({
  type: Constants.RESET_RESOLVE_CONVERSATION_STARTED,
});
export const setChangePasswordStarted = () => ({
  type: Constants.SET_CHANGE_PASSWORD_STARTED,
});

export const resetChangePasswordStarted = () => ({
  type: Constants.RESET_CHANGE_PASSWORD_STARTED,
});

export const setFetchTermsAndConditionsStarted = () => ({
  type: Constants.SET_FETCH_TERMS_AND_CONDITIONS_STARTED,
});
export const setUploadPaperConsentStarted = () => ({
  type: Constants.SET_UPLOAD_PAPER_CONSENT_STARTED,
});

export const resetFetchTermsAndConditionsStarted = () => ({
  type: Constants.RESET_FETCH_TERMS_AND_CONDITIONS_STARTED,
});
export const resetUploadConsentStarted = () => ({
  type: Constants.RESET_UPLOAD_PAPER_CONSENT_STARTED,
});

export const setDeleteAppointmentStarted = () => ({
  type: Constants.SET_DELETE_APPOINTMENT_STARTED,
});

export const resetDeleteAppointmentStarted = () => ({
  type: Constants.RESET_DELETE_APPOINTMENT_STARTED,
});

export const setCreatePatientStarted = () => ({
  type: Constants.SET_CREATE_PATIENT_STARTED,
});

export const resetCreatePatientStarted = () => ({
  type: Constants.RESET_CREATE_PATIENT_STARTED,
});

export const setResendInviteStarted = () => ({
  type: Constants.SET_RESEND_INVITE_STARTED,
});

export const resetResendInviteStarted = () => ({
  type: Constants.RESET_RESEND_INVITE_STARTED,
});

export const setSendMessageStarted = () => ({
  type: Constants.SET_SEND_MESSAGE_STARTED,
});

export const resetSendMessageStarted = () => ({
  type: Constants.RESET_SEND_MESSAGE_STARTED,
});

export const setAddCommentOnNoteStarted = () => ({
  type: Constants.SET_ADD_COMMENT_ON_NOTE_STARTED,
});

export const resetAddCommentOnNoteStarted = () => ({
  type: Constants.RESET_ADD_COMMENT_ON_NOTE_STARTED,
});

export const clearLocationReducerData = () => ({
  type: Constants.CLEAR_DATA,
});

export const increaseAPICallsInProgressCount = () => ({
  type: Constants.INCREASE_API_CALLS_IN_PROGRESS_COUNTER,
});

export const decreaseAPICallsInProgressCount = () => ({
  type: Constants.DECREASE_API_CALLS_IN_PROGRESS_COUNTER,
});

export const clearVerifyOTP = () => ({
  type: Constants.CLEAR_VERIFY_OTP,
});

export const sendVerificationCodeAction = params => dispatch => {
  dispatch(sendVerificationCodeRequestSent());
  dispatch(clearVerifyOTP());
  getVerificationCode(params)
    .then(() => {
      dispatch(sendVerificationCodeSucceeded());
      dispatch(setUserProfileContactNumberUpdated());
    })
    .catch(error => {
      dispatch(sendVerificationCodeRequestFailed(error.response.data));
    });
};

export const verifyOTPRequestSent = () => ({
  type: Constants.VERIFY_OTP_REQUEST_SENT,
});

export const verifyOTPSucceeded = () => ({
  type: Constants.VERIFY_OTP_REQUEST_SUCCEED,
});

export const verifyOTPRequestFailed = data => ({
  type: Constants.VERIFY_OTP_REQUEST_FAILED,
  data,
});

export const fetchCohortsListRequestSucceeded = data => ({
  type: Constants.FETCH_COHORTS_REQUEST_SUCCEED,
  data,
});

export const addRequstAndCallBackToStore = request => ({
  type: Constants.ADD_REQUEST_AND_CALLBACK_IN_STORE,
  data: {
    request,
  },
});

export const removeRequstAndCallBackFromStore = id => ({
  type: Constants.REMOVE_REQUEST_AND_CALLBACK_IN_STORE,
  data: {
    id,
  },
});

export const verifyOTPAction = params => dispatch => {
  dispatch(verifyOTPRequestSent());
  verifyCode(params)
    .then(() => {
      dispatch(verifyOTPSucceeded());
      dispatch(getUserInfo());
      dispatch(setUserProfileVerifyOtpUpdated());
    })
    .catch(error => {
      dispatch(verifyOTPRequestFailed(error.response.data));
    });
};

export const getTimezoneLocationRequest = params => dispatch => {
  let resp = null;
  dispatch(getLocationRequestSent());
  getLocationTimezone({ zipcode: params.postal_code })
    .then(response => {
      resp = {};
      const { location, error } = response.data;
      if (error) {
        dispatch(getLocationRequestSucceeded(resp));
        return;
      }
      if (location) {
        resp.name = location.name;
        // eslint-disable-next-line prefer-destructuring
        resp.title = location.title;
        resp.latitude = location.lat;
        resp.longitude = location.lng;
        resp.timezone = location.timezone;
      }
      dispatch(getLocationRequestSucceeded(resp));
    })
    .catch(error => {
      dispatch(getLocationRequestFailed(error.response.data));
    });
};

export const getDepartmentSupportedKeysRequest = () => dispatch => {
  dispatch(getDepartmentSupportedKeysRequestSent());
  getDepartmentSupportedKeys()
    .then(response => {
      dispatch(getDepartmentSupportedKeysRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getDepartmentSupportedKeysRequestFailed());
    });
};
export const validateEmailUniquness = params => dispatch => {
  dispatch(validateEmailUniqunessRequestSent());
  return isEmailUnique(params);
};

export const validatePhoneUniquness = params => dispatch => {
  dispatch(validatePhoneUniqunessRequestSent());
  return isPhoneUnique(params);
};

export const getSearchCategoryListRequest = () => dispatch => {
  dispatch(getSearchCategoryListRequestSent());
  getSearchCategoryList()
    .then(response => {
      dispatch(getSearchCategoryListRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getSearchCategoryListRequestFailed());
    });
};

export const getStatesListForDropdown = () => dispatch => {
  dispatch(getStatesListRequestSent());
  getStatesList()
    .then(response => {
      dispatch(getStatesListRequestSucceed(response.data));
    })
    .catch(() => {
      dispatch(getStatesListRequestFailed());
    });
};

export const dispatchActionWithCallBack =
  (action, data, callBack) => dispatch => {
    const uniqueRequestId = uuidv4();
    dispatch(addRequstAndCallBackToStore({ id: uniqueRequestId, callBack }));
    // eslint-disable-next-line no-param-reassign
    data.params.uniqueRequestId = uniqueRequestId;
    dispatch(action(...Object.keys(data).map(key => data[key])));
  };

export const fetchCohortsListRequest = params => dispatch => {
  fetchCohortsList(params)
    .then(response => {
      console.log(response);
      dispatch(fetchCohortsListRequestSucceeded(response.data));
    })
    .catch(() => {});
};

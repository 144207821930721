import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Appointments from 'containers/Appointments';
import Tasks from 'containers/Tasks';
import Surveys from 'containers/Surveys';
import Conversations from 'containers/Conversations';
import Notes from 'containers/Notes';
import Consent from 'containers/Consent';
import Outcomes from 'containers/Outcomes';
import {
  DISABLE_PATIENT_MESSAGES,
  ACCESS_CONTROL_KEYS,
} from 'containers/../constants';
import Diaries from 'containers/Ediary';
import AuditTrail from 'containers/AuditTrail';

const patientDetailsConfig = [
  {
    id: 1,
    name: 'visits',
    href: '#visits',
    keyForAccessControl: ACCESS_CONTROL_KEYS.VISITS,
    disabled: false,
    title: {
      icon: (
        <span
          className='icon-font-a-calendar icon primary-text-color'
          aria-hidden='true'
        />
      ),
      text: 'Visits',
    },
    component: Appointments,
  },
  {
    id: 2,
    name: 'tasks',
    href: '#tasks',
    keyForAccessControl: ACCESS_CONTROL_KEYS.TASKS,
    disabled: false,
    title: {
      icon: (
        <span
          className='icon icon-tasks primary-text-color'
          aria-hidden='true'
        />
      ),
      text: 'Tasks',
      keyForMissedData: 'missedTasksCount',
      count: 0,
    },

    component: Tasks,
  },
  {
    id: 3,
    name: 'surveys',
    href: '#surveys',
    keyForAccessControl: ACCESS_CONTROL_KEYS.SURVEYS,
    disabled: false,
    title: {
      icon: (
        <span
          className='icon icon-font-a-surveys primary-text-color'
          aria-hidden='true'
        />
      ),
      text: 'Surveys',
    },

    component: Surveys,
  },
  {
    id: 4,
    name: 'messages',
    href: '#messages',
    keyForAccessControl: ACCESS_CONTROL_KEYS.MESSAGES,
    disabled: DISABLE_PATIENT_MESSAGES,
    title: {
      icon: (
        <span
          className='icon icon-font-a-email primary-text-color'
          aria-hidden='true'
        />
      ),
      text: 'Messaging',
      keyForMissedData: 'unresolvedConversationsCount',
      count: 0,
    },

    component: Conversations,
  },
  {
    id: 5,
    name: 'Diary',
    href: '#Diary',
    keyForAccessControl: ACCESS_CONTROL_KEYS.DIARY,
    disabled: false,
    title: {
      icon: <span className='icon icon-menu_book' aria-hidden='true' />,
      text: 'Diary',
      keyForMissedData: 'newDiaryEntry',
      count: 0,
    },

    component: Diaries,
  },
  {
    id: 6,
    name: 'notes',
    href: '#patientlog',
    keyForAccessControl: ACCESS_CONTROL_KEYS.NOTES,
    disabled: false,
    title: {
      icon: (
        <span
          className='icon icon-notes primary-text-color'
          aria-hidden='true'
        />
      ),
      text: 'Notes',
      keyForMissedData: 'newNotesCount',
      count: 0,
    },

    component: Notes,
  },
  {
    id: 7,
    name: 'Consent',
    href: '#consent',
    keyForAccessControl: ACCESS_CONTROL_KEYS.CONSENT,
    disabled: false,
    title: {
      icon: (
        <CheckOutlinedIcon
          style={{ fontSize: '20px' }}
          className='consent-tab-icon'
        />
      ),
      text: 'Consent',
    },

    component: Consent,
  },
  {
    id: 8,
    name: 'outcomes_reports',
    href: '#outcomes_reports',
    keyForAccessControl: ACCESS_CONTROL_KEYS.OUTCOMES_REPORTS,
    disabled: false,
    title: {
      icon: (
        <span
          className='icon icon-font-a-outcome-reports primary-text-color'
          aria-hidden='true'
        />
      ),
      text: 'OutComes',
    },
    component: Outcomes,
  },
  {
    id: 9,
    name: 'audit_trail',
    href: '#audit_trail',
    keyForAccessControl: ACCESS_CONTROL_KEYS.AUDIT_TRAIL,
    disabled: false,
    title: {
      icon: (
        <span className='icon icon-featured_play_list' aria-hidden='true' />
      ),
      text: 'Audit Trail',
    },
    component: AuditTrail,
  },
];
export default patientDetailsConfig;

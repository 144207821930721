import React from 'react';
import PropTypes from 'prop-types';
import Attachments from 'containers/Attachments';
import { dateStringToCustomDateTime } from 'utils/dateUtils';
import './commentCard.scss';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

const PROVIDER = 'Provider';
const SUPER_USER = 'Super User';
const PATIENT_ADVOCATE = 'PatientAdvocate';
const PHYSICIAN = 'Physician';

export const CommentCard = props => {
  const getUserType = (key, currentUser) => {
    switch (key) {
      case PROVIDER:
        return PHYSICIAN;
      default:
        return currentUser.attributes.userProfileName;
    }
  };

  const { item, currentUser } = props;
  const currentUserId = currentUser.attributes.id;
  const displayCreatedName =
    item.createdById === currentUserId ? 'You' : item.createdByName;
  let commentAuthorTag = null;
  if (item.createdById !== currentUserId) {
    if (item.hasSuperUserPrivileges) {
      if (item.createdByType === PROVIDER) {
        commentAuthorTag = SUPER_USER;
      } else if (item.createdByType === PATIENT_ADVOCATE) {
        commentAuthorTag = `Super ${currentUser.attributes.userProfileName}`;
      }
    } else {
      commentAuthorTag = getUserType(item.createdByType, currentUser);
    }
  }

  return (
    <div className='row comment-container' data-id={item.id}>
      <ChatBubbleOutlineOutlinedIcon className='comment-bubble-icon note-details-page-icons' />
      <div className='comment-card-right-pane'>
        <div className='comment-text'>{item.text}</div>
        <div className='comment-time-container'>
          {displayCreatedName}
          {` commented on: `}
          {dateStringToCustomDateTime(item.createdAt)}
        </div>
        <div className='comment-attachments'>
          <Attachments mediaResources={item.mediaResources} isAdded={false} />
        </div>
      </div>
    </div>
  );
};

export default CommentCard;

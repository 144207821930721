import React from 'react';
import { useSelector } from 'react-redux';
import CommentCard from './CommentCard';

const CommentsList = props => {
  const { comments } = props;
  const currentUser = useSelector(store => store.currentUser);

  return comments.map(item => (
    <CommentCard key={item.id} item={item} currentUser={currentUser} />
  ));
};

export default CommentsList;

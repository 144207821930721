/*
 *
 * Patient Search Constants
 *
 */

export const PATIENT_SEARCH_REQUEST_SENT =
  'app/PatientSearch/PATIENT_SEARCH_REQUEST_SENT';
export const PATIENT_SEARCH_REQUEST_SUCCEED =
  'app/PatientSearch/PATIENT_SEARCH_REQUEST_SUCCEED';
export const PATIENT_SEARCH_REQUEST_FAILED =
  'app/PatientSearch/PATIENT_SEARCH_REQUEST_FAILED';

export const ADD_NEW_PATIENT_BUTTON_CLICKED =
  'app/PatientSearch/ADD_NEW_PATIENT_BUTTON_CLICKED';
export const CLOSE_ADD_NEW_PATIENT_MODAL =
  'app/PatientSearch/CLOSE_ADD_NEW_PATIENT_MODAL_BUTTON_CLICKED';

import React from 'react';

export const BackButton = props => (
  <div
    className={props.className}
    role='button'
    style={props.style}
    onClick={() => {
      props.goBack();
    }}
  >
    <span className='btn-text cursor-pointer'>{props.text || 'Go Back'}</span>
  </div>
);

export const IconWithRectangularBorder = props => {
  const borderStyle = props.borderColor
    ? `2px solid ${props.borderColor}`
    : 'none';
  return (
    <span
      className='modal-content-icon'
      style={{ backgroundColor: props.backgroundColor, color: props.color }}
    >
      <span
        className='modal-content-icon-circle'
        style={{ border: borderStyle }}
      >
        {props.icon}
      </span>
    </span>
  );
};
export const IconWithCircularBorder = props => (
  <span
    className={`icon-circle-body ${props.className}`}
    style={{ backgroundColor: props.backgroundColor, border: props.border }}
  >
    <span className='icon-circle'>{props.icon}</span>
  </span>
);

import {
  createConsent,
  updateConsent,
  fetchAllConsentsOfTheApplication,
  downloadTemplateOfConsent,
  validateForUniqueConsentVersion,
} from 'services/admin';
import { showToaster } from 'common/toasterActions';

import {
  FETCH_CONSENTS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
  RESET_ADD_CONSENT_DATA,
  SET_SELECTED_CONSENT_ID,
  UPDATE_CREATE_CONSENT_ADMIN_VALUE_IN_STORE,
  SAVE_ADD_CONSENT_FORM_DATA,
} from './constants';
import { resetDataForDoubleAuthetication } from '../../../components/MultiAuthenticatedRequest/actions';

const fetchConsentsListOnAdminPageRequestSucceeded = data => {
  return {
    type: FETCH_CONSENTS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
    data: {
      consentsHeaders: data.consentAttributes,
      consentsData: data.consents,
      pagination: data.pagination,
    },
  };
};

export const saveAddConsentFormData = data => {
  return {
    type: SAVE_ADD_CONSENT_FORM_DATA,
    data,
  };
};

export const resetAddConsentData = data => {
  return {
    data,
    type: RESET_ADD_CONSENT_DATA,
  };
};

export const setSelectedConsentIdRequest = (id, consentData) => {
  return {
    type: SET_SELECTED_CONSENT_ID,
    data: { id, consentData },
  };
};

export const updateConsentAdminValue = (key, value) => {
  return {
    type: UPDATE_CREATE_CONSENT_ADMIN_VALUE_IN_STORE,
    key,
    value,
  };
};

export const downloadTemplateOfConsentRequest = param => dispatch => {
  downloadTemplateOfConsent(param)
    .then(response => {
      window.open(response.data.downloadLink);
    })
    .catch(() => {});
};

export const createConsentRequest = params => dispatch => {
  createConsent(params)
    .then(response => {
      dispatch(resetDataForDoubleAuthetication())
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {});
};

export const updateConsentRequest = params => dispatch => {
  updateConsent(params)
    .then(response => {
      dispatch(resetDataForDoubleAuthetication())
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {});
};

export const fetchConsentsListOnAdminPageRequest = params => dispatch => {
  fetchAllConsentsOfTheApplication(params)
    .then(response => {
      dispatch(fetchConsentsListOnAdminPageRequestSucceeded(response.data));
    })
    .catch(() => {});
};

export const validateForUniqueConsentVersionRequest = params => dispatch => {
  return validateForUniqueConsentVersion(params);
};

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import PropTypes from 'prop-types';
import { saveAddConsentFormData } from '../action';

const AddConsentStepOneForm = props => {
  const dispatch = useDispatch();

  const data = useSelector(store => store.consentAdminReducer);
  const adminData = useSelector(store => store.AdminReducer);

  const [errors, setErrors] = useState({});
  const [consentData, setConsentData] = useState({
    title: '',
    body: '',
    locationCode: null,
  });

  useEffect(() => {
    setConsentData({
      ...consentData,
      title: data.title,
      body: data.body,
      locationCode: data.locationCode,
    });
  }, [data]);

  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'title',
      label: 'Enter Title',
      type: 'text',
      placeholder: 'Title',
      value: consentData.title,
      validation: [{ rule: 'isRequired', message: 'Title is required' }],
      visible: true,
      required: true,
    },
    {
      id: 2,
      input_type: 'input',
      name: 'body',
      label: 'Body',
      type: 'text',
      placeholder: 'Enter Body',
      value: consentData.body,
      validation: [{ rule: 'isRequired', message: 'Body is required' }],
      visible: true,
      required: true,
    },
    {
      id: 5,
      input_type: 'single_select',
      name: 'locationCode',
      label: 'Location Code',
      placeholder: 'Select Location Code',
      validation: [
        { rule: 'isRequired', message: 'Location Code is required' },
      ],
      value: consentData.locationCode,
      options: adminData.locationCodes,
      visible: true,
      required: true,
    },
  ];

  const { onContinue, step } = props;

  const continueToNextStep = () => {
    dispatch(saveAddConsentFormData(consentData));
    onContinue(step + 1);
  };

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      consentData,
      errorsInForm => {
        setErrors(errorsInForm);
      },
      () => {
        continueToNextStep();
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        setConsentData({ ...consentData, [key]: value });
      }}
      buttons={[
        <PrimaryButton
          style={{ width: '176px' }}
          onClick={() => onSubmit()}
          key='primaryButton'
        >
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

AddConsentStepOneForm.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

export default AddConsentStepOneForm;

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Proptypes from 'prop-types';
import Media from 'react-media';
import { getCurrentUser } from 'services/storage';
import UnSupportedPageView from 'containers/UnSupportedPageView';
import {
  defaultLandingPageRouteGenerator,
  isValidatedUsersInitialProcessCompleted,
} from 'common/authGuard';
import { useSelector } from 'react-redux';
import {
  USER_TYPE_PATIENT,
  USER_TYPE_PATIENT_ADVOCATE,
  USER_TYPE_PROVIDER,
} from '../../constants';

/**
 * AuthenticatedRoute is a base component which checks for an authenticated user.
 * @param props - Component properties that get passed to the rendered component
 * @param {allowedRoles} - Array of user types. checks whether the signedIn user is authorized to visit the route.
 *
 * example : <AuthenticatedRoute path="/" allowedRoles={["Provider"]}/>
 * @class AuthenticatedRoute
 */
const AuthenticatedRoute = props => {
  const { component: Container, ...rest } = props;
  const currentUser = getCurrentUser();
  const userType = currentUser !== null ? currentUser.type : '';

  const { isSignedIn } = useSelector(store => store.currentUser);

  const isAuthorized = () => {
    if (props.allowedRoles) {
      return props.allowedRoles.some(
        item => item.toLowerCase() === userType.toLowerCase()
      );
    }
    return true;
  };

  const renderAuthenticatedComponents = () => {
    if (
      isAuthorized() &&
      isValidatedUsersInitialProcessCompleted(props.location)
    ) {
      return <Container {...props} />;
    }

    const redirectRoute = defaultLandingPageRouteGenerator(props.location);
    return <Redirect to={redirectRoute} replace />;
  };

  return (
    <Media
      queries={{
        screenIsSmall: '(max-width: 767px)',
        deviceOrientation: '(orientation: landscape)',
        screenIsMedium: '(max-width: 1023px)',
      }}
    >
      {matches =>
        (matches.deviceOrientation && matches.screenIsMedium) ||
        matches.screenIsSmall ? (
          <Route
            {...rest}
            render={props => {
              if (isSignedIn && userType) {
                if (
                  userType.toLowerCase() !== USER_TYPE_PATIENT.toLowerCase()
                ) {
                  return <UnSupportedPageView />;
                }
              }
              return renderAuthenticatedComponents();
            }}
          />
        ) : (
          <Route {...rest} render={props => renderAuthenticatedComponents()} />
        )
      }
    </Media>
  );
};

AuthenticatedRoute.propTypes = {
  component: Proptypes.any,
  allowedRoles: Proptypes.array,
};

export default AuthenticatedRoute;

export const MedStaffAuthorizedRoute = props => (
  <AuthenticatedRoute
    allowedRoles={[USER_TYPE_PATIENT_ADVOCATE, USER_TYPE_PROVIDER]}
    {...props}
  />
);

export const SuperUserAuthorizedRoute = props => {
  const currentUser = getCurrentUser();

  if (currentUser === null) {
    return <Redirect to={{ pathname: '/signin' }} />;
  }

  if (currentUser.isSupportUser && !currentUser.hasSuperUserPrivileges) {
    return <UnSupportedPageView />;
  }
  return (
    <AuthenticatedRoute
      allowedRoles={[USER_TYPE_PATIENT_ADVOCATE, USER_TYPE_PROVIDER]}
      {...props}
    />
  );
};

import { ClientInterface } from "../NativeNavigation/ClientInterface";
import ClientListenerIdentifier from "../NativeNavigation/ClientListenerIdentifier";

const useClientListener = (): ClientInterface => {
  const searchParams = new URLSearchParams(window.location.search);
  const platform = searchParams.get('Platform') || searchParams.get('platform') || '0';
  const manager = new ClientListenerIdentifier(platform);
  return manager.getClientListenerObject();
};

export default useClientListener;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { BackButton } from 'components/UtilityComponents';
import TabContentWrapper from 'containers/PatientDetails/Components/TabContentWrapper';
import NoteInfo from '../NoteInfo';
import './noteDetails.scss';
import CommentsList from '../CommentsList';
import CommentRespond from '../CommentRespond';
import { getPatientNoteDetailsRequest } from './action';

const NoteDetails = props => {
  const { noteId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  useEffect(() => {
    const inputParams = {
      id: noteId,
      patientId,
      procedureId,
    };
    dispatch(getPatientNoteDetailsRequest(inputParams));
  }, []);

  const comments = useSelector(
    store => store.patientNoteDetailsReducer.comments
  );

  const backButtonContent = () => {
    return (
      <span className='note-details-back-button'>
        <div className='icon icon-font-a-left-chevron' />
        <div className='icon-notes' />
        <div className='note-details-back-button-text'>Notes</div>
      </span>
    );
  };

  return (
    <div className='communication-log-details-container'>
      <TabContentWrapper
        header={
          <BackButton
            text={backButtonContent()}
            goBack={() =>
              history.replace(`${window.location.pathname}#patientLog`)
            }
            style={{ width: 'fit-content', margin: '0px 0px 24px -24px' }}
          />
        }
        body={
          <>
            <NoteInfo noteId={noteId} />
            <CommentsList comments={comments} />
            <CommentRespond noteId={noteId} />
          </>
        }
      />
    </div>
  );
};

NoteDetails.propTypes = {
  noteId: PropTypes.string.isRequired,
};

export default NoteDetails;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import validate, { clearErrorsForField } from 'common/validator';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import {
  resetConversationStartDone,
  dispatchActionWithCallBack,
} from 'common/actions';
import HelpBlock from 'components/HelpBlock';
import AddAttachmentModal from 'containers/AddAttachmentModal';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { startNewConversation } from '../action';

const validationConfig = {
  fields: ['newConversationSubject', 'newConversationMessage'],
  rules: {
    newConversationSubject: [
      { rule: 'isRequired', message: 'Subject is required' },
    ],
    newConversationMessage: [
      { rule: 'isRequired', message: 'Message is required' },
    ],
  },
};

const Conversations = props => {
  const dispatch = useDispatch();
  const params = useParams();

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  const [errors, setErrors] = useState({});
  const [newConversationSubject, setNewConversationSubject] = useState('');
  const [newConversationMessage, setNewConversationMessage] = useState('');
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const userType = useSelector(store => store.currentUser.attributes.type);
  const conversationStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.conversationStarted
  );

  useEffect(() => {
    if (conversationStarted) {
      props.onHideStartConversationModal();
      resetState();
      dispatch(resetConversationStartDone());
    }
  }, [conversationStarted]);

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      setNewConversationMessage(`${newConversationMessage}\n`);
    }
  };

  const onAddAttachment = () => {
    setShowAttachmentModal(true);
    setShowAttachments(true);
  };

  const getConversationType = () => {
    switch (userType) {
      case 'PatientAdvocate':
        return 'patient_advocate';
      case 'Provider':
        return 'patient_provider';
    }
  };

  const onSendMessageValidationSuccess = () => {
    setErrors({});
    const params = {
      user_procedure_id: procedureId,
      subject: newConversationSubject,
      message_body: newConversationMessage,
      secure_action_code: SecureCodes.CREATE_CONVERSATION,
      needs_reason: true,
      media_resources: attachments,
      type: getConversationType(),
    };
    dispatch(
      secureActionWithDoubleAuthentication(startNewConversation, params)
    );
  };

  const onSendMessage = () => {
    validate(
      validationConfig,
      { newConversationSubject, newConversationMessage },
      errors => setErrors(errors),
      onSendMessageValidationSuccess
    );
  };

  const resetState = () => {
    setShowAttachmentModal(false);
    setShowAttachments(false);
    setNewConversationSubject('');
    setNewConversationMessage('');
    setAttachments([]);
  };

  return (
    <FormModal
      show={props.showStartConversationModal}
      onHide={() => {
        resetState();
        props.onHideStartConversationModal();
      }}
      title={{
        text: 'Send a New Message',
      }}
      modalStyle={{
        width: '700px',
        height: 'auto',
      }}
      form={{
        form: (
          <form id='start-conversation-form'>
            <div
              className={`form-group ${
                errors.newConversationSubject !== undefined ? 'has-error' : ''
              }`}
            >
              <label htmlFor='reason'>Subject</label>
              <input
                type='text'
                name='newConversationSubject'
                id='newConversationSubject'
                className='form-control'
                placeholder='Type a subject of the message'
                value={newConversationSubject}
                onChange={e => {
                  setErrors(clearErrorsForField(e.target.name));
                  setNewConversationSubject(e.target.value);
                }}
              />
              <HelpBlock value={errors.newConversationSubject} />
            </div>
            <div
              className={`form-group ${
                errors.newConversationMessage !== undefined ? 'has-error' : ''
              }`}
            >
              <label htmlFor='reason'>Message</label>
              <textarea
                name='newConversationMessage'
                id='newConversationMessage'
                className='form-control'
                placeholder='Type a message to Subject here'
                value={newConversationMessage}
                onChange={e => {
                  setErrors(clearErrorsForField(e.target.name));
                  setNewConversationMessage(e.target.value);
                }}
                onKeyUp={handleKeyPress}
              />
              <HelpBlock value={errors.newConversationMessage} />
            </div>
            <AddAttachmentModal
              onUpdateAttachments={attachments => setAttachments(attachments)}
              showModal={showAttachmentModal}
              onModalClose={() => setShowAttachmentModal(false)}
              showAddedAttachments={showAttachments}
              mediaResources={attachments}
            />
          </form>
        ),
      }}
      buttons={[
        <SecondaryButton onClick={onAddAttachment} className='message-buttons'>
          Add Attachment
        </SecondaryButton>,
        <PrimaryButton
          form='start-conversation-form'
          className='message-buttons'
          onClick={onSendMessage}
        >
          Send
        </PrimaryButton>,
      ]}
    />
  );
};

export default Conversations;

import {
  fetchProceduresOfTheDepartment,
  fetchAllProviders,
  fetchAllDepartments,
  fetchAllLocations,
  fetchCreateUserDropdownOptions,
  fetchTimezones,
  fetchAdvocates,
  getLocationCodes,
  getEventKinds,
  fetchTaskIdentifiers,
  fetchResourceCategories,
  fetchResourceActions,
} from 'services/admin';

import {
  FETCH_PROCEDURES_REQUEST_SUCCEED,
  FETCH_ALL_PROVIDERS_SUCCEED,
  FETCH_DEPARTMENTS_SUCCEED,
  FETCH_LOCATIONS_SUCCEED,
  FETCH_CREATE_USER_DROPDOWN_OPTIONS,
  FETCH_TIMEZONES_SUCCEED,
  FETCH_ADVOCATES_SUCCEED,
  GET_LOCATION_CODES_REQUEST_SUCCEED,
  GET_EVENT_KINDS_REQUEST_SUCCEED,
  GET_TASK_IDENTIFIERS_REQUEST_SUCCEED,
  FETCH_RESOURCE_CATEGORIES_SUCCEED,
  FETCH_RESOURCE_ACTIONS_SUCCEED,
} from './constants';

const fetchAllProceduresOfTheDepartmentSucceeded = data => {
  return {
    type: FETCH_PROCEDURES_REQUEST_SUCCEED,
    data,
  };
};

const fetchAllProvidersSucceeded = data => {
  return {
    type: FETCH_ALL_PROVIDERS_SUCCEED,
    data,
  };
};

const fetchDepartmentsSucceed = data => {
  return {
    type: FETCH_DEPARTMENTS_SUCCEED,
    data,
  };
};

const fetchLocationsSucceed = data => {
  return {
    type: FETCH_LOCATIONS_SUCCEED,
    data,
  };
};

const fetchTimezonesRequestSucceed = data => {
  return {
    type: FETCH_TIMEZONES_SUCCEED,
    data,
  };
};

const fetchAdvocatesRequestSucceed = data => {
  return {
    type: FETCH_ADVOCATES_SUCCEED,
    data,
  };
};

const fetchResourceCategoriesRequestSucceed = data => {
  return {
    type: FETCH_RESOURCE_CATEGORIES_SUCCEED,
    data,
  };
};

const fetchResourceActionsSucceed = data => {
  return {
    type: FETCH_RESOURCE_ACTIONS_SUCCEED,
    data,
  };
};

const fetchCreateUserDropdownOptionsRequestSucceed = data => {
  return {
    type: FETCH_CREATE_USER_DROPDOWN_OPTIONS,
    data,
  };
};

const getLocationCodesRequestSucceed = data => {
  return {
    type: GET_LOCATION_CODES_REQUEST_SUCCEED,
    data,
  };
};

const getEventKindsRequestSucceed = data => {
  return {
    type: GET_EVENT_KINDS_REQUEST_SUCCEED,
    data,
  };
};

const getTaskIdentifiersRequestSucceed = data => {
  return {
    type: GET_TASK_IDENTIFIERS_REQUEST_SUCCEED,
    data,
  };
};

export const fetchAllProceduresOfTheDepartmentRequest = () => dispatch => {
  fetchProceduresOfTheDepartment()
    .then(response => {
      dispatch(fetchAllProceduresOfTheDepartmentSucceeded(response.data));
    })
    .catch(() => {});
};

export const fetchAllProvidersRequest = () => dispatch => {
  fetchAllProviders()
    .then(response => {
      dispatch(fetchAllProvidersSucceeded(response.data));
    })
    .catch(() => {});
};

export const fetchAllDepartmentsRequest = () => dispatch => {
  fetchAllDepartments()
    .then(response => {
      dispatch(fetchDepartmentsSucceed(response.data));
    })
    .catch(() => {});
};

export const fetchAllLocationsRequest = () => dispatch => {
  fetchAllLocations()
    .then(response => {
      dispatch(fetchLocationsSucceed(response.data));
    })
    .catch(() => {});
};

export const fetchCreateUserDropdownOptionsRequest = () => dispatch => {
  fetchCreateUserDropdownOptions()
    .then(response => {
      dispatch(fetchCreateUserDropdownOptionsRequestSucceed(response.data));
    })
    .catch(() => {});
};

export const fetchTimezonesRequest = () => dispatch => {
  fetchTimezones()
    .then(response => {
      dispatch(fetchTimezonesRequestSucceed(response.data));
    })
    .catch(() => {});
};

export const fetchAdvocatesRequest = () => dispatch => {
  fetchAdvocates()
    .then(response => {
      dispatch(fetchAdvocatesRequestSucceed(response.data.advocates));
    })
    .catch(() => {});
};

export const getLocationCodesRequest = params => dispatch => {
  getLocationCodes(params)
    .then(response => {
      dispatch(getLocationCodesRequestSucceed(response.data.locations));
    })
    .catch(() => {});
};

export const getEventKindsRequest = params => dispatch => {
  getEventKinds(params)
    .then(response => {
      dispatch(getEventKindsRequestSucceed(response.data.eventKinds));
    })
    .catch(() => {});
};

export const getTaskIdentifiersRequest = params => dispatch => {
  fetchTaskIdentifiers(params)
    .then(response => {
      dispatch(getTaskIdentifiersRequestSucceed(response.data.tasks));
    })
    .catch(() => {});
};

export const fetchResourceCategoriesRequest = () => dispatch => {
  fetchResourceCategories()
    .then(response => {
      dispatch(
        fetchResourceCategoriesRequestSucceed(response.data.resourceCategories)
      );
    })
    .catch(() => {});
};

export const fetchResourceActionsRequest = () => dispatch => {
  fetchResourceActions()
    .then(response => {
      dispatch(fetchResourceActionsSucceed(response.data.resourceActions));
    })
    .catch(() => {});
};

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FlashMessage from 'components/FlashMessage';
import { removeToaster } from 'common/toasterActions';
import difference from 'lodash/difference';

const Toasters = props => {
  const [toasters, setToasters] = useState([]);
  const toastersInRedux = useSelector(store => store.toasters);

  useEffect(() => {
    const newToasters = difference(toastersInRedux, toasters);
    if (newToasters.length)
      newToasters.forEach(toast =>
        setTimeout(() => dispatch(removeToaster(toast.id)), toast.timeout)
      );
    setToasters(toastersInRedux);
  }, [toastersInRedux]);

  const dispatch = useDispatch();

  const onRemoveToast = (event, toasterId) => {
    event.preventDefault();
    dispatch(removeToaster(toasterId));
  };

  return (
    <>
      {toasters
        ? toasters.map(toast => (
            <FlashMessage
              key={toast.id}
              {...toast}
              onRemoveToast={onRemoveToast}
            />
          ))
        : null}
    </>
  );
};
export default Toasters;

/*
 *
 * Surveys constants
 *
 */

export const GET_SURVEYS_REQUEST_SENT = 'app/SURVEYS/GET_SURVEYS_REQUEST_SENT';
export const GET_SURVEYS_REQUEST_SUCCEED =
  'app/SURVEYS/GET_SURVEYS_REQUEST_SUCCEED';
export const GET_SURVEYS_REQUEST_FAILED =
  'app/SURVEYS/GET_SURVEYS_REQUEST_FAILED';

export const CLEAR_SURVEYS_REQUEST_SENT =
  'app/SURVEYS/CLEAR_SURVEYS_REQUEST_SENT';
export const CLEAR_SURVEYS_REQUEST_SUCCEED =
  'app/SURVEYS/CLEAR_SURVEYS_REQUEST_SUCCEED';
export const CLEAR_SURVEYS_REQUEST_FAILED =
  'app/SURVEYS/CLEAR_SURVEYS_REQUEST_FAILED';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './auditTrail.scss';
import TabContentWrapper from 'containers/PatientDetails/Components/TabContentWrapper';
import Pagination from 'components/Pagination';
import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import Emptyview from 'components/Emptyview';
import { useParams } from 'react-router';
import AuditTrailTable from './AuditTrailTable';
import {
  fetchAuditTrailsCsvRequest,
  fetchAuditTrailsRequest,
  fetchConsentsForAuditTrailRequest,
} from './action';
import ConsentAuditTrailDataModal from './ConsentAuditTrailDataModal';

const AuditTrail = props => {
  const dispatch = useDispatch();
  const params = useParams();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (props.isActive) {
      dispatch(
        fetchAuditTrailsRequest({
          patient_id: parseInt(params.patientId, 10) || -1,
          page,
        })
      );
      dispatch(
        fetchConsentsForAuditTrailRequest({
          patient_id: parseInt(params.patientId, 10) || -1,
          user_procedure_id: parseInt(params.procedureId, 10) || -1,
        })
      );
    }
  }, [props.isActive, page]);

  const pagination = useSelector(store => store.auditTrailsReducer.pagination);
  const auditTrails = useSelector(
    store => store.auditTrailsReducer.auditTrails
  );
  const auditTrailsHeaders = useSelector(
    store => store.auditTrailsReducer.auditTrailsHeaders
  );
  const exportConsentAuditTrailAllowed = useSelector(
    store => store.consentsAuditTrailReducer.exportConsentAuditTrailAllowed
  );

  const [showModal, setShowModal] = useState(false);

  return (
    <TabContentWrapper
      header={
        <div className='header-right'>
          <TertiaryButton
            style={{ width: 'fit-content' }}
            onClick={() => {
              setShowModal(true);
            }}
            disabled={!exportConsentAuditTrailAllowed}
          >
            <span className='icon icon-download' />
            &nbsp;
            <span>EXPORT CONSENT AUDIT TRAIL</span>
          </TertiaryButton>
          {auditTrails.length ? (
            <>
              <TertiaryButton
                style={{ width: 'fit-content' }}
                onClick={() =>
                  dispatch(
                    fetchAuditTrailsCsvRequest({
                      id: parseInt(params.patientId, 10),
                    })
                  )
                }
              >
                <span className='icon icon-download' />
                &nbsp;
                <span>EXPORT CSV</span>
              </TertiaryButton>
              <TertiaryButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  window.print();
                }}
              >
                <span className='icon icon-font-a-print' />
                &nbsp;
                <span>PRINT</span>
              </TertiaryButton>
            </>
          ) : (
            ''
          )}
        </div>
      }
      body={
        <>
          {auditTrails.length ? (
            <AuditTrailTable data={auditTrails} headers={auditTrailsHeaders} />
          ) : (
            <Emptyview
              className='icon icon-featured_play_list'
              message='No Audit Trails'
            />
          )}
          <ConsentAuditTrailDataModal
            showModal={showModal}
            onModalClose={() => setShowModal(false)}
            patientId={parseInt(params.patientId, 10) || -1}
          />
        </>
      }
      footer={
        auditTrails.length ? (
          <Pagination
            pagination={pagination}
            title='Audit Trails'
            handlePagination={page => setPage(page)}
          />
        ) : (
          <></>
        )
      }
    />
  );
};

export default AuditTrail;

/*
 *
 * Task actions
 *
 */
import { showToaster } from 'common/toasterActions';
import {
  getPatientTasks as ApiCall,
  sendTaskReminder,
  createTask,
  deleteSelectedTask,
  getPatientTaskDetails,
  getReminderHistory,
  deleateAllRecurringTasksInstance,
  postMarkTaskCompleteService,
  getOptionalTasks,
  assignTasks,
} from 'services/patientDetails';
import { getPatientInfo } from 'containers/PatientDetails/actions';

import { resetDataForDoubleAuthetication } from 'components/MultiAuthenticatedRequest/actions';
import * as Constants from './constants';

export const getPatientTasksRequestSent = () => ({
  type: Constants.GET_TASKS_REQUEST_SENT,
});

export const getPatientTasksRequestSucceeded = tasks => ({
  type: Constants.GET_TASKS_REQUEST_SUCCEED,
  tasks,
});

export const getPatientTasksRequestFailed = () => ({
  type: Constants.GET_TASKS_REQUEST_FAILED,
});

export const sendTasksReminderRequestSent = () => ({
  type: Constants.POST_TASKS_REMINDER_REQUEST_SENT,
});

export const sendTasksReminderRequestSucceeded = data => ({
  type: Constants.POST_TASKS_REMINDER_REQUEST_SUCCEED,
  data,
});

export const sendTasksReminderRequestFailed = () => ({
  type: Constants.POST_TASKS_REMINDER_REQUEST_FAILED,
});

export const createTaskRequestSent = data => ({
  type: Constants.POST_CREATE_TASK_REQUEST_SENT,
  data,
});

export const createTaskRequestSucceeded = () => ({
  type: Constants.POST_CREATE_TASK_REQUEST_SUCCEED,
});

export const createTaskRequestFailed = () => ({
  type: Constants.POST_CREATE_TASK_REQUEST_FAILED,
});

export const deleteTaskRequestSent = data => ({
  type: Constants.POST_DELETE_TASK_REQUEST_SENT,
  data,
});

export const deleteTaskRequestSucceeded = taskId => ({
  type: Constants.POST_DELETE_TASK_REQUEST_SUCCEED,
  taskId,
});

export const deleteTaskRequestFailed = () => ({
  type: Constants.POST_DELETE_TASK_REQUEST_FAILED,
});

export const deleteAllRecurringTasksRequestSent = data => ({
  type: Constants.POST_DELETE_ALL_RECURRING_TASKS_REQUEST_SENT,
  data,
});

export const deleteAllRecurringTasksRequestSucceeded = taskId => ({
  type: Constants.POST_DELETE_ALL_RECURRING_TASKS_REQUEST_SUCCEED,
  taskId,
});

export const deleteAllRecurringTasksRequestFailed = () => ({
  type: Constants.POST_DELETE_ALL_RECURRING_TASKS_REQUEST_FAILED,
});

export const getPatientTaskDetailsRequestSent = () => ({
  type: Constants.GET_TASKS_DETAILS_REQUEST_SENT,
});

export const getPatientTaskDetailsRequestSucceeded = taskDetails => ({
  type: Constants.GET_TASKS_DETAILS_REQUEST_SUCCEED,
  taskDetails,
});

export const getPatientTaskDetailsRequestFailed = () => ({
  type: Constants.GET_TASKS_DETAILS_REQUEST_FAILED,
});

export const clearTaskDetailsState = () => ({
  type: Constants.TASK_DETAILS_CLEAR_STATE,
});

export const getReminderHistoryRequestSent = () => ({
  type: Constants.GET_TASKS_REMINDER_HISTORY_REQUEST_SENT,
});

export const getReminderHistoryRequestSucceeded = (userTaskId, data) => ({
  type: Constants.GET_TASKS_REMINDER_HISTORY_REQUEST_SUCCEED,
  payload: {
    data,
    userTaskId,
  },
});

export const getReminderHistoryRequestFailed = userTaskId => ({
  type: Constants.GET_TASKS_REMINDER_HISTORY_REQUEST_FAILED,
  userTaskId,
});

export const markTaskCompleteRequestSent = () => ({
  type: Constants.POST_MARK_TASK_COMPLETE_REQUEST_SENT,
});

export const markTaskCompleteRequestSucceeded = taskId => ({
  type: Constants.POST_MARK_TASK_COMPLETE_REQUEST_SUCCEED,
  taskId,
});

export const markTaskCompleteRequestFailed = () => ({
  type: Constants.POST_MARK_TASK_COMPLETE_REQUEST_FAILED,
});

export const resetAll = () => ({
  type: Constants.RESET_ALL_TASK,
});

export const getOptionalTasksRequestSent = () => ({
  type: Constants.GET_OPTIONAL_TASKS_REQUEST_SENT,
});

export const getOptionalTasksRequestSucceeded = tasks => ({
  type: Constants.GET_OPTIONAL_TASKS_REQUEST_SUCCEED,
  tasks,
});

export const getOptionalTasksRequestFailed = () => ({
  type: Constants.GET_OPTIONAL_TASKS_REQUEST_FAILED,
});

export const assignOptionalTasksRequestSent = data => ({
  type: Constants.ASSIGN_OPTIONAL_TASK_REQUEST_SENT,
  data,
});

export const assignOptionalTasksRequestSucceeded = () => ({
  type: Constants.ASSIGN_OPTIONAL_TASK_REQUEST_SUCCEED,
});

export const assignOptionalTasksRequestFailed = () => ({
  type: Constants.ASSIGN_OPTIONAL_TASK_REQUEST_FAILED,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getPatientTasks =
  (id, userProcedureId, procedurePhaseId, showAll) => dispatch => {
    if (id === undefined || id === null) {
      return;
    }
    dispatch(getPatientTasksRequestSent());
    ApiCall(id, userProcedureId, showAll, procedurePhaseId)
      .then(response => {
        dispatch(getPatientTasksRequestSucceeded(response.data));
      })
      // eslint-disable-next-line no-unused-vars
      .catch(error => {
        dispatch(getPatientTasksRequestFailed());
      });
  };

export const sendPatientTaskReminder = params => dispatch => {
  dispatch(sendTasksReminderRequestSent());
  sendTaskReminder(params)
    .then(response => {
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: 'Task reminder sent successfully' }));
      dispatch(sendTasksReminderRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(sendTasksReminderRequestFailed());
      if (error.response.data.error)
        dispatch(
          showToaster({ type: 'error', message: error.response.data.error })
        );
    });
};

export const createPatientTask =
  (patientId, userProcedureId, data, showAllTasks, password) => dispatch => {
    dispatch(createTaskRequestSent());
    createTask(patientId, userProcedureId, data, password)
      .then(response => {
        if (response.data) {
          dispatch(resetDataForDoubleAuthetication());
          dispatch(createTaskRequestSucceeded());
          dispatch(showToaster({ message: 'Task created successfully' }));
          dispatch(
            getPatientTasks(patientId, userProcedureId, null, showAllTasks)
          );
        }
      })
      .catch(error => {
        dispatch(createTaskRequestFailed());
        if (error.response.data.error)
          dispatch(
            showToaster({ type: 'error', message: error.response.data.error })
          );
      });
  };

export const deleteCreatedTask = params => dispatch => {
  dispatch(deleteTaskRequestSent());
  deleteSelectedTask(params)
    .then(response => {
      if (response.data) {
        dispatch(
          getPatientTasks(
            params.patientId,
            params.procedureId,
            params.phaseId,
            params.showAllTasks
          )
        );
        dispatch(deleteTaskRequestSucceeded(params.taskId));
        dispatch(resetDataForDoubleAuthetication());
        dispatch(showToaster({ message: 'Task deleted successfully' }));
      }
    })
    .catch(error => {
      dispatch(deleteTaskRequestFailed());
      if (error.response.data.error)
        dispatch(
          showToaster({ type: 'error', message: error.response.data.error })
        );
    });
};

export const deleateAllRecurringTasks = (userTaskId, password) => dispatch => {
  dispatch(deleteAllRecurringTasksRequestSent());
  deleateAllRecurringTasksInstance(userTaskId, password)
    .then(response => {
      if (response.data) {
        dispatch(deleteAllRecurringTasksRequestSucceeded(userTaskId));
        dispatch(resetDataForDoubleAuthetication());
        dispatch(showToaster({ message: 'Task deleted successfully' }));
      }
    })
    .catch(error => {
      dispatch(deleteAllRecurringTasksRequestFailed());
      if (error.response.data.error)
        dispatch(
          showToaster({ type: 'error', message: error.response.data.error })
        );
    });
};

export const getTaskDetails = (taskId, patientId) => dispatch => {
  dispatch(getPatientTaskDetailsRequestSent());
  getPatientTaskDetails(taskId, patientId)
    .then(response => {
      dispatch(getPatientTaskDetailsRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(getPatientTaskDetailsRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const clearTaskDetails = () => dispatch => {
  dispatch(clearTaskDetailsState());
};

export const getReminderHistoryDetails = userTaskId => dispatch => {
  dispatch(getReminderHistoryRequestSent());
  getReminderHistory(userTaskId)
    .then(response => {
      dispatch(getReminderHistoryRequestSucceeded(userTaskId, response.data));
    })
    .catch(error => {
      dispatch(getReminderHistoryRequestFailed(userTaskId));
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const markTaskCompleteRequest = params => dispatch => {
  dispatch(markTaskCompleteRequestSent());
  postMarkTaskCompleteService(params)
    .then(response => {
      if (response.data) {
        dispatch(resetDataForDoubleAuthetication());
        dispatch(markTaskCompleteRequestSucceeded());
        dispatch(
          showToaster({
            message: `Task marked as ${
              params.completed_on !== '' ? 'complete' : 'incomplete'
            }`,
          })
        );
        dispatch(
          getPatientTasks(
            params.patientId,
            params.procedureId,
            params.phaseId,
            params.showAllTasks
          )
        );
        dispatch(getPatientInfo(params.patientId, params.procedureId));
        if (params.isRecurring) {
          dispatch(getTaskDetails(params.id, params.paatientId));
        }
      }
    })
    .catch(error => {
      dispatch(markTaskCompleteRequestFailed());
      if (error.response.data.error)
        dispatch(
          showToaster({ type: 'error', message: error.response.data.error })
        );
    });
};

export const getOptionalTasksAction = userProcedureId => dispatch => {
  dispatch(getOptionalTasksRequestSent());
  getOptionalTasks(userProcedureId)
    .then(response => {
      dispatch(getOptionalTasksRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(getOptionalTasksRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const assignOptionalTasks =
  (patientId, userProcedureId, data, showAllTasks, password) => dispatch => {
    dispatch(assignOptionalTasksRequestSent());
    assignTasks(patientId, userProcedureId, data, password)
      .then(response => {
        if (response.data) {
          dispatch(resetDataForDoubleAuthetication());
          dispatch(assignOptionalTasksRequestSucceeded());
          dispatch(showToaster({ message: 'Task assigned successfully' }));
          dispatch(
            getPatientTasks(patientId, userProcedureId, null, showAllTasks)
          );
        }
      })
      .catch(error => {
        dispatch(assignOptionalTasksRequestFailed());
        dispatch(
          showToaster({ type: 'error', message: error.response.data.error })
        );
      });
  };

import { getProcedureManagers } from 'services/patientDetails';
import {
  GET_PROCEDURE_MANAGERS_REQUEST_SENT,
  GET_PROCEDURE_MANAGERS_REQUEST_SUCCEED,
  GET_PROCEDURE_MANAGERS_REQUEST_FAILED,
} from './constants';

export const getProcedureManagersRequestSent = () => ({
  type: GET_PROCEDURE_MANAGERS_REQUEST_SENT,
});

export const getProcedureManagersRequestSucceeded = data => ({
  type: GET_PROCEDURE_MANAGERS_REQUEST_SUCCEED,
  data,
});

export const getProcedureManagersRequestFailed = () => ({
  type: GET_PROCEDURE_MANAGERS_REQUEST_FAILED,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getProcedureManagersAction = params => dispatch => {
  dispatch(getProcedureManagersRequestSent());
  getProcedureManagers(params)
    .then(response => {
      dispatch(getProcedureManagersRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getProcedureManagersRequestFailed());
    });
};

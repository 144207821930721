/* eslint-disable no-nested-ternary */
/** \n * A DataTable component that displays data in a table format and allows the user to sort columns by clicking on the header.
@component
@author Dilip Joshi
@param {Object} props - The props object holding the data to be displayed
@param {Array.} props.tableData - An array of objects containing the data to be displayed in the table
@param {Array.} props.tableHeaders - An array of objects containing the table header information
@returns {JSX.Element} - A table containing the data with sortable headers
 */

import React, { useEffect, useState } from 'react';
import './styles/dataTable.scss';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton } from '@material-ui/core';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import variables from 'assets/stylesheets/_variables.scss';
import Tooltip from '@mui/material/Tooltip';
import ToolTipLocal from 'components/StyleGuideComponents/ToolTip';

import { TertiaryButton } from './common/buttons';

const DataTable = props => {
  const [sortingStatus, setSortingStatus] = useState({});
  const [tableData, setTableData] = useState(props.tableData);

  useEffect(() => {
    setTableData(props.tableData);
  }, [props.tableData]);

  const tableStyle = {
    minWidth: '400px',
    ...props.style,
  };

  const [asc, desc] = ['ascendingOrder', 'descendingOrder'];

  useEffect(() => {
    const data = {};
    props.tableHeaders.map(header => {
      if (header.sortable) {
        if (!sortingStatus.hasOwnProperty(header.key)) {
          data[header.key] = asc;
        }
      }
    });
    setSortingStatus({ ...sortingStatus, ...data });
  }, [props.tableHeaders]);

  const getSortingIcon = field => {
    if (sortingStatus[field] === asc) {
      return <ArrowDownwardIcon />;
    }
    return <ArrowUpwardIcon />;
  };

  const sortAsc = field => {
    return function (a, b) {
      if (a[field] > b[field]) {
        return 1;
      }
      if (a[field] < b[field]) {
        return -1;
      }
      return 0;
    };
  };

  const sortDesc = field => {
    return function (a, b) {
      if (a[field] > b[field]) {
        return -1;
      }
      if (a[field] < b[field]) {
        return 1;
      }
      return 0;
    };
  };

  const sortData = field => {
    const data = [...tableData];
    if (sortingStatus[field] === asc) {
      setTableData([...data].sort(sortDesc(field)));
      setSortingStatus({ ...sortingStatus, [field]: desc });
    } else {
      setTableData([...data].sort(sortAsc(field)));

      setSortingStatus({ ...sortingStatus, [field]: asc });
    }
  };

  const getTableCellDataForMultiValue = (row, header) => {
    return row[header.key].length ? (
      <td style={props.tableDataCellStyle}>
        <table className='table-in-cell'>
          {row[header.key].map(sub_data => {
            return <td>{`${sub_data}`}</td>;
          })}{' '}
        </table>
      </td>
    ) : (
      <td style={props.tableDataCellStyle}>--</td>
    );
  };

  const getTableCellDataForSubtitledValue = (row, header) => {
    return row[header.key]?.length ? (
      <td style={props.tableDataCellStyle}>
        <div className='container-in-cell'>
          <span className='title-in-cell' style={props.styleForTitleInCell}>{`${
            row[header.key][0]
          }`}</span>
          {row[header.key]?.length > 1 ? (
            <span
              className='subtitle-in-cell'
              style={props.styleForSubtitleInCell}
            >
              {row[header.key][1]}
            </span>
          ) : (
            ''
          )}
        </div>
      </td>
    ) : (
      <td style={props.tableDataCellStyle}>--</td>
    );
  };

  const getStatusIcon = icon => {
    if (icon === 'error')
      return <span className='icon icon-font-a-error-message' />;
    if (icon === 'sync') return <span className='icon icon-font-a-clear-all' />;
    return '';
  };

  const getTableCellDataForStatusValue = (row, header) => {
    return row[header.key] && row[header.key]?.value ? (
      <td style={props.tableDataCellStyle}>
        <div className='status-container'>
          <div className='status-cell-data'>
            <div className='status-container'>
              <span className='status-value'>{row[header.key]?.value}</span>
            </div>
            {row[header.key]?.icon?.visible ? (
              row[header.key]?.icon?.showToolTip ? (
                <ToolTipLocal
                  content={row[header.key]?.icon?.title}
                  tipPosition='top'
                >
                  <TertiaryButton
                    onClick={e => {
                      e.stopPropagation();
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      row[header.key]?.onClick
                        ? row[header.key]?.onClick()
                        : () => {};
                    }}
                    disabled={row[header.key]?.icon?.disabled}
                    style={{ width: 'fit-content' }}
                  >
                    {getStatusIcon(row[header.key]?.icon?.value)}
                  </TertiaryButton>
                </ToolTipLocal>
              ) : (
                <TertiaryButton
                  onClick={e => {
                    e.stopPropagation();
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    row[header.key]?.onClick
                      ? row[header.key]?.onClick()
                      : () => {};
                  }}
                  disabled={row[header.key]?.icon?.disabled}
                  style={{ width: 'fit-content' }}
                >
                  {getStatusIcon(row[header.key]?.icon?.value)}
                </TertiaryButton>
              )
            ) : (
              ''
            )}
          </div>
          {row[header.key]?.icon?.visible &&
          !row[header.key]?.icon?.showToolTip ? (
            <span className='helper-text'>{row[header.key]?.icon?.title}</span>
          ) : (
            ''
          )}
        </div>
      </td>
    ) : (
      <td style={props.tableDataCellStyle}>--</td>
    );
  };

  const getTableCellDataForButton = (row, header) => {
    return (
      <td style={props.tableDataCellStyle}>
        <TertiaryButton
          onClick={e => {
            e.stopPropagation();
            row[header.key]?.onClick();
          }}
        >
          {row[header.key]?.value}
        </TertiaryButton>{' '}
      </td>
    );
  };

  const getTableCellDataForEditableId = (row, header) => {
    return (
      <td style={props.tableDataCellStyle}>
        <span
          className='id-with-edit'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {row[header.key]}{' '}
          <Tooltip
            title={
              <span
                style={{ fontSize: '12px' }}
              >{`Edit this ${props.title}`}</span>
            }
          >
            <IconButton onClick={() => props.onRowClick(row.id)}>
              <EditOutlinedIcon
                fontSize='small'
                sx={{
                  color: variables.colorPrimary,
                  height: '20px',
                  width: '20px',
                }}
              />
            </IconButton>
          </Tooltip>
        </span>
      </td>
    );
  };

  return (
    <div style={{ margin: tableStyle.margin ? tableStyle.margin : '16px' }}>
      <table style={tableStyle} className='data-table'>
        <tr className='data-table-row'>
          {props.tableHeaders.map((header, index) => (
            <th
              style={props.tableHeaderCellStyle}
              key={`key_${index.toString()}`}
            >
              <span style={props.tableHeaderCellStyle}>
                {header.title}
                {header.sortable ? (
                  <TertiaryButton
                    style={{ minWidth: 'none', width: 'fit-content' }}
                    onClick={() => sortData(header.key)}
                  >
                    {getSortingIcon(header.key)}
                  </TertiaryButton>
                ) : (
                  ''
                )}
              </span>
            </th>
          ))}
        </tr>
        {tableData.map(row => (
          <tr key={row.id} className='data-table-row'>
            {props.tableHeaders.map((header, index) => {
              if (header.isMultiValue)
                return getTableCellDataForMultiValue(row, header);
              if (header.needSubtitle)
                return getTableCellDataForSubtitledValue(row, header);
              if (header.isStatusValue)
                return getTableCellDataForStatusValue(row, header);
              if (typeof row[header.key] === 'object' && row[header.key]?.value)
                return getTableCellDataForButton(row, header);
              if (header.key === 'id' && props.onRowClick)
                return getTableCellDataForEditableId(row, header);
              return (
                <td
                  style={props.tableDataCellStyle}
                  key={`key_${index.toString()}`}
                >{`${row[header.key] ? row[header.key] : 'N/A'}`}</td>
              );
            })}
          </tr>
        ))}
      </table>
    </div>
  );
};

export default DataTable;

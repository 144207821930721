import React from 'react';
import PropTypes from 'prop-types';
import './flash_message.scss';
import variables from 'assets/stylesheets/_variables.scss';
import { IconButton, Typography } from '@mui/material';
import { IconWithRectangularBorder } from 'components/UtilityComponents';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneIcon from '@mui/icons-material/Done';

const FlashMessage = props => {
  return (
    <div
      className={`flash-message ${
        props.type === 'success' ? 'success' : 'error'
      }`}
    >
      <div className='message-content'>
        {props.type === 'success' ? (
          <IconWithRectangularBorder
            backgroundColor={variables.colorAlertPositiveDark}
            borderColor={variables.colorWhite}
            icon={
              <DoneIcon
                sx={{
                  fontSize: '26px',
                  color: variables.colorWhite,
                }}
              />
            }
          />
        ) : (
          <IconWithRectangularBorder
            backgroundColor={variables.colorAlertNegativeDark}
            borderColor={variables.colorWhite}
            icon={
              <CloseOutlinedIcon
                sx={{
                  fontSize: '26px',
                  color: variables.colorWhite,
                }}
              />
            }
          />
        )}
        <span className='message'>{props.message}</span>
      </div>
      <div className='toaster-close-icon'>
        <IconButton onClick={event => props.onRemoveToast(event, props.id)}>
          <CloseOutlinedIcon sx={{ color: variables.colorPrimary }} />
        </IconButton>
      </div>
    </div>
  );
};

export default FlashMessage;

/*
 *
 * DashBoard constants
 *
 */

export const GET_PROVIDER_SEARCH_REQUEST_SENT =
  'app/Dashboard/GET_PROVIDER_SEARCH_REQUEST_SENT';
export const GET_PROVIDER_SEARCH_REQUEST_SUCCEED =
  'app/Dashboard/GET_PROVIDER_SEARCH_REQUEST_SUCCEED';
export const GET_PROVIDER_SEARCH_REQUEST_FAILED =
  'app/Dashboard/GET_PROVIDER_SEARCH_REQUEST_FAILED';

export const GET_PROVIDER_FILTERS_REQUEST_SENT =
  'app/Dashboard/GET_PROVIDER_FILTERS_REQUEST_SENT';
export const GET_PROVIDER_FILTERS_REQUEST_SUCCEED =
  'app/Dashboard/GET_PROVIDER_FILTERS_REQUEST_SUCCEED';
export const GET_PROVIDER_FILTERS_REQUEST_FAILED =
  'app/Dashboard/GET_PROVIDER_FILTERS_REQUEST_FAILED';

export const GET_PROVIDER_PATIENTS_RANGE_REQUEST_SENT =
  'app/Dashboard/GET_PROVIDER_PATIENTS_RANGE_REQUEST_SENT';
export const GET_PROVIDER_PATIENTS_RANGE_REQUEST_SUCCEED =
  'app/Dashboard/GET_PROVIDER_PATIENTS_RANGE_REQUEST_SUCCEED';
export const GET_PROVIDER_PATIENTS_RANGE_REQUEST_FAILED =
  'app/Dashboard/GET_PROVIDER_PATIENTS_RANGE_REQUEST_FAILED';

export const ADD_NEW_PATIENT_REQUEST_SENT =
  'app/Dashboard/ADD_NEW_PATIENT_REQUEST_SENT';
export const ADD_NEW_PATIENT_REQUEST_SUCCEED =
  'app/Dashboard/ADD_NEW_PATIENT_REQUEST_SUCCEED';
export const ADD_NEW_PATIENT_REQUEST_FAILED =
  'app/Dashboard/ADD_NEW_PATIENT_REQUEST_FAILED';

export const GET_EHR_ACTIVITY_REQUEST_SENT =
  'app/Dashboard/GET_EHR_ACTIVITY_REQUEST_SENT';
export const GET_EHR_ACTIVITY_REQUEST_SUCCEED =
  'app/Dashboard/GET_EHR_ACTIVITY_REQUEST_SUCCEED';
export const GET_EHR_ACTIVITY_REQUEST_FAILED =
  'app/Dashboard/GET_EHR_ACTIVITY_REQUEST_FAILED';

export const GET_APPOINTMENTS_SEARCH_REQUEST_SENT =
  'app/Dashboard/GET_APPOINTMENTS_SEARCH_REQUEST_SENT';
export const GET_APPOINTMENTS_SEARCH_REQUEST_SUCCEED =
  'app/Dashboard/GET_APPOINTMENTS_SEARCH_REQUEST_SUCCEED';
export const GET_APPOINTMENTS_SEARCH_REQUEST_FAILED =
  'app/Dashboard/GET_APPOINTMENTS_SEARCH_REQUEST_FAILED';

export const GET_APPOINTMENTS_SEARCH_WITHIN_RANGE_REQUEST_SENT =
  'app/Dashboard/GET_APPOINTMENTS_SEARCH_WITHIN_RANGE_REQUEST_SENT';
export const GET_APPOINTMENTS_SEARCH_WITHIN_RANGE_REQUEST_SUCCEED =
  'app/Dashboard/GET_APPOINTMENTS_SEARCH_WITHIN_RANGE_REQUEST_SUCCEED';
export const GET_APPOINTMENTS_SEARCH_WITHIN_RANGE_REQUEST_FAILED =
  'app/Dashboard/GET_APPOINTMENTS_SEARCH_WITHIN_RANGE_REQUEST_FAILED';

export const SET_SELECTED_FILTER_ID = 'app/Dashboard/SET_SELECTED_FILTER_ID';

export const RESET_STATE = 'app/Dashboard/RESET_STATE';

import {
  PrimaryButton,
  SecondaryAlphaButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import {
  fetchConsentsListOnAdminPageRequest,
  fetchLocationsListOnAdminPageRequest,
  fetchUsersListOnAdminPageRequest,
  updateConsentAdminValue,
  updateLocationAdminValue,
} from '../action';

const ConsentFilter = props => {
  const dispatch = useDispatch();

  const [consentData, setConsentData] = useState({
    filterName: null,
  });

  const consentAdminReducer = useSelector(store => store.consentAdminReducer);

  const [errors, setErrors] = useState({});
  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'filterName',
      label: 'Title',
      type: 'text',
      placeholder: 'Type Title here',
      value: consentData.filterName,
      visible: true,
    },
  ];

  const onSubmit = () => {
    dispatch(updateConsentAdminValue('filterName', consentData.filterName));
    dispatch(updateConsentAdminValue('page', 1));
  };

  useEffect(() => {
    setConsentData({
      filterName: consentAdminReducer.filterName,
    });
  }, [consentAdminReducer.filterName]);

  return (
    <div className='filters-container'>
      <div className='filters-label'>Filters</div>
      <FormGenerator
        className='add-edit-form'
        id='consentFilter'
        formConfig={config}
        errors={errors}
        onChange={(key, value) => {
          clearErrorsForField(errors, key);
          setConsentData({ filterName: value });
        }}
        buttons={[
          <PrimaryButton
            style={{ maxWidth: 'none' }}
            disabled={!consentData.filterName}
            className={consentData.filterName ? 'cursor-pointer' : ''}
            onClick={() => {
              setConsentData({ filterName: '' });
              dispatch(updateConsentAdminValue('filterName', ''));
            }}
          >
            Clear All
          </PrimaryButton>,
          <SecondaryButton
            style={{ maxWidth: 'none' }}
            type='submit'
            onClick={() => onSubmit()}
            form='consentFilter'
          >
            Apply Filters
          </SecondaryButton>,
        ]}
        {...props}
      />
    </div>
  );
};

export default ConsentFilter;

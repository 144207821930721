/*
 *
 * CONNECT constants
 *
 */

export const GET_NOTES_REQUEST_SENT = 'app/Connect/GET_NOTES_REQUEST_SENT';
export const GET_NOTES_REQUEST_SUCCEED =
  'app/Connect/GET_NOTES_REQUEST_SUCCEED';
export const GET_NOTES_REQUEST_FAILED = 'app/Connect/GET_NOTES_REQUEST_FAILED';

export const POST_CREATE_NOTES_REQUEST_SENT =
  'app/Connect/POST_CREATE_NOTES_REQUEST_SENT';
export const POST_CREATE_NOTES_REQUEST_SUCCEED =
  'app/Connect/POST_CREATE_NOTES_REQUEST_SUCCEED';
export const POST_CREATE_NOTES_REQUEST_FAILED =
  'app/Connect/POST_CREATE_NOTES_REQUEST_FAILED';

export const EMPTY_COMMUNICATION_LOG_MESSAGE =
  'Tap on Create Note to create one';

export const IS_NOTE_DETAIL_VIEW = 'app/Connect/IS_NOTE_DETAIL_VIEW';
export const SELECTED_NOTE_ID = 'app/Connect/SELECTED_NOTE_ID';

export const MARK_AS_READ_ALL_NOTES_REQUEST_SENT =
  'app/Connect/MARK_AS_READ_ALL_NOTES_REQUEST_SENT';
export const MARK_AS_READ_ALL_NOTES_REQUEST_SUCCEED =
  'app/Connect/MARK_AS_READ_ALL_NOTES_REQUEST_SUCCEED';
export const MARK_AS_READ_ALL_NOTES_REQUEST_FAILED =
  'app/Connect/MARK_AS_READ_ALL_NOTES_REQUEST_FAILED';

import React, { useState } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import {
  timeWithTimezoneString,
  dateStringToCustomDateTime,
} from 'utils/dateUtils';
import './createdByCard.scss';

const SHARED = 'Shared';
const PRIVATE = 'Private';

const NoteCreatedByCard = props => {
  const location = useLocation();
  const urlQuery = queryString.parse(location.search);

  const [noteId, setNoteId] = useState(urlQuery.note_id);

  const displayCreatedName =
    props.createdById === props.currentUserId ? 'You' : props.createdByName;
  const cardLabel = noteId
    ? `Note added by : ${displayCreatedName} on `
    : `System Notification | Last modified: ${displayCreatedName}`;
  return (
    <div className='created-by-container'>
      <span className='created-by-label'>{`${cardLabel} `}</span>
      <span className='created-by-time'>
        {noteId
          ? dateStringToCustomDateTime(props.createdAt)
          : `@ ${timeWithTimezoneString(props.createdAt)}`}
      </span>
    </div>
  );
};

export default NoteCreatedByCard;

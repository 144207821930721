import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { getValidationsObject } from 'utils/stringUtils';
import PropTypes from 'prop-types';
import { saveAddResourceFormData } from '../action';

const AddResourceStepTwoForm = props => {
  const dispatch = useDispatch();

  const resourceAdminData = useSelector(store => store.resourceAdminReducer);
  const adminData = useSelector(store => store.AdminReducer);

  const [errors, setErrors] = useState({});
  const [resourceData, setResourceData] = useState({
    value: '',
    displayValue: '',
    action: null,
    isValueDisplayed: false,
  });

  useEffect(() => {
    setResourceData({
      ...resourceData,
      value: resourceAdminData.value,
      displayValue: resourceAdminData.displayValue,
      action: resourceAdminData.action,
      isValueDisplayed: resourceAdminData.isValueDisplayed,
    });
  }, [
    resourceAdminData.value,
    resourceAdminData.displayValue,
    resourceAdminData.action,
    resourceAdminData.isValueDisplayed,
  ]);

  const getValueFieldTypeForForm = () => {
    switch (resourceData.action?.value) {
      case 'call':
        return 'phone';
      case 'email':
        return 'input';
      case 'open_link':
        return 'masked_input';
      default:
        return 'input';
    }
  };

  const getValueValidationForForm = () => {
    const validationObj = [
      { rule: 'isRequired', message: 'Value is required' },
    ];
    switch (resourceData.action?.value) {
      case 'email':
        validationObj.push({
          rule: 'isEmail',
          message: 'Invalid Email is Provided For action',
        });
        break;
      case 'call':
        validationObj.push({
          rule: 'isPhone',
          message: 'Invalid Mobile Number is Provided For action',
        });
        break;
      case 'open_link':
        validationObj.push({
          rule: 'isValidURL',
          message: 'Invalid URL is Provided For action',
        });
        break;
      default:
        break;
    }
    return validationObj;
  };

  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'displayValue',
      label: 'Display Value ( Value to be shown to patient )',
      type: 'text',
      placeholder: 'Enter Display Value',
      validation: [
        { rule: 'isRequired', message: 'Display Value is required' },
      ],
      value: resourceData.displayValue,
      visible: true,
      required: true,
    },
    {
      id: 2,
      input_type: 'single_select',
      placeholder: 'Select Action',
      label:
        'Select Action ( Select Action needs to be performed on this Resource )',
      name: 'action',
      value: resourceData.action,
      options: adminData.actions,
      validation: [{ rule: 'isRequired', message: 'Action is required' }],
      visible: true,
      required: true,
    },
    {
      id: 3,
      input_type: getValueFieldTypeForForm(),
      name: 'value',
      label: 'Value ( Enter Value for Selected Action )',
      type: 'text',
      mask:
        resourceData.action?.label === 'call' ? '+1 999-999-9999' : 'https://',
      placeholder: 'Enter Value',
      validation: getValueValidationForForm(),
      value: resourceData.value,
      visible: true,
      required: true,
    },
    {
      id: 4,
      input_type: 'checkbox',
      label: 'should value be displayed ?',
      name: 'isValueDisplayed',
      value: resourceData.isValueDisplayed,
      visible: true,
    },
  ];

  const { onContinue, onBack, step } = props;

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      resourceData,
      errorsInForm => {
        setErrors(errorsInForm);
      },
      () => {
        dispatch(saveAddResourceFormData(resourceData));
        onContinue(step + 1);
      }
    );
  };

  return (
    <FormGenerator
      id='addResourceStepTwoForm'
      className='add-edit-form'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        const data = {};
        if (key === 'action') {
          data.value = '';
        }
        data[key] = value;
        setResourceData({ ...resourceData, ...data });
      }}
      onSubmit={() => {
        onSubmit();
      }}
      {...props}
      buttons={[
        <SecondaryButton
          style={{ width: '176px' }}
          onClick={() => {
            dispatch(saveAddResourceFormData(resourceData));
            onBack(step);
          }}
          key='secondaryButton'
        >
          <span>Back</span>
        </SecondaryButton>,
        <PrimaryButton
          form='addUserStepTwoForm'
          type='submit'
          style={{ width: '176px' }}
          onClick={() => {
            onSubmit();
          }}
          key='primaryButton'
        >
          <span>Continue</span>
        </PrimaryButton>,
      ]}
    />
  );
};

AddResourceStepTwoForm.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

export default AddResourceStepTwoForm;

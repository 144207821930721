import React from 'react';
import { isTBD, dateStringToCustomDate } from 'utils/dateUtils';
import DiaryCard from './diaryCard';
import './diaryBucket.scss';

const DiaryCardBucket = props => {
  const startDateDisplay = isTBD(props.date) ? 'TBD' : props.date;
  return (
    <div className='diary-bucket'>
      <div className='diary-header'>{startDateDisplay}</div>
      {props.diaryEntries.map(diary => (
        <DiaryCard key={diary.id} diary={diary} />
      ))}
    </div>
  );
};

export default DiaryCardBucket;

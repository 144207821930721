import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { saveAddTaskFormData, saveAddTaskReminderFormData } from '../action';

const AddTaskReminderStepTwoForm = props => {
  const dispatch = useDispatch();

  const data = useSelector(store => store.taskReminderAdminReducer);
  const adminData = useSelector(store => store.AdminReducer);

  const [errors, setErrors] = useState({});
  const [taskReminderData, setTaskReminderData] = useState({
    department: null,
    procedure: null,
    reminderType: null,
    type: null,
  });

  useEffect(() => {
    const updatedData = {};
    Object.keys(taskReminderData).forEach(
      key => (updatedData[key] = data[key])
    );
    setTaskReminderData({ ...taskReminderData, ...updatedData });
  }, [data]);

  const config = [
    {
      id: 1,
      input_type: 'single_select',
      name: 'department',
      label: 'Department',
      placeholder: 'Department',
      value: taskReminderData.department,
      options: adminData.departments,
      validation: [{ rule: 'isRequired', message: 'Department is required' }],
      visible: true,
    },
    {
      id: 2,
      input_type: 'single_select',
      name: 'procedure',
      label: 'Procedure',
      placeholder: 'Procedure',
      value: taskReminderData.procedure,
      options: adminData.procedures,
      validation: [{ rule: 'isRequired', message: 'Procedure is required' }],
      visible: true,
    },
    {
      id: 2,
      input_type: 'single_select',
      name: 'reminderType',
      label: 'Reminder Type',
      placeholder: 'Reminder Type',
      value: taskReminderData.reminderType,
      options: adminData.reminderTypes,
      validation: [
        { rule: 'isRequired', message: 'Reminder Type is required' },
      ],
      visible: true,
    },
    {
      id: 2,
      input_type: 'single_select',
      name: 'type',
      label: 'Type',
      placeholder: 'Type',
      value: taskReminderData.type,
      options: adminData.taskTypes,
      validation: [{ rule: 'isRequired', message: 'Type is required' }],
      visible: true,
    },
  ];

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      taskReminderData,
      errors => {
        setErrors(errors);
      },
      () => {
        dispatch(saveAddTaskReminderFormData(taskReminderData));
        props.onStepChange(props.step + 1);
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        setTaskReminderData({ ...taskReminderData, [key]: value });
      }}
      buttons={[
        <SecondaryButton
          style={{ width: '176px' }}
          onClick={() => props.onStepChange(props.step - 1)}
        >
          <span>Back</span>
        </SecondaryButton>,
        <PrimaryButton style={{ width: '176px' }} onClick={() => onSubmit()}>
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

export default AddTaskReminderStepTwoForm;

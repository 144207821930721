import {
  GET_SURVEYS_REQUEST_SENT,
  GET_SURVEYS_REQUEST_SUCCEED,
  GET_SURVEYS_REQUEST_FAILED,
  CLEAR_SURVEYS_REQUEST_SENT,
  CLEAR_SURVEYS_REQUEST_SUCCEED,
  CLEAR_SURVEYS_REQUEST_FAILED,
} from './constants';

const initialState = {
  isLoading: false,
  surveys: [],
};

function patientSurveyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEYS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SURVEYS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        surveys: [...action.surveys.surveys],
      };
    case GET_SURVEYS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_SURVEYS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case CLEAR_SURVEYS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_SURVEYS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default patientSurveyReducer;

import React from 'react';
import PropTypes from 'prop-types';
import { doGetDownloadLink } from 'services/documents';
import DownloadFile from 'components/DownloadFile';
import './attachment_card.scss';
import { getFileNameFromPath } from '../../utils/stringUtils';

const AttachmentCard = props => {
  const { attachment, isAdded } = props;

  const getAttachmentIcon = attachmentType => {
    switch (attachmentType) {
      case 'image':
        return (
          <span className='icon icon-font-a-attachment-jpeg primary-text-color' />
        );
      case 'audio':
        return (
          <span className='icon icon-font-a-sound-file primary-text-color' />
        );
      case 'video':
        return (
          <span className='icon icon-font-b-video-file primary-text-color' />
        );
      default:
        return (
          <span className='icon icon-font-b-generic-document primary-text-color' />
        );
    }
  };

  const handleAttachmentRemoved = attachmentId => {
    props.onAttachmentRemoved(attachmentId);
  };

  return (
    <div
      className={`separation-${props.index} attachment-row cursor-pointer`}
      key={attachment.id}
    >
      {isAdded ? (
        <span className='remove-attachment-container float-left'>
          <DownloadFile args={[attachment.id]} serviceCall={doGetDownloadLink}>
            <span
              role='button'
              tabIndex='0'
              className='attachment-name'
              title={getFileNameFromPath(
                attachment.originalFileName &&
                  attachment.originalFileName !== ''
                  ? attachment.originalFileName
                  : attachment.name
              )}
            >
              {getFileNameFromPath(
                attachment.originalFileName &&
                  attachment.originalFileName !== ''
                  ? attachment.originalFileName
                  : attachment.name
              )}
            </span>
          </DownloadFile>
          <span
            role='button'
            tabIndex='0'
            className='remove-attachment-icon icon icon-font-a-close remove-icon'
            onClick={() => handleAttachmentRemoved(attachment.id)}
          />
        </span>
      ) : (
        <DownloadFile args={[attachment.id]} serviceCall={doGetDownloadLink}>
          <span className='attachment-icon'>
            {getAttachmentIcon(attachment.mediaType)}
          </span>
          <span
            className='attachment-name'
            title={getFileNameFromPath(
              attachment.originalFileName && attachment.originalFileName !== ''
                ? attachment.originalFileName
                : attachment.name
            )}
          >
            {getFileNameFromPath(
              attachment.originalFileName && attachment.originalFileName !== ''
                ? attachment.originalFileName
                : attachment.name
            )}
          </span>
        </DownloadFile>
      )}
    </div>
  );
};

export default AttachmentCard;

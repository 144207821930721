/*
 *
 * CONNECT constants
 *
 */

export const GET_NOTES_DETAILS_REQUEST_SENT =
  'app/Connect/GET_NOTES_DETAILS_REQUEST_SENT';
export const GET_NOTES_DETAILS_REQUEST_SUCCEED =
  'app/Connect/GET_NOTES_DETAILS_REQUEST_SUCCEED';
export const GET_NOTES_DETAILS_REQUEST_FAILED =
  'app/Connect/GET_NOTES_DETAILS_REQUEST_FAILED';

export const POST_RESPOND_TO_NOTES_REQUEST_SENT =
  'app/Connect/POST_RESPOND_TO_NOTES_REQUEST_SENT';
export const POST_RESPOND_TO_NOTES_REQUEST_SUCCEED =
  'app/Connect/POST_RESPOND_TO_NOTES_REQUEST_SUCCEED';
export const POST_RESPOND_TO_NOTES_REQUEST_FAILED =
  'app/Connect/POST_RESPOND_TO_NOTES_REQUEST_FAILED';

export const PUT_EDIT_NOTES_REQUEST_SENT =
  'app/Connect/PUT_EDIT_NOTES_REQUEST_SENT';
export const PUT_EDIT_NOTES_REQUEST_SUCCEED =
  'app/Connect/PUT_EDIT_NOTES_REQUEST_SUCCEED';
export const PUT_EDIT_NOTES_REQUEST_FAILED =
  'app/Connect/PUT_EDIT_NOTES_REQUEST_FAILED';

export const PUT_EDIT_COMMENTS_REQUEST_SENT =
  'app/Connect/PUT_EDIT_COMMENTS_REQUEST_SENT';
export const PUT_EDIT_COMMENTS_REQUEST_SUCCEED =
  'app/Connect/PUT_EDIT_COMMENTS_REQUEST_SUCCEED';
export const PUT_EDIT_COMMENTS_REQUEST_FAILED =
  'app/Connect/PUT_EDIT_COMMENTS_REQUEST_FAILED';

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getProcedureManagersAction } from 'components/TeamInformation/actions';
import PropTypes from 'prop-types';
import PatientInfoCard from './ReusableFragments/PatientInfoCard';
import { TertiaryButton } from '../StyleGuideComponents/common/buttons';
import TeamInformation from '../TeamInformation';

import { colors } from '../StyleGuideComponents/StyleGuideCSS';

const TeamInfo = props => {
  const params = useParams();
  const dispatch = useDispatch();
  const [showTeamInformation, setShowTeamInformation] = useState(false);

  useEffect(() => {
    const { procedureId } = props;
    const paramsToAPI = {
      user_procedure_id: procedureId,
    };
    dispatch(getProcedureManagersAction(paramsToAPI));
  }, []);

  const teamInfo = useSelector(store => store.procedureManagers.miniTeam);

  const getTeamMemberString = teamInfoInArg => {
    const members = teamInfoInArg.map(team => {
      return team.fullName;
    });
    return members.join(', ');
  };

  const getMiniTeamDetails = () => {
    const keys = Object.keys(teamInfo);
    const team = keys.map(key => {
      return (
        <span className='team-info-key-value' key={key}>
          <span className='team-name-label'>{teamInfo[key].displayName}</span>
          <span className='team-name'>
            {getTeamMemberString(teamInfo[key]?.members) || 'NA'}
          </span>
        </span>
      );
    });
    return team;
  };
  const teamInformationHeader = () => {
    return (
      <>
        <div className='card-icon'>
          <PeopleOutlinedIcon
            fontSize='small'
            sx={{
              color: colors.colorPrimaryDark,
              height: '24px',
              width: '24px',
            }}
          />
        </div>
        <div className='card-title'>Team Information</div>
        <span className='action'>
          <TertiaryButton onClick={() => setShowTeamInformation(true)}>
            <span className='action-icon'>
              <VisibilityOutlinedIcon
                fontSize='small'
                sx={{
                  color: colors.colorPrimary,
                  height: '16px',
                  width: '16px',
                }}
              />
            </span>
            <span className='action-text'>See All</span>
          </TertiaryButton>
        </span>
      </>
    );
  };
  const teamInformationBody = () => {
    return <div className='team-info-body'>{getMiniTeamDetails()}</div>;
  };
  return (
    <PatientInfoCard
      id='team'
      className='team'
      header={teamInformationHeader()}
      content={teamInformationBody()}
    >
      <div className='header'>{teamInformationHeader()}</div>
      <div className='body-content'>{teamInformationBody()}</div>

      <TeamInformation
        show={showTeamInformation}
        procedureId={params.procedureId}
        onHide={() => setShowTeamInformation(false)}
      />
    </PatientInfoCard>
  );
};

TeamInfo.propTypes = {
  procedureId: PropTypes.number.isRequired,
};

export default TeamInfo;

/**
@author Dilip Joshi

@desc This is a Higher Order Component (HOC) that provides data for rendering the patient details page. This HOC takes a WrappedComponent as an argument and returns a new component that is enhanced with additional functionality, specifically configuring the tabs displayed on the page. 

The HOC pulls data from the URL parameters using the `useParams` hook. It then selects the relevant `userProcedures` data from the Redux store using the `useSelector` hook. Finally, using this `userProcedures` data, the HOC updates the configuration of a set of `tabs` that it returns to the WrappedComponent.

This set of `tabs` is created using the `useState` hook and initialized with the `patientDetailsConfig` object imported from a separate configuration file. The useEffect hook is used to update the `tabs` configuration when necessary, namely when the `compositeProcedure` or `hasOutcomeTemplates` data changes.

The HOC enhances the functionality of the WrappedComponent by passing it the `tabs` configuration as a prop. This allows the WrappedComponent to render a UI based on the updated `tabs` configuration.

Example of how to use the withPatientDetails HOC:
 */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { SHOW_OUTCOMES_CHART, ACCESS_CONTROL_KEYS } from 'constants';
import patientDetailsConfig from './patientDetailsConfig';

const withPatientDetails = WrappedComponent => {
  return function withConfig(props) {
    const params = useParams();

    const procedureId = parseInt(params.procedureId, 10) || -1;
    const userProcedures = useSelector(
      store => store.currentPatientInfo.patient.userProcedures
    );
    const hasOutcomeTemplates = useSelector(
      store => store.currentPatientInfo.hasOutcomeTemplates
    );
    const appFeatures = useSelector(store => store.currentUser.appFeatures);
    const compositeProcedure = userProcedures.find(up => up.id === procedureId);
    const [tabs, setTabs] = useState(patientDetailsConfig);

    // This function will update the keys in tabs
    const getUpdatedTabs = (oldTabs, dataToUpdate) => {
      let newTabs = [...oldTabs];
      dataToUpdate.forEach(data => {
        newTabs = newTabs.map(tab => ({
          ...tab,
          [data.key]: data.value(tab),
        }));
      });
      return newTabs;
    };

    useEffect(() => {
      /* Updating disabled field of the tab as per access control. */
      const updatedTabs = getUpdatedTabs(tabs, [
        {
          key: 'disabled',
          value: tab => !appFeatures[tab.keyForAccessControl].canView,
        },
      ]);
      setTabs(updatedTabs);
    }, []);

    useEffect(() => {
      if (compositeProcedure) {
        const updatedTabs = getUpdatedTabs(tabs, [
          {
            key: 'title',
            value: tab => ({
              ...tab.title,
              /* Updating the count of the tab. */
              count: tab?.title?.keyForMissedData
                ? compositeProcedure[tab?.title?.keyForMissedData]
                : tab.count,
            }),
          },
          /* Updating disabled field of the tab as per access control. */
          {
            key: 'disabled',
            value: tab => {
              if (
                tab.keyForAccessControl === ACCESS_CONTROL_KEYS.OUTCOMES_REPORTS
              ) {
                return !(SHOW_OUTCOMES_CHART && hasOutcomeTemplates);
              }
              return !appFeatures[tab.keyForAccessControl].canView;
            },
          },
        ]);
        setTabs(updatedTabs);
      }
    }, [compositeProcedure, hasOutcomeTemplates]);

    return <WrappedComponent {...props} tabs={tabs} />;
  };
};

export default withPatientDetails;

import React, { PureComponent } from 'react';
import './style.scss';
import Strings from 'strings';

const PassiveModeFooter = () => {
  return (
    <div className='passive-mode-footer'>
      <div className='title'>{`The  ${Strings.APPLICATION_NAME} Team`}</div>
      <div className='subtitle'>Questions and technical support:</div>
      <div className='contact-details'>
        <a href={Strings.SUPPORT_CONTACT_NUMBER}>
          {Strings.SUPPORT_CONTACT_NUMBER_STRING}
        </a>
        &nbsp;|&nbsp;
        <a href={`mailto:${Strings.SUPPORT_EMAIL}`}>{Strings.SUPPORT_EMAIL}</a>
      </div>
    </div>
  );
};

export default PassiveModeFooter;

import React from 'react';
import './styles.scss';
import PassiveModeFooter from 'components/PassiveModeFooter';
import FormGenerator from 'components/FormGenerator';
import { clearErrorsForField } from 'common/validator';
import {
  PrimaryButton,
  PrimaryButtonLarge,
  TertiaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import {
  dateWithTimeStringToMMSDateFormat,
  dateStringToCustomDate,
} from 'utils/dateUtils';
import DownloadFile from 'components/DownloadFile';
import useSiteClosureRequestViewController from './ViewController';

const ViewSiteClosureRequest: React.FC = () => {
  const {
    config,
    formData,
    setFormData,
    errors,
    submitPin,
    siteClosureRequestData,
    resendPin,
  } = useSiteClosureRequestViewController();

  if (siteClosureRequestData.requestKey && siteClosureRequestData.statusText) {
    return (
      <div className='view-site-closure-request-container'>
        <div className='welcome-header'>
          {siteClosureRequestData.statusText}
        </div>
        {siteClosureRequestData.isRequestCompleted ? (
          <div className='row request-data-status'>
            <div className='data-container'>
              <span className='lbl-name'>{`Requested on: `}</span>
              <span className='value'>
                {dateWithTimeStringToMMSDateFormat(
                  siteClosureRequestData.requestedAt
                )}
              </span>
            </div>
            <div className='data-container'>
              <span className='lbl-name'>{`Generated on: `}</span>
              <span className='value'>
                {dateWithTimeStringToMMSDateFormat(
                  siteClosureRequestData.preparedAt
                )}
              </span>
            </div>
            <div className='data-container'>
              <DownloadFile
                serviceCall={() =>
                  new Promise((resolve, reject) => {
                    resolve({
                      data: {
                        downloadLink: siteClosureRequestData.downloadLink,
                      },
                    });
                  })
                }
              >
                <PrimaryButtonLarge style={{ maxWidth: 'none' }}>
                  Download Data File
                </PrimaryButtonLarge>
              </DownloadFile>
              <div className='expiring-text'>{`(Expiring on: ${dateStringToCustomDate(
                siteClosureRequestData.expiredAt
              )})`}</div>
            </div>
          </div>
        ) : (
          ''
        )}
        <PassiveModeFooter />
      </div>
    );
  }

  return (
    <div className='view-site-closure-request-container'>
      <div className='welcome-header'>{`Please Enter Your PIN code: `}</div>
      <div className='login-form-container no-padding'>
        <div className='login-form-view text-center'>
          <div className='resend-code-message-and-text'>
            <div className=''>
              <br />
              We sent a PIN code to your email / mobile number:
              <br />
            </div>

            <TertiaryButton
              style={{ width: 'fit-content' }}
              onClick={resendPin}
              onKeyDown={() => {}}
            >
              Resend Code
            </TertiaryButton>
          </div>
          <FormGenerator
            id='enter-pin-form'
            className='enter-pin-form'
            formConfig={config}
            onChange={(name: string, value: string) => {
              clearErrorsForField(errors, name);
              setFormData({ ...formData, [name]: value });
            }}
            errors={errors}
          />
          <div className='text-center'>
            <PrimaryButton
              type='submit'
              form='enter-pin-form'
              onClick={submitPin}
              style={{ maxWidth: 'none' }}
            >
              {' '}
              Continue{' '}
            </PrimaryButton>
          </div>
        </div>
      </div>
      <PassiveModeFooter />
    </div>
  );
};

export default ViewSiteClosureRequest;

/*
 *
 * PatientDetailsPage actions
 *
 */
import {
  getPatientNotes,
  postCreateNotes,
  markAsReadPatientAllNotes,
} from 'services/connect';
import { setEditNotesStarted } from 'common/actions';
import { showToaster } from 'common/toasterActions';
import { resetDataForDoubleAuthetication } from 'components/MultiAuthenticatedRequest/actions';
import {
  GET_NOTES_REQUEST_SENT,
  GET_NOTES_REQUEST_SUCCEED,
  GET_NOTES_REQUEST_FAILED,
  POST_CREATE_NOTES_REQUEST_SENT,
  POST_CREATE_NOTES_REQUEST_SUCCEED,
  POST_CREATE_NOTES_REQUEST_FAILED,
  IS_NOTE_DETAIL_VIEW,
  SELECTED_NOTE_ID,
  MARK_AS_READ_ALL_NOTES_REQUEST_SENT,
  MARK_AS_READ_ALL_NOTES_REQUEST_SUCCEED,
  MARK_AS_READ_ALL_NOTES_REQUEST_FAILED,
} from './constants';

export const markAsReadPatientAllNotesRequestSent = () => ({
  type: MARK_AS_READ_ALL_NOTES_REQUEST_SENT,
});

export const markAsReadPatientAllNotesRequestSucceeded = data => ({
  type: MARK_AS_READ_ALL_NOTES_REQUEST_SUCCEED,
  data,
});

export const markAsReadPatientAllNotesRequestFailed = () => ({
  type: MARK_AS_READ_ALL_NOTES_REQUEST_FAILED,
});

export const getPatientNotesRequestSent = () => ({
  type: GET_NOTES_REQUEST_SENT,
});

export const getPatientNotesRequestSucceeded = data => ({
  type: GET_NOTES_REQUEST_SUCCEED,
  data,
});

export const getPatientNotesRequestFailed = () => ({
  type: GET_NOTES_REQUEST_FAILED,
});

export const createNotesRequestSent = () => ({
  type: POST_CREATE_NOTES_REQUEST_SENT,
});

export const createNotesRequestSucceeded = data => ({
  type: POST_CREATE_NOTES_REQUEST_SUCCEED,
  data,
});

export const createNotesRequestFailed = () => ({
  type: POST_CREATE_NOTES_REQUEST_FAILED,
});

export const setNoteDetailViewStatus = param => ({
  type: IS_NOTE_DETAIL_VIEW,
  data: param,
});

export const setSelectedNoteId = noteId => ({
  type: SELECTED_NOTE_ID,
  data: noteId,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getPatientNotesRequest =
  (patientId, userProcedureId) => dispatch => {
    dispatch(getPatientNotesRequestSent());
    getPatientNotes(patientId, userProcedureId)
      .then(response => {
        dispatch(getPatientNotesRequestSucceeded(response.data));
      })
      .catch(() => {
        dispatch(getPatientNotesRequestFailed());
      });
  };

export const markAsReadPatientAllNotesRequest =
  (patientId, userProcedureId) => dispatch => {
    dispatch(markAsReadPatientAllNotesRequestSent());
    markAsReadPatientAllNotes(patientId, userProcedureId)
      .then(response => {
        dispatch(markAsReadPatientAllNotesRequestSucceeded());
        dispatch(getPatientNotesRequest(patientId, userProcedureId));
      })
      .catch(() => {
        dispatch(markAsReadPatientAllNotesRequestFailed());
      });
  };

export const doPostCreateNotesRequest = params => dispatch => {
  dispatch(createNotesRequestSent());
  postCreateNotes(params)
    .then(response => {
      dispatch(createNotesRequestSucceeded(response.data.data));
      dispatch(setEditNotesStarted());
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: 'Note created successfully' }));
      dispatch(
        getPatientNotesRequest(params.user_id, params.user_procedure_id)
      );
    })
    .catch(() => {
      dispatch(createNotesRequestFailed());
    });
};

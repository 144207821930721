import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { InputBoxStyleGuide } from './StyleGuideCSS';

const SelectDropdown = props => {
  return (
    <FormControl className='select-dropdown' fullWidth size='small'>
      <TextField
        select
        id={props.id || 'selecd_dropdown_id'}
        size={props.size || 'medium'}
        value={props.value}
        label={props.isInlineLabelNeeded ? props.label : ''}
        error={props.error}
        onChange={props.handleChange}
        required
      >
        {props.options
          ? props.options.map(option => (
              <MenuItem value={option}> {option.label}</MenuItem>
            ))
          : []}
      </TextField>
      <FormHelperText id='outlined-weight-helper-text' className='no-margin'>
        <p
          style={{
            color: `${InputBoxStyleGuide.colorAlertNegative}`,
            fontSize: '12px',
          }}
        >
          {props.error && props.errorText ? props.errorText : ''}
        </p>
      </FormHelperText>
    </FormControl>
  );
};

export default SelectDropdown;

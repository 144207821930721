import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { saveAddTaskFormData } from '../action';

const AddTaskStepThreeForm = props => {
  const dispatch = useDispatch();

  const data = useSelector(store => store.taskAdminReducer);

  const [errors, setErrors] = useState({});
  const [taskData, setTaskData] = useState({
    offsetInDays: '',
    timings: '',
    expiryOffsetInDays: '',
    expiryTimings: null,
    timeOffset: '',
  });

  useEffect(() => {
    const updatedData = {};
    Object.keys(taskData).forEach(key => (updatedData[key] = data[key]));
    setTaskData({ ...taskData, ...updatedData });
  }, [data]);

  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'offsetInDays',
      label: 'Offset In Days',
      type: 'number',
      placeholder: 'Offset In Days',
      value: taskData.offsetInDays,
      visible: true,
    },
    {
      id: 2,
      input_type: 'input',
      name: 'timings',
      label: 'Timings',
      type: 'time',
      placeholder: 'Timings',
      value: taskData.timings,
      visible: true,
    },

    {
      id: 4,
      input_type: 'input',
      name: 'expiryOffsetInDays',
      label: 'Expiry Offset In Days',
      type: 'number',
      placeholder: 'Expiry Offset In Days',
      value: taskData.expiryOffsetInDays,
      visible: true,
    },
    {
      id: 5,
      input_type: 'input',
      name: 'expiryTimings',
      label: 'Expiry Timings',
      type: 'time',
      placeholder: 'Expiry Timings',
      value: taskData.expiryTimings,
      visible: true,
    },
    {
      id: 6,
      input_type: 'input',
      name: 'timeOffset',
      label: 'Time Offset',
      type: 'number',
      placeholder: 'Time Offset',
      value: taskData.timeOffset,
      visible: true,
    },
  ];

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      taskData,
      errors => {
        setErrors(errors);
      },
      () => {
        dispatch(saveAddTaskFormData(taskData));
        props.onStepChange(props.step + 1);
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        setTaskData({ ...taskData, [key]: value });
      }}
      buttons={[
        <SecondaryButton
          style={{ width: '176px' }}
          onClick={() => props.onStepChange(props.step - 1)}
        >
          <span>Back</span>
        </SecondaryButton>,
        <PrimaryButton style={{ width: '176px' }} onClick={() => onSubmit()}>
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

export default AddTaskStepThreeForm;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SingleSelectWithBorder from 'components/SelectV2';
import { DEFAULT_PAGINATION_LIMIT } from 'constants';
import { PrimaryButton } from '../StyleGuideComponents/common/buttons';
import './pagination.scss';

const LIMIT_OPTIONS = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];

const Pagination = props => {
  const {
    pagination,
    handlePagination,
    title,
    showLimit,
    handleLimitChange,
    limit,
  } = props;
  const [pageNumber, setPageNumber] = useState(pagination && pagination.page);

  const goToPrevPage = () => {
    if (pagination.prevPage) {
      handlePagination(pagination.prevPage);
    }
  };

  const goToNextPage = () => {
    if (pagination.nextPage) {
      handlePagination(pagination.nextPage);
    }
  };

  const goToPage = () => {
    if (pageNumber && pageNumber <= pagination.totalPages && pageNumber > 0) {
      handlePagination(pageNumber);
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      goToPage();
    }
  };

  const onPageInputValueChange = e => {
    setPageNumber(parseInt(e.target.value, 10));
  };

  useEffect(() => {
    if (pagination.page !== pageNumber) {
      setPageNumber(pagination.page);
      if (!pagination.avoidScroll) {
        window.scrollTo(0, 0);
      }
    }
  }, [pagination]);

  const paginationTitle = title || 'Subjects';

  return pagination.total > 0 ? (
    <div className='pagination-container'>
      <div className='pagination-body-container text-right no-padding'>
        {showLimit ? (
          <>
            <span>View:</span>
            <SingleSelectWithBorder
              id='pagination-limit-id'
              className='pagination-limit'
              onChange={({ value }) => {
                handleLimitChange(value);
              }}
              value={LIMIT_OPTIONS.find(
                obj => obj.value === parseInt(limit, 10)
              )}
              options={LIMIT_OPTIONS}
              menuPlacement='top'
              isSearchable={false}
            />
          </>
        ) : (
          ''
        )}
        <span>
          {`${paginationTitle} ${pagination.startNumber}-${pagination.endNumber}`}{' '}
          <span>
            (of&nbsp;
            {pagination.total})
          </span>
        </span>
        <div className='go-to-page'>
          <div className='page-input'>
            <input
              type='number'
              min={1}
              value={pageNumber || ''}
              onChange={onPageInputValueChange}
              onKeyPress={handleKeyPress}
            />
          </div>
          <PrimaryButton
            className='btn-pagination goto-btn-pagination'
            onClick={goToPage}
          >
            <span style={{ fontSize: '12px', fontWeight: '400' }}>
              {' '}
              Go to Page{' '}
            </span>
          </PrimaryButton>
        </div>
        <div
          className={`prev-page page-nav-icon ${
            pagination.prevPage === null ? '' : ''
          }`}
          onClick={goToPrevPage}
          role='button'
          onKeyDown={() => {}}
          tabIndex='0'
        >
          <span className='icon icon-font-a-left-chevron' />
        </div>
        <div
          className={`next-page page-nav-icon ${
            pagination.nextPage === null ? '' : ''
          }`}
          onClick={goToNextPage}
          role='button'
          onKeyDown={() => {}}
          tabIndex='0'
        >
          <span className='icon icon-font-a-right-chevron' />
        </div>
      </div>
      <div className='clearfix' />
    </div>
  ) : null;
};

Pagination.propTypes = {
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    prevPage: PropTypes.number,
    nextPage: PropTypes.number,
    totalPages: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    startNumber: PropTypes.number.isRequired,
    endNumber: PropTypes.number.isRequired,
    avoidScroll: PropTypes.bool,
  }).isRequired,
  handlePagination: PropTypes.func.isRequired,
  title: PropTypes.string,
  showLimit: PropTypes.bool,
  handleLimitChange: PropTypes.func,
  limit: PropTypes.number,
};

Pagination.defaultProps = {
  title: 'Subjects',
  showLimit: false,
  handleLimitChange: () => {},
  limit: DEFAULT_PAGINATION_LIMIT,
};

export default Pagination;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import { dispatchActionWithCallBack } from 'common/actions';
import { showToaster } from 'common/toasterActions';
import {
  saveAddLocationFormData,
  validateIfLocationCodeIsUniq,
} from '../action';

const AddLocationStepOneForm = props => {
  const dispatch = useDispatch();

  const locationAdminData = useSelector(store => store.locationAdminReducer);

  const [errors, setErrors] = useState({});
  const [locationData, setLocationData] = useState({
    name: '',
    title: '',
    latitude: '',
    longitude: '',
    code: '',
  });

  useEffect(() => {
    setLocationData({
      ...locationData,
      title: locationAdminData.title,
      latitude: locationAdminData.latitude,
      longitude: locationAdminData.longitude,
      code: locationAdminData.code,
      name: locationAdminData.name,
    });
  }, [
    locationAdminData.title,
    locationAdminData.latitude,
    locationAdminData.longitude,
    locationAdminData.code,
  ]);

  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Enter Name',
      value: locationData.name,
      validation: [{ rule: 'isRequired', message: 'Name is required' }],
      visible: true,
      required: true,
    },
    {
      id: 2,
      input_type: 'input',
      name: 'title',
      label: 'Address',
      type: 'text',
      placeholder: 'Enter Address',
      value: locationData.title,
      validation: [{ rule: 'isRequired', message: 'Address is required' }],
      visible: true,
      required: true,
    },
    {
      id: 3,
      input_type: 'input',
      name: 'latitude',
      label: 'Latitude',
      type: 'text',
      placeholder: 'Enter Latitude',
      value: locationData.latitude,
      validation: [{ rule: 'isRequired', message: 'Latitude is required' }],
      visible: true,
      required: true,
    },
    {
      id: 4,
      input_type: 'input',
      name: 'longitude',
      label: 'Longitude',
      type: 'text',
      placeholder: 'Enter Longitude',
      value: locationData.longitude,
      validation: [{ rule: 'isRequired', message: 'Longitude is required' }],
      visible: true,
      required: true,
    },
    {
      id: 5,
      input_type: 'input',
      name: 'code',
      label: 'Enter Code',
      type: 'text',
      placeholder: 'Code',
      value: locationData.code,
      validation: [{ rule: 'isRequired', message: 'Code is required' }],
      visible: true,
      required: true,
    },
  ];

  const onContinueToNextStep = () => {
    dispatch(saveAddLocationFormData(locationData));
    props.onContinue(props.step + 1);
  };

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      locationData,
      errors => {
        setErrors(errors);
      },
      () => {
        if (!locationAdminData.selectedLocationId) {
          dispatch(validateIfLocationCodeIsUniq({ code: locationData.code }))
            .then(response => {
              if (response.data.isCodeUnique) {
                onContinueToNextStep();
              } else {
                setErrors({
                  ...errors,
                  code: 'Location with Given Code already exists',
                });
              }
            })
            .catch(() => {
              dispatch(
                showToaster({
                  message: 'Something went wrong while saving the data',
                })
              );
            });
        } else {
          onContinueToNextStep();
        }
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      id='addLocationStepOneForm'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        setLocationData({ ...locationData, [key]: value });
      }}
      buttons={[
        <PrimaryButton
          form='addLocationStepOneForm'
          type='submit'
          style={{ width: '176px' }}
          onClick={() => {
            onSubmit();
          }}
        >
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

export default AddLocationStepOneForm;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import { saveAddResourceFormData } from '../action';

const AddResourceStepOneForm = props => {
  const dispatch = useDispatch();

  const data = useSelector(store => store.resourceAdminReducer);
  const adminData = useSelector(store => store.AdminReducer);

  const [errors, setErrors] = useState({});
  const [resourceData, setResourceData] = useState({
    title: '',
    department: null,
    procedure: null,
    location: null,
  });

  useEffect(() => {
    setResourceData({
      ...resourceData,
      title: data.title,
      department: data.department,
      procedure: data.procedure,
      location: data.location,
    });
  }, [data]);

  const config = [
    {
      id: 1,
      input_type: 'single_select',
      placeholder: 'Select Department',
      label: 'Select Department',
      name: 'department',
      value: resourceData.department,
      options: adminData.departments,
      validation: [{ rule: 'isRequired', message: 'Department is required' }],
      visible: true,
      required: true,
    },
    {
      id: 2,
      input_type: 'single_select',
      placeholder: 'Select Procedure',
      label: 'Select Procedure',
      value: resourceData.procedure,
      name: 'procedure',
      options: adminData.procedures,
      validation: [{ rule: 'isRequired', message: 'Procedure is required' }],
      visible: true,
      required: true,
    },
    {
      id: 3,
      input_type: 'single_select',
      placeholder: 'Select Location',
      label: 'Select Location',
      value: resourceData.location,
      name: 'location',
      options: adminData.locations,
      validation: [{ rule: 'isRequired', message: 'Location is required' }],
      visible: true,
      required: true,
    },
    {
      id: 4,
      input_type: 'input',
      name: 'title',
      label: 'Title',
      type: 'text',
      placeholder: 'Enter Title',
      validation: [{ rule: 'isRequired', message: 'Title is required' }],
      value: resourceData.title,
      visible: true,
      required: true,
    },
  ];

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      resourceData,
      errors => {
        setErrors(errors);
      },
      () => {
        dispatch(saveAddResourceFormData(resourceData));
        props.onContinue(props.step + 1);
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      id='addResourceStepOneForm'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        setResourceData({ ...resourceData, [key]: value });
      }}
      buttons={[
        <PrimaryButton
          form='addResourceStepOneForm'
          type='submit'
          style={{ width: '176px' }}
          onClick={() => onSubmit()}
        >
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

export default AddResourceStepOneForm;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { snakeCase } from 'change-case-object';
import { dispatchActionWithCallBack } from 'common/actions';
import FormDataPreview from 'containers/AdminPage/FormDataPreview';
import { timeInMeridianFormat } from 'utils/dateUtils';
import { updateNotificationAdminValue } from 'containers/AdminPage/NotificationsAdmin/action';
import PropTypes from 'prop-types';
import { createTaskReminderRequest } from '../action';

const AddTaskReminderFormPreview = props => {
  const dispatch = useDispatch();

  const taskReminderAdminData = useSelector(
    store => store.taskReminderAdminReducer
  );

  const makeParamsReady = data => {
    const params = {};
    Object.keys(data).map(key => {
      if (
        key === 'type' ||
        key === 'procedure' ||
        key === 'department' ||
        key === 'relatedTaskIdentifier' ||
        key === 'reminderType'
      )
        params[snakeCase(key)] = taskReminderAdminData[key].value;
      else if (
        key === 'title' ||
        key === 'body' ||
        key === 'newReminderText' ||
        key === 'offsetInDays' ||
        key === 'timings' ||
        key === 'relatedTaskIdentifier' ||
        key === 'uniqueIdentifier'
      ) {
        if (key === 'timings') {
          if (!taskReminderAdminData[key]) params[snakeCase(key)] = 0;
          else
            params[snakeCase(key)] = timeInMeridianFormat(
              taskReminderAdminData[key]
            );
        } else params[snakeCase(key)] = taskReminderAdminData[key];
      }
      return params;
    });
    return params;
  };

  const onSubmit = () => {
    const { onClose } = props;
    const tasksData = makeParamsReady(taskReminderAdminData);
    const action = createTaskReminderRequest;
    dispatch(
      dispatchActionWithCallBack(
        action,
        {
          params: { id: taskReminderAdminData.selectedTaskId, ...tasksData },
        },
        () => {
          onClose();
          dispatch(
            updateNotificationAdminValue('showAddNotificationsInfoModal', true)
          );
        }
      )
    );
  };

  const dataToShow = [
    {
      title: 'Task Detail',
      editStep: 1,
      columnsData: [
        {
          label: 'TITLE',
          value: taskReminderAdminData.title,
          visible: taskReminderAdminData.title,
        },
        {
          label: 'NEW REMINDER TEXT',
          value: taskReminderAdminData.newReminderText,
          visible: taskReminderAdminData.newReminderText,
        },
        {
          label: 'BODY',
          value: taskReminderAdminData.body,
          visible: taskReminderAdminData.body,
        },
      ],
    },
    {
      title: 'TASK CONTEXT',
      editStep: 2,
      columnsData: [
        {
          label: 'DEPARTMENT',
          value: taskReminderAdminData.department?.label,
          visible: !!taskReminderAdminData.department?.value,
        },
        {
          label: 'PROCEDURE',
          value: taskReminderAdminData.procedure?.label,
          visible: !!taskReminderAdminData.procedure?.value,
        },
        {
          label: 'REMINDER TYPE',
          value: taskReminderAdminData.reminderType?.label,
          visible: taskReminderAdminData.reminderType?.value !== null,
        },
        {
          label: 'TYPE',
          value: taskReminderAdminData.type?.label,
          visible: !!taskReminderAdminData.type?.value,
        },
      ],
    },

    {
      title: 'TASK TIMINGS',
      editStep: 3,
      columnsData: [
        {
          label: 'OFFSET IN DAYS',
          value: taskReminderAdminData.offsetInDays,
          visible: true,
        },
        {
          label: 'TIMINGS',
          value: timeInMeridianFormat(taskReminderAdminData.timings),
          visible: taskReminderAdminData.timings,
        },
        {
          label: 'RELATED TASK IDENTIFIER',
          value: taskReminderAdminData.relatedTaskIdentifier?.value,
          visible: true,
        },
        {
          label: 'UNIQUE IDENTIFIER',
          value: taskReminderAdminData.uniqueIdentifier,
          visible: taskReminderAdminData.uniqueIdentifier,
        },
      ],
    },
  ];

  return (
    <FormDataPreview dataToShow={dataToShow} {...props} onSubmit={onSubmit} />
  );
};

AddTaskReminderFormPreview.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddTaskReminderFormPreview;

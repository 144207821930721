/** \n * Component Name: PatientDetailsTabContent
 * @author Dilip Joshi
 * @desc
 * The PatientDetailsTabContent is a React component that renders a div with className 'patient-details-tab-content-wrapper'.
 * Within this div, there are two inner divs with classNames 'wrapper-body' and none (for the footer), respectively.
 * These inner divs contain the values of props.header, props.body, and props.footer.
 *
 * Usage:
 * <PatientDetailsTabContent
 *     header={String}
 *     body={React Component/Object}
 *     footer={React Component/Object}
 * />
 *
 * Props:
 * - header: A string representing the header of the component.
 * - body: A React component or object that will be displayed within a div with className 'wrapper-body'.
 * - footer: A React component or object that will be displayed within a div with no className.
 *
 * Example:
 * <PatientDetailsTabContent
 *     header="Patient Details"
 *     body={<PatientDetailsList/>}
 *     footer={<Link to="/patientDetails">View all patient details</Link>}
 * />
 * */

import React from 'react';
import '../patientDetails.scss';

const PatientDetailsTabContent = props => {
  return (
    <div
      className={`patient-details-tab-content-wrapper ${
        props.className ? props.className : ''
      }`}
    >
      {props.header && <div style={{ marginTop: '16px' }}>{props.header}</div>}
      <div className='wrapper-body'>{props.body}</div>
      {props.footer && (
        <div style={{ marginBottom: '16px' }}>{props.footer}</div>
      )}
    </div>
  );
};

export default PatientDetailsTabContent;

import { showToaster } from 'common/toasterActions';

import {
  fetchAllResourcesOfTheDepartment,
  createResource,
  upadteResource,
} from 'services/admin';
import {
  FETCH_RESOURCES_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
  UPDATE_CREATE_RESOURCE_ADMIN_VALUE_IN_STORE,
  SAVE_ADD_RESOURCE_FORM_DATA,
  RESET_ADD_RESOURCE_DATA,
  SET_SELECTED_RESOURCE_ID,
  UPDATE_RESOURCES_DATA_AFTER_SORTING,
} from './constants';
import { resetDataForDoubleAuthetication } from '../../../components/MultiAuthenticatedRequest/actions';

const fetchResourcesListOnAdminPageRequestSucceeded = data => {
  return {
    type: FETCH_RESOURCES_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
    data: {
      resourcesHeaders: data.resourceAttributes,
      resourcesData: data.resources,
      pagination: data.pagination,
    },
  };
};

export const updateResourceAdminValue = (key, value) => {
  return {
    type: UPDATE_CREATE_RESOURCE_ADMIN_VALUE_IN_STORE,
    key,
    value,
  };
};

export const saveAddResourceFormData = data => {
  return {
    type: SAVE_ADD_RESOURCE_FORM_DATA,
    data,
  };
};

export const resetAddResourcesData = data => {
  return {
    data,
    type: RESET_ADD_RESOURCE_DATA,
  };
};

export const setSelectedResourceIdRequest = (id, resourceData) => {
  return {
    type: SET_SELECTED_RESOURCE_ID,
    data: { id, resourceData },
  };
};

export const updateResourcesData = data => {
  return {
    type: UPDATE_RESOURCES_DATA_AFTER_SORTING,
    data,
  };
};

export const createResourceRequest = params => dispatch => {
  createResource(params)
    .then(response => {
      dispatch(showToaster({ message: response.data.message }));
      dispatch(resetDataForDoubleAuthetication());
    })
    .catch(() => {});
};

export const updateResourceRequest = params => dispatch => {
  upadteResource(params)
    .then(response => {
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {});
};

export const fetchResourcesListOnAdminPageRequest = params => dispatch => {
  fetchAllResourcesOfTheDepartment(params)
    .then(response => {
      dispatch(fetchResourcesListOnAdminPageRequestSucceeded(response.data));
    })
    .catch(() => {});
};

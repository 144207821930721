import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import { clearErrorsForField } from 'common/validator';
import { updateUserAdminValue } from '../action';

const UserFilter = props => {
  const dispatch = useDispatch();

  const [usersData, setUsersData] = useState({
    filterNameOrEmail: '',
  });

  const userAdminReducer = useSelector(store => store.userAdminReducer);

  useEffect(() => {
    return () => {
      dispatch(updateUserAdminValue('filterNameOrEmail', ''));
    };
  }, []);

  useEffect(() => {
    setUsersData({
      filterNameOrEmail: userAdminReducer.filterNameOrEmail,
    });
  }, [userAdminReducer.filterNameOrEmail]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState({});
  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'filterNameOrEmail',
      label: 'Name/Email/Contact Number',
      type: 'text',
      placeholder: 'Type Name or Email or Contact Number here',
      value: usersData.filterNameOrEmail,
      visible: true,
    },
  ];

  const onSubmit = () => {
    dispatch(
      updateUserAdminValue('filterNameOrEmail', usersData.filterNameOrEmail)
    );
    dispatch(updateUserAdminValue('page', 1));
  };

  return (
    <div className='filters-container'>
      <div className='filters-label'>Filters</div>
      <FormGenerator
        className='add-edit-form'
        id='userFilter'
        formConfig={config}
        errors={errors}
        onChange={(key, value) => {
          clearErrorsForField(errors, key);
          setUsersData({ filterNameOrEmail: value });
        }}
        buttons={[
          <PrimaryButton
            style={{ maxWidth: 'none' }}
            disabled={!usersData.filterNameOrEmail}
            className={usersData.filterNameOrEmail ? 'cursor-pointer' : ''}
            onClick={() => {
              setUsersData({ filterNameOrEmail: '' });
              dispatch(updateUserAdminValue('filterNameOrEmail', ''));
            }}
            key='users_admin_primary_button'
          >
            Clear All
          </PrimaryButton>,
          <SecondaryButton
            form='userFilter'
            type='submit'
            style={{ maxWidth: 'none' }}
            onClick={() => onSubmit()}
            key='users_admin_secondary_button'
          >
            Apply Filters
          </SecondaryButton>,
        ]}
        {...props}
      />
    </div>
  );
};

export default UserFilter;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HelpBlock from 'components/HelpBlock';
import PhoneInput from 'components/PhoneInput';
import Datetime from 'react-datetime';
import validate, { clearErrorsForField } from 'common/validator';
import { patientInfoValidationConfigForEditPatient } from 'components/AddPatientView/validators';
import ZipInput from 'components/ZipInput';
import moment from 'moment';
import SingleSelectWithBorder from 'components/SelectV2';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import {
  getFormattedDate,
  dateStringToDateWithDateFormat,
} from 'utils/dateUtils';
import {
  updatePatientInfoAction,
  resetFlagForPatientInfoUpdated,
} from 'containers/PatientDetails/actions';
import {
  resetEditPatientStarted,
  validateEmailUniquness,
  validatePhoneUniquness,
  getCounterSigneRequest,
  getStatesListForDropdown,
  fetchCohortsListRequest,
} from 'common/actions';
import 'components/AddPatientView/style.scss';
import './styles.scss';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import PropTypes from 'prop-types';
import {
  EDIT_COUNTER_SIGNEE_DISCLAIMER_TEXT,
  ACCESS_CONTROL_KEYS,
} from 'constants';
import {
  PrimaryButton,
  SecondaryButton,
} from '../StyleGuideComponents/common/buttons';

const EditPatientForm = props => {
  const getValueForDropDown = (options, valueId, fields = null) => {
    const option = options.filter(item => {
      return item.id === valueId;
    });
    if (option.length) {
      let fieldValue = '';
      if (fields) {
        fields.forEach(field => {
          fieldValue = `${fieldValue + option[0][field]} `;
        });
        return { value: option[0].id, label: fieldValue };
      }
      return { value: option[0].id, label: option[0].name.trim() };
    }
    return '';
  };

  const getOptionsForDropDown = (options, fields = null) => {
    return options.map(option => {
      let fieldValue = '';
      if (fields) {
        fields.forEach(field => {
          fieldValue = `${fieldValue + option[field]} `;
        });
      } else {
        fieldValue = option.name;
      }
      return { value: option.id, label: fieldValue.trim() };
    });
  };

  const dispatch = useDispatch();
  const paramsInRoute = useParams();

  const statesOptions = useSelector(
    store => store.addPatientReducer.statesList
  );

  const appFeatures = useSelector(
    store => store.currentUser && store.currentUser?.appFeatures
  );
  const subjectInformation = useSelector(
    store => store.currentPatientInfo.patient
  );
  const isSubjectIdEditable = useSelector(
    store => store.currentPatientInfo.isSubjectIdEditable
  );
  const infoUpdated = useSelector(
    store => store.updatePatientInfoReducer.infoUpdated
  );

  const cohortOptions = useSelector(store => store.cohortsReducer.cohorts);
  const [errors, setErrors] = useState([]);
  const [firstName, setFirstName] = useState(
    subjectInformation.firstName || ''
  );
  const [lastName, setLastName] = useState(subjectInformation.lastName || '');
  const [addressLine1, setAddressLine1] = useState(
    subjectInformation.addressLine1 || ''
  );
  const [addressLine2, setAddressLine2] = useState(
    subjectInformation.addressLine2 ? subjectInformation.addressLine2 : ''
  );
  const [city, setCity] = useState(subjectInformation.city || '');
  const [state, setState] = useState(
    getValueForDropDown(statesOptions, subjectInformation.state) || ''
  );
  const [zipcode, setZipcode] = useState(subjectInformation.zipcode || '');
  const [email, setEmail] = useState(subjectInformation.email || '');
  const [contactNumber, setContactNumber] = useState(
    subjectInformation.contactNumber || ''
  );
  const [DOB, setDOB] = useState(
    getFormattedDate(subjectInformation.dob) || ''
  );
  const [subjectStudyId, setSubjectStudyId] = useState(
    subjectInformation.subjectStudyId ? subjectInformation.subjectStudyId : ''
  );
  const [cohort, setCohort] = useState(subjectInformation.cohort || null);
  const setEditPatientStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.setEditPatientStarted
  );
  const isCounterSigneEditable = useSelector(
    store => store.currentPatientInfo.isCounterSigneEditable
  );
  const [counterSigneId, setCounterSigneId] = useState(null);
  const counterSigne = useSelector(
    store => store.counterSigneReducer.counterSigne
  );
  const userProcedure = subjectInformation.userProcedures[0];

  const {
    onModalClose,
    formEditDisabled: formEditDisabledInProps,
    setFormEditDisabled: setFormEditDisabledInProps,
  } = props;

  const formEditDisabled = appFeatures[ACCESS_CONTROL_KEYS.PATIENT_DEMOGRAPHICS]
    .canEdit
    ? formEditDisabledInProps
    : true;

  useEffect(() => {
    dispatch(getStatesListForDropdown());
    dispatch(fetchCohortsListRequest({ user_procedure_id: userProcedure.id }));
  }, []);

  useEffect(() => {
    setState(getValueForDropDown(statesOptions, subjectInformation.state));
  }, [statesOptions]);

  useEffect(() => {
    if (infoUpdated) {
      onModalClose();
      dispatch(resetFlagForPatientInfoUpdated());
    }
  }, [infoUpdated]);

  useEffect(() => {
    if (setEditPatientStarted) {
      onModalClose();
      dispatch(resetEditPatientStarted());
    }
  }, [setEditPatientStarted]);

  useEffect(() => {
    const { procedureId, investigatorId, locationId } = userProcedure;
    const params = {
      id: procedureId,
      provider_id: investigatorId,
      location_id: locationId,
    };
    dispatch(getCounterSigneRequest(params));
    setFirstName(subjectInformation.firstName);
    setLastName(subjectInformation.lastName);
    setAddressLine1(subjectInformation.addressLine1);
    setAddressLine2(
      subjectInformation.addressLine2 ? subjectInformation.addressLine2 : ''
    );
    setCity(subjectInformation.city);
    setState(getValueForDropDown(statesOptions, subjectInformation.state));
    setZipcode(subjectInformation.zipcode);
    setEmail(subjectInformation.email);
    setContactNumber(subjectInformation.contactNumber);
    setDOB(getFormattedDate(subjectInformation.dob));
    setSubjectStudyId(
      subjectInformation.subjectStudyId ? subjectInformation.subjectStudyId : ''
    );
    setCounterSigneId(userProcedure.counterSigner.id);
  }, [subjectInformation]);

  const today = moment();

  const disableFutureDt = current => {
    return current.isBefore(today);
  };

  const changeFieldHandler = (e, setFunction) => {
    if (errors[e.target.name]) {
      setErrors(clearErrorsForField(errors, e.target.name));
    }
    setFunction(e.target.value);
  };

  const getTrimmedDOB = DOBInParam => {
    if (typeof DOBInParam === 'object') return DOBInParam;
    return DOBInParam.trim();
  };

  const submitForm = () => {
    const editPatientParams = {
      id: parseInt(paramsInRoute.patientId, 10),
      userProcedureId: parseInt(paramsInRoute.procedureId, 10),
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      email: email.trim(),
      contact_number: contactNumber.replace('+1', '').replace(/ /g, ''),
      address: {
        line_1: addressLine1.trim(),
        line_2: addressLine2.trim(),
        city: city.trim(),
        state: state.value.trim(),
        zipcode: zipcode.trim(),
      },
      user_procedure_id: paramsInRoute.procedureId,
      cohort_id: cohort.value,
    };
    if (isSubjectIdEditable)
      editPatientParams.subject_study_id = subjectStudyId.trim().length
        ? subjectStudyId.trim()
        : null;
    if (DOB.toString().length > 0)
      editPatientParams.dob = dateStringToDateWithDateFormat(
        getTrimmedDOB(DOB)
      );
    if (isCounterSigneEditable) {
      if (counterSigneId !== userProcedure.counterSigner.id) {
        editPatientParams.counter_signer_id = counterSigneId;
      }
    }
    editPatientParams.secure_action_code = SecureCodes.EDIT_PATIENT;
    editPatientParams.needs_reason = true;
    dispatch(
      secureActionWithDoubleAuthentication(
        updatePatientInfoAction,
        editPatientParams
      )
    );
  };

  const checkPhoneAndEmailUniqueness = (contactNumberInParam, emailInParam) => {
    const phone = contactNumberInParam.replace('+1', '').replace(/ /g, '');

    if (phone.length === 10) {
      const promiseArray = [];
      let isPhoneUnique = true;
      let isEmailUnique = true;
      if (subjectInformation.email !== emailInParam)
        promiseArray.push(
          dispatch(validateEmailUniquness({ email: emailInParam }))
        );
      if (subjectInformation.contactNumber !== phone)
        promiseArray.push(dispatch(validatePhoneUniquness({ phone })));
      Promise.all(promiseArray)
        .then(responses => {
          responses.forEach(response => {
            if (Object.keys(response.data).includes('isPhoneUnique'))
              isPhoneUnique =
                typeof response.data.isPhoneUnique === 'undefined'
                  ? true
                  : response.data.isPhoneUnique;

            if (Object.keys(response.data).includes('isEmailUnique'))
              isEmailUnique =
                response.data.isEmailUnique === 'undefined'
                  ? true
                  : response.data.isEmailUnique;
          });
          if (!isPhoneUnique)
            setErrors({
              ...errors,
              contactNumber: 'Patient with phone number already exists',
            });
          else if (!isEmailUnique)
            setErrors({
              ...errors,
              email: 'Patient with email already exists',
            });
          else submitForm();
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const onNextValidationSuccess = () => {
    checkPhoneAndEmailUniqueness(contactNumber, email);
  };

  const onNextValidationFailure = errorsInForm => {
    setErrors(errorsInForm);
    // dispatch(StopLoaderForSavingData())
  };

  const isDataUpdateOnForm = () => {
    return (
      firstName !== subjectInformation.firstName ||
      lastName !== subjectInformation.lastName ||
      addressLine1 !== subjectInformation.addressLine1 ||
      addressLine2 !== subjectInformation.addressLine2 ||
      city !== subjectInformation.city ||
      state !== subjectInformation.state ||
      zipcode !== subjectInformation.zipcode ||
      email !== subjectInformation.email ||
      contactNumber !== subjectInformation.contactNumber ||
      getFormattedDate(DOB) !== getFormattedDate(subjectInformation.dob) ||
      subjectStudyId !== subjectInformation.subjectStudyId
    );
  };

  const onSubmit = e => {
    e.preventDefault();
    if (isDataUpdateOnForm()) {
      const dataToValidate = {
        firstName,
        lastName,
        addressLine1: addressLine1.trim(),
        addressLine2,
        city,
        state,
        zipcode,
        email: email.trim(),
        contactNumber,
        cohort,
      };
      if (DOB.toString().length > 0) dataToValidate.DOB = getTrimmedDOB(DOB);

      validate(
        patientInfoValidationConfigForEditPatient,
        dataToValidate,
        onNextValidationFailure,
        onNextValidationSuccess
      );
    } else {
      onModalClose();
    }
  };

  return (
    <>
      <form id='edit-patient-form' className='edit-patient-form-body'>
        <div
          className={`form-group ${
            errors.firstName !== undefined ? 'has-error' : ''
          }`}
        >
          <label className='form-label required' htmlFor='firstName'>
            First Name
          </label>
          <input
            disabled={formEditDisabled}
            type='text'
            className='form-control'
            value={firstName}
            placeholder='Enter First Name'
            onChange={e => changeFieldHandler(e, setFirstName)}
            name='firstName'
          />
          <HelpBlock value={errors.firstName} />
        </div>
        <div
          className={`form-group ${
            errors.lastName !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='lastName' className='form-label required'>
            Last Name
          </label>
          <input
            type='text'
            className='form-control'
            value={lastName}
            placeholder='Enter Last Name'
            onChange={e => changeFieldHandler(e, setLastName)}
            name='lastName'
            disabled={formEditDisabled}
          />
          <HelpBlock value={errors.lastName} />
        </div>

        <div
          className={`form-group ${
            errors.addressLine1 !== undefined ? 'has-error' : ''
          }`}
        >
          <label
            htmlFor='addressLine1 required'
            className='form-label required'
          >
            Address Line 1
          </label>
          <input
            type='text'
            className='form-control'
            value={addressLine1}
            placeholder='Enter Address Line 1'
            onChange={e => changeFieldHandler(e, setAddressLine1)}
            name='addressLine1'
            disabled={formEditDisabled}
          />
          <HelpBlock value={errors.addressLine1} />
        </div>

        <div
          className={`form-group ${
            errors.addressLine2 !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='addressLine2' className='form-label'>
            Address Line 2 (Optional)
          </label>
          <input
            type='text'
            className='form-control'
            value={addressLine2}
            placeholder='Enter Address Line 2 (Optional)'
            onChange={e => changeFieldHandler(e, setAddressLine2)}
            name='addressLine2'
            disabled={formEditDisabled}
          />
          <HelpBlock value={errors.addressLine2} />
        </div>
        <div className='horizontal-form'>
          <div
            className={`form-group inline-form ${
              errors.city !== undefined ? 'has-error' : ''
            }`}
          >
            <label htmlFor='city' className='form-label required'>
              city
            </label>
            <input
              type='text'
              className='form-control'
              value={city}
              placeholder='City'
              onChange={e => changeFieldHandler(e, setCity)}
              name='city'
              disabled={formEditDisabled}
            />
            <HelpBlock value={errors.city} />
          </div>

          <div
            className={`form-group inline-form ${
              errors.state !== undefined ? 'has-error' : ''
            }`}
          >
            <label className='form-label required' htmlFor='state'>
              State
            </label>
            <SingleSelectWithBorder
              id='select-state-name'
              placeholder='State'
              disabled={formEditDisabled}
              onChange={({ value }) => {
                setErrors(clearErrorsForField(errors, 'state'));
                setState(() => {
                  const option = statesOptions.filter(stateOption => {
                    return stateOption.id === value;
                  });
                  if (option.length) {
                    return { value: option[0].id, label: option[0].name };
                  }
                  return '';
                });
              }}
              value={state}
              options={getOptionsForDropDown(statesOptions)}
            />
            <HelpBlock value={errors.state} />
          </div>

          <div
            className={`form-group inline-form ${
              errors.zipcode !== undefined ? 'has-error' : ''
            }`}
          >
            <label htmlFor='zipcode' className='form-label required'>
              Zip Code
            </label>

            <ZipInput
              className='form-control'
              id='zip'
              name='zipcode'
              placeholder='Zip Code'
              value={zipcode}
              onChange={e => changeFieldHandler(e, setZipcode)}
              disabled={formEditDisabled}
            />
            <HelpBlock value={errors.zipcode} />
          </div>
        </div>
        <div
          className={`form-group ${
            errors.email !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='email' className='form-label required'>
            Email address
          </label>
          <input
            type='text'
            className='form-control'
            value={email}
            placeholder='Enter Email Address'
            onChange={e => changeFieldHandler(e, setEmail)}
            name='email'
            disabled={formEditDisabled}
          />
          <HelpBlock value={errors.email} />
        </div>

        <div
          className={`form-group ${
            errors.contactNumber !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='phone' className='form-label required'>
            Phone Number
          </label>
          <PhoneInput
            className='form-control'
            id='phone'
            name='contactNumber'
            placeholder='Enter Phone Number'
            value={contactNumber}
            onChange={e => changeFieldHandler(e, setContactNumber)}
            disabled={formEditDisabled}
          />
          <HelpBlock value={errors.contactNumber} />
        </div>
        <div
          className={`form-group ${
            errors.counterSigneId !== undefined ? 'has-error' : ''
          }`}
        >
          <label className='form-label required' htmlFor='counterSigneId'>
            Consent Form Countersigner
          </label>
          <SingleSelectWithBorder
            id='select-counter-signe'
            disabled={!isCounterSigneEditable || formEditDisabled}
            placeholder='Select Consent Form Countersigner'
            onChange={({ value }) => {
              setCounterSigneId(value);
            }}
            value={getValueForDropDown(counterSigne, counterSigneId, [
              'firstName',
              'lastName',
            ])}
            options={getOptionsForDropDown(counterSigne, [
              'firstName',
              'lastName',
            ])}
          />
          <HelpBlock value={errors.counterSigneId} />
          {
            // eslint-disable-next-line no-nested-ternary
            !formEditDisabled ? (
              !isCounterSigneEditable ? (
                <HelpBlock
                  className='disclaimer-text'
                  value={EDIT_COUNTER_SIGNEE_DISCLAIMER_TEXT}
                />
              ) : (
                ''
              )
            ) : (
              ''
            )
          }
        </div>
        <div className='horizontal-form-bottom'>
          <div
            className={`form-group ${
              errors.DOB !== undefined ? 'has-error' : ''
            }`}
          >
            <label htmlFor='procedureDate'>Date of Birth</label>
            <Datetime
              timeFormat={false}
              dateFormat='MM-DD-YYYY'
              // style={{ display: 'bubble' }}
              className='date-picker-field float-left date-field form-control'
              inputProps={{
                placeholder: 'Enter Date of Birth',
                readOnly: false,
                disabled: formEditDisabled,
              }}
              closeOnSelect
              closeOnTab
              value={DOB}
              isValidDate={disableFutureDt}
              popperPlacement='top'
              popperModifiers={{
                flip: {
                  behavior: ['top'], // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false, // tell it not to try to stay within the view (prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false, // turn off since needs preventOverflow to be enabled
                },
              }}
              onChange={date => {
                setDOB(date);
                setErrors(clearErrorsForField(errors, 'DOB'));
              }}
            />
            <HelpBlock value={errors.DOB} />
          </div>
          <div
            className={`form-group ${
              errors.subjectStudyId !== undefined ? 'has-error' : ''
            }`}
          >
            <label className='form-label' htmlFor='subjectStudyId'>
              subject study id
            </label>
            <input
              disabled={formEditDisabled || !isSubjectIdEditable}
              type='text'
              className='form-control'
              value={subjectStudyId}
              placeholder='Enter Subject Study ID'
              onChange={e => changeFieldHandler(e, setSubjectStudyId)}
              name='subjectStudyId'
            />
          </div>
        </div>
        <div
          className={`form-group inline-form ${
            errors.cohort !== undefined ? 'has-error' : ''
          }`}
        >
          <label className='form-label required' htmlFor='cohort'>
            Cohort
          </label>
          <SingleSelectWithBorder
            id='select-cohort-name'
            placeholder='Select Cohort'
            disabled={formEditDisabled}
            onChange={selectedOption => {
              setErrors(clearErrorsForField(errors, 'cohort'));
              setCohort(selectedOption);
            }}
            value={cohort}
            options={cohortOptions}
          />
          <HelpBlock value={errors.cohort} />
        </div>
      </form>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '16px',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}
      >
        {appFeatures[ACCESS_CONTROL_KEYS.PATIENT_DEMOGRAPHICS].canEdit &&
          (formEditDisabledInProps ? (
            <SecondaryButton
              style={{ width: '152px' }}
              onClick={() => setFormEditDisabledInProps(false)}
            >
              Edit Subject Details
            </SecondaryButton>
          ) : (
            ''
          ))}
        <span>
          <PrimaryButton
            style={{ width: '256px' }}
            onClick={formEditDisabled ? onModalClose : onSubmit}
            form='edit-patient-form'
            type='submit'
          >
            {formEditDisabled ? 'Okay' : 'Save'}
          </PrimaryButton>
        </span>
      </div>
    </>
  );
};

EditPatientForm.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  formEditDisabled: PropTypes.bool.isRequired,
  setFormEditDisabled: PropTypes.func.isRequired,
};
export default EditPatientForm;

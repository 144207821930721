import DataTable from 'components/StyleGuideComponents/DataTable';
import React, { useEffect, useState } from 'react';

const LocationsData = props => {
  const getDataToShow = data => {
    const dataToShow = data.map(location => {
      const newObj = { ...location };
      newObj.locationProviders = newObj.locationProviders.map(
        provider => provider.label
      );
      newObj.locationAdvocates = newObj.locationAdvocates.map(loc => loc.label);
      newObj.pi = newObj.pi.label;
      newObj.locationDepartment = newObj.locationDepartment.label;

      return newObj;
    });
    return dataToShow;
  };

  return (
    <DataTable
      tableHeaders={props.data.locationHeaders}
      tableData={getDataToShow(props.data.locationsData)}
      onRowClick={id => props.updateSelectedLocationId(id)}
      tableDataCellStyle={{ textAlign: 'center' }}
      tableHeaderCellStyle={{ textAlign: 'center' }}
      title='Location'
    />
  );
};

export default LocationsData;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import { getPatientNotesRequest } from './action';
import './notes.scss';
import NoteDetails from './Components/NoteDetails';
import NotesList from './Components/NotesList';

const Notes = props => {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  const urlQuery = queryString.parse(location.search);
  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  const [noteId, setNoteId] = useState(urlQuery.note_id);

  useEffect(() => {
    if (props.isActive && !noteId)
      dispatch(getPatientNotesRequest(patientId, procedureId));
  }, [props.isActive, noteId]);

  useEffect(() => {
    setNoteId(queryString.parse(location.search).note_id);
  }, [location]);

  return (
    <>
      {noteId ? (
        <div>
          <NoteDetails noteId={noteId} />
        </div>
      ) : (
        <NotesList />
      )}
    </>
  );
};

export default Notes;

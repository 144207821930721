// import { times } from "lodash";
import IOSClientListener from "utils/NativeNavigation/IOSClientListener";
import AndroidClientListener from "utils/NativeNavigation/AndroidClientListener";

class ClientListenerIdentifier {
  platform: string = 'ios';

  constructor(platform: string) {
    this.platform = platform;
  }

  getClientListenerObject = () => {
    if (this.platform === "android") {
      return new AndroidClientListener();
    }
    if (this.platform === 'ios') {
      return new IOSClientListener();
    }
    return new IOSClientListener();
  };
}

export default ClientListenerIdentifier;

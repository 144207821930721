/** \n * StepThree Component
 * @author Dilip Joshi
 * @description
 * Displays the subject and study information entered by the user, along with edit and save buttons
 *
 * Props:
 * - onModalClose: on modal close handler
 * - moveToPage: function to change active step
 *
 * Uses:
 * - Usage example:
 *   <StepThree
 *      onModalClose={() => null}
 *      moveToPage={() => null}
 *   />
 *
 */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import variables from 'assets/stylesheets/_variables.scss';
import { onSaveNewPatientRequest } from 'containers/Dashboard/actions';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { dateStringToDateWithDateFormat } from 'utils/dateUtils';
import {
  clearStudyInformation,
  clearSubjectInformation,
  resetCreatePatientStarted,
} from 'common/actions';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import moment from 'moment';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';

const StepThree = props => {
  const dispatch = useDispatch();
  const addPatientData = useSelector(store => store.addPatientReducer);
  const createPatientStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.createPatientStarted
  );

  useEffect(() => {
    if (createPatientStarted) {
      props.onModalClose();
      dispatch(resetCreatePatientStarted());
    }
  }, [createPatientStarted]);

  const getTrimmedDOB = DOB => {
    if (typeof DOB === 'string') return new Date(DOB.trim());
    return DOB;
  };

  const onSaveNewPatient = () => {
    const data = {
      first_name: addPatientData.firstName.trim(),
      last_name: addPatientData.lastName.trim(),
      procedure_id: addPatientData.studyNameId.value,
      provider_id: addPatientData.masterProviderId.value,
      counter_signe_id: addPatientData.counterSigneId.value,
      contact_number: addPatientData.contactNumber
        .replace('+1', '')
        .replace(/ /g, ''),
      email: addPatientData.email.trim(),
      location_id: addPatientData.locationId.value,
      address: {
        line_1: addPatientData.addressLine1.trim(),
        line_2: addPatientData.addressLine2.trim(),
        city: addPatientData.city.trim(),
        state: addPatientData.state.value.trim(),
        zipcode: addPatientData.zipcode.trim(),
      },
    };
    data.secure_action_code = SecureCodes.CREATE_PATIENT;
    data.needs_reason = true;
    if (addPatientData.DOB.toString().length > 0)
      data.dob = dateStringToDateWithDateFormat(
        getTrimmedDOB(addPatientData.DOB)
      );
    dispatch(
      secureActionWithDoubleAuthentication(onSaveNewPatientRequest, data)
    );
  };

  const cancelFormWithoutSaving = () => {
    dispatch(clearSubjectInformation());
    dispatch(clearStudyInformation());
    props.onModalClose();
  };

  return (
    <div className='step-3-data'>
      <div className='data-information'>
        <div className='subject-information-wrapper'>
          <span className='info-title'>
            <span onClick={() => props.moveToPage(1)}>SUBJECT INFORMATION</span>

            <span>
              <IconButton
                onClick={() => {
                  props.moveToPage(1);
                }}
              >
                <EditOutlinedIcon
                  fontSize='small'
                  sx={{
                    color: variables.colorPrimary,
                    height: '20px',
                    width: '20px',
                  }}
                />
              </IconButton>
            </span>
          </span>
          <div className='info-columns'>
            <div className='info-column'>
              <span className='info'>
                <span className='info-key'>FIRST NAME</span>
                <span className='info-value'>{addPatientData.firstName}</span>
              </span>
              <span className='info'>
                <span className='info-key'>LAST NAME</span>
                <span className='info-value'>{addPatientData.lastName}</span>
              </span>
              <span />
            </div>
            <div className='info-column'>
              <span className='info'>
                <span className='info-key'>ADDRESS LINE 1</span>
                <span className='info-value'>
                  {addPatientData.addressLine1}
                </span>
              </span>
              <span className='info'>
                <span className='info-key'>ADDRESS LINE 2</span>
                <span className='info-value'>
                  {addPatientData.addressLine2 || '- -'}
                </span>
              </span>
              <span />
            </div>

            <div className='info-column'>
              <span className='info'>
                <span className='info-key'>CITY</span>
                <span className='info-value'>{addPatientData.city}</span>
              </span>
              <span className='info'>
                <span className='info-key'>STATE</span>
                <span className='info-value'>{addPatientData.state.label}</span>
              </span>
              <span className='info'>
                <span className='info-key'>ZIP CODE</span>
                <span className='info-value'>{addPatientData.zipcode}</span>
              </span>
            </div>
            <div className='info-column'>
              <span className='info'>
                <span className='info-key'>PHONE NUMBER</span>
                <span className='info-value'>
                  {addPatientData.contactNumber}
                </span>
              </span>
              <span className='info'>
                <span className='info-key'>Email ID</span>
                <span className='info-value'>{addPatientData.email}</span>
              </span>
              <span />
            </div>
            <div className='info-column'>
              <span className='info'>
                {addPatientData.DOB && (
                  <>
                    <span className='info-key'>DATE OF BIRTH</span>
                    <span className='info-value'>
                      {moment(
                        new Date(addPatientData.DOB),
                        moment.ISO_8601
                      ).format('MM-DD-YYYY')}
                    </span>
                  </>
                )}
              </span>
              <span />
              <span />
            </div>
          </div>
        </div>
        {/* <div className="vertical-line" /> */}
        <div className='study-information-wrapper'>
          <span className='info-title'>
            <span>STUDY INFORMATION</span>

            <span>
              <IconButton
                onClick={() => {
                  props.moveToPage(2);
                }}
              >
                <EditOutlinedIcon
                  fontSize='small'
                  sx={{
                    color: variables.colorPrimary,
                    height: '20px',
                    width: '20px',
                  }}
                />
              </IconButton>
            </span>
          </span>

          <div className='info-columns'>
            <div className='info-column'>
              <span className='info'>
                <span className='info-key'>STUDY NAME</span>
                <span className='info-value'>
                  {addPatientData.studyNameId.label}
                </span>
              </span>
              <span className='info'>
                <span className='info-key'>STUDY SITE</span>
                <span className='info-value'>
                  {addPatientData.locationId.label}
                </span>
              </span>
              <span className='info'>
                <span className='info-key'>INVESTIGATOR</span>
                <span className='info-value'>
                  {addPatientData.masterProviderId.label}
                </span>
              </span>
              <span className='info'>
                <span className='info-key'>CONSENT COUNTERSIGNER</span>
                <span className='info-value'>
                  {addPatientData.counterSigneId.label}
                </span>
              </span>
              <span className='info' />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '16px',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}
      >
        <span>
          <SecondaryButton onClick={cancelFormWithoutSaving}>
            <span className='add-patient-form-button cancel-button'>
              Cancel without saving
            </span>
          </SecondaryButton>
        </span>
        <span style={{ width: '150px' }}>
          <PrimaryButton
            onClick={onSaveNewPatient}
            form='add-patient-step-one-form'
          >
            <span className='add-patient-form-button'>Save</span>
          </PrimaryButton>
        </span>
      </div>
    </div>
  );
};

export default StepThree;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import AddConsentFormPreview from './AddConsentFormPreview';
import AddConsentStepOneForm from './AddConsentStepOneForm';
import AddConsentStepTwoForm from './AddConsentStepTwoForm';
import { resetAddConsentData, updateConsentAdminValue } from '../action';

const AddEditConsentModal = props => {
  const dispatch = useDispatch();

  const consentAdminData = useSelector(store => store.consentAdminReducer);

  const LAST_STEP = 3;

  const step = useSelector(store => store.consentAdminReducer.step);

  const previousStep = useSelector(
    store => store.consentAdminReducer.previousStep
  );

  const handleModalHide = () => {
    dispatch(
      resetAddConsentData({
        page: consentAdminData.page,
        filterName: consentAdminData.filterName,
      })
    );
    dispatch(updateConsentAdminValue('step', 1));
    props.onHide();
  };

  const onStepChange = newStep => {
    dispatch(updateConsentAdminValue('step', newStep));
    dispatch(updateConsentAdminValue('previousStep', step));
  };

  const onBack = step => {
    onStepChange(step - 1);
  };

  const onContinue = newStep => {
    if (previousStep === LAST_STEP) {
      dispatch(updateConsentAdminValue('step', LAST_STEP));
    } else {
      dispatch(updateConsentAdminValue('step', newStep));
    }
    dispatch(updateConsentAdminValue('previousStep', step));
  };

  const getFormBasedOnStep = step => {
    switch (step) {
      case 1:
        return (
          <AddConsentStepOneForm
            step={1}
            totalSteps={3}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onClose={() => handleModalHide()}
          />
        );
      case 2:
        return (
          <AddConsentStepTwoForm
            step={2}
            totalSteps={3}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onClose={() => handleModalHide()}
          />
        );
      case 3:
        return (
          <AddConsentFormPreview
            step={3}
            totalSteps={3}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onStepChange={step => onStepChange(step)}
            onClose={() => handleModalHide()}
            updateSelectedConsentId={() => {
              dispatch(updateConsentAdminValue('selectedConsentId'));
            }}
          />
        );
    }
  };

  const getFormSubheader = () => {
    switch (step) {
      case 1:
        return 'Consent Information';
        break;
      case 2:
        return 'Review and Finish';
      default:
        return 'Add New Consent';
    }
  };

  return (
    <FormModal
      show={props.show}
      onHide={handleModalHide}
      title={{
        text: consentAdminData.selectedConsentId
          ? 'Edit Consent Info'
          : 'Add New Consent',
      }}
      subheader={
        <div className='modal-subheader-content'>
          <div>{getFormSubheader()}</div>
          <div className='text-right'>
            <span className='steps-count active-step-count'>{`${step} of 3`}</span>
          </div>
        </div>
      }
      modalStyle={{
        width: '700px',
        height: '700px',
      }}
      form={{
        form: getFormBasedOnStep(step),
      }}
    />
  );
};

export default AddEditConsentModal;

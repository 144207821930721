import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'components/StyleGuideComponents/DataTable';
import React, { useMemo } from 'react';

const ResourceData = props => {
  const data = props.data.resourcesData;

  const getDataToShow = useMemo(() => {
    const dataToShow = data.map(resource => {
      const newObj = { ...resource };
      newObj.location = newObj.location.label;
      newObj.procedure = newObj.procedure.label;
      newObj.action = newObj.action.label;
      return newObj;
    });
    return dataToShow;
  }, [props.data.resourcesData]);

  return (
    <DataTable
      tableHeaders={props.data.resourcesHeaders}
      tableData={getDataToShow}
      onRowClick={id => props.updateSelectedResourceId(id)}
      tableDataCellStyle={{ textAlign: 'center' }}
      tableHeaderCellStyle={{ textAlign: 'center' }}
      title='Resource'
    />
  );
};

export default ResourceData;

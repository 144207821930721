import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import AttachmentCard from 'components/AttachmentCard';
import { pluralize } from 'utils/stringUtils';
import './attachments.scss';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';

class Attachments extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    const { mediaResources } = this.props;
    return !isEqual(nextProps.mediaResources, mediaResources);
  }

  onAttachmentRemoved = mediaResourceId => {
    const { onAttachmentRemoved } = this.props;
    onAttachmentRemoved(mediaResourceId);
  };

  render() {
    const { mediaResources, isAdded, notShowAttachmentsCount } = this.props;
    if (mediaResources !== undefined && mediaResources.length > 0) {
      return (
        <div className='attachments-container clr'>
          {!notShowAttachmentsCount && (
            <div className='attachments-count'>
              <AttachmentOutlinedIcon />
              <div>{`${`${mediaResources.length} `}${pluralize(
                'Attachment',
                mediaResources.length
              )}`}</div>
            </div>
          )}
          {mediaResources.map((mediaResource, index) => (
            <AttachmentCard
              index={index}
              classname='attachment-seperator'
              key={mediaResource.id}
              attachment={mediaResource}
              isAdded={isAdded}
              onAttachmentRemoved={this.onAttachmentRemoved}
            />
          ))}
        </div>
      );
    }
    return '';
  }
}

Attachments.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  mediaResources: PropTypes.arrayOf(PropTypes.object),
  isAdded: PropTypes.bool,
  onAttachmentRemoved: PropTypes.func,
  notShowAttachmentsCount: PropTypes.bool,
};

Attachments.defaultProps = {
  mediaResources: [],
  isAdded: false,
  onAttachmentRemoved: () => {},
  notShowAttachmentsCount: false,
};

export default Attachments;

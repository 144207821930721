import { fetchAdminAuditTrail } from 'services/admin';
import { AppDispatch } from 'store';
import { APIResponse, AppAction } from 'types';
import * as Constants from './constants';
import { ResponseDataType } from './types';

type FetchAuditTrailAPIResponseType = APIResponse & {
  data: ResponseDataType;
};

const fetchAuditTrailSucceeded = (data: ResponseDataType): AppAction => {
  return {
    type: Constants.FETCH_ADMIN_AUDIT_TRAIL,
    data,
  };
};

export const resetAdminAuditTrailData = () => {
  return {
    type: Constants.RESET_ADMIN_AUDIT_TRAIL,
  };
};

// redux thunk actions
export const fetchAdminAuditTrailRequest = () => (dispatch: AppDispatch) => {
  fetchAdminAuditTrail()
    .then((response: FetchAuditTrailAPIResponseType) => {
      dispatch(fetchAuditTrailSucceeded(response.data));
    })
    .catch(() => {});
};

import React from 'react';
import moment from 'moment-timezone';
import { isTBD } from 'utils/dateUtils';
import TaskCard from 'components/TaskCard';
import PropTypes from 'prop-types';

const TaskBucket = props => {
  const { date, tasks } = props;
  const startDateDisplay = isTBD(moment(date)) ? 'TBD' : date;
  return (
    <div className='task-bucket'>
      <div className='task-header'>{startDateDisplay}</div>
      {tasks.map(task => (
        <TaskCard key={task.id} task={task} />
      ))}
    </div>
  );
};

TaskBucket.propTypes = {
  date: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TaskBucket;

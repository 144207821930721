import { getItemFromStorage } from 'services/storage';
import { proxyAuthHeaderKeys } from 'services/constants';

export const isMasquerading = () => {
  let isMasqueradingSession = true;
  proxyAuthHeaderKeys.forEach(key => {
    const localKey = key === 'proxyAuthorization' ? 'proxyToken' : key;
    if (getItemFromStorage(localKey)) {
      isMasqueradingSession = isMasquerading && true;
    } else {
      isMasqueradingSession = false;
    }
  });
  return isMasqueradingSession;
};

export const isiOS =
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

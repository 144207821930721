import { ClientInterface } from "utils/NativeNavigation/ClientInterface";

class AndroidClientListener implements ClientInterface {
  private platformObject: ClientInterface;

  constructor() {
    // @ts-ignore
    this.platformObject = window?.Android;
  }

  openInFullWebContainer = (message: string = "open In Full Web Container"): void => {
    this.platformObject.openInFullWebContainer(message);
  };

  onCompleted = (message: string = "on Completed"): void => {
    this.platformObject.onCompleted(message);
  };

  onCancelled = (message: string = "on Cancelled"): void => {
    this.platformObject.onCancelled(message);
  };

  onError = (message: string = "on Error"): void => {
    this.platformObject.onError(message);
  };
}

export default AndroidClientListener;

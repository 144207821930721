import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { signOutUser } from 'common/authThunkActions';
import { LoaderModal } from 'components/StyleGuideComponents/common/modals';
import { useHistory } from 'react-router-dom';

const LogOut = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isSignedIn } = useSelector(state => state.currentUser);

  useEffect(() => {
    dispatch(signOutUser());
  }, []);

  useEffect(() => {
    if (!isSignedIn) history.replace('/signin');
  }, [isSignedIn]);

  return <></>;
};

export default LogOut;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import AddNotificationFormPreview from './AddNotificationFormPreview';
import AddNotificationStepOneForm from './AddNotificationStepOneForm';
import AddNotificationStepTwoForm from './AddNotificationStepTwoForm';
import { resetAddNotificationData } from '../action';

const AddEditNotificationModal = props => {
  const dispatch = useDispatch();

  const notificationAdminData = useSelector(
    store => store.notificationAdminReducer
  );

  const [step, setStep] = useState(1);

  const handleModalHide = () => {
    dispatch(
      resetAddNotificationData({
        page: notificationAdminData.page,
        filterName: notificationAdminData.filterName,
      })
    );
    setStep(1);
    props.updateSelectedNotificationId(null);
    props.onHide();
  };

  const getFormBasedOnStep = step => {
    switch (step) {
      case 1:
        return (
          <AddNotificationStepOneForm
            step={1}
            totalSteps={3}
            onStepChange={step => setStep(step)}
            onClose={() => handleModalHide()}
          />
        );
      case 2:
        return (
          <AddNotificationStepTwoForm
            step={2}
            totalSteps={3}
            onStepChange={step => setStep(step)}
            onClose={() => handleModalHide()}
          />
        );

      case 3:
        return (
          <AddNotificationFormPreview
            step={3}
            totalSteps={3}
            onStepChange={step => setStep(step)}
            onClose={() => handleModalHide()}
          />
        );
    }
  };

  const getFormSubheader = () => {
    switch (step) {
      case 1:
        return 'Personal Information';
      case 2:
        return 'Position Information';
      case 3:
        return 'Role Information';
      default:
        return 'Add New User';
    }
  };

  return (
    <FormModal
      show={props.show}
      onHide={handleModalHide}
      title={{
        text: props.selectedNotificationId ? 'Edit Task Info' : 'Add New Task',
      }}
      subheader={
        <div className='modal-subheader-content'>
          <div>{getFormSubheader()}</div>
          <div className='text-right'>
            <span className='steps-count active-step-count'>{`${step} of 4`}</span>
          </div>
        </div>
      }
      modalStyle={{
        width: '800px',
        height: '800px',
      }}
      form={{
        form: getFormBasedOnStep(step),
      }}
    />
  );
};

export default AddEditNotificationModal;

import React, { useState, useEffect } from 'react';
import BarChart from './Barchart';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import './styles.scss';
import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import { getSurveyActionText } from '../utils';

// TODO: Use makeStyle instead of inline style.
export default function ImplantPainScore(props) {
  const {
    data,
    getChartData,
    getChartColors,
    getXLabels,
    appendProcedureDataToBaselineData,
    generateXAxisLegend,
    generateChartLegend,
  } = props;

  const implantPageOneData =
    data?.pain_score_data?.implant_pain_score_first_page_data ?? [];
  const implantPageTwoData =
    data?.pain_score_data?.implant_pain_score_second_page_data ?? [];
  const baselineData = data?.pain_score_data?.baseline_pain_score_data ?? [];
  const actionText = getSurveyActionText() ?? null;

  const [page, setPage] = useState(1);

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          autoSkip: false,
        },
        categorySpacing: 0.1,
      },
    },
  };

  const pageOneChartData = {
    labels: appendProcedureDataToBaselineData(
      implantPageOneData,
      baselineData,
      getXLabels
    ),
    datasets: [
      {
        data: appendProcedureDataToBaselineData(
          implantPageOneData,
          baselineData,
          getChartData
        ),
        backgroundColor: appendProcedureDataToBaselineData(
          implantPageOneData,
          baselineData,
          getChartColors
        ),
        datalabels: {
          display: true,
        },
      },
    ],
  };

  const pageTwoChartData = {
    labels: appendProcedureDataToBaselineData(
      implantPageTwoData,
      baselineData,
      getXLabels
    ),
    datasets: [
      {
        data: appendProcedureDataToBaselineData(
          implantPageTwoData,
          baselineData,
          getChartData
        ),
        backgroundColor: appendProcedureDataToBaselineData(
          implantPageTwoData,
          baselineData,
          getChartColors
        ),
        datalabels: {
          display: true,
        },
      },
    ],
  };

  const prevPage = page => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextPage = page => {
    if (page < 2 && implantPageTwoData != null && implantPageTwoData.length > 0) {
      setPage(page + 1);
    }
  };

  const IMPLANT_LEGEND_HASH = {
    BL: 'Baseline',
    W: 'Week',
    M: 'Month',
    Y: 'Year',
  };

  return (
    <>
      <div className='summary-page-divider' />
      <div className='implant-chart-wrapper' style={{marginTop: "16px"}}>
        <p className='title-font-regular chart-title uppercase'>IMPLANT PAIN SCORES</p>
        <div className='naviagtor'>
          <div className='left-navigator' type="button" onClick={() => prevPage(page)}>
            <ChevronLeftIcon className='navigator-icon left-navigator__icon' style={{ color: page === 1 ? '#bdccd3' : '#0483df' }}/>
            <TertiaryButton disabled={page === 1} className='left-navigator__text fit-content'>Previous</TertiaryButton>
          </div>
          <div className='right-navigator' type="button" onClick={() => nextPage(page)}>
            <TertiaryButton disabled={page === 2} className='right-navigator__text fit-content'>Next</TertiaryButton>
            <ChevronRightIcon className='navigator-icon right-navigator__text' style={{ color: page === 2 ? '#bdccd3' : '#0483df' }}/>
          </div>
        </div>
        {page == 1 ? (
          <>
            <BarChart
              titleClassNames='uppercase'
              options={options}
              data={pageOneChartData}
              generateChartLegend={generateChartLegend}
              getActionText={actionText}
            />
          </>
        ) : (
          <>
            <BarChart
              titleClassNames='uppercase'
              options={options}
              data={pageTwoChartData}
              generateChartLegend={generateChartLegend}
              getActionText={actionText}
            />
          </>
        )}
        {generateXAxisLegend(IMPLANT_LEGEND_HASH)}
      </div>
    </>
  );
}

import axios from './base';
import {
  RESEND_PIN_FOR_SITE_CLOSURE_REQUEST,
  SHOW_SITE_CLOSURE_REQUEST,
} from './constants';

export type FetchSiteClosureRequestDataParamsType = {
  request_key: string;
  request_pin: string;
};

export type ResendPinForSiteClosureRequestDataParamsType = {
  request_key: string;
};

export const fetchSiteClosureRequestData = (
  params: FetchSiteClosureRequestDataParamsType
) => {
  return axios.post(SHOW_SITE_CLOSURE_REQUEST, params);
};

export const resendPinForSiteClosureRequest = (
  params: ResendPinForSiteClosureRequestDataParamsType
) => {
  return axios.post(RESEND_PIN_FOR_SITE_CLOSURE_REQUEST, params);
};

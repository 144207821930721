/** \n * AdminTabWraper component renders a template Design of Admin Page. 
 * @author Dilip Joshi
 * This Component defines the basic template in which we will fit the Admin Page Design
 * through the necessary steps to complete the process which includes to capture subject and study 
 * information and a review before creating and saving the patient.
 *
 * @param {object} props - The props object contains the following properties:
 * @param {object} props.filterSection - The Component that deals with the filters on Admin page shoud be passed.
 * @param {object} props.dataSection - Object which contains the JSX to show on main admin page, it will have three sub sections like header, content and footer 
 
 *  @example
 * ```jsx
 <AdminTabWrapper
        filterSection={<filter />}
        dataSection={{
          header: (<header></header>,
          content: (<content></content>),
          footer: (<footer></footer>),
        }}
      />
    </>
 * ```
 */

import React from 'react';
import PropTypes from 'prop-types';

const AdminTabWrapper = props => {
  const { filterSection, dataSection } = props;
  return (
    <div className='admin-tab-content-wrapper'>
      <div className='admin-tab-filter-section'>{filterSection}</div>
      <div className='admin-tab-data-section'>
        <div className='admin-tab-data-section-header'>
          {dataSection?.header}
        </div>
        <div className='admin-tab-data-section-content'>
          {dataSection?.content}
        </div>
        <div className='admin-tab-data-section-footer'>
          {dataSection?.footer}
        </div>
      </div>
    </div>
  );
};

AdminTabWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dataSection: PropTypes.object,
  filterSection: PropTypes.element,
};

AdminTabWrapper.defaultProps = {
  dataSection: {},
  filterSection: <div />,
};
export default AdminTabWrapper;

export const FETCH_RESOURCES_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED =
  'src/containers/admin_page/FETCH_RESOURCES_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED';
export const UPDATE_CREATE_RESOURCE_ADMIN_VALUE_IN_STORE =
  'src/container/admin_page/UPDATE_CREATE_RESOURCE_ADMIN_VALUE_IN_STORE';
export const SAVE_ADD_RESOURCE_FORM_DATA =
  'src/container/admin_page/SAVE_ADD_RESOURCE_FORM_DATA';
export const RESET_ADD_RESOURCE_DATA =
  'src/containers/admin_page/RESET_ADD_RESOURCE_DATA';
export const SET_SELECTED_RESOURCE_ID =
  'src/containers/admin_page/SET_SELECTED_RESOURCE_ID';
export const UPDATE_RESOURCES_DATA_AFTER_SORTING =
  'src/containers/admin_page/UPDATE_RESOURCES_DATA_AFTER_SORTING';

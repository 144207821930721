import {
  GET_PROCEDURE_MANAGERS_REQUEST_SENT,
  GET_PROCEDURE_MANAGERS_REQUEST_SUCCEED,
  GET_PROCEDURE_MANAGERS_REQUEST_FAILED,
} from './constants';

const initialState = {
  isLoading: false,
  procedureManagers: [],
  miniTeam: [],
  megaTeam: [],
};

export const procedureManagersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROCEDURE_MANAGERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROCEDURE_MANAGERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        miniTeam: action.data.miniTeam,
        megaTeam: action.data.megaTeam,
        procedureManagers: action.data.miniTeam,
      };
    case GET_PROCEDURE_MANAGERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

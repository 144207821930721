import React, { useState, useEffect } from 'react';
import DefaultTimePicker from 'rc-time-picker';
import moment from 'moment';
import './style.scss';

const TimePicker = props => {
  const { value, onChange, placeholder, disabled } = props;
  const [open, setOpen] = useState(false);

  const addbtn = () => (
    <button className='close-icon' onClick={toggleOpen}>
      Done
    </button>
  );

  const handleValueChange = newValue => {
    onChange(newValue);
  };

  const toggleOpen = () => {
    const currentDateTime = moment();
    if (!value) {
      onChange(currentDateTime);
    }
    setOpen(!open);
  };

  return (
    <div style={{ position: 'relative' }}>
      <DefaultTimePicker
        className={props.className ? props.className : ''}
        placeholder={placeholder || 'Select Timfe'}
        style={{ width: '100%', position: 'relative' }}
        showSecond={false}
        onChange={handleValueChange}
        format='hh:mm a'
        use12Hours
        value={value}
        disabled={disabled}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        open={open}
        clearIcon={<i className='icon' />}
        inputIcon={<i className='icon icon-schedule primary-text-color' />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        addon={addbtn}
      />
    </div>
  );
};
TimePicker.defaultProps = {
  isValueAltered: false,
};

export default TimePicker;

export const pluralize = (string, count) =>
  count === 1 ? string : `${string}s`;

export const capitalize = str => str.replace(/\b\w/g, l => l.toUpperCase());

export const getFileNameFromPath = filePath =>
  filePath.substring(filePath.lastIndexOf('/') + 1);

export const camelCaseToString = (str, convertToCap = false) => {
  const result = str.replace(/([a-z])([A-Z])/g, '$1 $2');
  if (convertToCap) {
    return capitalize(result);
  }
  return result.toLowerCase();
};

export const getFormatedPhoneNumber = (strInParam, country = 'US') => {
  if (strInParam !== '' && strInParam !== null) {
    // Strip all characters from the input except digits
    let str = strInParam.replace(/\D/g, '');
    // Trim the remaining str to ten characters, to preserve phone number format
    str = str.substring(0, 10);
    // Based upon the length of the string, we add formatting as necessary
    if (country === 'US') {
      str = `${str.substring(0, 3)}-${str.substring(3, 6)}-${str.substring(
        6,
        10
      )}`;
    } else {
      str = `(${str.substring(0, 2)}) ${str.substring(2, 6)}-${str.substring(
        6,
        10
      )}`;
    }

    // return str;
    return `+1 ${str}`;
  }
  return '';
};

export const trimCountryCodeFromPhoneNumber = str => {
  return process.env.COUNTRY_OF_APPLICATION === 'USA'
    ? str.substring(2, str.length).replace(/\s/g, '')
    : str.substring(3, str.length).replace(/\s/g, '');
};

export const truncateText = (text, truncationLength, ending = '...') => {
  let truncatedText = '';
  if (text) {
    truncatedText = text.substring(0, truncationLength);
    if (text.length > truncationLength) {
      truncatedText += ending;
    }
  }
  return truncatedText;
};

export const getValidationsObject = data => {
  let fields = [];
  let rules = {};
  data.forEach(field => {
    if (field.visible && !field.disabled && field.validation) {
      if (field.validateOnlyIf === undefined || field.validateOnlyIf) {
        fields = [...fields, field.name];
        rules = { ...rules, [field.name]: field.validation };
      }
    }
  });
  return { fields, rules };
};

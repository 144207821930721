export const FETCH_LOCATIONS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED =
  'src/containers/admin_page/FETCH_LOCATIONS_ON_ADMIN_PAGE_REQUEST_SUCCEED';

export const UPDATE_CREATE_LOCATION_ADMIN_VALUE_IN_STORE =
  'src/container/admin_page/UPDATE_CREATE_LOCATION_ADMIN_VALUE_IN_STORE';

export const SET_SELECTED_LOCATION_ID =
  'src/containers/admin_page/SET_SELECTED_LOCATION_ID';

export const SAVE_ADD_LOCATION_FORM_DATA =
  'src/containers/admin_page/SAVE_ADD_LOCATION_FORM_DATA';

export const RESET_ADD_LOCATION_DATA =
  'src/containers/admin_page/RESET_ADD_LOCATION_DATA';

import { useSelector, useDispatch } from 'react-redux';
import {
  PrimaryButton,
  SecondaryAlphaButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import React, { useEffect, useState } from 'react';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import {
  fetchLocationsListOnAdminPageRequest,
  fetchUsersListOnAdminPageRequest,
  updateLocationAdminValue,
} from '../action';

const LocationFilter = props => {
  const dispatch = useDispatch();

  const [locationData, setLocationData] = useState({
    filterName: null,
  });

  const locationAdminData = useSelector(store => store.locationAdminReducer);

  const [errors, setErrors] = useState({});
  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'filterName',
      label: 'Name',
      type: 'text',
      placeholder: 'Type Name here',
      value: locationData.filterName,
      visible: true,
    },
  ];

  useEffect(() => {
    setLocationData({ filterName: locationAdminData.filterName });
  }, [locationAdminData.filterName]);

  const onSubmit = () => {
    dispatch(updateLocationAdminValue('filterName', locationData.filterName));
    dispatch(updateLocationAdminValue('page', 1));
  };

  return (
    <div className='filters-container'>
      <div className='filters-label'>Filters</div>
      <FormGenerator
        className='add-edit-form'
        id='locationFilter'
        formConfig={config}
        errors={errors}
        onChange={(key, value) => {
          clearErrorsForField(errors, key);
          setLocationData({ filterName: value });
        }}
        buttons={[
          <PrimaryButton
            style={{ maxWidth: 'none' }}
            disabled={!locationData.filterName}
            className={locationData.filterName ? 'cursor-pointer' : ''}
            onClick={() => {
              setLocationData({ filterName: '' });
              dispatch(updateLocationAdminValue('filterName', ''));
            }}
          >
            Clear All
          </PrimaryButton>,
          <SecondaryButton
            style={{ maxWidth: 'none' }}
            type='submit'
            onClick={() => onSubmit()}
            form='locationFilter'
          >
            Apply Filters
          </SecondaryButton>,
        ]}
        {...props}
      />
    </div>
  );
};

export default LocationFilter;

import React from 'react';
import './user_id.scss';

export default props => {
  const pad = (str, max) => {
    str = str.toString();
    return str.length < max ? pad(`0${str}`, max) : str;
  };
  if (props.mrn && props.mrn !== '') {
    return (
      <div className='user-mrn-container'>
        <div className='mrn-label'>MRN</div>
        <div className='mrn-value'>{props.mrn}</div>
      </div>
    );
  }
  return <div className='user-id-container'>ID &nbsp;{pad(props.id, 5)}</div>;
};

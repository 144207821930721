import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import { NOTES_EDIT_CHARACTERS_LIMIT } from 'constants';
import { updateAppointmentNotes } from 'containers/Appointments/action';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { resetEditVisitNoteStarted } from 'common/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';

const EditAppointmentNote = props => {
  const dispatch = useDispatch();
  const params = useParams();

  const [note, setNote] = useState(props.note);

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];
  const userProcedures = useSelector(
    store => store.currentPatientInfo.patient.userProcedures
  );
  const editVisitNoteStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.editVisitNoteStarted
  );
  const compositeProcedure = userProcedures.find(up => up.id === procedureId);

  useEffect(() => {
    if (editVisitNoteStarted) {
      props.closeShowEditNoteModal();
      dispatch(resetEditVisitNoteStarted());
    }
  }, [editVisitNoteStarted]);

  const onEditAppointmentNotes = e => {
    e.preventDefault();
    const params = {
      patientId,
      procedureId,
      appointmentId: props.appointmentId,
      compositeProcedureId: compositeProcedure?.compositeProcedureId,
      additional_note: note,
      secure_action_code: SecureCodes.EDIT_APPOINTMENT_NOTE,
      needs_reason: true,
    };
    dispatch(
      secureActionWithDoubleAuthentication(updateAppointmentNotes, params)
    );
  };

  return (
    <FormModal
      show={props.showEditNoteModal}
      onHide={() => props.closeShowEditNoteModal()}
      title={{
        text: 'Edit Notes',
      }}
      modalStyle={{
        width: '700px',
        height: 'auto',
      }}
      form={{
        form: (
          <form className='visit-note-form' id='visit-note-form'>
            <div className='form-group'>
              <label htmlFor='notes'>Notes</label>
              <textarea
                type='text'
                className='visit-note-input'
                maxLength={NOTES_EDIT_CHARACTERS_LIMIT}
                value={note}
                onChange={e => {
                  setNote(e.target.value);
                }}
                name='note'
                placeholder='Type here ...'
              />
            </div>
            <span className='character-limit'>
              {`${note.length} / ${NOTES_EDIT_CHARACTERS_LIMIT}`}
            </span>
          </form>
        ),
      }}
      buttons={[
        <PrimaryButton
          type='submit'
          form='visit-note-form'
          style={{ width: '260px', margin: '16px 0px' }}
          onClick={e => onEditAppointmentNotes(e)}
        >
          Save
        </PrimaryButton>,
      ]}
    />
  );
};

export default EditAppointmentNote;

import { showToaster } from 'common/toasterActions';

import {
  fetchAllLocationsOfTheApplication,
  updateLocation,
  createLocation,
  validateForUniqLocationCode,
} from 'services/admin';
import {
  FETCH_LOCATIONS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
  UPDATE_CREATE_LOCATION_ADMIN_VALUE_IN_STORE,
  SET_SELECTED_LOCATION_ID,
  SAVE_ADD_LOCATION_FORM_DATA,
  RESET_ADD_LOCATION_DATA,
} from './constants';
import { resetDataForDoubleAuthetication } from '../../../components/MultiAuthenticatedRequest/actions';

const fetchLocationsListOnAdminPageRequestSucceeded = data => {
  return {
    type: FETCH_LOCATIONS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
    data: {
      locationsHeaders: data.locationAttributes,
      locationsData: data.locationsData,
      pagination: data.pagination,
    },
  };
};

export const createLocationRequest = params => dispatch => {
  createLocation(params)
    .then(response => {
      dispatch(resetDataForDoubleAuthetication())
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {});
};

export const updateLocationAdminValue = (key, value) => {
  return {
    type: UPDATE_CREATE_LOCATION_ADMIN_VALUE_IN_STORE,
    key,
    value,
  };
};

export const setSelectedLocationIdRequest = (id, locationData) => {
  return {
    type: SET_SELECTED_LOCATION_ID,
    data: { id, locationData },
  };
};

export const saveAddLocationFormData = data => {
  return {
    type: SAVE_ADD_LOCATION_FORM_DATA,
    data,
  };
};

export const updateLocationRequest = params => dispatch => {
  updateLocation(params)
    .then(response => {
      dispatch(resetDataForDoubleAuthetication())
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {});
};

export const resetAddLocationsData = () => {
  return {
    type: RESET_ADD_LOCATION_DATA,
  };
};

export const fetchLocationsListOnAdminPageRequest = params => dispatch => {
  fetchAllLocationsOfTheApplication(params)
    .then(response => {
      dispatch(fetchLocationsListOnAdminPageRequestSucceeded(response.data));
    })
    .catch(() => {});
};

// eslint-disable-next-line no-unused-vars
export const validateIfLocationCodeIsUniq = params => dispatch => {
  return validateForUniqLocationCode(params);
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddEditTaskModal from './Components/AddEditTaskModal';
import { updateTaskAdminValue, resetAddTasksData } from './action';

const TaskAdmin = props => {
  const dispatch = useDispatch();

  const [selectedTaskId, setSlectedTaskId] = useState(null);

  const show = useSelector(store => store.taskAdminReducer.show);

  return (
    <AddEditTaskModal
      show={show}
      onHide={() => {
        dispatch(resetAddTasksData());
        dispatch(updateTaskAdminValue('show', false));
      }}
      updateSelectedTaskId={id => setSlectedTaskId(id)}
    />
  );
};

export default TaskAdmin;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  dateStringToCustomDate,
  isTBD,
  dateStringToDateWithDateFormat,
  dateTimeStringToMMSDateFormatWithTimzoneAbbreviation,
} from 'utils/dateUtils';
import moment from 'moment-timezone';
import HelpBlock from 'components/HelpBlock';
import DateTime from 'react-datetime';
import TimePicker from 'components/TimePicker';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import validate, { clearErrorsForField } from 'common/validator';
import { validateAppointmentConfig } from 'containers/Appointments/validators';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import { updateAppointment } from 'containers/Appointments/action';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { resetEditVisitStarted } from 'common/actions';
import { APPOINTMENT_TIME_FORMAT, DATE_FORMAT } from 'constants';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { showToaster } from 'common/toasterActions';
import PropTypes from 'prop-types';

const EditAppointment = props => {
  const editVisitStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.editVisitStarted
  );

  const paramsInRoute = useParams();
  const dispatch = useDispatch();

  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentTime, setAppointmentTime] = useState(null);
  const [appointmentTitle, setAppointmentTitle] = useState('');
  const [errors, setErrors] = useState({});

  const [patientId, procedureId] = [
    parseInt(paramsInRoute.patientId, 10) || -1,
    parseInt(paramsInRoute.procedureId, 10) || -1,
  ];

  const userProcedures = useSelector(
    store => store.currentPatientInfo.patient.userProcedures
  );
  const compositeProcedure = userProcedures.find(up => up.id === procedureId);

  const getTimezoneTime = (time, location) => {
    if (location) {
      return moment(time).tz(location.timezone);
    }
    return moment(time);
  };

  const resetState = () => {
    setAppointmentDate(null);
    setAppointmentTime(null);
    setAppointmentTitle('');
    setErrors({});
  };

  const { showEditModal, item, closeShowEditModal } = props;

  const getTrimmedAppointmentDate = date => {
    if (typeof date === 'object') return date;
    return date.trim();
  };

  useEffect(() => {
    if (showEditModal) {
      setAppointmentTitle(item.title);
      if (!item.dateKnown && isTBD(item.time)) {
        setAppointmentDate(null);
      } else {
        setAppointmentDate(getTimezoneTime(item.time, item.location));
        if (item.timeKnown) {
          setAppointmentTime(getTimezoneTime(item.time, item.location));
        } else {
          setAppointmentTime(null);
        }
      }
    }
  }, [item, showEditModal]);

  useEffect(() => {
    if (editVisitStarted) {
      closeShowEditModal();
      dispatch(resetEditVisitStarted());
      resetState();
    }
  }, [editVisitStarted]);

  const renderInput = propsInFunc => (
    <div className='rdt-custom-date-input-wrap'>
      <input {...propsInFunc} />
    </div>
  );

  const checkIfFormIsEdited = () => {
    if (
      appointmentTitle.trim() !== item.title &&
      item.eventKindName?.toLowerCase() === 'User Created'.toLowerCase()
    )
      return true;

    if (!item.dateKnown) {
      if (appointmentDate != null) {
        return true;
      }
    } else {
      if (
        dateStringToCustomDate(appointmentDate) !==
        dateStringToCustomDate(getTimezoneTime(item.time, item.location))
      ) {
        return true;
      }
      if (!item.timeKnown) {
        if (appointmentTime != null) {
          return true;
        }
      } else if (
        dateTimeStringToMMSDateFormatWithTimzoneAbbreviation(
          appointmentTime
        ) !==
        dateTimeStringToMMSDateFormatWithTimzoneAbbreviation(
          getTimezoneTime(item.time, item.location)
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const onFormValidationSuccess = () => {
    const params = {
      id: item.id,
      procedureId,
      appointmentId: item.id,
      compositeProcedureId: compositeProcedure.compositeProcedureId,
      patientId,
    };
    params.date_known = appointmentDate ? '1' : '0';

    if (appointmentDate?.toString().length > 0)
      params.date = dateStringToDateWithDateFormat(
        getTrimmedAppointmentDate(appointmentDate)
      );
    params.time_known = appointmentTime ? '1' : '0';
    params.time = appointmentTime
      ? appointmentTime.format(APPOINTMENT_TIME_FORMAT)
      : null;
    params.title = appointmentTitle;
    params.secure_action_code = SecureCodes.EDIT_APPOINTMENT;
    params.needs_reason = true;

    if (!checkIfFormIsEdited()) {
      dispatch(
        showToaster({
          message: 'At least one field need to be edited to submit the form',
          type: 'error',
        })
      );
    } else
      dispatch(secureActionWithDoubleAuthentication(updateAppointment, params));
  };

  const onUpdateAppointment = e => {
    e.preventDefault();
    const dataToValidate = { appointmentTitle };
    if (appointmentDate?.toString().length > 0)
      dataToValidate.appointmentDate =
        getTrimmedAppointmentDate(appointmentDate);
    validate(
      validateAppointmentConfig,
      dataToValidate,
      errorsInForm => setErrors(errorsInForm),
      onFormValidationSuccess
    );
  };

  const renderAppointmentModal = () => {
    return (
      <form className='visits-form' id='edit-visit-form'>
        <div
          className={`form-group visits-name ${
            errors.appointmentTitle ? 'has-error' : ''
          }`}
        >
          <label htmlFor='visit-name' className='form-label required'>
            VISIT NAME
          </label>
          <input
            name='visit-name'
            className='visits-form-inputs'
            value={appointmentTitle}
            disabled={
              item.eventKindName?.toLowerCase() !== 'User Created'.toLowerCase()
            }
            required
            maxLength='250'
            onChange={e => {
              setAppointmentTitle(e.target.value);
              setErrors(clearErrorsForField(errors, 'appointmentTitle'));
            }}
          />
          <HelpBlock value={errors.appointmentTitle} />
        </div>

        <div className='visits-form-row'>
          <div
            className={`form-group rowelement ${
              errors.appointmentDate ? 'has-error' : ''
            }`}
          >
            <label htmlFor='date'>Date</label>
            <DateTime
              timeFormat={false}
              dateFormat={DATE_FORMAT}
              className='date-picker-field float-left date-field form-control visits-form-inputs'
              closeOnSelect
              closeOnTab
              popperPlacement='top'
              popperModifiers={{
                flip: {
                  behavior: ['top'], // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false, // tell it not to try to stay within the view (prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false, // turn off since needs preventOverflow to be enabled
                },
              }}
              renderInput={renderInput}
              name='appointment_date'
              inputProps={{ placeholder: 'Select Date', readOnly: false }}
              value={appointmentDate}
              onChange={date => {
                setAppointmentDate(date.toString().trim().length ? date : null);
                setAppointmentTime(
                  date.toString().trim().length ? appointmentTime : null
                );
                setErrors(clearErrorsForField(errors, 'appointmentDate'));
              }}
            />
            <HelpBlock value={errors.appointmentDate} />
          </div>
          <div
            className={`form-group rowelement ${
              errors.appointmentTime ? 'has-error' : ''
            }`}
          >
            <label htmlFor='time'>time</label>
            <TimePicker
              className='visits-form-inputs'
              placeholder='Select Time'
              disabled={!appointmentDate}
              defaultValue={appointmentTime}
              value={appointmentTime}
              isValueAltered
              onChange={time => {
                setAppointmentTime(time);
                setErrors(clearErrorsForField(errors, 'appointmentTime'));
              }}
            />
            <HelpBlock value={errors.appointmentTime} />
          </div>
        </div>
      </form>
    );
  };

  return (
    <FormModal
      show={showEditModal}
      onHide={() => {
        closeShowEditModal();
        resetState();
      }}
      title={{
        text: 'Edit Visit',
      }}
      modalStyle={{
        overflowY: 'inherit',
      }}
      form={{
        form: renderAppointmentModal(),
      }}
      buttons={[
        <PrimaryButton
          type='submit'
          form='edit-visit-form'
          style={{ width: '260px', margin: '32px 0px 16px 0px' }}
          onClick={e => onUpdateAppointment(e)}
          key='primaryButton'
        >
          Save
        </PrimaryButton>,
      ]}
    />
  );
};

EditAppointment.propTypes = {
  showEditModal: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  closeShowEditModal: PropTypes.func.isRequired,
};
export default EditAppointment;

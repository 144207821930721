import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import './consent.scss';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import Datetime from 'react-datetime';
import validate, { clearErrorsForField } from 'common/validator';
import HelpBlock from 'components/HelpBlock';
import AddAttachmentModal from 'containers/AddAttachmentModal';
import {
  getCounterSigneRequest,
  resetUploadConsentStarted,
} from 'common/actions';
import SingleSelectWithBorder from 'components/SelectV2';
import moment from 'moment';
import { dateStringToDateWithDateFormat } from 'utils/dateUtils';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import colors from '../../assets/stylesheets/_variables.scss';
import Password from '../../components/StyleGuideComponents/Password';
import { CONSENT_SUPPORTED_FILE_FORMATS } from '../../constants';
import { uploadPaperConsentRequest } from './action';

const UploadConsentForm = props => {
  const validationConfig = {
    fields: [
      'subjectSignedDate',
      'counterSigneSignedDate',
      'counterSigneId',
      'mediaResource',
    ],
    rules: {
      subjectSignedDate: [
        { rule: 'isRequired', message: "Subject's signed date is required" },
        { rule: 'isValidDate', message: 'Enter valid date' },
        {
          rule: 'isFutureDate',
          message: "Subject's signed date can not be in future",
        },
      ],
      counterSigneSignedDate: [
        {
          rule: 'isRequired',
          message: "Counter signe's signed date is required",
        },
        { rule: 'isValidDate', message: 'Enter valid date' },
        {
          rule: 'isFutureDate',
          message: "Counter signe's signed date can not be in future",
        },
      ],
      mediaResource: [
        { rule: 'isRequired', message: 'Paper consent document is required' },
      ],
      counterSigneId: [
        { rule: 'isRequired', message: 'Counter signe is required' },
      ],
    },
  };

  const dispatch = useDispatch();
  const params = useParams();

  const today = moment();

  const [subjectSignedDate, setSubjectSignedDate] = useState('');
  const [counterSigneSignedDate, setCounterSigneSignedDate] = useState('');
  const [mediaResource, setMediaResource] = useState([]);
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [procedureId, setProcedureId] = useState(null);
  const [providerId, setProviderId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [counterSigneId, setCounterSigneId] = useState(null);

  const isUploadInProgress = useSelector(
    store => store.documentsReducer.isUploadInProgress
  );
  const uploadPaperConsentStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.uploadPaperConsentStarted
  );
  const patient = useSelector(store => store.currentPatientInfo.patient);
  const counterSigne = useSelector(
    store => store.counterSigneReducer.counterSigne
  );

  useEffect(() => {
    if (patient.userProcedures.length) {
      setProcedureId(patient.userProcedures[0].procedureId);
      setProviderId(patient.userProcedures[0].investigatorId);
      setLocationId(patient.userProcedures[0].locationId);
    }
  }, [patient.userProcedures]);

  useEffect(() => {
    if (uploadPaperConsentStarted) {
      onModalClose();
      dispatch(resetUploadConsentStarted());
    }
  }, [uploadPaperConsentStarted]);

  useEffect(() => {
    if (props.showModal)
      dispatch(
        getCounterSigneRequest({
          id: procedureId,
          provider_id: providerId,
          location_id: locationId,
        })
      );
  }, [props.showModal]);

  const resetState = () => {
    setSubjectSignedDate('');
    setCounterSigneSignedDate('');
    setPassword('');
    setCounterSigneId(null);
    setMediaResource([]);
    setErrors({});
  };

  const onModalClose = () => {
    props.onModalClose();
    resetState();
  };

  const onUpdateAttachments = attachments => {
    setMediaResource(attachments);
    setErrors(clearErrorsForField(errors, 'mediaResource'));
  };

  const onUploadConsentSubmit = () => {
    const dataToValidate = {
      subjectSignedDate,
      counterSigneSignedDate,
      mediaResource,
      password,
      counterSigneId,
    };
    dataToValidate.subjectSignedDate = getTrimmedDate(subjectSignedDate);
    dataToValidate.counterSigneSignedDate = getTrimmedDate(
      counterSigneSignedDate
    );
    validate(
      validationConfig,
      dataToValidate,
      onFormValidationFailure,
      onFormValidationSuccess
    );
  };

  const getTrimmedDate = date => {
    if (typeof date === 'object') return date;
    return date.trim();
  };

  const onFormValidationSuccess = () => {
    if (subjectSignedDate > counterSigneSignedDate) {
      setErrors({
        ...errors,
        counterSigneSignedDate:
          "Couter signee's signed date must be same or after subject's signed date",
      });
    } else {
      const data = {
        patient_id: params.patientId,
        consent_form_id: props.consentFormId,
        user_procedure_id: params.procedureId,
        subject_signed_date: dateStringToDateWithDateFormat(subjectSignedDate),
        counter_signe_signed_date: dateStringToDateWithDateFormat(
          counterSigneSignedDate
        ),
        counter_signe_id: counterSigneId,
        media_resources: mediaResource,
        needs_reason: true,
        secure_action_code: SecureCodes.UPLOAD_PAPER_CONSENT,
      };
      dispatch(
        secureActionWithDoubleAuthentication(uploadPaperConsentRequest, data)
      );
    }
  };

  const onFormValidationFailure = errors => {
    setErrors(errors);
  };

  const selectCounterSigne = ({ value }) => {
    setErrors(clearErrorsForField(errors, 'counterSigneId'));
    setCounterSigneId(value);
  };

  const getValueForDropDown = (options, valueId, fields = null) => {
    const option = options.filter(item => {
      return item.id === valueId;
    });
    if (option.length) {
      let fieldValue = '';
      if (fields) {
        fields.forEach(field => {
          fieldValue = `${fieldValue + option[0][field]} `;
        });
        return { value: option[0].id, label: fieldValue };
      }
      return { value: option[0].id, label: option[0].name.trim() };
    }
    return '';
  };

  const getOptionsForDropDown = (options, fields = null) => {
    return options.map(option => {
      let fieldValue = '';
      if (fields) {
        fields.forEach(field => {
          fieldValue = `${fieldValue + option[field]} `;
        });
      } else {
        fieldValue = option.name;
      }
      return { value: option.id, label: fieldValue.trim() };
    });
  };

  return (
    <FormModal
      show={props.showModal}
      onHide={onModalClose}
      title={{
        text: 'Upload Executed Form',
      }}
      subheader='Please only upload a document here that is fully executed (ie. signed by BOTH the subject and the investigator or designee). 
                    By entering your password below, you are confirming that this is a fully executed informed consent form.'
      modalStyle={{
        width: '700px',
        height: 'auto',
        overflowY: 'unset',
      }}
      subheaderStyle={{
        background: colors.colorWhite,
        color: colors.colorBlack,
      }}
      form={{
        form: (
          <form
            className='upload-consent-form'
            id='upload-consent-form'
            autoComplete='off'
          >
            <div
              className={`form-group ${
                errors.mediaResource !== undefined ? 'has-error' : ''
              }`}
            >
              <label className='form-label' htmlFor='attachment'>
                Add an attachment
              </label>
              <AddAttachmentModal
                onUpdateAttachments={onUpdateAttachments}
                showModal={props.showModal}
                onModalClose={props.onModalClose}
                isBindedWithModal
                fileFormats={CONSENT_SUPPORTED_FILE_FORMATS}
                maxAttachmentCount={1}
                mediaResources={mediaResource}
              />
              <HelpBlock value={errors.mediaResource} />
            </div>
            <div
              className={`form-group ${
                errors.subjectSignedDate !== undefined ? 'has-error' : ''
              }`}
            >
              <label className='form-label' htmlFor='date'>
                Enter Date of Subject’s Signature
              </label>
              <Datetime
                timeFormat={false}
                dateFormat='MM/DD/YYYY'
                style={{ display: 'bubble' }}
                className='date-picker-field float-left date-field form-control upload-consent-form-fields'
                inputProps={{ placeholder: 'mm/dd/yyyy', readOnly: false }}
                closeOnSelect
                closeOnTab
                value={subjectSignedDate}
                isValidDate={current => current.isBefore(today)}
                popperPlacement='top'
                popperModifiers={{
                  flip: {
                    behavior: ['top'], // don't allow it to flip to be above
                  },
                  preventOverflow: {
                    enabled: false, // tell it not to try to stay within the view (prevents the popper from covering the element you clicked)
                  },
                  hide: {
                    enabled: false, // turn off since needs preventOverflow to be enabled
                  },
                }}
                onChange={date => {
                  setSubjectSignedDate(date);
                  setErrors(clearErrorsForField(errors, 'subjectSignedDate'));
                }}
              />
              <HelpBlock value={errors.subjectSignedDate} />
            </div>
            <div className='counter-signe-block'>
              <div
                className={`form-group counter-signe-name ${
                  errors.counterSigneId !== undefined ? 'has-error' : ''
                }`}
              >
                <label className='form-label' htmlFor='counterSigneId'>
                  Select Name of countersigner
                </label>
                <SingleSelectWithBorder
                  id='select-counter-signe'
                  placeholder='Select'
                  onChange={selectCounterSigne}
                  value={getValueForDropDown(counterSigne, counterSigneId, [
                    'firstName',
                    'lastName',
                  ])}
                  options={getOptionsForDropDown(counterSigne, [
                    'firstName',
                    'lastName',
                  ])}
                />
                <HelpBlock value={errors.counterSigneId} />
              </div>
              <div
                className={`form-group counter-signe-date ${
                  errors.counterSigneSignedDate !== undefined ? 'has-error' : ''
                }`}
              >
                <label className='form-label' htmlFor='date'>
                  Enter Date of Counter Signee's Signature
                </label>
                <Datetime
                  timeFormat={false}
                  dateFormat='MM/DD/YYYY'
                  style={{ display: 'bubble' }}
                  className='date-picker-field float-left date-field form-control upload-consent-form-fields'
                  inputProps={{
                    placeholder: 'mm/dd/yyyy',
                    readOnly: false,
                    disabled: !subjectSignedDate,
                  }}
                  closeOnSelect
                  closeOnTab
                  value={counterSigneSignedDate}
                  isValidDate={current =>
                    current.isBetween(subjectSignedDate - 1, today)
                  }
                  popperPlacement='top'
                  popperModifiers={{
                    flip: {
                      behavior: ['top'], // don't allow it to flip to be above
                    },
                    preventOverflow: {
                      enabled: false, // tell it not to try to stay within the view (prevents the popper from covering the element you clicked)
                    },
                    hide: {
                      enabled: false, // turn off since needs preventOverflow to be enabled
                    },
                  }}
                  onChange={date => {
                    setCounterSigneSignedDate(date);
                    setErrors(
                      clearErrorsForField(errors, 'counterSigneSignedDate')
                    );
                  }}
                />
                <HelpBlock value={errors.counterSigneSignedDate} />
              </div>
            </div>
          </form>
        ),
      }}
      buttons={[
        <PrimaryButton
          disabled={isUploadInProgress}
          onClick={onUploadConsentSubmit}
          form='upload-consent-form'
          style={{ width: '260px', margin: '36px 0px 0px 0px' }}
        >
          Upload
        </PrimaryButton>,
      ]}
    />
  );
};

export default UploadConsentForm;

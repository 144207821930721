/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-deprecated */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, combineReducers } from 'redux';
import injectReducer from 'utils/injectReducer';

import AddPatientView from 'components/AddPatientView';
import AvatarImage from 'assets/images/insightprologo.png';

import {
  getDepartmentProceduresReducer,
  getDepartmentProvidersReducer,
} from 'common/reducer';
import {
  getDepartmentProceduresRequest,
  getDepartmentProvidersRequest,
} from 'common/actions';
import { SecondaryButton } from 'components/StyleGuideComponents/common/buttons';
import { withRouter } from 'react-router';
import { onSaveNewPatientRequest } from '../Dashboard/actions';

import '../Dashboard/dashboard.scss';
import { addNewPatientReducer } from '../Dashboard/reducer';

class UnSupportedPageView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      checkFromProps: false,
      // eslint-disable-next-line react/no-unused-state
      isRequestInProgress: false,
    };
  }

  componentWillMount() {
    const {
      getDepartmentProceduresRequestLocal,
      getDepartmentProvidersRequestLocal,
    } = this.props;
    getDepartmentProceduresRequestLocal();
    getDepartmentProvidersRequestLocal();
  }

  componentWillReceiveProps(nextProps) {
    const state = {
      procedures: nextProps.procedures,
      isRequestInProgress: nextProps.isRequestInProgress,
    };

    const { checkFromProps } = this.state;

    if (checkFromProps) {
      state.show = nextProps.show;
    }
    this.setState(state);
  }

  onModalClose() {
    this.setState({ show: false });
    this.checkFromPropsCall(false);
  }

  checkFromPropsCall(value) {
    this.setState({
      checkFromProps: value,
    });
  }

  signOut() {
    const { history } = this.props;
    history.push('/logout');
  }

  render() {
    const { show } = this.state;
    const {
      onSaveNewPatientRequestLocal,
      procedures,
      hasSuperUserPrivileges,
      patientAdvocates,
      isSupportUser,
    } = this.props;
    return (
      <div className='app-smaller-container'>
        <div className='image-container'>
          <img src={AvatarImage} alt='' />
        </div>
        <div className='info-container'>
          To access the full feature, please Sign in from a tablet or desktop.
        </div>

        <div className='log-out-btn-container'>
          <div className='btn-separator' />
          <SecondaryButton onClick={this.signOut}>Log out</SecondaryButton>
        </div>

        {show && (
          <AddPatientView
            show={show}
            onSaveNewPatientRequest={onSaveNewPatientRequestLocal}
            procedures={procedures}
            checkFromPropsCall={this.checkFromPropsCall}
            onModalClose={this.onModalClose}
            hasSuperUserPrivileges={hasSuperUserPrivileges}
            patientAdvocates={patientAdvocates}
            isSupportUser={isSupportUser}
          />
        )}
      </div>
    );
  }
}

UnSupportedPageView.propTypes = {
  getDepartmentProvidersRequestLocal: PropTypes.func,
  onSaveNewPatientRequestLocal: PropTypes.func,
  hasSuperUserPrivileges: PropTypes.bool,
  show: PropTypes.bool,
  procedures: PropTypes.array,
  getDepartmentProceduresRequestLocal: PropTypes.func,
  isRequestInProgress: PropTypes.bool,
  patientAdvocates: PropTypes.array,
  isSupportUser: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
};

UnSupportedPageView.defaultProps = {
  getDepartmentProvidersRequestLocal: () => {},
  onSaveNewPatientRequestLocal: () => {},
  hasSuperUserPrivileges: false,
  show: false,
  procedures: [],
  getDepartmentProceduresRequestLocal: () => {},
  isRequestInProgress: false,
  patientAdvocates: [],
};

const mapStateToProps = state => ({
  show: state.unSupportedPageView.addNewPatientReducer.show,
  isRequestInProgress:
    state.unSupportedPageView.addNewPatientReducer.isRequestInProgress,
  procedures:
    state.unSupportedPageView.getDepartmentProceduresReducer.procedures,
  toasts: state.unSupportedPageView.toasts,
  userType: state.currentUser.attributes.type,
  hasSuperUserPrivileges: state.currentUser.attributes.hasSuperUserPrivileges,
  isSupportUser: state.currentUser.attributes.isSupportUser,
  patientAdvocates:
    state.unSupportedPageView.getDepartmentProvidersReducer.providers,
});

const mapDispatchToProps = dispatch => ({
  onSaveNewPatientRequestLocal: params =>
    dispatch(onSaveNewPatientRequest(params)),
  getDepartmentProceduresRequestLocal: () =>
    dispatch(getDepartmentProceduresRequest()),
  getDepartmentProvidersRequestLocal: () =>
    dispatch(getDepartmentProvidersRequest()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'unSupportedPageView',
  reducer: combineReducers({
    addNewPatientReducer,
    getDepartmentProceduresReducer,
    getDepartmentProvidersReducer,
  }),
});

export default compose(
  withRouter,
  withReducer,
  withConnect
)(UnSupportedPageView);

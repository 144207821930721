import { showToaster } from 'common/toasterActions';
import {
  fetchConsents,
  consentResendInvite,
  uploadPaperConsent,
} from 'services/patientDetails';
import { getPatientInfo } from 'containers/PatientDetails/actions';
import { setUploadPaperConsentStarted } from 'common/actions';
import { resetDataForDoubleAuthetication } from 'components/MultiAuthenticatedRequest/actions';
import {
  FETCH_CONSENT_REQUEST_SENT,
  FETCH_CONSENT_REQUEST_SUCCEED,
  FETCH_CONSENT_REQUEST_FAILED,
  DOWNLOAD_SIGNED_CONSENT_REQUEST_SENT,
  DOWNLOAD_SIGNED_CONSENT_REQUEST_SUCCEED,
  DOWNLOAD_SIGNED_CONSENT_REQUEST_FAILED,
  CONSENT_RESEND_INVITE_REQUEST_SENT,
  CONSENT_RESEND_INVITE_REQUEST_SUCCEED,
  CONSENT_RESEND_INVITE_REQUEST_FAILED,
  UPLOAD_PAPER_CONSENT_REQUEST_SENT,
  UPLOAD_PAPER_CONSENT_REQUEST_SUCCEED,
  UPLOAD_PAPER_CONSENT_REQUEST_FAILED,
} from './constants';

export const fetchConsentRequestSent = () => ({
  type: FETCH_CONSENT_REQUEST_SENT,
});

export const fetchConsentRequestSucceded = data => ({
  type: FETCH_CONSENT_REQUEST_SUCCEED,
  data,
});

export const fetchConsentRequestFailed = () => ({
  type: FETCH_CONSENT_REQUEST_FAILED,
});

export const fetchConsentRequest = params => dispatch => {
  dispatch(fetchConsentRequestSent());
  fetchConsents({ params })
    .then(response => {
      dispatch(fetchConsentRequestSucceded(response.data));
    })
    .catch(() => {
      dispatch(fetchConsentRequestFailed());
    });
};

export const downloadSignedConsentRequestSent = () => ({
  type: DOWNLOAD_SIGNED_CONSENT_REQUEST_SENT,
});

export const downloadSignedConsentRequestSucceded = () => ({
  type: DOWNLOAD_SIGNED_CONSENT_REQUEST_SUCCEED,
});

export const downloadSignedConsentRequestFailed = () => ({
  type: DOWNLOAD_SIGNED_CONSENT_REQUEST_FAILED,
});

export const consentResendInviteRequestSent = () => ({
  type: CONSENT_RESEND_INVITE_REQUEST_SENT,
});

export const consentResendInviteRequestSucceded = () => ({
  type: CONSENT_RESEND_INVITE_REQUEST_SUCCEED,
});

export const consentResendInviteRequestFailed = () => ({
  type: CONSENT_RESEND_INVITE_REQUEST_FAILED,
});

export const consentResendInviteRequest = params => dispatch => {
  dispatch(consentResendInviteRequestSent());
  consentResendInvite(params)
    .then(response => {
      dispatch(consentResendInviteRequestSucceded());
      dispatch(showToaster({ message: response.data.title }));
      dispatch(resetDataForDoubleAuthetication());
    })
    .catch(() => {
      dispatch(consentResendInviteRequestFailed());
    });
};

export const uploadPaperConsentRequestSent = () => ({
  type: UPLOAD_PAPER_CONSENT_REQUEST_SENT,
});

export const uploadPaperConsentRequestSucceed = data => ({
  type: UPLOAD_PAPER_CONSENT_REQUEST_SUCCEED,
  data,
});

export const uploadPaperConsentRequestFailed = () => ({
  type: UPLOAD_PAPER_CONSENT_REQUEST_FAILED,
});

export const uploadPaperConsentRequest = params => dispatch => {
  dispatch(uploadPaperConsentRequestSent());
  uploadPaperConsent(params)
    .then(response => {
      dispatch(uploadPaperConsentRequestSucceed(response.data.consent));
      dispatch(getPatientInfo(params.patient_id, params.user_procedure_id));
      dispatch(
        fetchConsentRequest({
          patient_id: params.patient_id,
          user_procedure_id: params.user_procedure_id,
        })
      );
      dispatch(showToaster({ message: 'Successfully Uploaded!' }));
      dispatch(setUploadPaperConsentStarted());
      dispatch(resetDataForDoubleAuthetication());
    })
    .catch(() => {});
};

import { STATE, ERRORS } from 'enums';

export const changeSearchParams = (state, message = null) => {
  const searchParams = {
    state,
  };

  const currentSearchParams = new URL(window.location.toString()).searchParams;

  if (state === STATE.ERROR) {
    searchParams.message = message || ERRORS.SOMETHING_WENT_WRONG;
  }

  if (currentSearchParams.get('state') !== state) {
    window.location.search = new URLSearchParams(searchParams).toString();
  }
};

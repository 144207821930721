/** \n * @module AddPatientStepOne
 * @author Dilip Joshi
 * @desc React Functional Component responsible for rendering the form for adding a new patient to the database.
 * This component includes form validation using the `validate` method from the `common/validator` module,
 * and async validation for phone numbers and emails using `validateEmailUniquness` and `validatePhoneUniquness` actions.
 *
 *
 * @example
 * import AddPatientStepOne from './AddPatientStepOne.js' ;
 *
 *  function Demo() {
 *   return (
 *     <div className="Demo">
 *      <AddPatientStepOne country="US" nextPage="2" onModalClose={() => console.log('Modal closed!'}/>
 *     </div>
 *   );
 * }
 * @param {string} props.country - country default for react-phone-input-2
 * @param {string} props.nextPage - the page to be navigated to when the form is submitted successfully
 * @param {func} props.onModalClose - callback function to be called on the modal close
 * @returns {React.FC} - a functional component
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import HelpBlock from 'components/HelpBlock';
import PhoneInput from 'components/PhoneInput';
import ZipInput from 'components/ZipInput';
import moment from 'moment';
import Datetime from 'react-datetime';
import validate, { clearErrorsForField } from 'common/validator';
import SingleSelectWithBorder from 'components/SelectV2';

import {
  validateEmailUniquness,
  validatePhoneUniquness,
  validatePhoneUniqunessRequestSucceeded,
  validatePhoneUniqunessRequestFailed,
  validateEmailUniqunessRequestSucceeded,
  validateEmailUniqunessRequestFailed,
  saveSubjectInformation,
  clearSubjectInformation,
  showLoaderForAddPatient,
  stopLoaderForAddPatient,
} from 'common/actions';
import { patientInfoValidationConfig } from './validators';

import './style.scss';
import {
  PrimaryButton,
  SecondaryButton,
} from '../StyleGuideComponents/common/buttons';

// const currentUserStore = useSelector((store) => store.currentUser);

const AddPatientView = props => {
  const dispatch = useDispatch();
  const subjectInformation = useSelector(store => store.addPatientReducer);
  const statesOptions = useSelector(
    store => store.addPatientReducer.statesList
  );

  const [errors, setErrors] = useState([]);
  const [firstName, setFirstName] = useState(
    subjectInformation.firstName || ''
  );
  const [lastName, setLastName] = useState(subjectInformation.lastName || '');
  const [addressLine1, setAddressLine1] = useState(
    subjectInformation.addressLine1 || ''
  );
  const [addressLine2, setAddressLine2] = useState(
    subjectInformation.addressLine2 || ''
  );
  const [city, setCity] = useState(subjectInformation.city || '');
  const [state, setState] = useState(subjectInformation.state || '');
  const [zipcode, setZipcode] = useState(subjectInformation.zipcode || '');
  const [email, setEmail] = useState(subjectInformation.email || '');
  const [contactNumber, setContactNumber] = useState(
    subjectInformation.contactNumber || ''
  );
  const [DOB, setDOB] = useState(subjectInformation.DOB || '');

  const changeFieldHandler = (e, setFunction) => {
    if (errors[e.target.name]) {
      setErrors(clearErrorsForField(errors, e.target.name));
    }
    setFunction(e.target.value);
  };

  const checkPhoneAndEmailUniqueness = (contactNumber, email) => {
    const phone = contactNumber.replace('+1', '').replace(/ /g, '');
    if (phone.length === 10) {
      dispatch(validateEmailUniquness({ email }))
        .then(response => {
          if (response.data.isEmailUnique) {
            dispatch(validateEmailUniqunessRequestSucceeded(response.data));
            dispatch(validatePhoneUniquness({ phone }))
              .then(response => {
                if (response.data.isPhoneUnique) {
                  dispatch(
                    validatePhoneUniqunessRequestSucceeded(response.data)
                  );
                  dispatch(
                    saveSubjectInformation({
                      firstName,
                      lastName,
                      addressLine1,
                      addressLine2,
                      city,
                      state,
                      zipcode,
                      email,
                      contactNumber,
                      DOB,
                    })
                  );
                  dispatch(stopLoaderForAddPatient());
                  props.moveToPage(props.nextPage);
                } else {
                  setErrors({
                    ...errors,
                    contactNumber: 'Patient with phone number already exists',
                  });
                  dispatch(stopLoaderForAddPatient());
                }
              })
              .catch(() => {
                dispatch(validatePhoneUniqunessRequestFailed());
                dispatch(stopLoaderForAddPatient());
              });
          } else {
            setErrors({
              ...errors,
              email: 'Patient with email already exists',
            });
            dispatch(stopLoaderForAddPatient());
          }
        })
        .catch(() => {
          dispatch(validateEmailUniqunessRequestFailed());
          dispatch(stopLoaderForAddPatient());
        });
    }
  };

  const getOptionsForDropDown = (options, fields = null) => {
    return options.map(option => {
      let fieldValue = '';
      if (fields) {
        fields.forEach(field => {
          fieldValue = `${fieldValue + option[field]} `;
        });
      } else {
        fieldValue = option.name;
      }
      return { value: option.id, label: fieldValue.trim() };
    });
  };

  const getTrimmedDOB = DOB => {
    if (typeof DOB === 'object') return DOB;
    return DOB.trim();
  };

  const onNextValidationSuccess = () => {
    checkPhoneAndEmailUniqueness(contactNumber, email.trim());
  };

  const onNextValidationFailure = errors => {
    setErrors(errors);
    dispatch(stopLoaderForAddPatient());
  };

  const onSubmit = e => {
    e.preventDefault();
    dispatch(showLoaderForAddPatient());
    const dataToValidate = {
      firstName,
      lastName,
      addressLine1: addressLine1.trim(),
      addressLine2,
      city,
      state,
      zipcode,
      email: email.trim(),
      contactNumber,
    };
    if (DOB.toString().length > 0) dataToValidate.DOB = getTrimmedDOB(DOB);
    validate(
      patientInfoValidationConfig,
      dataToValidate,
      onNextValidationFailure,
      onNextValidationSuccess
    );
  };

  const cancelFormWithoutSaving = () => {
    dispatch(clearSubjectInformation());
    props.onModalClose();
  };

  const today = moment();
  const disableFutureDt = current => {
    return current.isBefore(today);
  };

  return (
    <>
      <form id='add-patient-step-one-form' className='step-one-form'>
        <div
          className={`form-group ${
            errors.firstName !== undefined ? 'has-error' : ''
          }`}
        >
          <label className='form-label required' htmlFor='firstName'>
            First Name
          </label>
          <input
            type='text'
            className='form-control'
            value={firstName}
            placeholder='Enter First Name'
            onChange={e => changeFieldHandler(e, setFirstName)}
            name='firstName'
          />
          <HelpBlock value={errors.firstName} />
        </div>
        <div
          className={`form-group ${
            errors.lastName !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='lastName' className='form-label required'>
            Last Name
          </label>
          <input
            type='text'
            className='form-control'
            value={lastName}
            placeholder='Enter Last Name'
            onChange={e => changeFieldHandler(e, setLastName)}
            name='lastName'
          />
          <HelpBlock value={errors.lastName} />
        </div>

        <div
          className={`form-group ${
            errors.addressLine1 !== undefined ? 'has-error' : ''
          }`}
        >
          <label
            htmlFor='addressLine1 required'
            className='form-label required'
          >
            Address Line 1
          </label>
          <input
            type='text'
            className='form-control'
            value={addressLine1}
            placeholder='Enter Address Line 1'
            onChange={e => changeFieldHandler(e, setAddressLine1)}
            name='addressLine1'
          />
          <HelpBlock value={errors.addressLine1} />
        </div>

        <div
          className={`form-group ${
            errors.addressLine2 !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='addressLine2' className='form-label'>
            Address Line 2 (Optional)
          </label>
          <input
            type='text'
            className='form-control'
            value={addressLine2}
            placeholder='Enter Address Line 2 (Optional)'
            onChange={e => changeFieldHandler(e, setAddressLine2)}
            name='addressLine2'
          />
          <HelpBlock value={errors.addressLine2} />
        </div>
        <div className='horizontal-form'>
          <div
            className={`form-group inline-form ${
              errors.city !== undefined ? 'has-error' : ''
            }`}
          >
            <label htmlFor='city' className='form-label required'>
              city
            </label>
            <input
              type='text'
              className='form-control'
              value={city}
              placeholder='City'
              onChange={e => changeFieldHandler(e, setCity)}
              name='city'
            />
            <HelpBlock value={errors.city} />
          </div>

          <div
            className={`form-group inline-form ${
              errors.state !== undefined ? 'has-error' : ''
            }`}
          >
            <label className='form-label required' htmlFor='state'>
              State
            </label>
            <SingleSelectWithBorder
              id='select-state-name'
              placeholder='State'
              onChange={({ value }) => {
                setErrors(clearErrorsForField(errors, 'state'));
                setState(() => {
                  const option = statesOptions.filter(stateOption => {
                    return stateOption.id === value;
                  });
                  if (option.length) {
                    return { value: option[0].id, label: option[0].name };
                  }
                  return '';
                });
              }}
              value={state}
              options={getOptionsForDropDown(statesOptions)}
            />
            <HelpBlock value={errors.state} />
          </div>

          <div
            className={`form-group inline-form ${
              errors.zipcode !== undefined ? 'has-error' : ''
            }`}
          >
            <label htmlFor='zipcode' className='form-label required'>
              Zip Code
            </label>
            <ZipInput
              className='form-control'
              id='zip'
              name='zipcode'
              placeholder='Zip Code'
              value={zipcode}
              onChange={e => changeFieldHandler(e, setZipcode)}
            />
            <HelpBlock value={errors.zipcode} />
          </div>
        </div>
        <div
          className={`form-group ${
            errors.email !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='email' className='form-label required'>
            Email address
          </label>
          <input
            type='text'
            className='form-control'
            value={email}
            placeholder='Enter Email Address'
            onChange={e => changeFieldHandler(e, setEmail)}
            name='email'
          />
          <HelpBlock value={errors.email} />
        </div>

        <div
          className={`form-group ${
            errors.contactNumber !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='phone' className='form-label required'>
            Phone Number
          </label>
          <PhoneInput
            className='form-control'
            id='phone'
            name='contactNumber'
            placeholder='Enter Phone Number'
            value={contactNumber}
            onChange={e => changeFieldHandler(e, setContactNumber)}
            country={props.country}
          />
          <HelpBlock value={errors.contactNumber} />
        </div>

        <div
          className={`form-group ${
            errors.DOB !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='procedureDate'>Date of Birth</label>
          <Datetime
            timeFormat={false}
            dateFormat='MM-DD-YYYY'
            style={{ display: 'bubble' }}
            className='date-picker-field float-left date-field form-control'
            inputProps={{ placeholder: 'Enter Date of Birth', readOnly: false }}
            closeOnSelect
            closeOnTab
            popperPlacement='top'
            value={DOB}
            isValidDate={disableFutureDt}
            popperModifiers={{
              flip: {
                behavior: ['top'], // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false, // tell it not to try to stay within the view (prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false, // turn off since needs preventOverflow to be enabled
              },
            }}
            onChange={date => {
              setDOB(date);
              setErrors(clearErrorsForField(errors, 'DOB'));
            }}
          />
          <HelpBlock value={errors.DOB} />
        </div>
        {/* New Fields */}
      </form>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '16px',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}
      >
        <span>
          <SecondaryButton onClick={cancelFormWithoutSaving}>
            <span className='add-patient-form-button'>
              Cancel without saving
            </span>
          </SecondaryButton>
        </span>
        <span style={{ width: 'fitContent' }}>
          <PrimaryButton
            onClick={onSubmit}
            form='add-patient-step-one-form'
            type='submit'
          >
            <span className='add-patient-form-button'>
              Continue to Study Information
            </span>
          </PrimaryButton>
        </span>
      </div>
    </>
  );
};

export default AddPatientView;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import AddResourceFormPreview from './AddResourceFormPreview';
import AddResourceStepOneForm from './AddResourceStepOneForm';
import AddResourceStepTwoForm from './AddResourceStepTwoForm';
import { resetAddResourcesData, updateResourceAdminValue } from '../action';

const AddEditResourceModal = props => {
  const dispatch = useDispatch();

  const LAST_STEP = 3;

  const resourceAdminData = useSelector(store => store.resourceAdminReducer);

  const step = useSelector(store => store.resourceAdminReducer.step);

  const previousStep = useSelector(
    store => store.resourceAdminReducer.previousStep
  );

  const handleModalHide = () => {
    dispatch(
      resetAddResourcesData({
        page: resourceAdminData.page,
        filterName: resourceAdminData.filterName,
      })
    );
    props.updateSelectedResourceId(null);
    props.onHide();
  };

  const onStepChange = newStep => {
    dispatch(updateResourceAdminValue('step', newStep));
    dispatch(updateResourceAdminValue('previousStep', step));
  };

  const onBack = step => {
    onStepChange(step - 1);
  };

  const onContinue = newStep => {
    if (previousStep === LAST_STEP) {
      dispatch(updateResourceAdminValue('step', LAST_STEP));
    } else {
      dispatch(updateResourceAdminValue('step', newStep));
    }
    dispatch(updateResourceAdminValue('previousStep', step));
  };

  const getFormBasedOnStep = step => {
    switch (step) {
      case 1:
        return (
          <AddResourceStepOneForm
            step={1}
            totalSteps={LAST_STEP}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onClose={() => handleModalHide()}
          />
        );
      case 2:
        return (
          <AddResourceStepTwoForm
            step={2}
            totalSteps={LAST_STEP}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onClose={() => handleModalHide()}
          />
        );
      case 3:
        return (
          <AddResourceFormPreview
            step={3}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onStepChange={step => onStepChange(step)}
            handleModalHide={handleModalHide}
            onClose={() => handleModalHide()}
          />
        );
    }
  };

  const getFormSubheader = () => {
    switch (step) {
      case 1:
        return 'Personal Information';
        break;
      case 2:
        return 'Position Information';
        break;
      case 3:
        return 'Review and Finish';
      default:
        return 'Add New User';
    }
  };

  return (
    <FormModal
      show={props.show}
      onHide={handleModalHide}
      title={{
        text: resourceAdminData.selectedResourceId
          ? 'Edit Resource Info'
          : 'Add New Resource',
      }}
      subheader={
        <div className='modal-subheader-content'>
          <div>{getFormSubheader()}</div>
          <div className='text-right'>
            <span className='steps-count active-step-count'>{`${step} of ${LAST_STEP}`}</span>
          </div>
        </div>
      }
      modalStyle={{
        width: '700px',
        height: '800px',
      }}
      form={{
        form: getFormBasedOnStep(step),
      }}
    />
  );
};

export default AddEditResourceModal;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import {
  resetAddLocationsData,
  resetAddUsersData,
  updateLocationAdminValue,
} from '../action';
import AddLocationStepOneForm from './AddLocationStepOneForm';
import AddLocationStepTwoForm from './AddLocationStepTwoForm';
import AddLocationFormPreview from './AddLocationFormPreview';

const AddEditLocationModal = props => {
  const dispatch = useDispatch();
  const LAST_STEP = 3;

  const locationAdminData = useSelector(store => store.locationAdminReducer);
  const { step } = locationAdminData;

  const previousStep = useSelector(
    store => store.locationAdminReducer.previousStep
  );

  const handleModalHide = () => {
    dispatch(resetAddLocationsData());
    dispatch(updateLocationAdminValue('step', 1));
    props.updateSelectedLocationId(null);
    props.onHide();
  };

  const onStepChange = newStep => {
    dispatch(updateLocationAdminValue('step', newStep));
    dispatch(updateLocationAdminValue('previousStep', step));
  };

  const onBack = step => {
    onStepChange(step - 1);
  };

  const onContinue = newStep => {
    if (previousStep === LAST_STEP) {
      dispatch(updateLocationAdminValue('step', LAST_STEP));
    } else {
      dispatch(updateLocationAdminValue('step', newStep));
    }
    dispatch(updateLocationAdminValue('previousStep', step));
  };

  const getFormSubheader = () => {
    switch (step) {
      case 1:
        return 'Location Information';
        break;
      case 2:
        return 'Location People';
        break;
      case 3:
        return 'Review and Finish';
      default:
        return 'Add New Location';
    }
  };

  const getFormBasedOnStep = step => {
    switch (step) {
      case 1:
        return (
          <AddLocationStepOneForm
            step={1}
            totalSteps={3}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onClose={() => handleModalHide()}
          />
        );
      case 2:
        return (
          <AddLocationStepTwoForm
            step={2}
            totalSteps={3}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onClose={() => handleModalHide()}
          />
        );

      case 3:
        return (
          <AddLocationFormPreview
            step={3}
            totalSteps={3}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onStepChange={step => onStepChange(step)}
            handleModalHide={handleModalHide}
          />
        );
    }
  };

  return (
    <FormModal
      show={props.show}
      onHide={handleModalHide}
      title={{
        text: locationAdminData.selectedLocationId
          ? 'Edit Location Info'
          : 'Add New Location',
      }}
      subheader={
        <div className='modal-subheader-content'>
          <div>{getFormSubheader()}</div>
          <div className='text-right'>
            <span className='steps-count active-step-count'>{`${step} of 3`}</span>
          </div>
        </div>
      }
      modalStyle={{
        width: '700px',
        height: '700px',
      }}
      form={{
        form: getFormBasedOnStep(step),
      }}
    />
  );
};

export default AddEditLocationModal;

import { QUICK_VIEW_SUMMARY, DEFAULT_URL, OUTCOMES_CARD } from "services/constants";
import { getAuthValues } from "utils/webContainerAuth";
import axios from "axios";

export const getQuickViewSummary = (userProcedureId) => {
  const url = DEFAULT_URL + QUICK_VIEW_SUMMARY.replace(":user_procedure_id", userProcedureId);
  return axios.get(`${url}`, {
    headers: getAuthValues(),
  });
};


export const getOutcomesCardSummary = (userProcedureId) => {
  const url = DEFAULT_URL + OUTCOMES_CARD;

  const queryParams = {
    user_procedure_id: userProcedureId,
  };

  return axios.get(`${url}`, {
    headers: getAuthValues(),
    params: queryParams,
  });
};

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { resetEditNotesStarted } from 'common/actions';
import { useSelector, useDispatch } from 'react-redux';
import validate, { clearErrorsForField } from 'common/validator';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import HelpBlock from 'components/HelpBlock';
import AddAttachmentModal from 'containers/AddAttachmentModal';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import { COMMUNICATION_LOG_SUPPORTED_FILE_FORMATS } from 'constants';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { doPostCreateNotesRequest } from '../action';

const validationConfig = {
  fields: ['title', 'description'],
  rules: {
    title: [{ rule: 'isRequired', message: 'Title is required' }],
    description: [{ rule: 'isRequired', message: 'Description is required' }],
  },
};

const CreateNote = props => {
  const params = useParams();
  const dispatch = useDispatch();

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isShared, setIsShared] = useState(true);

  const [mediaResources, setMediaResources] = useState([]);

  const isUploadInProgress = useSelector(
    store => store.documentsReducer.isUploadInProgress
  );
  const editNoteStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.editNoteStarted
  );

  useEffect(() => {
    if (editNoteStarted) {
      dispatch(resetEditNotesStarted());
      resetState();
      props.onModalClose();
    }
  }, [editNoteStarted]);

  const validateFormFields = () => {
    if (!isUploadInProgress) {
      return false;
    }
    return true;
  };

  const onSaveCommunicationLog = e => {
    e.preventDefault();
    validate(
      validationConfig,
      { title, description },
      errors => setErrors(errors),
      onFormValidationSuccess
    );
  };

  const onFormValidationSuccess = () => {
    const params = {
      user_procedure_id: procedureId,
      user_id: patientId,
      description: description.trim(),
      title: title.trim(),
      is_shared: isShared ? 1 : 0,
      media_resources: mediaResources,
      secure_action_code: SecureCodes.CREATE_NOTE,
      needs_reason: true,
    };
    dispatch(
      secureActionWithDoubleAuthentication(doPostCreateNotesRequest, params)
    );
  };

  const resetState = () => {
    setTitle('');
    setDescription('');
    setMediaResources([]);
  };

  return (
    <FormModal
      show={props.showModal}
      onHide={() => {
        resetState();
        props.onModalClose();
      }}
      title={{
        text: 'Create Note',
      }}
      modalStyle={{
        width: 'auto',
        height: 'auto',
      }}
      form={{
        form: (
          <form className='create-note-form' id='create-note-form'>
            <div
              className={`form-group ${
                errors.title !== undefined ? 'has-error' : ''
              } note-title`}
            >
              <label htmlFor='title'>Title</label>
              <input
                type='text'
                value={title}
                placeholder='Title'
                maxLength='250'
                onChange={e => {
                  clearErrorsForField(errors, 'title');
                  setTitle(e.target.value);
                }}
                className='form-control'
                id='title'
              />
              <HelpBlock value={errors.title} />
            </div>
            <div
              className={`form-group ${
                errors.description !== undefined ? 'has-error' : ''
              }`}
            >
              <label htmlFor='description'>Description</label>
              <textarea
                type='text'
                value={description}
                placeholder='Type here ...'
                onChange={e => {
                  clearErrorsForField(errors, 'description');
                  setDescription(e.target.value);
                }}
                id='description'
                className='form-control'
              />
              <HelpBlock value={errors.description} />
            </div>
            <div className='attachment-section form-group'>
              <label htmlFor='attachment'>Add an attachment</label>
              <AddAttachmentModal
                onUpdateAttachments={attachments => {
                  setMediaResources(attachments);
                }}
                showModal={props.showModal}
                onModalClose={props.onModalClose}
                isBindedWithModal
                fileFormats={COMMUNICATION_LOG_SUPPORTED_FILE_FORMATS}
                mediaResources={mediaResources}
              />
            </div>
          </form>
        ),
      }}
      buttons={[
        <PrimaryButton
          disabled={validateFormFields()}
          onClick={onSaveCommunicationLog}
          form='create-note-form'
          type='submit'
          style={{ width: '260px', margin: '24px 0px' }}
        >
          Create
        </PrimaryButton>,
      ]}
    />
  );
};

export default CreateNote;

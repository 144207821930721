/*
 *
 * Connect reducer
 *
 */
import {
  GET_NOTES_DETAILS_REQUEST_SENT,
  GET_NOTES_DETAILS_REQUEST_SUCCEED,
  GET_NOTES_DETAILS_REQUEST_FAILED,
  POST_RESPOND_TO_NOTES_REQUEST_SENT,
  POST_RESPOND_TO_NOTES_REQUEST_SUCCEED,
  POST_RESPOND_TO_NOTES_REQUEST_FAILED,
  PUT_EDIT_NOTES_REQUEST_SENT,
  PUT_EDIT_NOTES_REQUEST_SUCCEED,
  PUT_EDIT_NOTES_REQUEST_FAILED,
  PUT_EDIT_COMMENTS_REQUEST_SENT,
  PUT_EDIT_COMMENTS_REQUEST_SUCCEED,
  PUT_EDIT_COMMENTS_REQUEST_FAILED,
} from './constants';

const initialState = {
  isLoading: false,
  isEditNoteRequestInProgress: false,
  isAddCommentRequestInProgress: false,
  noteDetails: {
    title: '',
    description: '',
    creadtedById: null,
    createdByName: '',
    isShared: 0,
    createdAt: null,
    mediaResources: [],
  },

  comments: [],
};

function patientNoteDetailsReducer(state = initialState, action) {
  let newComments = [];
  let comments = null;
  switch (action.type) {
    case GET_NOTES_DETAILS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isAddCommentRequestInProgress: false,
        noteDetails: null,
        comments: [],
      };
    case PUT_EDIT_NOTES_REQUEST_SENT:
      return {
        ...state,
        isEditNoteRequestInProgress: true,
      };
    case PUT_EDIT_NOTES_REQUEST_SUCCEED:
      return {
        ...state,
        isEditNoteRequestInProgress: false,
        noteDetails: action.data,
      };
    case GET_NOTES_DETAILS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_NOTES_DETAILS_REQUEST_SUCCEED:
      ({ comments } = action.data);
      return {
        ...state,
        isLoading: false,
        noteDetails: action.data,
        comments,
      };
    case PUT_EDIT_COMMENTS_REQUEST_SENT:
    case POST_RESPOND_TO_NOTES_REQUEST_SENT:
      return {
        ...state,
        isAddCommentRequestInProgress: true,
      };
    case POST_RESPOND_TO_NOTES_REQUEST_SUCCEED:
      newComments = state.comments.concat(action.data);
      return {
        ...state,
        isAddCommentRequestInProgress: false,
        comments: newComments,
      };
    case PUT_EDIT_COMMENTS_REQUEST_SUCCEED:
      comments = state.comments.filter(item => item.id !== action.data.id);
      newComments = comments.concat(action.data);
      return {
        ...state,
        isAddCommentRequestInProgress: false,
        comments: newComments,
      };
    case PUT_EDIT_COMMENTS_REQUEST_FAILED:
    case POST_RESPOND_TO_NOTES_REQUEST_FAILED:
      return {
        ...state,
        isAddCommentRequestInProgress: false,
      };
    case PUT_EDIT_NOTES_REQUEST_FAILED:
      return {
        ...state,
        isEditNoteRequestInProgress: false,
      };
    default:
      return state;
  }
}

export default patientNoteDetailsReducer;

import React from 'react';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import SchoolIcon from '@mui/icons-material/School';
import ArticleIcon from '@mui/icons-material/Article';
import UserAdmin from './UsersAdmin/UsersAdmin';
import LocationsAdmin from './LocationsAdmin/LocationsAdmin';
import ConsentAdmin from './ConsentAdmin/ConsentAdmin';
import TeamResource from './TeamResource/TeamResource';
import AuditTrailAdmin from './AuditTrailAdmin';

export const userAdminTabsConfig = [
  {
    id: 1,
    name: 'users',
    href: '#users',
    disabled: false,
    title: {
      icon: <AdminPanelSettingsIcon sx={{ fontSize: '32px' }} />,
      text: 'Users',
    },
    component: UserAdmin,
  },
  {
    id: 2,
    name: 'locations',
    href: '#locations',
    disabled: false,
    title: {
      icon: <AddLocationIcon sx={{ fontSize: '32px' }} />,
      text: 'Locations',
    },

    component: LocationsAdmin,
  },
  {
    id: 3,
    name: 'consent',
    href: '#consent',
    disabled: false,
    title: {
      icon: <DocumentScannerIcon sx={{ fontSize: '32px' }} />,
      text: 'Consents',
    },

    component: ConsentAdmin,
  },
  {
    id: 4,
    name: 'team_resources',
    href: '#team-resources',
    disabled: false,
    title: {
      icon: <SchoolIcon sx={{ fontSize: '32px' }} />,
      text: 'Team Resources',
    },
    component: TeamResource,
  },
  {
    id: 5,
    name: 'admin_audit_trail',
    href: '#audit_trail',
    disabled: false,
    title: {
      icon: <ArticleIcon sx={{ fontSize: '32px' }} />,
      text: 'Audit Trail',
    },
    component: AuditTrailAdmin,
  },
];

export const defaultTab = 1;

export const provider_value = 'Provider';
export const patient_advocate_value = 'PatientAdvocate';

export const master_value = 'master';
export const support_value = 'support';

export const UserTypes = [
  { label: 'Master', value: master_value },
  { label: 'Support', value: support_value },
];

export const Types = [
  { label: 'Provider', value: provider_value },
  { label: 'PatientAdvocate', value: patient_advocate_value },
];

export const UserRoleOptions = {
  [provider_value]: [
    { name: 'Health Care Provider', id: 'HCP' },
    { name: 'CRC', id: 'CRC' },
    { name: 'RA', id: 'RA' },
  ],
  [patient_advocate_value]: [
    { name: 'Rep', id: 'Rep' },
    { name: 'FCE', id: 'FCE' },
  ],
};

export const GenderOptions = [
  { label: 'Male', value: 0 },
  { label: 'Female', value: 1 },
  { label: 'Other', value: 2 },
];

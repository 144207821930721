/** \n * AddPatientView component renders a modal for adding new patient subject.
 * @author Dilip Joshi
 * When a user needs to add a new patient subject, this component is used to guide the user
 * through the necessary steps to complete the process which includes to capture subject and study
 * information and a review before creating and saving the patient.
 *
 * @param {object} props - The props object contains the following properties:
 * @param {boolean} props.show - If true, the AddPatientView modal will show. If false, the modal will be hidden.
 * @param {function} props.onSaveNewPatientRequest - A function to be called to save the new patient data.
 * @param {function} props.onModalClose - A function to be called when the user closes the modal.
 *
 * @example
 * ```jsx
 * import AddPatientView from './AddPatientView';
 * const MyComponent = () => {
 *    const [showAddPatientModal, setShowAddPatientModal] = useState(false);
 *    const handleSaveNewPatientRequest = (patient) => {
 *      // Code to save the new patient data
 *    }
 *    const handleAddPatientModalClose = () => {
 *      setShowAddPatientModal(false);
 *    }
 *    return (
 *      <>
 *        <button onClick={() => setShowAddPatientModal(true)}>Add New Patient</button>
 *        <AddPatientView show={showAddPatientModal} onSaveNewPatientRequest={handleSaveNewPatientRequest} onModalClose={handleAddPatientModalClose} />
 *      </>
 *    );
 * }
 * ```
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearSubjectInformation,
  clearStudyInformation,
  getStatesListForDropdown,
} from 'common/actions';
import { FormModal } from '../StyleGuideComponents/common/modals';
import LightboxModal from '../LightboxModal';
import StepOneForm from './StepOneForm';
import StepTwoForm from './StepTwoForm';
import StepThree from './StepThree';
import './style.scss';

const AddPatientView = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStatesListForDropdown());
  }, []);

  const [activeFormStep, setActiveFormPage] = useState(1);
  const [totalSteps, setTotalSteps] = useState(3);

  const modalClose = () => {
    dispatch(clearSubjectInformation());
    dispatch(clearStudyInformation());
    props.onModalClose();
    setActiveFormPage(1);
  };

  const getStepWiseData = () => {
    switch (activeFormStep) {
      case 1:
        return (
          <StepOneForm
            onModalClose={modalClose}
            nextPage={2}
            moveToPage={page => setActiveFormPage(page)}
          />
        );
        break;
      case 2:
        return (
          <StepTwoForm
            onModalClose={modalClose}
            previousPage={1}
            nextPage={3}
            moveToPage={page => setActiveFormPage(page)}
          />
        );
      case 3:
        return (
          <StepThree
            onModalClose={modalClose}
            nextPage={3}
            previousPage={2}
            moveToPage={page => setActiveFormPage(page)}
            onSaveNewPatientRequest={props.onSaveNewPatientRequest}
          />
        );
    }
  };

  const getFormSubheader = () => {
    switch (activeFormStep) {
      case 1:
        return 'Subject Information';
        break;
      case 2:
        return 'Study Information';
        break;
      case 3:
        return 'Review and Finish';
        break;
      default:
        return 'Add New Patient';
    }
  };

  return (
    <FormModal
      show={props.show}
      onHide={modalClose}
      title={{
        text: 'Add a Subject',
      }}
      modalStyle={{
        height: '90%',
        width: '750px',
      }}
      subheader={
        <div className='modal-subheader-content'>
          <div>{getFormSubheader()}</div>
          <div className='text-right'>
            <span className='steps-count active-step-count'>{`${activeFormStep} of ${totalSteps}`}</span>
          </div>
        </div>
      }
      form={{
        form: getStepWiseData(),
      }}
      buttons={[]}
    />
  );
};

export default AddPatientView;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import AddTaskReminderFormPreview from './AddTaskReminderFormPreview';
import AddTaskReminderStepOneForm from './AddTaskReminderStepOneForm';
import AddTaskReminderStepTwoForm from './AddTaskReminderStepTwoForm';
import { resetAddTasksReminderData } from '../action';
import AddTaskReminderStepThreeForm from './AddTaskReminderStepThreeForm';

const AddEditTaskReminderModal = props => {
  const dispatch = useDispatch();

  const taskReminderAdminData = useSelector(
    store => store.taskReminderAdminReducer
  );

  const [step, setStep] = useState(1);

  const handleModalHide = () => {
    dispatch(
      resetAddTasksReminderData({
        page: taskReminderAdminData.page,
        filterName: taskReminderAdminData.filterName,
      })
    );
    setStep(1);
    props.updateSelectedTaskReminderId(null);
    props.onHide();
  };

  const getFormBasedOnStep = step => {
    switch (step) {
      case 1:
        return (
          <AddTaskReminderStepOneForm
            step={1}
            totalSteps={4}
            onStepChange={step => setStep(step)}
            onClose={() => handleModalHide()}
          />
        );
      case 2:
        return (
          <AddTaskReminderStepTwoForm
            step={2}
            totalSteps={4}
            onStepChange={step => setStep(step)}
            onClose={() => handleModalHide()}
          />
        );

      case 3:
        return (
          <AddTaskReminderStepThreeForm
            step={3}
            totalSteps={4}
            onStepChange={step => setStep(step)}
            onClose={() => handleModalHide()}
          />
        );
      case 4:
        return (
          <AddTaskReminderFormPreview
            step={4}
            totalSteps={4}
            onStepChange={step => setStep(step)}
            onClose={() => handleModalHide()}
          />
        );
    }
  };

  const getFormSubheader = () => {
    switch (step) {
      case 1:
        return 'Personal Information';
      case 2:
        return 'Position Information';
      case 3:
        return 'Role Information';
      default:
        return 'Add New User';
    }
  };

  return (
    <FormModal
      show={props.show}
      onHide={handleModalHide}
      title={{
        text: props.selectedTaskReminderId ? 'Edit Task Info' : 'Add New Task',
      }}
      subheader={
        <div className='modal-subheader-content'>
          <div>{getFormSubheader()}</div>
          <div className='text-right'>
            <span className='steps-count active-step-count'>{`${step} of 4`}</span>
          </div>
        </div>
      }
      modalStyle={{
        width: '800px',
        height: '800px',
      }}
      form={{
        form: getFormBasedOnStep(step),
      }}
    />
  );
};

export default AddEditTaskReminderModal;

/*
 *
 * Profile Page reducer
 *
 */
import camelcaseKeys from 'change-case-object';
import { RESET_GLOBAL_REDUCERS } from '../constants';
import * as Constants from './constants';

export const docInitState = {
  isUploadInProgress: false,
  isUploadSuccess: false,
  document: null,
  mediaResource: {},
  error: '',
  extraDoc: {
    document: null,
    mediaResource: {},
  },
  consentDocument: '',
};

export const documentsReducer = (state = docInitState, action) => {
  switch (action.type) {
    case Constants.GET_DOC_AUTHORIZED_URL_REQUEST_SENT:
    case Constants.GET_DOC_AUTHORIZED_URL_REQUEST_SUCCEED:
    case Constants.DOCUMENT_UPLOAD_REQUEST_SENT:
      return {
        ...state,
        isUploadInProgress: true,
        error: '',
      };
    case Constants.DOCUMENT_UPLOAD_REQUEST_SUCCEED:
      if (action.data.isExtra) {
        return {
          ...state,
          isUploadInProgress: false,
          isUploadSuccess: true,
          extraDoc: {
            document: action.data.url,
            mediaResource: action.data.mediaResource,
          },
          error: '',
        };
      }
      return {
        ...state,
        isUploadInProgress: false,
        isUploadSuccess: true,
        document: action.data.url,
        mediaResource: action.data.mediaResource,
        error: '',
        consentDocument: action.data.mediaResource.name, // adding the consent document to access it in useradmin while adding a new consent
      };
    case Constants.DOCUMENT_UPLOAD_REQUEST_FAILED:
    case Constants.GET_DOC_AUTHORIZED_URL_REQUEST_FAILED:
      return {
        ...state,
        isUploadInProgress: false,
        isUploadSuccess: false,
        error: 'Could not upload the document, please try again.',
      };
    case Constants.DOCUMENT_UPLOAD_CLEAR_STATE:
      return { ...docInitState, consentDocument: state.consentDocument };
    case Constants.DOCUMENT_UPLOAD_REMOVE_DOCUMENT:
      // return {
      //   ...state,
      //   isUploadInProgress: false,
      //   mediaResource: state.mediaResources.filter((item) => item.id !== action.id),
      //   error: '',
      // };
      return docInitState;
    case RESET_GLOBAL_REDUCERS:
      return docInitState;
    default:
      return state;
  }
};

const locationsInitialState = {
  isLoading: false,
  locations: [],
};

export const getProcedureLocationsReducer = (
  state = locationsInitialState,
  action
) => {
  switch (action.type) {
    case Constants.CLEAR_STUDY_INFORMATION:
      return {
        ...state,
        locations: [],
      };
    case Constants.GET_PROCEDURE_LOCATIONS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_PROCEDURE_LOCATIONS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        locations: action.data.locations,
      };
    case Constants.GET_PROCEDURE_LOCATIONS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return locationsInitialState;
    default:
      return state;
  }
};

export const getProcedureProvidersReducer = (
  state = locationsInitialState,
  action
) => {
  switch (action.type) {
    case Constants.GET_PROCEDURE_PROVIDERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_PROCEDURE_PROVIDERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        providers: action.data.providers,
      };
    case Constants.GET_PROCEDURE_PROVIDERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return locationsInitialState;
    default:
      return state;
  }
};

const masterProvidersInitialState = {
  isLoading: false,
  masterProviders: [],
};
export const getProcedureMasterProvidersReducer = (
  state = masterProvidersInitialState,
  action
) => {
  switch (action.type) {
    case Constants.CLEAR_STUDY_INFORMATION:
      return {
        ...state,
        masterProviders: [],
      };
    case Constants.GET_PROCEDURE_MASTER_PROVIDERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_PROCEDURE_MASTER_PROVIDERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        masterProviders: action.data.providers,
      };
    case Constants.GET_PROCEDURE_MASTER_PROVIDERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return masterProvidersInitialState;
    default:
      return state;
  }
};

const counterSigneeInitialState = {
  isLoading: false,
  counterSigne: [],
};

export const getCounterSigneReducer = (
  state = counterSigneeInitialState,
  action
) => {
  switch (action.type) {
    case Constants.CLEAR_STUDY_INFORMATION:
      return {
        ...state,
        counterSigne: [],
      };
    case Constants.GET_COUNTER_SIGNE_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_COUNTER_SIGNE_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        counterSigne: action.data.counterSigne,
      };
    case Constants.GET_COUNTER_SIGNE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return counterSigneeInitialState;
    default:
      return state;
  }
};

const procedureModifiersInitialState = {
  isLoading: false,
  procedureModifiers: [],
};

export const getProcedureModifiersReducer = (
  state = procedureModifiersInitialState,
  action
) => {
  switch (action.type) {
    case Constants.GET_PROCEDURE_MODIFIERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_PROCEDURE_MODIFIERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        procedureModifiers: action.data.modifiers,
      };
    case Constants.GET_PROCEDURE_MODIFIERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return procedureModifiersInitialState;
    default:
      return state;
  }
};

const updateProcedureModifiersInitState = {
  isLoading: false,
  message: null,
};

export const updateProcedureModifiersReducer = (
  state = updateProcedureModifiersInitState,
  action
) => {
  switch (action.type) {
    case Constants.UPDATE_PROCEDURE_MODIFIERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        message: null,
      };
    case Constants.UPDATE_PROCEDURE_MODIFIERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        message: 'Procedure Updated Successfully',
      };
    case Constants.UPDATE_PROCEDURE_MODIFIERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getDepartmentProceduresInitialState = {
  isLoading: false,
  procedures: [],
};

export const getDepartmentProceduresReducer = (
  state = getDepartmentProceduresInitialState,
  action
) => {
  switch (action.type) {
    case Constants.CLEAR_STUDY_INFORMATION:
      return {
        ...state,
        procedures: [],
      };
    case Constants.GET_DEPARTMENT_PROCEDURES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_DEPARTMENT_PROCEDURES_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        procedures: action.data.procedures,
      };
    case Constants.GET_DEPARTMENT_PROCEDURES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getDepartmentProvidersInitialState = {
  isLoading: false,
  providers: [],
};

export const getDepartmentProvidersReducer = (
  state = getDepartmentProvidersInitialState,
  action
) => {
  switch (action.type) {
    case Constants.GET_DEPARTMENT_PROVIDERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_DEPARTMENT_PROVIDERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        providers: action.data.providers,
      };
    case Constants.GET_DEPARTMENT_PROVIDERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getDepartmentOnlyProvidersInitialState = {
  isLoading: false,
  onlyProviders: [],
};

export const getDepartmentOnlyProvidersReducer = (
  state = getDepartmentOnlyProvidersInitialState,
  action
) => {
  switch (action.type) {
    case Constants.GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        onlyProviders: action.data.providers,
      };
    case Constants.GET_DEPARTMENT_ONLY_PROVIDERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const getDepartmentOnlyGeneralCardiologistsInitialState = {
  isLoading: false,
  onlyGeneralCardiologists: [],
  isMfaEnabled: false,
};

export const getDepartmentOnlyGeneralCardiologistsReducer = (
  state = getDepartmentOnlyGeneralCardiologistsInitialState,
  action
) => {
  switch (action.type) {
    case Constants.GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        onlyGeneralCardiologists: action.data.generalCardiologists,
        isMfaEnabled: action.data.isMfaEnabled,
      };
    case Constants.GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

// Save Search Filters
const saveSearchFiltersInitState = {
  isSaving: false,
  message: null,
  error: null,
};

export const saveSearchFiltersReducer = (
  state = saveSearchFiltersInitState,
  action
) => {
  switch (action.type) {
    case Constants.SAVE_SEARCH_FILTERS_SENT:
    case Constants.UPDATE_SEARCH_FILTERS_SENT:
      return {
        ...state,
        isSaving: true,
        message: null,
        error: null,
      };
    case Constants.SAVE_SEARCH_FILTERS_SUCCEED:
      return {
        ...state,
        isSaving: false,
        message: 'Filter saved successfully',
      };
    case Constants.UPDATE_SEARCH_FILTERS_SUCCEED:
      return {
        ...state,
        isSaving: false,
        message: 'Filter updated successfully',
      };
    case Constants.SAVE_SEARCH_FILTERS_FAILED:
    case Constants.UPDATE_SEARCH_FILTERS_FAILED:
      return {
        ...state,
        isSaving: false,
        error: action.data.error,
      };
    default:
      return state;
  }
};

// Save Search Filters
const deleteCustomFiltersInitState = {
  isDeleting: false,
  message: null,
  error: null,
};

export const deleteCustomFiltersReducer = (
  state = deleteCustomFiltersInitState,
  action
) => {
  switch (action.type) {
    case Constants.DELETE_SEARCH_FILTERS_SENT:
      return {
        ...state,
        isDeleting: true,
        message: null,
        error: null,
      };
    case Constants.DELETE_SEARCH_FILTERS_SUCCEED:
      return {
        ...state,
        isDeleting: false,
        message: 'Filter deleted successfully',
      };
    case Constants.DELETE_SEARCH_FILTERS_FAILED:
      return {
        ...state,
        isDeleting: false,
        error: 'Something went wrong',
      };
    default:
      return state;
  }
};

const initStateAddProcedure = {
  hasError: false,
  isAdded: false,
  isLoading: false,
};

export const addNewProcedureReducer = (
  state = initStateAddProcedure,
  action
) => {
  switch (action.type) {
    case Constants.ADD_NEW_PROCEDURE_REQUEST_SENT:
      return {
        isLoading: true,
        hasError: false,
        isAdded: false,
      };
    case Constants.ADD_NEW_PROCEDURE_REQUEST_SUCCEED:
      return {
        hasError: false,
        isAdded: true,
        isLoading: false,
      };
    case Constants.ADD_NEW_PROCEDURE_REQUEST_FAILED:
      return {
        hasError: true,
        isAdded: false,
        isLoading: false,
      };
    case Constants.CLEAR_ADD_PROCEDURE_STATES_REQUEST:
      return {
        hasError: false,
        isAdded: false,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return initStateAddProcedure;
    default:
      return state;
  }
};

// Mobile Number Verification
const codeInitialState = {
  isFetchingCode: false,
  verificationCodeFetched: false,
  hasCodeError: false,
};

export const sendVerificationCodeReducer = (
  state = codeInitialState,
  action
) => {
  switch (action.type) {
    case Constants.GET_VERIFICATION_CODE_REQUEST_SENT:
      return {
        ...state,
        isFetchingCode: true,
        verificationCodeFetched: false,
        hasCodeError: false,
      };
    case Constants.GET_VERIFICATION_CODE_REQUEST_SUCCEED:
      return {
        ...state,
        isFetchingCode: false,
        verificationCodeFetched: true,
      };
    case Constants.GET_VERIFICATION_CODE_REQUEST_FAILED:
      return {
        ...state,
        isFetchingCode: false,
        verificationCodeFetched: false,
        hasCodeError: true,
      };
    case Constants.CLEAR_VERIFICATION_CODE:
      return {
        ...state,
        isFetchingCode: false,
        verificationCodeFetched: false,
        hasCodeError: false,
      };
    default:
      return state;
  }
};

const verifyInitialState = {
  isVerifyingCode: false,
  hasVerifyError: false,
  isVerifiedPhoneNumber: false,
};

export const verifyOTPReducer = (state = verifyInitialState, action) => {
  switch (action.type) {
    case Constants.VERIFY_OTP_REQUEST_SENT:
      return {
        ...state,
        isVerifyingCode: true,
        hasVerifyError: false,
        isVerifiedPhoneNumber: false,
      };
    case Constants.VERIFY_OTP_REQUEST_SUCCEED:
      return {
        ...state,
        isVerifyingCode: false,
        isVerifiedPhoneNumber: true,
      };
    case Constants.VERIFY_OTP_REQUEST_FAILED:
      return {
        ...state,
        isVerifyingCode: false,
        hasVerifyError: true,
      };
    case Constants.CLEAR_VERIFY_OTP:
      return {
        ...state,
        isVerifyingCode: false,
        hasVerifyError: false,
        isVerifiedPhoneNumber: false,
      };
    default:
      return state;
  }
};

const locationInitialState = {
  isLoading: false,
  result: null,
  hasError: false,
  errorMsg: '',
};

export const locationReducer = (state = locationInitialState, action) => {
  switch (action.type) {
    case Constants.GET_LOCATION_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMsg: '',
      };
    case Constants.GET_LOCATION_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        result: action.data,
        errorMsg: '',
      };
    case Constants.GET_LOCATION_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMsg:
          action.data && action.data.error
            ? action.data.error
            : 'Something went wrong',
      };
    case Constants.CLEAR_DATA:
    case RESET_GLOBAL_REDUCERS:
      return locationInitialState;
    default:
      return state;
  }
};

const getDepartmentSupportedKeysInitialState = {
  isLoading: false,
  supportedKeys: [],
};

export const getDepartmentSupportedKeysReducer = (
  state = getDepartmentSupportedKeysInitialState,
  action
) => {
  switch (action.type) {
    case Constants.GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        supportedKeys: action.data.supportedKeys,
      };
    case Constants.GET_DEPARTMENT_SUPPORTED_KEYS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const validateEmailUniquenessInitialState = {
  isEmailUnique: false,
};

export const validateEmailUniquenessReducer = (
  state = validateEmailUniquenessInitialState,
  action
) => {
  switch (action.type) {
    case Constants.VALIDATE_EMAIL_UNIQUENESS_REQUEST_SENT:
      return {
        ...state,
      };
    case Constants.VALIDATE_EMAIL_UNIQUENESS_REQUEST_SUCCEED:
      return {
        ...state,
        isEmailUnique: action.data.isEmailUnique,
      };
    case Constants.VALIDATE_EMAIL_UNIQUENESS_REQUEST_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

const validatePhoneUniquenessInitialState = {
  isPhoneUnique: false,
};

export const validatePhoneUniquenessReducer = (
  state = validatePhoneUniquenessInitialState,
  action
) => {
  switch (action.type) {
    case Constants.VALIDATE_PHONE_UNIQUENESS_REQUEST_SENT:
      return {
        ...state,
      };
    case Constants.VALIDATE_PHONE_UNIQUENESS_REQUEST_SUCCEED:
      return {
        ...state,
        isPhoneUnique: action.data.isPhoneUnique,
      };
    case Constants.VALIDATE_PHONE_UNIQUENESS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
const getSearchCategoryListInitialState = {
  isLoading: false,
  categoryList: {},
};

export const getSearchCategoryListReducer = (
  state = getSearchCategoryListInitialState,
  action
) => {
  switch (action.type) {
    case Constants.GET_SEARCH_CATEGORY_LIST_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_SEARCH_CATEGORY_LIST_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        categoryList: camelcaseKeys.snakeCase(action.data.categoryList),
      };
    case Constants.GET_SEARCH_CATEGORY_LIST_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const addPatientData = {
  firstName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipcode: '',
  email: '',
  contactNumber: '',
  DOB: '',
  studyNameId: { value: null, label: null },
  locationId: { value: null, label: null },
  masterProviderId: { value: null, label: null },
  counterSigneId: { value: null, label: null },
  isLoading: false,
  statesList: [],
};

export const AddPatientReducer = (state = addPatientData, action) => {
  switch (action.type) {
    case Constants.SHOW_LOADER_FOR_ADD_PATIENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.STOP_LOADER_FOR_ADD_PATIENT:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.SAVE_SUBJECT_INFORMATION:
      return {
        ...state,
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        addressLine1: action.data.addressLine1,
        addressLine2: action.data.addressLine2,
        city: action.data.city,
        state: action.data.state,
        zipcode: action.data.zipcode,
        email: action.data.email,
        contactNumber: action.data.contactNumber,
        DOB: action.data.DOB,
      };
    case Constants.CLEAR_SUBJECT_INFORMATION:
      return {
        ...state,
        firstName: '',
        lastName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipcode: '',
        email: '',
        contactNumber: '',
        DOB: '',
      };
    case Constants.SAVE_STUDY_INFORMATION:
      return {
        ...state,
        studyNameId: action.data.studyNameId
          ? action.data.studyNameId
          : state.studyNameId,
        locationId: action.data.locationId
          ? action.data.locationId
          : state.locationId,
        masterProviderId: action.data.masterProviderId
          ? action.data.masterProviderId
          : state.masterProviderId,
        counterSigneId: action.data.counterSigneId
          ? action.data.counterSigneId
          : state.counterSigneId,
      };

    case Constants.CLEAR_STUDY_INFORMATION:
      return {
        ...state,
        studyNameId: { value: null, label: null },
        locationId: { value: null, label: null },
        masterProviderId: { value: null, label: null },
        counterSigneId: { value: null, label: null },
      };
    case Constants.GET_STATES_LIST_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_STATES_LIST_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        statesList: action.data.states,
      };
    case Constants.GET_STATES_LIST_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
const modalCloseData = {
  userProfileNameUpdated: false,
  userProfileEmailUpdated: false,
  userProfileContactNumberUpdated: false,
  userProfileVerifyOTPUpdated: false,
  confirmPasswordDoneSucceed: false,
  conversationStarted: false,
  setEditPatientStarted: false,
  addVisitStarted: false,
  editVisitStarted: false,
  editVisitNoteStarted: false,
  editNoteStarted: false,
  profilePhotoUpdateStarted: false,
  phaseChangeStarted: false,
  exitStudyStarted: false,
  resolveConversationStarted: false,
  changePasswordStarted: false,
  fetchTermsAndConditionsStarted: false,
  deleteAppointmentStarted: false,
  createPatientStarted: false,
  resendInviteStarted: false,
  uploadPaperConsentStarted: false,
  sendMessageStarted: false,
  addCommentOnNoteStarted: false,
};

export const ModalCloseOnPromiseReducer = (state = modalCloseData, action) => {
  switch (action.type) {
    case Constants.SET_USER_PROFILE_NAME_UPDATED:
      return {
        ...state,
        userProfileNameUpdated: true,
      };
    case Constants.RESET_USER_PROFILE_NAME_UPDATED:
      return {
        ...state,
        userProfileNameUpdated: false,
      };
    case Constants.SET_USER_PROFILE_EMAIL_UPDATED:
      return {
        ...state,
        userProfileEmailUpdated: true,
      };
    case Constants.RESET_USER_PROFILE_EMAIL_UPDATED:
      return {
        ...state,
        userProfileEmailUpdated: false,
      };
    case Constants.SET_USER_PROFILE_CONTACT_NUMBER_UPDATED:
      return {
        ...state,
        userProfileContactNumberUpdated: true,
      };
    case Constants.RESET_USER_PROFILE_CONTACT_NUMBER_UPDATED:
      return {
        ...state,
        userProfileContactNumberUpdated: false,
      };
    case Constants.SET_USER_PROFILE_VERIFY_OTP_UPDATED:
      return {
        ...state,
        userProfileVerifyOTPUpdated: true,
      };
    case Constants.RESET_USER_PROFILE_VERIFY_OTP_UPDATED:
      return {
        ...state,
        userProfileVerifyOTPUpdated: false,
      };
    case Constants.SET_CONFIRM_PASSWORD_DONE:
      return {
        ...state,
        confirmPasswordDoneSucceed: true,
      };
    case Constants.RESET_CONFIRM_PASSWORD_DONE:
      return {
        ...state,
        confirmPasswordDoneSucceed: false,
      };
    case Constants.SET_CONVERSATION_START_DONE:
      return {
        ...state,
        conversationStarted: true,
      };
    case Constants.RESET_CONVERSATION_START_DONE:
      return {
        ...state,
        conversationStarted: false,
      };
    case Constants.SET_EDIT_PATIENT_STARTED:
      return {
        ...state,
        setEditPatientStarted: true,
      };
    case Constants.RESET_EDIT_PATIENT_STARTED:
      return {
        ...state,
        setEditPatientStarted: false,
      };
    case Constants.SET_ADD_VISIT_STARTED:
      return {
        ...state,
        addVisitStarted: true,
      };
    case Constants.RESET_ADD_VISIT_STARTED:
      return {
        ...state,
        addVisitStarted: false,
      };
    case Constants.SET_EDIT_VISIT_STARTED:
      return {
        ...state,
        editVisitStarted: true,
      };
    case Constants.RESET_EDIT_VISIT_STARTED:
      return {
        ...state,
        editVisitStarted: false,
      };
    case Constants.SET_EDIT_VISIT_NOTE_STARTED:
      return {
        ...state,
        editVisitNoteStarted: true,
      };
    case Constants.RESET_EDIT_VISIT_NOTE_STARTED:
      return {
        ...state,
        editVisitNoteStarted: false,
      };
    case Constants.SET_EDIT_NOTE_STARTED:
      return {
        ...state,
        editNoteStarted: true,
      };
    case Constants.RESET_EDIT_NOTE_STARTED:
      return {
        ...state,
        editNoteStarted: false,
      };
    case Constants.SET_PROFILE_PHOTO_UPLOAD_STARTED:
      return {
        ...state,
        profilePhotoUpdateStarted: true,
      };
    case Constants.RESET_PROFILE_PHOTO_UPLOAD_STARTED:
      return {
        ...state,
        profilePhotoUpdateStarted: false,
      };
    case Constants.SET_PHASE_CHANGE_STARTED:
      return {
        ...state,
        phaseChangeStarted: true,
      };
    case Constants.RESET_PHASE_CHANGE_STARTED:
      return {
        ...state,
        phaseChangeStarted: false,
      };
    case Constants.SET_EXIT_STUDY_STARTED:
      return {
        ...state,
        exitStudyStarted: true,
      };
    case Constants.RESET_EXIT_STUDY_STARTED:
      return {
        ...state,
        exitStudyStarted: false,
      };
    case Constants.SET_RESOLVE_CONVERSATION_STARTED:
      return {
        ...state,
        resolveConversationStarted: true,
      };
    case Constants.SET_CHANGE_PASSWORD_STARTED:
      return {
        ...state,
        changePasswordStarted: true,
      };
    case Constants.RESET_CHANGE_PASSWORD_STARTED:
      return {
        ...state,
        changePasswordStarted: false,
      };

    case Constants.RESET_RESOLVE_CONVERSATION_STARTED:
      return {
        ...state,
        resolveConversationStarted: false,
      };

    case Constants.SET_CREATE_PATIENT_STARTED:
      return {
        ...state,
        createPatientStarted: true,
      };

    case Constants.RESET_CREATE_PATIENT_STARTED:
      return {
        ...state,
        createPatientStarted: false,
      };

    case Constants.SET_RESEND_INVITE_STARTED:
      return {
        ...state,
        resendInviteStarted: true,
      };

    case Constants.RESET_FETCH_TERMS_AND_CONDITIONS_STARTED:
      return {
        ...state,
        fetchTermsAndConditionsStarted: false,
      };
    case Constants.SET_FETCH_TERMS_AND_CONDITIONS_STARTED:
      return {
        ...state,
        fetchTermsAndConditionsStarted: true,
      };

    case Constants.SET_DELETE_APPOINTMENT_STARTED:
      return {
        ...state,
        deleteAppointmentStarted: true,
      };
    case Constants.RESET_DELETE_APPOINTMENT_STARTED:
      return {
        ...state,
        deleteAppointmentStarted: false,
      };
    case Constants.RESET_RESEND_INVITE_STARTED:
      return {
        ...state,
        resendInviteStarted: false,
      };

    case Constants.SET_UPLOAD_PAPER_CONSENT_STARTED:
      return {
        ...state,
        uploadPaperConsentStarted: true,
      };

    case Constants.RESET_UPLOAD_PAPER_CONSENT_STARTED:
      return {
        ...state,
        uploadPaperConsentStarted: false,
      };

    case Constants.SET_SEND_MESSAGE_STARTED:
      return {
        ...state,
        sendMessageStarted: true,
      };
    case Constants.RESET_SEND_MESSAGE_STARTED:
      return {
        ...state,
        sendMessageStarted: false,
      };
    case Constants.SET_ADD_COMMENT_ON_NOTE_STARTED:
      return {
        ...state,
        addCommentOnNoteStarted: true,
      };
    case Constants.RESET_ADD_COMMENT_ON_NOTE_STARTED:
      return {
        ...state,
        addCommentOnNoteStarted: false,
      };
    default:
      return state;
  }
};

const loadingReducerData = {
  apiCallsCount: 0,
};

export const LoadingReducer = (state = loadingReducerData, action) => {
  switch (action.type) {
    case Constants.INCREASE_API_CALLS_IN_PROGRESS_COUNTER:
      return {
        ...state,
        apiCallsCount: state.apiCallsCount + 1,
      };
    case Constants.DECREASE_API_CALLS_IN_PROGRESS_COUNTER:
      return {
        ...state,
        apiCallsCount: state.apiCallsCount - 1,
      };
    default:
      return state;
  }
};

const requestsAndCallBackData = {
  requests: [],
};

export const requestsAndCallBackReducer = (
  state = requestsAndCallBackData,
  action
) => {
  switch (action.type) {
    case Constants.ADD_REQUEST_AND_CALLBACK_IN_STORE:
      return {
        ...state,
        requests: [...state.requests, action.data.request],
      };

    case Constants.REMOVE_REQUEST_AND_CALLBACK_IN_STORE:
      return {
        ...state,
        requests: state.requests.filter(req => req.id !== action.data.id),
      };
    default:
      return state;
  }
};

const initialCohortsData = {
  cohorts: [{ label: 'dummy', value: 3 }],
};

export const cohortsReducer = (state = initialCohortsData, action) => {
  switch (action.type) {
    case Constants.FETCH_COHORTS_REQUEST_SUCCEED:
      return {
        ...state,
        cohorts: action.data.cohorts,
      };
    default:
      return state;
  }
};

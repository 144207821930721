import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormDataPreview from 'containers/AdminPage/FormDataPreview';
import { snakeCase } from 'change-case-object';
import { dispatchActionWithCallBack } from 'common/actions';
import {
  createConsentRequest,
  resetAddConsentData,
  updateConsentRequest,
  fetchConsentsListOnAdminPageRequest,
} from '../action';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';

const AddConsentFormPreview = props => {
  const dispatch = useDispatch();

  const data = useSelector(store => store.consentAdminReducer);

  const templateName = useSelector(
    store => store.documentsReducer.consentDocument
  );

  const makeParamsReady = data => {
    const params = {};
    Object.keys(data).map(key => {
      if (key === 'locationCode' || key === 'procedure')
        params[snakeCase(key)] = data[key].value;
      else if (key === 'title' || key === 'body' || key === 'templateId')
        params[snakeCase(key)] = data[key];
      else if (key === 'template') {
        if (templateName.startsWith('consent_templates')) {
          params[snakeCase(key)] =
            templateName.split('/').length > 1
              ? templateName.split('/')[1]
              : null;
        }
      }
    });
    params.needs_reason = true;
    if (data.selectedConsentId)
      params.secure_action_code = SecureCodes.EDIT_CONSENT;
    else
      params.secure_action_code = SecureCodes.ADD_CONSENT;
    return params;
  };

  const onSubmit = () => {
    const consentData = makeParamsReady(data);

    const action = data.selectedConsentId
      ? updateConsentRequest
      : createConsentRequest;
    dispatch(
      dispatchActionWithCallBack(
        secureActionWithDoubleAuthentication,
        {
          action,
          params: { id: data.selectedConsentId, ...consentData },
        },
        () => {
          props.onClose();
          dispatch(
            fetchConsentsListOnAdminPageRequest({
              name: data.filterName,
              page: data.page,
            })
          );
        }
      )
    );
  };

  const dataToShow = [
    {
      title: 'Consent Information',
      editStep: 1,
      columnsData: [
        {
          label: 'TITLE',
          value: data.title,
          visible: data.title,
        },
        {
          label: 'BODY',
          value: data.body,
          visible: data.body,
        },
      ],
    },
    {
      title: 'Consent Site',
      editStep: 2,
      columnsData: [
        {
          label: 'LOCATION',
          value: data.locationCode.label,
          visible: data.locationCode.value,
        },
        {
          label: 'PROCEDURE',
          value: data.procedure.label,
          visible: data.procedure.value,
        },
        {
          label: 'TEMPLATE ID',
          value: data.templateId,
          visible: data.templateId,
        },
        {
          label: 'TEMPLATE',
          value: data.template.length
            ? data.template[0].originalFileName
            : '--',
          visible: data.template.length,
        },
      ],
    },
  ];
  return (
    <FormDataPreview dataToShow={dataToShow} {...props} onSubmit={onSubmit} />
  );
};

export default AddConsentFormPreview;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { saveAddTaskFormData, saveAddTaskReminderFormData } from '../action';

const AddTaskReminderStepThreeForm = props => {
  const dispatch = useDispatch();

  const data = useSelector(store => store.taskReminderAdminReducer);
  const adminData = useSelector(store => store.AdminReducer);

  const [errors, setErrors] = useState({});
  const [taskReminderData, settaskReminderData] = useState({
    offsetInDays: '',
    timings: '',
    relatedTaskIdentifier: null,
    uniqueIdentifier: '',
  });

  useEffect(() => {
    const updatedData = {};
    Object.keys(taskReminderData).forEach(
      key => (updatedData[key] = data[key])
    );
    settaskReminderData({ ...taskReminderData, ...updatedData });
  }, [data]);

  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'offsetInDays',
      label: 'Offset In Days',
      type: 'number',
      placeholder: 'Offset In Days',
      value: taskReminderData.offsetInDays,
      visible: true,
    },
    {
      id: 2,
      input_type: 'input',
      name: 'timings',
      label: 'Timings',
      type: 'time',
      placeholder: 'Timings',
      value: taskReminderData.timings,
      visible: true,
    },
    {
      id: 3,
      input_type: 'single_select',
      name: 'relatedTaskIdentifier',
      label: 'Related Task Identifier',
      type: 'text',
      placeholder: 'Related Task Identifier',
      options: adminData.taskIdentifiers,
      value: taskReminderData.relatedTaskIdentifier,
      validation: [
        { rule: 'isRequired', message: 'Related Task Identifier is required' },
      ],
      visible: true,
    },
    {
      id: 4,
      input_type: 'masked_input',
      name: 'uniqueIdentifier',
      label: 'Unique Identifier',
      type: 'text',
      placeholder: 'Unique Identifier',
      mask: 'InsightPro_SENSE_SCSStudy_',
      value: taskReminderData.uniqueIdentifier,
      validation: [
        { rule: 'isRequired', message: 'Unique Indentfier is required' },
      ],
      visible: true,
    },
  ];

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      taskReminderData,
      errors => {
        setErrors(errors);
      },
      () => {
        dispatch(saveAddTaskReminderFormData(taskReminderData));
        props.onStepChange(props.step + 1);
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        settaskReminderData({ ...taskReminderData, [key]: value });
      }}
      buttons={[
        <SecondaryButton
          style={{ width: '176px' }}
          onClick={() => props.onStepChange(props.step - 1)}
        >
          <span>Back</span>
        </SecondaryButton>,
        <PrimaryButton style={{ width: '176px' }} onClick={() => onSubmit()}>
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

export default AddTaskReminderStepThreeForm;

/*
 *
 * Tasks constants
 *
 */

export const GET_TASKS_REQUEST_SENT = 'app/Tasks/GET_TASKS_REQUEST_SENT';
export const GET_TASKS_REQUEST_SUCCEED = 'app/Tasks/GET_TASKS_REQUEST_SUCCEED';
export const GET_TASKS_REQUEST_FAILED = 'app/Tasks/GET_TASKS_REQUEST_FAILED';

export const POST_TASKS_REMINDER_REQUEST_SENT =
  'app/Tasks/POST_TASKS_REMINDER_REQUEST_SENT';
export const POST_TASKS_REMINDER_REQUEST_SUCCEED =
  'app/Tasks/POST_TASKS_REMINDER_REQUEST_SUCCEED';
export const POST_TASKS_REMINDER_REQUEST_FAILED =
  'app/Tasks/POST_TASKS_REMINDER_REQUEST_FAILED';

export const POST_CREATE_TASK_REQUEST_SENT =
  'app/Tasks/POST_CREATE_TASK_REQUEST_SENT';
export const POST_CREATE_TASK_REQUEST_SUCCEED =
  'app/Tasks/POST_CREATE_TASK_REQUEST_SUCCEED';
export const POST_CREATE_TASK_REQUEST_FAILED =
  'app/Tasks/POST_CREATE_TASK_REQUEST_FAILED';

export const ASSIGN_OPTIONAL_TASK_REQUEST_SENT =
  'app/Tasks/ASSIGN_OPTIONAL_TASK_REQUEST_SENT';
export const ASSIGN_OPTIONAL_TASK_REQUEST_SUCCEED =
  'app/Tasks/ASSIGN_OPTIONAL_TASK_REQUEST_SUCCEED';
export const ASSIGN_OPTIONAL_TASK_REQUEST_FAILED =
  'app/Tasks/ASSIGN_OPTIONAL_TASK_REQUEST_FAILED';

export const POST_DELETE_TASK_REQUEST_SENT =
  'app/Tasks/POST_DELETE_TASK_REQUEST_SENT';
export const POST_DELETE_TASK_REQUEST_SUCCEED =
  'app/Tasks/POST_DELETE_TASK_REQUEST_SUCCEED';
export const POST_DELETE_TASK_REQUEST_FAILED =
  'app/Tasks/POST_DELETE_TASK_REQUEST_FAILED';

export const POST_DELETE_ALL_RECURRING_TASKS_REQUEST_SENT =
  'app/Tasks/POST_DELETE_ALL_RECURRING_TASKS_REQUEST_SENT';
export const POST_DELETE_ALL_RECURRING_TASKS_REQUEST_SUCCEED =
  'app/Tasks/POST_DELETE_ALL_RECURRING_TASKS_REQUEST_SUCCEED';
export const POST_DELETE_ALL_RECURRING_TASKS_REQUEST_FAILED =
  'app/Tasks/POST_DELETE_ALL_RECURRING_TASKS_REQUEST_FAILED';

export const GET_TASKS_DETAILS_REQUEST_SENT =
  'app/Tasks/GET_TASKS_DETAILS_REQUEST_SENT';
export const GET_TASKS_DETAILS_REQUEST_SUCCEED =
  'app/Tasks/GET_TASKS_DETAILS_REQUEST_SUCCEED';
export const GET_TASKS_DETAILS_REQUEST_FAILED =
  'app/Tasks/GET_TASKS_DETAILS_REQUEST_FAILED';
export const TASK_DETAILS_CLEAR_STATE = 'app/Tasks/TASKS_DETAILS_CLEAR_STATE';

export const GET_TASKS_REMINDER_HISTORY_REQUEST_SENT =
  'app/Tasks/GET_TASKS_REMINDER_HISTORY_REQUEST_SENT';
export const GET_TASKS_REMINDER_HISTORY_REQUEST_SUCCEED =
  'app/Tasks/GET_TASKS_REMINDER_HISTORY_REQUEST_SUCCEED';
export const GET_TASKS_REMINDER_HISTORY_REQUEST_FAILED =
  'app/Tasks/GET_TASKS_REMINDER_HISTORY_REQUEST_FAILED';

export const POST_MARK_TASK_COMPLETE_REQUEST_SENT =
  'app/Tasks/POST_MARK_TASK_COMPLETE_REQUEST_SENT';
export const POST_MARK_TASK_COMPLETE_REQUEST_SUCCEED =
  'app/Tasks/POST_MARK_TASK_COMPLETE_REQUEST_SUCCEED';
export const POST_MARK_TASK_COMPLETE_REQUEST_FAILED =
  'app/Tasks/POST_MARK_TASK_COMPLETE_REQUEST_FAILED';

export const RESET_ALL_TASK = 'app/patientApp/Tasks/RESET_ALL_TASK';

export const GET_OPTIONAL_TASKS_REQUEST_SENT =
  'app/Tasks/GET_OPTIONAL_TASKS_REQUEST_SENT';
export const GET_OPTIONAL_TASKS_REQUEST_SUCCEED =
  'app/Tasks/GET_OPTIONAL_TASKS_REQUEST_SUCCEED';
export const GET_OPTIONAL_TASKS_REQUEST_FAILED =
  'app/Tasks/GET_OPTIONAL_TASKS_REQUEST_FAILED';

export const TIME_IN_12_HOUR_FORMAT = [
  { value: '12:00 AM', label: '12:00 AM' },
  { value: '1:00 AM', label: '1:00 AM' },
  { value: '2:00 AM', label: '2:00 AM' },
  { value: '3:00 AM', label: '3:00 AM' },
  { value: '4:00 AM', label: '4:00 AM' },
  { value: '5:00 AM', label: '5:00 AM' },
  { value: '6:00 AM', label: '6:00 AM' },
  { value: '7:00 AM', label: '7:00 AM' },
  { value: '8:00 AM', label: '8:00 AM' },
  { value: '9:00 AM', label: '9:00 AM' },
  { value: '10:00 AM', label: '10:00 AM' },
  { value: '11:00 AM', label: '11:00 AM' },
  { value: '12:00 PM', label: '12:00 PM' },
  { value: '1:00 PM', label: '1:00 PM' },
  { value: '2:00 PM', label: '2:00 PM' },
  { value: '3:00 PM', label: '3:00 PM' },
  { value: '4:00 PM', label: '4:00 PM' },
  { value: '5:00 PM', label: '5:00 PM' },
  { value: '6:00 PM', label: '6:00 PM' },
  { value: '7:00 PM', label: '7:00 PM' },
  { value: '8:00 PM', label: '8:00 PM' },
  { value: '9:00 PM', label: '9:00 PM' },
  { value: '10:00 PM', label: '10:00 PM' },
  { value: '11:00 PM', label: '11:00 PM' },
];

export const REPEAT_TYPE = [
  { value: 'Never', label: 'Never' },
  { value: 'Daily', label: 'Daily' },
  { value: 'Weekly', label: 'Weekly' },
];

import * as Constants from './constants';

const initialState = {
  action: null,
  data: {},
  isRequestMultiAuthenticated: false,
  apiActions: [],
  currentPassword: '',
  otpSent: false,
  mfaFlowId: '',
  reasonOptions: [],
};

// eslint-disable-next-line import/prefer-default-export
export const MultiAuthenticatedRequestReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case Constants.FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_SUCCEEDED:
      return {
        ...state,
        reasonOptions: action.data.reasons,
      };
    case Constants.SEND_OTP_FOR_DOUBLE_AUTHENTICATION_SUCCEEDED:
      return {
        ...state,
        otpSent: action.data.otpSent,
        mfaFlowId: action.data.mfaFlowId,
      };
    case Constants.RESET_OTP_SENT_FLAG_FOR_DOUBLE_AUTHENTICATION:
      return {
        ...state,
        otpSent: false,
      };
    case Constants.SECURE_ACTION_FOR_DOUBLE_AUTHENTICATION:
      return {
        ...state,
        isRequestMultiAuthenticated: true,
        action: action.action,
        data: action.data,
      };

    case Constants.RESET_DATA_FOR_DOUBLE_AUTHENTICATION:
      return initialState;
    case Constants.ADD_ACTION_TO_CENTRAL_STATE:
      return {
        ...state,
        apiActions: [
          ...state.apiActions,
          { action: action.data.action, data: action.data.data },
        ],
      };
    case Constants.SAVE_CURRENT_PASSWORD:
      return {
        ...state,
        currentPassword: action.data,
      };
    default:
      return state;
  }
};

import React from 'react';
import { components } from 'react-select';
import {
  PrimaryButton,
  SecondaryButton,
} from '../StyleGuideComponents/common/buttons';

const Menu = ({ children, ...props }) => (
  <components.Menu {...props}>
    {children}
    <footer style={{ display: 'flex', columnGap: '24px', padding: '16px' }}>
      <span style={{ width: '50%' }}>
        <PrimaryButton
          style={{ width: '100%' }}
          onClick={() => handleApplyButton(props.selectProps)}
          onTouchEnd={() => handleApplyButton(props.selectProps)}
          onMouseDown={() => handleApplyButton(props.selectProps)}
        >
          Apply
        </PrimaryButton>
      </span>
      <span style={{ width: '50%' }}>
        <SecondaryButton
          style={{ width: '100%' }}
          onClick={() => handleClearButton(props)}
          onTouchEnd={() => handleClearButton(props)}
          onMouseDown={() => handleClearButton(props)}
        >
          Clear
        </SecondaryButton>
      </span>
    </footer>
  </components.Menu>
);

const handleApplyButton = selectedProps => {
  selectedProps.onApply(selectedProps.value, selectedProps.facet);
};

const handleClearButton = props => {
  props.clearValue();
  if (props.selectProps.onClearAndApply) {
    props.selectProps.onClearAndApply();
  }
};

export default Menu;

import {
  shareOutcomesReport,
  getSharedOutcomesReportsHistory,
} from 'services/outcomes';
import { showToaster } from 'common/toasterActions';
import {
  SHARE_OUTCOMES_REPORT_REQUEST_SENT,
  SHARE_OUTCOMES_REPORT_REQUEST_SUCCEED,
  SHARE_OUTCOMES_REPORT_REQUEST_FAILED,
  GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SENT,
  GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SUCCEED,
  GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_FAILED,
} from './constants';

export const shareOutcomesReportRequestSent = () => ({
  type: SHARE_OUTCOMES_REPORT_REQUEST_SENT,
});

export const shareOutcomesReportRequestSucceeded = () => ({
  type: SHARE_OUTCOMES_REPORT_REQUEST_SUCCEED,
});

export const shareOutcomesReportRequestFailed = () => ({
  type: SHARE_OUTCOMES_REPORT_REQUEST_FAILED,
});

export const getSharedOutcomesReportsHistoryRequestSent = () => ({
  type: GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SENT,
});

export const getSharedOutcomesReportsHistorySucceeded = data => ({
  type: GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_SUCCEED,
  data,
});

export const getSharedOutcomesReportsHistoryRequestFailed = () => ({
  type: GET_SHARED_OUTCOMES_REPORTS_HISTORY_REQUEST_FAILED,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const onShareOutcomesReport = params => dispatch => {
  dispatch(shareOutcomesReportRequestSent());
  shareOutcomesReport(params)
    .then(() => {
      dispatch(shareOutcomesReportRequestSucceeded());
      dispatch(
        showToaster({ message: 'Outcomes report shared successfully.' })
      );
    })
    .catch(() => {
      dispatch(shareOutcomesReportRequestFailed());
    });
};

export const onGetSharedOutcomesReportsHistory = params => dispatch => {
  dispatch(getSharedOutcomesReportsHistoryRequestSent());
  getSharedOutcomesReportsHistory(params)
    .then(response => {
      dispatch(getSharedOutcomesReportsHistorySucceeded(response.data));
    })
    .catch(() => {
      dispatch(getSharedOutcomesReportsHistoryRequestFailed());
    });
};

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Attachments from 'containers/Attachments';
import { BackButton } from 'components/UtilityComponents';
import NoteCreatedByCard from '../NoteCreatedByCard';
import './noteInfo.scss';

const NoteInfo = props => {
  const history = useHistory();

  const { currentUserId } = props;

  const noteDetails = useSelector(
    store => store.patientNoteDetailsReducer.noteDetails
  );

  return (
    <div className='communication-log-info'>
      <div className='communication-log-title'>{noteDetails.title}</div>
      <div className='horizontal-line' />
      <div className='communication-log-description'>
        {noteDetails.description}
      </div>
      <NoteCreatedByCard
        createdById={noteDetails.createdById}
        currentUserId={currentUserId}
        createdByName={noteDetails.createdByName}
        isShared={noteDetails.isShared === 1}
        createdAt={noteDetails.createdAt}
      />
      <Attachments
        mediaResources={noteDetails.mediaResources}
        isAdded={false}
      />
    </div>
  );
};

export default NoteInfo;

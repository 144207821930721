export const SECURE_ACTION_FOR_DOUBLE_AUTHENTICATION =
  'app/MultiAuthenticatedRequest/SECURE_ACTION_FOR_DOUBLE_AUTHENTICATION';
export const RESET_DATA_FOR_DOUBLE_AUTHENTICATION =
  'app/MultiAuthenticatedRequest/RESET_DATA_FOR_DOUBLE_AUTHENTICATION';
export const FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_STARTED =
  'app/MultiAuthenticatedRequest/FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_STARTED';
export const FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_FAILED =
  'app/MultiAuthenticatedRequest/FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_FAILED';
export const FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_SUCCEEDED =
  'app/MultiAuthenticatedRequest/FETCH_OPTIONS_FOR_DOUBLE_AUTHENTICATED_REASONS_SUCCEEDED';

export const ADD_ACTION_TO_CENTRAL_STATE =
  'app/MultiAuthenticatedRequest/ADD_ACTION_TO_CENTRAL_STATE';
export const REMOVE_ACTION_FROM_CENTRAL_STATE =
  'app/MultiAuthenticatedRequest/REMOVE_ACTION_FROM_CENTRAL_STATE';
export const SAVE_CURRENT_PASSWORD =
  'app/MultiAuthenticatedRequest/SAVE_CURRENT_PASSWORD';
export const REMOVE_CURRENT_PASSWORD =
  'app/MultiAuthenticatedRequest/REMOVE_CURRENT_PASSWORD';

export const SEND_OTP_FOR_DOUBLE_AUTHENTICATION_SUCCEEDED =
  'app/MultiAuthenticatedRequest/SEND_OTP_FOR_DOUBLE_AUTHENTICATION_SUCCEEDED';

export const RESET_OTP_SENT_FLAG_FOR_DOUBLE_AUTHENTICATION =
  'app/MultiAuthenticatedRequest/RESET_OTP_SENT_FLAG_FOR_DOUBLE_AUTHENTICATION';

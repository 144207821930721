import React from 'react';
import './PatientInfoCard.scss';

export default props => {
  return (
    <div
      className={`patient-info-card ${
        props.cardType && props.cardType === 'subject-card' ? 'left-bar' : ''
      } ${props.className ? props.className : ''}`}
    >
      {props.children}
    </div>
  );
};

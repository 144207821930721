import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { snakeCase } from 'change-case-object';
import { dispatchActionWithCallBack } from 'common/actions';
import FormDataPreview from 'containers/AdminPage/FormDataPreview';
import { timeInMeridianFormat } from 'utils/dateUtils';
import PropTypes from 'prop-types';
import { createNotificationRequest } from '../action';

const AddNotificationFormPreview = props => {
  const dispatch = useDispatch();

  const notificationAdminData = useSelector(
    store => store.notificationAdminReducer
  );

  const makeParamsReady = data => {
    const params = {};
    Object.keys(data).map(key => {
      if (
        key === 'type' ||
        key === 'procedure' ||
        key === 'department' ||
        key === 'eventKind'
      )
        params[snakeCase(key)] = notificationAdminData[key].value;
      else if (
        key === 'title' ||
        key === 'body' ||
        key === 'offsetInDays' ||
        key === 'timings' ||
        key === 'dueOffsetHours' ||
        key === 'uniqueIdentifier'
      ) {
        if (key === 'timings') {
          if (!notificationAdminData[key]) params[snakeCase(key)] = 0;
          else
            params[snakeCase(key)] = timeInMeridianFormat(
              notificationAdminData[key]
            );
        } else params[snakeCase(key)] = notificationAdminData[key];
      }
      return params;
    });
    return params;
  };

  const onSubmit = () => {
    const { onClose } = props;
    const tasksData = makeParamsReady(notificationAdminData);
    const action = createNotificationRequest;
    dispatch(
      dispatchActionWithCallBack(
        action,
        {
          params: { id: notificationAdminData.selectedTaskId, ...tasksData },
        },
        () => {
          onClose();
        }
      )
    );
  };

  const dataToShow = [
    {
      title: 'Task Dedftail',
      editStep: 1,
      columnsData: [
        {
          label: 'TITLE',
          value: notificationAdminData.title,
          visible: notificationAdminData.title,
        },
        {
          label: 'BODY',
          value: notificationAdminData.body,
          visible: notificationAdminData.body,
        },
        {
          label: 'OFFSET IN DAYS',
          value: notificationAdminData.offsetInDays,
          visible: true,
        },
        {
          label: 'TIMINGS',
          value: timeInMeridianFormat(notificationAdminData.timings),
          visible: notificationAdminData.timings,
        },
        {
          label: 'DUE OFFSET HOURS',
          value: notificationAdminData.dueOffsetHours,
          visible: true,
        },
      ],
    },
    {
      title: 'TASK CONTEXT',
      editStep: 2,
      columnsData: [
        {
          label: 'DEPARTMENT',
          value: notificationAdminData.department?.label,
          visible: !!notificationAdminData.department?.value,
        },
        {
          label: 'PROCEDURE',
          value: notificationAdminData.procedure?.label,
          visible: !!notificationAdminData.procedure?.value,
        },
        {
          label: 'EVENT KIND',
          value: notificationAdminData.eventKind?.label,
          visible: notificationAdminData.eventKind?.value !== null,
        },
        {
          label: 'TYPE',
          value: notificationAdminData.type?.label,
          visible: !!notificationAdminData.type?.value,
        },
        {
          label: 'UNIQUE IDENTIFIER',
          value: notificationAdminData.uniqueIdentifier,
          visible: notificationAdminData.uniqueIdentifier,
        },
      ],
    },
  ];

  return (
    <FormDataPreview dataToShow={dataToShow} {...props} onSubmit={onSubmit} />
  );
};

AddNotificationFormPreview.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default AddNotificationFormPreview;

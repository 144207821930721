export const FETCH_TASKS_REMINDER_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED =
  'src/containers/admin_page/FETCH_TASKS_REMINDER_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED';

export const SAVE_ADD_TASK_REMINDER_FORM_DATA =
  'src/containers/admin_page/SAVE_ADD_TASK_REMINDER_FORM_DATA';

export const UPDATE_CREATE_TASK_REMINDER_ADMIN_VALUE_IN_STORE =
  'src/containers/admin_page/UPDATE_CREATE_TASK_REMINDER_ADMIN_VALUE_IN_STORE';

export const SET_SELECTED_TASK_REMINDER_ID =
  'src/containers/admin_page/SET_SELECTED_TASK_REMINDER_ID';

export const RESET_ADD_TASK_REMINDER_DATA =
  'src/containers/admin_page/RESET_ADD_TASK_REMINDER_DATA';

import React, { useState } from 'react';
// import ClearIndicator from './Indicators';
import ValueContainer from './ValueContainer';
import MultiSelect from './MultiSelect';
import Option from './Options';
import Menu from './Menu';
import DropdownIndicator from './DropDownIndicator';

// eslint-disable-next-line react/prefer-stateless-function
export default class MultiSelectPill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { className: classes, ...props } = this.props;
    return (
      <MultiSelect
        {...props}
        menuIsOpen={this.state.open}
        className={`multi-select-container ${classes || ''}`}
        classNamePrefix='multi-select-pill'
        backspaceRemovesValue={false}
        onMenuClose={() => {
          this.setState({ open: false });
          props.onMenuClose(props.facetName, props.value);
        }}
        onChange={selected => props.onChange(selected)}
        onApply={() => this.setState({ open: false })}
        onMenuOpen={() => {
          this.setState({ open: true });
          if (props.onMenuOpen) props.onMenuOpen();
        }}
        components={{
          DropdownIndicator,
          Option,
          Menu,
        }}
      />
    );
  }
}

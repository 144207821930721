import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import ConversationDetails from './Components/ConversationDetails';
import { getPatientConversations } from './action';
import ConversationsList from './Components/ConversationsList';
import './style.scss';

const Conversations = props => {
  const { isActive } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();

  const urlQuery = queryString.parse(location.search);
  const procedureId = parseInt(params.procedureId, 10) || -1;

  const conversations = useSelector(store => store.conversations.conversations);
  const selectedPhaseId = useSelector(
    store => store.currentPatientInfo.selectedPhaseId
  );
  const userProcedures = useSelector(
    store => store.currentPatientInfo.patient.userProcedures
  );

  const [conversationId, setConversationId] = useState(urlQuery.conversationId);
  const [page, setPage] = useState(1);

  const compositeProcedure = userProcedures.find(up => up.id === procedureId);

  useEffect(() => {
    if (isActive && compositeProcedure && !conversationId)
      dispatch(
        getPatientConversations(
          compositeProcedure.compositeProcedureId,
          selectedPhaseId,
          page
        )
      );
  }, [page, isActive, compositeProcedure, conversationId]);

  useEffect(() => {
    setConversationId(queryString.parse(location.search).conversation_id);
  }, [location]);

  if (conversationId) {
    return (
      <div>
        <ConversationDetails selectedConversationId={conversationId} />
      </div>
    );
  }
  return (
    <ConversationsList
      conversations={conversations}
      handlePagination={inputPage => setPage(inputPage)}
    />
  );
};

Conversations.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default Conversations;

/**
 *
 * Team Information
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import { getFormatedPhoneNumber } from 'utils/stringUtils';
import './index.scss';

const TeamInformation = props => {
  const { onHide, show } = props;
  const { megaTeam, isLoading } = useSelector(store => store.procedureManagers);

  const informationTable = () => {
    return (
      <div className='team-information-container'>
        <div className='table-records'>
          <span className='title'>NAME</span>
          <span className='title'>ROLE</span>
          <span className='title'>PHONE NUMBER</span>
          <span className='title email-field'>EMAIL ID</span>
        </div>
        {megaTeam.map(item => {
          return (
            <div key={item.id} className='table-records'>
              <span className='column-body'>
                {item.fullName ? item.fullName : 'NA'}
              </span>
              <span className='column-body'>
                {item.displayName ? item.displayName : 'NA'}
              </span>
              <span className='column-body'>
                {item.contactNumber
                  ? getFormatedPhoneNumber(item.contactNumber)
                  : 'NA'}
              </span>
              <span className='column-body email-field'>
                {item.email ? item.email : 'NA'}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className='col-xs-12 manage-procedure-managers-container'>
      <div className='page-content col-xs-8 col-xs-offset-2'>
        {isLoading === false && (
          <FormModal
            show={show}
            onHide={onHide}
            modalStyle={{
              height: 'auto',
              width: 'auto',
            }}
            title={{
              text: 'Team Information',
            }}
            buttons={[
              <PrimaryButton
                style={{ width: '300px', margin: '8px 0' }}
                onClick={onHide}
                key='primaryButton'
              >
                Done
              </PrimaryButton>,
            ]}
            body={informationTable()}
            href='If you need to edit or add any Team Members, please contact your Medtronic Site Manager.'
          />
        )}
      </div>
    </div>
  );
};

TeamInformation.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default TeamInformation;

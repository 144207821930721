import 'whatwg-fetch';
import axios, { defaultAxios } from './base';

import {
  GET_DEPARTMENT_PROCEDURES,
  GET_DEPARTMENT_PROVIDERS,
  SAVE_SEARCH_FILTER,
  UPDATE_SEARCH_FILTER,
  DELETE_SEARCH_FILTER,
  GET_DEPARTMENT_ONLY_PROVIDERS,
  GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS,
  ADD_NEW_PATIENT_PROCEDURE,
  GET_LOCATION,
  GET_TIMEZONE,
  GET_LOCATION_TIMEZONE,
  GET_DEPARTMENT_SUPPORTED_KEYS,
  VALIDATE_EMAIL_UNIQUNESS,
  VALIDATE_PHONE_UNIQUNESS,
  GET_SEARCH_CATEGORY,
  GET_COOKIE_CONSENT_TEXT,
  POST_COOKIE_CONSENT_PROVIDED,
  GET_PROCEDURE_MASTER_PROVIDERS,
  GET_COUNTER_SIGNE,
  GET_STATES_LIST,
  GET_MULTIAUTHENTICATED_REQUEST_REASON_OPTIONS,
  GET_TRAINING_MATERIAL_FILE,
  SENT_OTP_FOR_DOUBLE_AUTHENTICATION,
  FETCH_COHORTS,
} from './constants';

export const getDepartmentProcedures = () =>
  axios.get(GET_DEPARTMENT_PROCEDURES);

export const getDepartmentProviders = () => axios.get(GET_DEPARTMENT_PROVIDERS);

export const getDepartmentOnlyProviders = () =>
  axios.get(GET_DEPARTMENT_ONLY_PROVIDERS);

export const getDepartmentOnlyGeneralCardiologists = () =>
  axios.get(GET_DEPARTMENT_ONLY_GENERAL_CARDIOLOGISTS);

export const saveFilterSelection = params =>
  axios.post(SAVE_SEARCH_FILTER, params);

export const updateFilterSelection = params => {
  const url = UPDATE_SEARCH_FILTER.replace(':id', params.id);
  return axios.put(url, params);
};

export const deleteCustomFilter = id => {
  const url = DELETE_SEARCH_FILTER.replace(':id', id);
  return axios.delete(url);
};

export const addNewPatientProcedure = params =>
  axios.post(ADD_NEW_PATIENT_PROCEDURE, params);

export const getLocation = params =>
  fetch(
    `${GET_LOCATION}?components=country%3AUS|postal_code%3A${params.postal_code}&key=${process.env.REACT_APP_MAP_KEY}`
  );

export const getTimezone = params =>
  fetch(
    `${GET_TIMEZONE}?location=${params.location}&timestamp=${params.timestamp}&key=${process.env.REACT_APP_MAP_KEY}`
  );

export const getLocationTimezone = params =>
  axios.get(GET_LOCATION_TIMEZONE, { params });

export const getDepartmentSupportedKeys = () =>
  axios.get(GET_DEPARTMENT_SUPPORTED_KEYS);

export const isEmailUnique = params =>
  axios.get(VALIDATE_EMAIL_UNIQUNESS, { params });

export const isPhoneUnique = params =>
  axios.get(VALIDATE_PHONE_UNIQUNESS, { params });

export const getSearchCategoryList = () => axios.get(GET_SEARCH_CATEGORY);

export const getCookieConsentTextApiCall = () =>
  defaultAxios.get(GET_COOKIE_CONSENT_TEXT);

export const getProcedureMasterProviders = params => {
  return axios.get(GET_PROCEDURE_MASTER_PROVIDERS, { params });
};

export const getCounterSigne = params => {
  return axios.get(GET_COUNTER_SIGNE, { params });
};

export const getStatesList = () => {
  return axios.get(GET_STATES_LIST);
};

export const postCookieConsentProvided = params =>
  defaultAxios.post(POST_COOKIE_CONSENT_PROVIDED, params);

export const getMultiAuthRequestReasonsOptions = data =>
  axios.get(GET_MULTIAUTHENTICATED_REQUEST_REASON_OPTIONS, {
    params: { action_code: data },
  });

export const getTrainingMaterialDataLink = () =>
  axios.get(GET_TRAINING_MATERIAL_FILE);

export const sendOtpForDoubleAuthentication = params => {
  return axios.post(SENT_OTP_FOR_DOUBLE_AUTHENTICATION, params);
};

export const fetchCohortsList = params => axios.get(FETCH_COHORTS, { params });

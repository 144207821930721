import { createTheme } from '@mui/material';
import { InputBoxStyleGuide } from '../StyleGuideCSS';

export const InputTextFieldTheme = createTheme({
  palette: {
    common: {
      black: `${InputBoxStyleGuide.colorSecondaryDark}`,
    },
    info: {
      light: `${InputBoxStyleGuide.colorAlertNegative}`,
      main: `${InputBoxStyleGuide.colorAlertNegative}`,
    },
    primary: {
      light: `${InputBoxStyleGuide.colorSecondaryDark}`,
      main: `${InputBoxStyleGuide.colorSecondaryDark}`,
    },
    text: {
      primary: `${InputBoxStyleGuide.colorInactiveGrey}`,
      main: `${InputBoxStyleGuide.colorInactiveGrey}`,
      disabled: '#4B3851',
    },
    action: {
      hover: `${InputBoxStyleGuide.colorInactiveGrey}`,
      active: `${InputBoxStyleGuide.colorSecondaryDark}`,
      focus: `${InputBoxStyleGuide.colorSecondaryDark}`,
    },
    error: {
      light: `${InputBoxStyleGuide.colorAlertNegative}`,
      main: `${InputBoxStyleGuide.colorAlertNegative}`,
    },
  },
  shape: {
    borderRadius: `${InputBoxStyleGuide.borderRadius}`,
  },
  typography: {
    label: {
      fontSize: `${InputBoxStyleGuide.fontSize}`,
      fontWeight: '400',
    },
    fontSize: 16,
    fontWeightRegular: `${InputBoxStyleGuide.fontWeight}`,
    fontFamily: `${InputBoxStyleGuide.fontFamily}`,
  },
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
});

export const modalTheme = createTheme({
  typography: {
    h2: { fontSize: 26, fontWeight: 500, color: '#4B3851' },
    body1: { fontSize: 16, fontWeight: 400 },
  },
});

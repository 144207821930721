import React, { useState } from 'react';
import { PrimaryModal } from 'components/StyleGuideComponents/common/modals';
import './auditTrail.scss';
import DataTable from 'components/StyleGuideComponents/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { downloadConsentAuditTrailDataRequest } from './action';

const ConsentAuditTrailDataModal = props => {
  const consents = useSelector(
    store => store.consentsAuditTrailReducer.consents
  );
  const headers = useSelector(store => store.consentsAuditTrailReducer.headers);
  const dispatch = useDispatch();

  const getDataToShow = () => {
    const dataToShow = consents.map(consent => {
      const obj = { ...consent };
      obj.downloadLink = {
        onClick: () => {
          dispatch(
            downloadConsentAuditTrailDataRequest({
              patient_id: props.patientId,
              patient_consent_id: obj.id,
            })
          );
        },
        value: 'Download',
      };
      return obj;
    });
    return dataToShow;
  };

  return (
    <PrimaryModal
      show={props.showModal}
      onHide={props.onModalClose}
      bodyHeader={{
        text: 'Consent Audit Trail Data',
      }}
      bodyContent={{
        text: (
          <div className='consent-audit-trail-data'>
            <DataTable tableHeaders={headers} tableData={getDataToShow()} />
          </div>
        ),
      }}
    />
  );
};

export default ConsentAuditTrailDataModal;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Emptyview from 'components/Emptyview';
import NoQuestions from 'assets/images/noquestions.png';
import MessagesListCard from '../MessagesListCard';
import { getConversationMessages } from './actions';

const MessagesList = props => {
  const dispatch = useDispatch();

  const messages = useSelector(store => store.messages.messages);

  useEffect(() => {
    dispatch(getConversationMessages(props.conversationId));
  }, []);

  return (
    <div>
      <div style={{ marginTop: '50px' }}>
        {messages.length > 0 ? (
          messages.map(item => (
            <MessagesListCard
              key={item.id}
              item={item}
              sent_by={item.sent_by}
            />
          ))
        ) : (
          <Emptyview imgSrc={NoQuestions} className='' message='No Messages' />
        )}
      </div>
    </div>
  );
};

export default MessagesList;

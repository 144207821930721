import React from 'react';
import Strings from 'strings';
import insightProStudyClinic from 'assets/images/insightProStudyClinic.png';
import accountCircle from 'assets/images/accountCircle.png';
import './landingPageWrapper.scss';
import PreLoginFooter from 'components/PreLoginFooter';
import Media from 'react-media';

const LandingPage = props => {
  const heroImageArea = <div className='image-area' />;

  const portalContainer = (
    <div className='portal-container'>
      <div className='patient-learn-more-container'>
        <img src={insightProStudyClinic} alt='icon insightProStudyClinic' />
        <div>{Strings.LOGIN_SCREEN_MESSAGE1}</div>
      </div>
      <div className='patient-learn-more-container'>
        <img src={accountCircle} alt='icon accountCircle' />
        <div>{Strings.LOGIN_SCREEN_MESSAGE2}</div>
      </div>
    </div>
  );

  const welcomeInfo = (
    <div className='content-info'>
      <div className='header text-center'>WELCOME</div>
      <div className='sub-header text-center'>
        Sign In to {Strings.APPLICATION_NAME}
      </div>
    </div>
  );

  return (
    <div>
      <Media query={{ maxWidth: '990px' }}>
        {screenIsSmall =>
          screenIsSmall ? (
            <div className='landing-page-wrapper'>
              <div className='content-area'>
                {heroImageArea}
                <div className='actual-content'>
                  {welcomeInfo}
                  {props.children}
                </div>
                {portalContainer}
                <PreLoginFooter />
              </div>
            </div>
          ) : (
            <div className='landing-page-wrapper'>
              <div className='hero-image-container'>
                {heroImageArea}
                {portalContainer}
              </div>

              <div className='actual-content-wrapper'>
                <div className='actual-content'>
                  {welcomeInfo}
                  {props.children}
                </div>
                <div className='pre-login-footer'>
                  <PreLoginFooter />
                </div>
              </div>
            </div>
          )
        }
      </Media>
    </div>
  );
};

export default LandingPage;

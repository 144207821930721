import React from 'react';

const SurveyQuestions = props => {
  const groupQuestions = questions => {
    const newQuestions = [];
    let lastHeader = '';
    questions.forEach(questionInParam => {
      const question = questionInParam;
      const sectionHeader = question.section ? question.section : '';
      question.shouldShowHeader = false;
      if (lastHeader !== sectionHeader && sectionHeader !== '') {
        lastHeader = sectionHeader;
        question.shouldShowHeader = true;
      }
      newQuestions.push(question);
    });
    return newQuestions;
  };

  const getAnswerText = question => {
    let answerText = question.answer;
    if (question.comment) {
      answerText += ` - ${question.comment}`;
    }
    return answerText;
  };

  const questions = groupQuestions(props.survey?.surveyQuestions);

  return questions.map(question => (
    <>
      {question.shouldShowHeader && question.section !== '' && (
        <div className='question-header section-to-print'>
          {question.section}
        </div>
      )}
      <div className='survey-row section-to-print' key={question.id}>
        <div className='survey-question section-to-print'>
          {question.question}
        </div>
        <div className='survey-answer section-to-print'>
          {question.answer === undefined
            ? 'Answer : Not Answered'
            : `Answer : ${getAnswerText(question)}`}
        </div>
      </div>
    </>
  ));
};

export default SurveyQuestions;

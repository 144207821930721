import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import { saveAddTaskFormData } from '../action';

const AddTaskStepOneForm = props => {
  const dispatch = useDispatch();

  const data = useSelector(store => store.taskAdminReducer);

  const [errors, setErrors] = useState({});
  const [taskData, setTaskData] = useState({
    title: '',
    description: '',
    linkName: '',
    linkEntityType: '',
    linkEntityIdentifier: '',
    relatedEventTemplateIdentifier: '',
    uniqueIdentifier: '',
  });

  useEffect(() => {
    const updatedData = {};
    Object.keys(taskData).forEach(key => (updatedData[key] = data[key]));
    setTaskData({ ...taskData, ...updatedData });
  }, [data]);

  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'title',
      label: 'Title',
      type: 'text',
      placeholder: 'Title',
      validation: [{ rule: 'isRequired', message: 'Title is required' }],
      value: taskData.title,
      visible: true,
    },
    {
      id: 2,
      input_type: 'textarea',
      name: 'description',
      label: 'Description',
      type: 'text',
      placeholder: 'Description',
      value: taskData.description,
      validation: [{ rule: 'isRequired', message: 'Description is required' }],
      visible: true,
    },

    {
      id: 4,
      input_type: 'input',
      name: 'linkName',
      label: 'Link Name(Text To Show On Button)',
      type: 'text',
      placeholder: 'Link Name',
      value: taskData.linkName,
      validation: [{ rule: 'isRequired', message: 'Link Name is required' }],
      visible: true,
    },
    {
      id: 5,
      input_type: 'input',
      name: 'linkEntityType',
      label: 'Link Entity Type',
      type: 'text',
      placeholder: 'Link Entity Type',
      value: taskData.linkEntityType,
      validation: [
        { rule: 'isRequired', message: 'Link Entity Type is required' },
      ],
      visible: true,
    },
    {
      id: 6,
      input_type: 'input',
      name: 'linkEntityIdentifier',
      label: 'Link Entity Identifier',
      type: 'text',
      placeholder: 'Link Entity Identifier',
      value: taskData.linkEntityIdentifier,
      validation: [
        { rule: 'isRequired', message: 'Link Entity Indentfier is required' },
      ],
      visible: true,
    },
    {
      id: 7,
      input_type: 'masked_input',
      name: 'relatedEventTemplateIdentifier',
      label: 'Related Event Template Identifier',
      type: 'text',
      placeholder: 'Related Event Template Identifier',
      mask: 'InsightPro_SENSE_SCSStudy_',
      value: taskData.relatedEventTemplateIdentifier,
      validation: [
        {
          rule: 'isRequired',
          message: 'Related Event Template Identifier is required',
        },
      ],
      visible: true,
    },
    {
      id: 8,
      input_type: 'masked_input',
      name: 'uniqueIdentifier',
      label: 'Unique Identifier',
      type: 'text',
      placeholder: 'Unique Identifier',
      mask: 'InsightPro_SENSE_SCSStudy_',
      value: taskData.uniqueIdentifier,
      validation: [
        { rule: 'isRequired', message: 'Unique Indentfier is required' },
      ],
      visible: true,
    },
  ];

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      taskData,
      errors => {
        setErrors(errors);
      },
      () => {
        dispatch(saveAddTaskFormData(taskData));
        props.onStepChange(props.step + 1);
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        setTaskData({ ...taskData, [key]: value });
      }}
      buttons={[
        <PrimaryButton style={{ width: '176px' }} onClick={() => onSubmit()}>
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

export default AddTaskStepOneForm;

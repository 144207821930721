/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectReducer from 'utils/injectReducer';
import {
  dateWithTimeStringToMMSDateFormat,
  dateStringToCustomDate,
} from 'utils/dateUtils';
import DownloadFile from 'components/DownloadFile';
import { downloadFile } from 'services/patient/downloadData';
import PassiveModeFooter from 'components/PassiveModeFooter';
import './styles.scss';
import validate from 'common/validator';
import { withRouter } from 'react-router-dom';
import InputTextField from 'components/StyleGuideComponents/InputTextField';
import {
  PrimaryButton,
  PrimaryButtonLarge,
} from 'components/StyleGuideComponents/common/buttons';
import viewDataDownloadRequestReducer from './reducers';
import { resendPinRequest, verifyPinRequest } from './actions';
import { STATUSES } from './constants';

const validationConfig = {
  fields: ['pin'],
  rules: {
    pin: [{ rule: 'isRequired', message: 'PIN is required' }],
  },
};

class ViewDataDownloadRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      key: '',
      pin: '',
      timer: 60,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.setState({
      key: match.params.key,
    });
    this.startTimer();
  }

  handleResend = () => {
    const { key } = this.state;
    const { resendPin } = this.props;
    resendPin(key);
    this.setState({
      timer: 60,
    });
  };

  onContinue = event => {
    event.preventDefault();
    validate(
      validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  onPinChange = value => {
    this.setState({
      pin: value.trim(),
    });
  };

  onFormValidationFailure = errors => {
    this.setState({ errors });
  };

  onFormValidationSuccess = () => {
    const { verifyPin } = this.props;
    const { key, pin } = this.state;
    this.setState({
      errors: {},
    });
    verifyPin(key, pin);
  };

  updateTimer = () => {
    const { timer } = this.state;
    const newTimerValue = timer - 1;
    if (newTimerValue < 0) {
      return;
    }
    this.setState({
      timer: newTimerValue,
    });
  };

  startTimer = () => {
    setInterval(this.updateTimer, 1000);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  stopTimer = () => {
    clearInterval(this.interval);
  };

  // eslint-disable-next-line class-methods-use-this
  renderStatusText = status => {
    let textString = null;
    switch (status) {
      case STATUSES.PREPARED:
        textString = 'Your data is ready';
        break;
      case STATUSES.REQUESTED:
        textString = 'Your data is being prepared for download';
        break;
      case STATUSES.INPROGESS:
        textString = 'Your data is being prepared for download';
        break;
      case STATUSES.FAILED:
        textString = 'Something went wrong';
        break;
      default:
        textString = null;
    }
    return textString ? <div>{textString}</div> : null;
  };

  renderDataDownloadRequest(data) {
    return (
      <div className='view-data-download-request-container'>
        {data !== null ? (
          <>
            <div className='welcome-header'>
              {this.renderStatusText(data.status)}
            </div>
            {data.status === STATUSES.PREPARED ? (
              <div className='row request-data-status'>
                <div className='data-container'>
                  <span className='lbl-name'>{`Requested on: `}</span>
                  <span className='value'>
                    {dateWithTimeStringToMMSDateFormat(data.created_at)}
                  </span>
                </div>
                <div className='data-container'>
                  <span className='lbl-name'>{`Generated on: `}</span>
                  <span className='value'>
                    {dateWithTimeStringToMMSDateFormat(data.created_at)}
                  </span>
                </div>
                <div className='data-container'>
                  <DownloadFile serviceCall={downloadFile}>
                    <PrimaryButtonLarge style={{ maxWidth: 'none' }}>
                      Download Data File
                    </PrimaryButtonLarge>
                  </DownloadFile>
                  <div className='expiring-text'>{`(Expiring on: ${dateStringToCustomDate(
                    data.expired_at
                  )})`}</div>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
        <PassiveModeFooter />
      </div>
    );
  }

  render() {
    const { errors, timer, pin } = this.state;
    const { dataDownloadRequest } = this.props;
    if (dataDownloadRequest) {
      return this.renderDataDownloadRequest(dataDownloadRequest);
    }

    return (
      <div className='view-data-download-request-container'>
        <div className='welcome-header'>{`Please Enter Your PIN code: `}</div>
        <div className='login-form-container no-padding'>
          <div className='login-form-view text-center'>
            <div className='resend-code-message-and-text'>
              <div className=''>
                <br />
                We sent a PIN code to your email / mobile number:
                <br />
              </div>
              {timer > 0 ? (
                <div className='timer'>Resend Code in {timer}s</div>
              ) : (
                <div
                  className='resend-code btn'
                  onClick={this.handleResend}
                  onKeyDown={() => {}}
                  role='button'
                  tabIndex={0}
                >
                  Resend Code
                </div>
              )}
            </div>
            <form className='enter-pin-form' name='form'>
              <div
                className={`form-group pos-rel ${
                  errors.verificationCode !== undefined ? 'has-error' : ''
                }`}
              >
                <InputTextField
                  value={pin}
                  label='PIN'
                  name='pin'
                  onChange={(name, value) => this.onPinChange(value)}
                  error={errors.pin}
                  errorText={errors.pin}
                />
              </div>

              <div className='text-center'>
                <PrimaryButton
                  onClick={this.onContinue}
                  style={{ maxWidth: 'none' }}
                >
                  {' '}
                  Continue{' '}
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>
        <PassiveModeFooter />
      </div>
    );
  }
}

ViewDataDownloadRequest.propTypes = {
  match: PropTypes.object,
  verifyPin: PropTypes.func,
  resendPin: PropTypes.func,
  dataDownloadRequest: PropTypes.object,
};

const mapStateToProps = state => ({
  dataDownloadRequest: state.viewDataDownloadRequest.dataDownloadRequest,
});

const mapDispatchToProps = dispatch => ({
  resendPin: key => dispatch(resendPinRequest(key)),
  verifyPin: (key, pin) => dispatch(verifyPinRequest(key, pin)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'viewDataDownloadRequest',
  reducer: viewDataDownloadRequestReducer,
});

export default compose(
  withRouter,
  withReducer,
  withConnect
)(ViewDataDownloadRequest);

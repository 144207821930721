import React from 'react';
import './noteCard.scss';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';
import NoteCreatedByCard from '../NoteCreatedByCard';

const NoteCard = props => {
  const { item, currentUserId, onNoteClick } = props;
  const noteStatus =
    item.readOn === null
      ? {
          noteStatusIconClassName: 'note-unread-icon',
          noteStatusMessage: 'UNREAD',
        }
      : {
          noteStatusIconClassName: 'note-read-icon',
          noteStatusMessage: 'READ',
        };

  const onCardClick = (event, logId) => {
    event.preventDefault();
    onNoteClick(logId);
  };

  return (
    <div
      className='notes'
      role='button'
      tabIndex='0'
      data-id={item.id}
      onClick={e => onCardClick(e, item.id)}
      onKeyDown={() => {}}
    >
      <div className='notes-pane1'>
        <span>
          <CircleIcon
            fontSize='small'
            className={`${noteStatus.noteStatusIconClassName}`}
          />
          &nbsp;
          <span className='non-zero-count'>{noteStatus.noteStatusMessage}</span>
        </span>
        <span>
          <AttachmentOutlinedIcon
            fontSize='small'
            className={`${
              item.mediaResourceCount > 0
                ? 'non-zero-count'
                : 'notes-pane1-item-icon'
            }`}
          />
          &nbsp;
          <span
            className={`${
              item.mediaResourceCount > 0
                ? 'non-zero-count'
                : 'notes-pane1-item-text'
            }`}
          >
            {item.mediaResourceCount === 1
              ? `${item.mediaResourceCount} ATTACHMENT`
              : `${item.mediaResourceCount} ATTACHMENT(s)`}
          </span>
        </span>

        <span>
          <CommentOutlinedIcon
            fontSize='small'
            className={`${
              item.commentCount > 0 ? 'non-zero-count' : 'notes-pane1-item-icon'
            }`}
          />
          &nbsp;
          <span
            className={`${
              item.commentCount > 0 ? 'non-zero-count' : 'notes-pane1-item-text'
            }`}
          >
            {item.commentCount === 1
              ? `${item.commentCount} COMMENT`
              : `${item.commentCount} COMMENTS`}
          </span>
        </span>
      </div>
      <div className='vertical-line' />
      <div className='notes-pane2'>
        <div className='communication-details-container'>
          <div className='communication-title'>{item.title}</div>
          <div className='communication-description'>{item.description}</div>
          <NoteCreatedByCard
            createdById={item.createdById}
            currentUserId={currentUserId}
            createdByName={item.createdByName}
            isShared={item.isShared === 1}
            createdAt={item.createdAt}
          />
        </div>
        <ChevronRightIcon className='communication-log-list-chevron' />
      </div>
    </div>
  );
};

NoteCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
    isShared: PropTypes.number,
    createdById: PropTypes.string,
    commentCount: PropTypes.number,
    mediaResourceCount: PropTypes.number,
    createdByName: PropTypes.string,
    readOn: PropTypes.string,
  }).isRequired,
  currentUserId: PropTypes.number.isRequired,
  onNoteClick: PropTypes.func.isRequired,
};

export default NoteCard;

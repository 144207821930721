import { AppAction } from 'types';
import * as Constants from './constants';

export type PaymentsDataHeaderType = {
  key: string;
  title: string;
  sortable: boolean;
  needSubtitle?: boolean;
  isStatusValue?: boolean;
};

export type PaymentsDataObjectType = {
  id: number;
  criteriaName: string;
  status: string;
  greenphireStatus: string;
  subjectStudyId: string;
  locationName: string;
  statusCheckLastAttemptedAt: string;
  canBeRefreshed: boolean;
  showCanBeRefreshed: boolean;
  createdAt: string;
  error: string;
};

export type ResponseDataType = {
  paymentHistoryHeaders: PaymentsDataHeaderType[] | [];
  paymentHistories: PaymentsDataObjectType[] | [];
  pagination: {
    endNumber: number | null;
    firstPage: number | null;
    lastPage: number | null;
    page: number | null;
    prevPage: number | null;
    startNumber: number | null;
    total: number | null;
    totalPages: number | null;
  };
  lastRefreshedAt: string;
};

type ActionType = AppAction & {
  data: ResponseDataType | PaymentsDataObjectType;
};

const initialState = <ResponseDataType>{
  paymentHistoryHeaders: [],
  paymentHistories: [],
  pagination: {
    endNumber: null,
    firstPage: null,
    lastPage: null,
    page: null,
    prevPage: null,
    nextPage: null,
    startNumber: null,
    total: null,
    totalPages: null,
  },
  lastRefreshedAt: '',
};

const paymentsReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case Constants.FETCH_PAYMENT_HISTORIES_REQUEST_SUCCEED: {
      const data = action.data as ResponseDataType;
      return {
        ...state,
        paymentHistoryHeaders: data.paymentHistoryHeaders,
        paymentHistories: data.paymentHistories,
        pagination: { ...state.pagination, ...data.pagination },
        lastRefreshedAt: data.lastRefreshedAt,
      };
    }

    case Constants.REFRESH_PAYMENT_HISTORY_REQUEST_SUCCEED: {
      const data = action.data as PaymentsDataObjectType;
      return {
        ...state,
        paymentHistories: state.paymentHistories.map(
          (item: PaymentsDataObjectType) => {
            if (item.id === data.id) return data;
            return item;
          }
        ),
      };
    }

    default:
      return state;
  }
};

export default paymentsReducer;

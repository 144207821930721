export const REGISTRATION_REQUEST_SENT = 'app/auth/REGISTRATION_REQUEST_SENT';
export const REGISTRATION_REQUEST_SUCCEEDED =
  'app/auth/REGISTRATION_REQUEST_SUCCEEDED';
export const REGISTRATION_REQUEST_FAILED =
  'app/auth/REGISTRATION_REQUEST_FAILED';

export const GET_PRIVACY_NOTICE_URL_REQUEST_SENT =
  'app/Common/GET_PRIVACY_NOTICE_URL_REQUEST_SENT';
export const GET_PRIVACY_NOTICE_URL_REQUEST_SUCCEEDED =
  'app/Common/GET_PRIVACY_NOTICE_URL_REQUEST_SUCCEEDED';
export const GET_PRIVACY_NOTICE_URL_REQUEST_FAILED =
  'app/Common/GET_PRIVACY_NOTICE_URL_REQUEST_FAILED';

export const GET_TERMS_AND_CONDITION_URL_REQUEST_SENT =
  'app/Common/GET_TERMS_AND_CONDITION_URL_REQUEST_SENT';
export const GET_TERMS_AND_CONDITION_URL_REQUEST_SUCCEEDED =
  'app/Common/GET_TERMS_AND_CONDITION_URL_REQUEST_SUCCEEDED';
export const GET_TERMS_AND_CONDITION_URL_REQUEST_FAILED =
  'app/Common/GET_TERMS_AND_CONDITION_URL_REQUEST_FAILED';
export const PATIENT_LOGIN_PREVENTED = 'app/auth/PATIENT_LOGIN_PREVENTED';
export const RESET_PATIENT_LOGIN_PREVENTED =
  'app/auth/RESET_PATIENT_LOGIN_PREVENTED';

export const CURRENT_USER_INFO_REQUEST_SENT =
  'app/auth/CURRENT_USER_INFO_REQUEST_SENT';
export const CURRENT_USER_INFO_REQUEST_SUCCEEDED =
  'app/auth/CURRENT_USER_INFO_REQUEST_SUCCEEDED';
export const CURRENT_USER_INFO_REQUEST_FAILED =
  'app/auth/CURRENT_USER_INFO_REQUEST_FAILED';

export const GENERATE_OTP_REQUEST_SENT = 'app/Common/GENERATE_OTP_REQUEST_SENT';
export const GENERATE_OTP_REQUEST_SUCCEEDED =
  'app/Common/GENERATE_OTP_REQUEST_SUCCEEDED';
export const GENERATE_OTP_REQUEST_FAILED =
  'app/Common/GENERATE_OTP_REQUEST_FAILED';

export const CLOSE_OTP_MODAL_REQUEST_SUCCEEDED =
  'app/Common/CLOSE_OTP_MODAL_REQUEST_SUCCEEDED';

export const SIGNIN_REQUEST_SENT = 'app/auth/SIGNIN_REQUEST_SENT';
export const SIGNIN_REQUEST_SUCCEEDED = 'app/auth/SIGNIN_REQUEST_SUCCEEDED';
export const SIGNIN_REQUEST_FAILED = 'app/auth/SIGNIN_REQUEST_FAILED';

export const SIGNOUT_REQUEST_SENT = 'app/auth/SIGNOUT_REQUEST_SENT';
export const SIGNOUT_REQUEST_SUCCEEDED = 'app/auth/SIGNOUT_REQUEST_SUCCEEDED';
export const SIGNOUT_REQUEST_FAILED = 'app/auth/SIGNOUT_REQUEST_FAILED';

export const SET_HAS_VERIFICATION_BEEN_ATTEMPTED =
  'app/auth/SET_HAS_VERIFICATION_BEEN_ATTEMPTED';

export const GET_USER_INFO_REQUEST_SENT =
  'app/Common/GET_USER_INFO_REQUEST_SENT';
export const GET_USER_INFO_REQUEST_SUCCEED =
  'app/Common/GET_USER_INFO_REQUEST_SUCCEED';
export const GET_USER_INFO_REQUEST_FAILED =
  'app/Common/GET_USER_INFO_REQUEST_FAILED';

export const UPDATE_USER_INFO_REQUEST_SENT =
  'app/Common/UPDATE_USER_INFO_REQUEST_SENT';
export const UPDATE_USER_INFO_REQUEST_SUCCEED =
  'app/Common/UPDATE_USER_INFO_REQUEST_SUCCEED';
export const UPDATE_USER_INFO_REQUEST_FAILED =
  'app/Common/UPDATE_USER_INFO_REQUEST_FAILED';

export const UPDATE_USER_PICTURE_REQUEST_SENT =
  'app/Common/UPDATE_USER_PICTURE_REQUEST_SENT';
export const UPDATE_USER_PICTURE_REQUEST_SUCCEED =
  'app/Common/UPDATE_USER_PICTURE_REQUEST_SUCCEED';
export const UPDATE_USER_PICTURE_REQUEST_FAILED =
  'app/Common/UPDATE_USER_PICTURE_REQUEST_FAILED';

export const UPDATE_USER_EMAIL_REQUEST_SENT =
  'app/Common/UPDATE_USER_EMAIL_REQUEST_SENT';
export const UPDATE_USER_EMAIL_REQUEST_SUCCEED =
  'app/Common/UPDATE_USER_EMAIL_REQUEST_SUCCEED';
export const UPDATE_USER_EMAIL_REQUEST_FAILED =
  'app/Common/UPDATE_USER_EMAIL_REQUEST_FAILED';

export const UPDATE_USER_ATTRIBUTE = 'app/auth/UPDATE_USER_ATTRIBUTE';

export const DELETE_ACCOUNT_REQUEST_SENT =
  'app/Common/DELETE_ACCOUNT_REQUEST_SENT';
export const DELETE_ACCOUNT_REQUEST_SUCCEED =
  'app/Common/DELETE_ACCOUNT_REQUEST_SUCCEED';
export const DELETE_ACCOUNT_REQUEST_FAILED =
  'app/Common/DELETE_ACCOUNT_REQUEST_FAILED';

export const SET_HAS_ACCEPTED_TERMS_AND_CONDITIONS =
  'app/Common/SET_HAS_ACCEPTED_TERMS_AND_CONDITIONS';
export const RESET_OPT_SENT_FLAG = 'app/Common/RESET_OPT_SENT_FLAG';

export const UPDATE_EMAIL_PREFERENCE = 'app/Common/UPDATE_EMAIL_PREFERENCE';

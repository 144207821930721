import {
  FETCH_RESOURCES_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
  UPDATE_CREATE_RESOURCE_ADMIN_VALUE_IN_STORE,
  SAVE_ADD_RESOURCE_FORM_DATA,
  RESET_ADD_RESOURCE_DATA,
  SET_SELECTED_RESOURCE_ID,
  UPDATE_RESOURCES_DATA_AFTER_SORTING,
} from './constants';

const paginationIntialData = {
  total: 0,
  page: 1,
  total_pages: 1,
  last_page: false,
  first_page: true,
  prev_page: null,
  next_page: 2,
  start_number: 1,
  end_number: 10,
};

const resourceAdminFormData = {
  title: '',
  description: '',
  displayValue: '',
  order: null,
  headerOrder: null,
  categoryOrder: null,
  resourceURL: '',
  selectedResourceId: null,
  department: null,
  procedure: null,
  category: null,
  location: null,
  action: null,
  hasDetails: false,
  value: '',
  isValueDisplayed: true,
  page: 1,
  filterName: null,
  step: 1,
  previousStep: 0,
};


const resourceAdminInitialData = {
  resourcesData: [],
  resourcesHeaders: [],
  pagination: paginationIntialData,
  ...resourceAdminFormData,
};

export const resourceAdminReducer = (
  state = resourceAdminInitialData,
  action
) => {
  switch (action.type) {
    case FETCH_RESOURCES_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED:
      return {
        ...state,
        resourcesData: action.data.resourcesData,
        resourcesHeaders: action.data.resourcesHeaders,
        pagination: action.data.pagination,
      };

    case SAVE_ADD_RESOURCE_FORM_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }

    case UPDATE_CREATE_RESOURCE_ADMIN_VALUE_IN_STORE: {
      return {
        ...state,
        [action?.key]: action?.value,
      };
    }

    case SET_SELECTED_RESOURCE_ID:
      return {
        ...state,
        ...action.data.resourceData,
        selectedResourceId: action.data.id,
      };

    case RESET_ADD_RESOURCE_DATA: {
      return {
        ...state,
        ...resourceAdminFormData,
        ...action.data,
      };
    }

    case UPDATE_RESOURCES_DATA_AFTER_SORTING:
      return {
        ...state,
        resourcesData: action.data,
      };
    default:
      return state;
  }
};

import React, { useState } from 'react';
import SingleSelect from './SingleSelect';

const SingleSelectNoBorder = props => {
  const { className: classes } = props;

  const [open, setOpen] = useState(false);

  return (
    <SingleSelect
      {...props}
      isSearchable={false}
      menuIsOpen={open}
      className={`without-border ${classes || ''}`}
      onMenuOpen={() => {
        if (!props.isDisabled) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      }}
      onMenuClose={() => {
        setOpen(false);
      }}
    />
  );
};

export default SingleSelectNoBorder;

import { RESET_GLOBAL_REDUCERS } from 'constants';
import {
  VERIFY_PIN_REQUEST_SENT,
  VERIFY_PIN_REQUEST_SUCCEED,
  VERIFY_PIN_REQUEST_FAILED,
} from './constants';

const initialState = {
  isLoading: false,
  dataDownloadRequest: null,
};

const viewDataDownloadRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_PIN_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_PIN_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        dataDownloadRequest: action.data.data_download_request,
      };
    case VERIFY_PIN_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return {
        ...state,
        isLoading: false,
        dataDownloadRequest: null,
      };
    default:
      return state;
  }
};

export default viewDataDownloadRequestReducer;

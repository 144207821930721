import { FormModal } from 'components/StyleGuideComponents/common/modals';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import React, { FC, useEffect, useState } from 'react';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import { useParams } from 'react-router-dom';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { useAppDispatch } from 'hooks';
import { dispatchActionWithCallBack } from 'common/actions';
import { updateDiaryRequest } from 'containers/Ediary/action';

type DiaryData = {
  id: number;
  activity: string;
};

type EditDiaryProps = {
  diary: DiaryData;
  showEditDiaryModal: boolean;
  setShowEditDiaryModal: (showEditDiaryModal: boolean) => void;
};

type PatientDetailsParams = {
  patientId: string;
  procedureId: string;
  tabId: string;
};

const EditDiary: FC<EditDiaryProps> = props => {
  const { diary, showEditDiaryModal, setShowEditDiaryModal } = props;

  const [diaryData, setDiaryData] = useState(diary);
  const [errors, setErrors] = useState({});

  const params = useParams<PatientDetailsParams>();
  const dispatch = useAppDispatch();

  const config = [
    {
      id: 'activity',
      input_type: 'textarea',
      name: 'activity',
      label: 'Activity',
      type: 'text',
      placeholder: '',
      value: diaryData.activity,
      validation: [
        { rule: 'isRequired', message: 'Activity Text is required' },
      ],
      visible: true,
      required: true,
    },
  ];

  const resetState = () => {
    setShowEditDiaryModal(false);
    setErrors({});
  };

  useEffect(() => {
    setDiaryData(diary);
  }, [diary]);

  const onFormValidationSuccess = () => {
    if (diaryData.activity.trim() === diary.activity) {
      setErrors({ ...errors, activity: 'Please update activity' });
    } else {
      const data = {
        id: diaryData.id,
        note: diaryData.activity,
        patient_id: parseInt(params.patientId, 10) || -1,
        procedure_id: parseInt(params.procedureId, 10) || -1,
        needs_reason: true,
        secure_action_code: SecureCodes.EDIT_DIARY,
      };

      dispatch(
        dispatchActionWithCallBack(
          secureActionWithDoubleAuthentication,
          { updateDiaryRequest, params: data },
          () => {
            resetState();
          }
        )
      );
    }
  };

  const updateDiary = (e: Event) => {
    e.preventDefault();
    validate(
      getValidationsObject(config),
      diaryData,
      (errorsInForm: object) => setErrors(errorsInForm),
      onFormValidationSuccess
    );
  };

  return (
    <FormModal
      show={showEditDiaryModal}
      onHide={() => {
        resetState();
      }}
      title={{
        text: 'Edit Diary Entry',
      }}
      modalStyle={{
        width: '700px',
        height: 'auto',
      }}
      form={{
        form: (
          <FormGenerator
            id='edit-diary-form'
            className='edit-diary-form'
            formConfig={config}
            onChange={(key: string, value: string) => {
              clearErrorsForField(errors, key);
              setDiaryData({ ...diaryData, [key]: value });
            }}
            errors={errors}
          />
        ),
      }}
      buttons={[
        <PrimaryButton
          type='submit'
          form='edit-diary-form'
          style={{ width: '260px' }}
          onClick={updateDiary}
          key='primaryButton'
        >
          Save
        </PrimaryButton>,
      ]}
    />
  );
};

export default EditDiary;

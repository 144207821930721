/*
 *
 * PatientDetailsPage reducer
 *
 */
import filter from 'lodash/filter';
import * as Constants from './constants';

const initialState = {
  isLoading: false,
  tasks: [],
  total: 0,
  hasError: false,
  taskDetails: {},
  isRequestInProgress: false,
  isMarkTaskCompleteRequestInProgress: false,
  patientHasSignedIn: true,
  procedureTasks: [],
  hasOptionalTaskAddedSuccessfully: false,
};

function patientTasksReducer(state = initialState, action) {
  let history = null;
  switch (action.type) {
    case Constants.GET_TASKS_REQUEST_SENT:
    case Constants.POST_TASKS_REMINDER_REQUEST_SENT:
    case Constants.POST_CREATE_TASK_REQUEST_SENT:
    case Constants.POST_DELETE_TASK_REQUEST_SENT:
    case Constants.POST_DELETE_ALL_RECURRING_TASKS_REQUEST_SENT:
    case Constants.GET_TASKS_DETAILS_REQUEST_SENT:
    case Constants.GET_OPTIONAL_TASKS_REQUEST_SENT:
    case Constants.ASSIGN_OPTIONAL_TASK_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case Constants.GET_TASKS_REMINDER_HISTORY_REQUEST_SENT:
      return {
        ...state,
        isRequestInProgress: true,
      };
    case Constants.GET_TASKS_REMINDER_HISTORY_REQUEST_SUCCEED:
      history = action.payload.data;
      if (state.taskDetails.tasks && state.taskDetails.tasks.length > 0) {
        return {
          ...state,
          isLoading: false,
          isRequestInProgress: false,
          taskDetails: {
            ...state.taskDetails,
            tasks: state.taskDetails.tasks.map(item => {
              if (item.id === action.payload.userTaskId) {
                return {
                  ...item,
                  history,
                };
              }
              return item;
            }),
          },
        };
      }
      return {
        ...state,
        isRequestInProgress: false,
        tasks: state.tasks.map(item => {
          if (item.id === action.payload.userTaskId) {
            return {
              ...item,
              history,
            };
          }
          return item;
        }),
      };
    case Constants.GET_TASKS_REMINDER_HISTORY_REQUEST_FAILED:
      if (state.taskDetails.tasks && state.taskDetails.tasks.length > 0) {
        return {
          ...state,
          isLoading: false,
          isRequestInProgress: false,
          taskDetails: {
            ...state.taskDetails,
            tasks: state.taskDetails.tasks.map(item => {
              if (item.id === action.userTaskId) {
                return {
                  ...item,
                  history: {
                    total: 0,
                    reminders: [],
                  },
                };
              }
              return item;
            }),
          },
        };
      }
      return {
        ...state,
        isRequestInProgress: false,
        tasks: state.tasks.map(item => {
          if (item.id === action.userTaskId) {
            return {
              ...item,
              history: {
                total: 0,
                reminders: [],
              },
            };
          }
          return item;
        }),
      };
    case Constants.GET_TASKS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        tasks: [...action.tasks.data],
        total: action.tasks.total,
      };
    case Constants.GET_OPTIONAL_TASKS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        optionalTasks: [...action.tasks.tasks],
      };
    case Constants.POST_TASKS_REMINDER_REQUEST_SUCCEED:
      if (state.taskDetails.tasks && state.taskDetails.tasks.length > 0) {
        return {
          ...state,
          isLoading: false,
          tasks: state.tasks.map(item =>
            item.id === action.data.userTask.id ? action.data.userTask : item
          ),
          taskDetails: {
            ...state.taskDetails,
            tasks: state.taskDetails.tasks.map(item =>
              item.id === action.data.userTask.id ? action.data.userTask : item
            ),
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        tasks: state.tasks.map(item =>
          item.id === action.data.userTask.id ? action.data.userTask : item
        ),
        patientHasSignedIn: action.data.userTask.userHasSignedIn,
      };
    case Constants.POST_CREATE_TASK_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.POST_CREATE_TASK_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case Constants.POST_TASKS_REMINDER_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.ASSIGN_OPTIONAL_TASK_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasOptionalTaskAddedSuccessfully: true,
      };
    case Constants.ASSIGN_OPTIONAL_TASK_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case Constants.GET_TASKS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.GET_OPTIONAL_TASKS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.POST_DELETE_TASK_REQUEST_SUCCEED:
    case Constants.POST_DELETE_ALL_RECURRING_TASKS_REQUEST_SUCCEED:
      if (state.taskDetails.tasks && state.taskDetails.tasks.length > 0) {
        return {
          ...state,
          isLoading: false,
          taskDetails: {
            ...state.taskDetails,
            tasks: filter(
              state.taskDetails.tasks,
              task => task.id !== action.taskId
            ),
          },
        };
      }
      return {
        ...state,
        isLoading: false,
        tasks: filter(state.tasks, task => task.id !== action.taskId),
      };
    case Constants.POST_DELETE_TASK_REQUEST_FAILED:
    case Constants.POST_DELETE_ALL_RECURRING_TASKS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.GET_TASKS_DETAILS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        taskDetails: action.taskDetails.recurringTask,
      };
    case Constants.GET_TASKS_DETAILS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };

    case Constants.TASK_DETAILS_CLEAR_STATE:
      return {
        ...state,
        taskDetails: {},
      };

    case Constants.POST_MARK_TASK_COMPLETE_REQUEST_SENT:
      return {
        ...state,
        isMarkTaskCompleteRequestInProgress: true,
      };
    case Constants.POST_MARK_TASK_COMPLETE_REQUEST_SUCCEED:
      return {
        ...state,
        isMarkTaskCompleteRequestInProgress: false,
      };
    case Constants.POST_MARK_TASK_COMPLETE_REQUEST_FAILED:
      return {
        ...state,
        isMarkTaskCompleteRequestInProgress: false,
      };

    case Constants.RESET_ALL_TASK:
      return initialState;

    default:
      return state;
  }
}

export default patientTasksReducer;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isiOS } from 'utils/common';
import { showToaster } from 'common/toasterActions';
import { TertiaryButton } from '../StyleGuideComponents/common/buttons';

const DownloadFile = props => {
  const dispatch = useDispatch();

  const { serviceCall, args = [] } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadClick = () => {
    let win = null;
    let loading = true;
    if (isiOS) {
      win = window.open('');
      loading = false;
    }
    setIsLoading(loading);

    serviceCall(...args)
      .then(response => {
        if (response) {
          setIsLoading(false);
          const downloadLink =
            response.data.downloadLink || response.data.download_link;
          if (isiOS && win) {
            win.location = downloadLink;
            win.focus();
          } else {
            win = window.location.assign(downloadLink, '_blank');
            win.focus();
          }
        }
      })
      .catch(error => {
        if (win) {
          win.close();
        }
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          dispatch(
            showToaster({ message: error.response.data.message, type: 'error' })
          );
        }
      });
  };

  return (
    <span>
      <TertiaryButton
        onClick={handleDownloadClick}
        style={{
          justifyContent: 'flex-start',
          padding: '0',
          height: 'auto',
          ...props.style,
        }}
      >
        <span>{props.children}</span>
      </TertiaryButton>
    </span>
  );
};

export default DownloadFile;

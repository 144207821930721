import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormDataPreview from 'containers/AdminPage/FormDataPreview';
import { snakeCase } from 'change-case-object';
import { dispatchActionWithCallBack } from 'common/actions';
import { fetchAllLocationsRequest } from 'containers/AdminPage/action';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import PropTypes from 'prop-types';
import {
  createLocationRequest,
  fetchLocationsListOnAdminPageRequest,
  updateLocationRequest,
} from '../action';

const AddLocationFormPreview = props => {
  const dispatch = useDispatch();

  const data = useSelector(store => store.locationAdminReducer);

  const makeParamsReady = dataInParams => {
    const params = {};
    Object.keys(dataInParams).map(key => {
      if (key === 'locationProviders')
        params[snakeCase(key)] = dataInParams[key].map(
          provider => provider.value
        );
      else if (key === 'locationAdvocates')
        params[snakeCase(key)] = dataInParams[key].map(
          advocate => advocate.value
        );
      else if (
        key === 'timezone' ||
        key === 'locationPi' ||
        key === 'locationDepartment'
      )
        params[snakeCase(key)] = dataInParams[key].value;
      else if (
        key === 'title' ||
        key === 'latitude' ||
        key === 'longitude' ||
        key === 'code' ||
        key === 'name' ||
        key === 'removeAsInvestigator'
      )
        params[snakeCase(key)] = dataInParams[key];
      return params;
    });
    params.needs_reason = true;
    if (data.selectedLocationId)
      params.secure_action_code = SecureCodes.EDIT_LOCATION;
    else
      params.secure_action_code = SecureCodes.ADD_LOCATION;
    return params;
  };

  const onSubmit = () => {
    const { handleModalHide } = props;
    const locationData = makeParamsReady(data);

    const action = data.selectedLocationId
      ? updateLocationRequest
      : createLocationRequest;
    dispatch(
      dispatchActionWithCallBack(
        secureActionWithDoubleAuthentication,
        {
          action,
          params: { id: data.selectedLocationId, ...locationData },
        },
        () => {
          handleModalHide();
          dispatch(
            fetchLocationsListOnAdminPageRequest({
              name: data.filterName,
              page: data.page,
            })
          );
          dispatch(fetchAllLocationsRequest());
        }
      )
    );
  };

  const dataToShow = [
    {
      title: 'Location Information',
      editStep: 1,
      columnsData: [
        {
          label: 'NAME',
          value: data.name,
          visible: data.name,
        },
        {
          label: 'ADDRESS',
          value: data.title,
          visible: data.title,
        },
        {
          label: 'LATITUDE',
          value: data.latitude,
          visible: data.latitude,
        },
        {
          label: 'LONGITUDE',
          value: data.longitude,
          visible: data.longitude,
        },
        {
          label: 'CODE',
          value: data.code,
          visible: data.code,
        },
      ],
    },
    {
      title: 'Location People',
      editStep: 2,
      columnsData: [
        {
          label: 'DEPARTMENT',
          value: data.locationDepartment?.label,
          visible: data.locationDepartment?.label,
        },
        {
          label: 'TIMEZONE',
          value: data.timezone?.value,
          visible: data.timezone?.value,
        },
        {
          label: 'PI',
          value: data.locationPi?.label,
          visible: data.locationPi?.value,
        },
        {
          label: 'PROVIDERS',
          value: data.locationProviders
            .map(provider => provider.label)
            .join(', '),
          visible: data.locationProviders.length,
        },
        {
          label: 'PATIENT ADVOCATES',
          value: data.locationAdvocates
            .map(advocate => advocate.label)
            .join(', '),
          visible: data.locationAdvocates.length,
        },
        {
          label:
            'REVOKE THE INVESTIGATOR STATUS OF OLD PI AND ASSIGN NEW PI AS INVESTIGATOR FOR THE PATIENTS ?',
          value: data.removeAsInvestigator ? 'Yes' : 'No',
          visible: data.selectedLocationId,
        },
      ],
    },
  ];
  return (
    <FormDataPreview dataToShow={dataToShow} {...props} onSubmit={onSubmit} />
  );
};

AddLocationFormPreview.propTypes = {
  handleModalHide: PropTypes.func.isRequired,
};

export default AddLocationFormPreview;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import './confirmationModal.scss';
import {
  SecondaryButton,
  AlertButton,
  PrimaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { PrimaryModal } from 'components/StyleGuideComponents/common/modals';

export default class ConfirmationModal extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      show: props.show ? props.show : false,
    };
    this.onModalClose = this.onModalClose.bind(this);
    this.onConfirmed = this.onConfirmed.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      show: nextProps.show,
    });
    if (nextProps.resolveConversationStarted) {
      this.onModalClose();
      nextProps.resetResolveConversationStarted();
    }
  }

  onModalClose() {
    this.props.onModalClose();
    this.setState({
      show: false,
    });
  }

  onConfirmed() {
    this.props.onConfirmed();
  }

  renderRenderButtons() {
    const buttonsArr = [
      <SecondaryButton onClick={this.onModalClose}>
        {this.props.cancelBtnText}
      </SecondaryButton>,
    ];
    if (this.props.isDeleteConfirmation)
      buttonsArr.push(
        <AlertButton
          onClick={this.onConfirmed}
          style={{ whiteSpace: 'nowrap' }}
        >
          {this.props.okBtnText}
        </AlertButton>
      );
    else
      buttonsArr.push(
        <PrimaryButton
          onClick={this.onConfirmed}
          style={{ whiteSpace: 'nowrap' }}
        >
          {this.props.okBtnText}
        </PrimaryButton>
      );
    return buttonsArr;
  }

  render() {
    return (
      <PrimaryModal
        show={this.state.show}
        onHide={this.onModalClose}
        bodyHeader={{
          text: this.props.title,
        }}
        bodyContent={{
          text: this.props.text,
        }}
        buttons={this.renderRenderButtons()}
      />
    );
  }
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onConfirmed: PropTypes.func.isRequired,
  cancelBtnText: PropTypes.string.isRequired,
  okBtnText: PropTypes.string.isRequired,
  isDeleteConfirmation: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  isDeleteConfirmation: false,
};

import React, { useState, useEffect } from 'react';
import fileType from 'file-type';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import findIndex from 'lodash/findIndex';
import {
  LoaderModal,
  PrimaryModal,
} from 'components/StyleGuideComponents/common/modals';
import {
  uploadDocumentWithAuthUrl,
  uploadDocumentClearState,
  uploadDocumentRemoveDocument,
} from 'common/actions';
import { getFileNameFromPath } from 'utils/stringUtils';
import Attachments from 'containers/Attachments';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import {
  IMAGE_FILE_FORMATS,
  MS_DOC_EXTENSIONS,
  ALL_FILE_FORMATS,
} from '../../constants';
import './addAttachmentModal.scss';
import LightboxModal from 'components/LightboxModal';

const AddAttachmentModal = props => {
  const dispatch = useDispatch();

  const initialState = {
    selectedImage: {
      name: null,
      size: null,
      type: null,
      file: null,
    },
    percentCompleted: 0,
    fileFormatSupported: true,
    maxAttachmentCount: props.maxAttachmentCount ? props.maxAttachmentCount : 2,
  };

  const [state, setState] = useState(initialState);

  const documentsReducerData = useSelector(store => store.documentsReducer);
  const attachment = {
    isUploadInProgress: documentsReducerData.isUploadInProgress,
    isUploadSuccess: documentsReducerData.isUploadSuccess,
    document: documentsReducerData.isExtra
      ? documentsReducerData.extraDoc.document
      : documentsReducerData.document,
    mediaResource: documentsReducerData.isExtra
      ? documentsReducerData.extraDoc.mediaResource
      : documentsReducerData.mediaResource,
    error: documentsReducerData.error,
  };

  useEffect(() => {
    if (state.selectedImage.name) {
      uploadDocument();
    }
  }, [state.selectedImage]);

  useEffect(() => {
    if (attachment.document) {
      if (props.mediaResources.length === 0) {
        props.onUpdateAttachments(
          props.mediaResources.concat(attachment.mediaResource)
        );
      } else if (
        findIndex(props.mediaResources, attachment.mediaResource) === -1
      ) {
        props.onUpdateAttachments(
          props.mediaResources.concat(attachment.mediaResource)
        );
      }
      dispatch(uploadDocumentClearState());
    }
  }, [attachment]);

  const onAttachmentRemoved = attachmentId => {
    props.onUpdateAttachments(
      props.mediaResources.filter(item => item.id !== attachmentId)
    );
    dispatch(uploadDocumentRemoveDocument(attachmentId));
  };

  const onImageChange = e => {
    e.preventDefault();
    let files;
    const readerArrayBuffer = new FileReader();

    if (e.dataTransfer) {
      ({ files } = e.dataTransfer);
    } else if (e.target) {
      ({ files } = e.target);
    }

    if (files && files[0]) {
      const blob = files[0];
      readerArrayBuffer.readAsArrayBuffer(blob);

      readerArrayBuffer.onloadend = event => {
        if (event.target.readyState === readerArrayBuffer.DONE) {
          const uint = new Uint8Array(event.target.result);
          const mimeType = fileType(uint);

          if (isValidFileFormat(mimeType, blob.type)) {
            setState({
              ...state,
              selectedImage: {
                name: files[0].name,
                size: files[0].size,
                type: files[0].type,
                file: files[0],
              },
              percentCompleted: 0,
              fileFormatSupported: true,
            });
          } else {
            setState({ ...state, fileFormatSupported: false });
          }
        }
      };
    }
  };

  const onModalClose = () => {
    if (!attachment.isUploadInProgress) {
      setState(initialState);
      dispatch(uploadDocumentClearState());
      props.onModalClose();
    }
  };

  const getFileType = mimeType => {
    return mimeType.includes('audio')
      ? 'audio'
      : mimeType.includes('image')
      ? 'image'
      : mimeType.includes('video')
      ? 'video'
      : 'document';
  };

  const isValidFileFormat = (mimeType, fileTypeExtension) => {
    let result = false;
    if (mimeType) {
      const { mime } = mimeType;
      if (mime) {
        const formats = props.fileFormats.split(',');
        if (mime === 'application/x-msi') {
          result = MS_DOC_EXTENSIONS.includes(fileTypeExtension);
          return result;
        }
        if (formats.length === 1) {
          if (formats[0] === '*/*') {
            result = ALL_FILE_FORMATS.some(m => m.includes(mime));
          } else {
            result = formats.some(m => m.includes(mime));
          }
        } else {
          result = formats.some(m => m.includes(mime));
        }
      }
    }
    return result;
  };

  const uploadDocument = () => {
    let percentCompleted = 0;
    const onUploadProgress = progressEvent => {
      percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setState({ ...state, percentCompleted });
    };
    dispatch(
      uploadDocumentWithAuthUrl(
        state.selectedImage.name,
        state.selectedImage.size,
        props.isFrom || 'attachment',
        getFileType(state.selectedImage.type),
        0,
        state.selectedImage.file,
        false,
        onUploadProgress,
        !!props.isExtra
      )
    );
  };

  const renderAttachmentContent = () => (
    <div className='attachment-container'>
      <div>
        <div
          className={`${
            props.mediaResources.length < state.maxAttachmentCount
              ? 'select-attachment'
              : 'select-attachment-disabled'
          }`}
        >
          <label
            htmlFor='answer_attachment'
            className={`${
              props.mediaResources.length < state.maxAttachmentCount
                ? 'cursor-pointer'
                : ''
            }`}
          >
            Click to attach a file
            <input
              type='file'
              name='answer_attachment'
              id='answer_attachment'
              disabled={
                attachment.isUploadInProgress ||
                props.mediaResources.length >= state.maxAttachmentCount
              }
              accept={props.fileFormats}
              onChange={onImageChange}
              onClick={
                props.mediaResources.length < state.maxAttachmentCount
                  ? e => {
                      e.target.value = null;
                    }
                  : null
              }
            />
          </label>
        </div>
        {!props.maxAttachmentCount && (
          <label className='message-container'>
            {`You can add upto  ${
              state.maxAttachmentCount == 1
                ? '1 attachment'
                : `${state.maxAttachmentCount} attachments`
            }`}
          </label>
        )}
        {!state.fileFormatSupported && (
          <label className='file-support-msg-container'>
            File Format not supported
          </label>
        )}
      </div>
      <div className='clr' />
      {state.selectedImage.name && state.selectedImage.name !== '' && (
        <div className='selected-file-name-container'>
          {state.percentCompleted <= 100 && attachment.isUploadInProgress && (
            <div>
              <span className='selected-file-name'>
                {state.selectedImage.name}
              </span>
              <ProgressBar
                className='progressbar'
                now={state.percentCompleted}
                label={`${state.percentCompleted}%`}
              />
              <LightboxModal show />
            </div>
          )}
          <LoaderModal
            show={
              state.percentCompleted >= 100 && attachment.isUploadInProgress
            }
          />
        </div>
      )}

      {props.mediaResources.map(mediaResource => (
        <div key={mediaResource.id} className='add-attachment-row'>
          <div className='add-attachment-container'>
            <span
              className='add-attachment-name'
              title={getFileNameFromPath(
                mediaResource.originalFileName || mediaResource.name
              )}
            >
              {getFileNameFromPath(
                mediaResource.originalFileName || mediaResource.name
              )}
            </span>
            <span
              className='remove-attachment-icon icon icon-font-a-close add-attachment-remove-icon cursor-pointer'
              onClick={() => onAttachmentRemoved(mediaResource.id)}
            />
          </div>
        </div>
      ))}
      <div className='clr' />
    </div>
  );

  if (props.isBindedWithModal) return renderAttachmentContent();

  return (
    <>
      {props.showModal && (
        <PrimaryModal
          show={props.showModal}
          onHide={onModalClose}
          bodyHeader={{
            text: 'Add Attachment',
          }}
          bodyContent={{
            text: renderAttachmentContent(),
          }}
          buttons={[
            <PrimaryButton
              disabled={attachment.isUploadInProgress}
              onClick={onModalClose}
            >
              Done
            </PrimaryButton>,
          ]}
        />
      )}
      {props.showAddedAttachments && (
        <Attachments
          mediaResources={props.mediaResources}
          onAttachmentRemoved={onAttachmentRemoved}
          isAdded
        />
      )}
    </>
  );
};

export default AddAttachmentModal;

AddAttachmentModal.defaultProps = {
  fileFormats: IMAGE_FILE_FORMATS.toString(),
  showAddedAttachments: true,
};

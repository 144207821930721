import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { snakeCase } from 'change-case-object';
import { dispatchActionWithCallBack } from 'common/actions';
import FormDataPreview from 'containers/AdminPage/FormDataPreview';
import { timeInMeridianFormat } from 'utils/dateUtils';
import { getTaskIdentifiersRequest } from 'containers/AdminPage/action';
import { updateTaskReminderAdminValue } from 'containers/AdminPage/TaskReminderAdmin/action';
import PropTypes from 'prop-types';
import {
  fetchTasksListOnAdminPageRequest,
  createTaskRequest,
  updateTaskRequest,
} from '../action';

const AddTaskFormPreview = props => {
  const dispatch = useDispatch();

  const taskAdminData = useSelector(store => store.taskAdminReducer);

  const makeParamsReady = data => {
    const params = {};
    Object.keys(data).map(key => {
      if (
        key === 'type' ||
        key === 'importanceLevel' ||
        key === 'procedure' ||
        key === 'department' ||
        key === 'eventKind' ||
        key === 'expiryEventKind'
      )
        params[snakeCase(key)] = taskAdminData[key].value;
      else if (
        key === 'offsetInDays' ||
        key === 'timings' ||
        key === 'expiryOffsetInDays' ||
        key === 'timings' ||
        key === 'title' ||
        key === 'description' ||
        key === 'linkName' ||
        key === 'linkEntityType' ||
        key === 'linkEntityIdentifier' ||
        key === 'relatedEventTemplateIdentifier' ||
        key === 'uniqueIdentifier' ||
        key === 'timeOffset' ||
        key === 'expiryTimings'
      ) {
        if (key === 'timings' || key === 'expiryTimings') {
          if (!taskAdminData[key]) params[snakeCase(key)] = 0;
          else
            params[snakeCase(key)] = timeInMeridianFormat(taskAdminData[key]);
        } else params[snakeCase(key)] = taskAdminData[key];
      }
      return params;
    });
    return params;
  };

  const onSubmit = () => {
    const { onClose } = props;
    const tasksData = makeParamsReady(taskAdminData);
    const action = taskAdminData.selectedTaskId
      ? updateTaskRequest
      : createTaskRequest;
    dispatch(
      dispatchActionWithCallBack(
        action,
        {
          params: { id: taskAdminData.selectedTaskId, ...tasksData },
        },
        () => {
          onClose();
          dispatch(
            fetchTasksListOnAdminPageRequest({
              name: taskAdminData.filterNameOrEmail,
              page: taskAdminData.page,
            })
          );
          dispatch(getTaskIdentifiersRequest());
          dispatch(
            updateTaskReminderAdminValue('showAddTaskReminderInfoModal', true)
          );
        }
      )
    );
  };

  const dataToShow = [
    {
      title: 'Task Detail',
      editStep: 1,
      columnsData: [
        {
          label: 'TITLE',
          value: taskAdminData.title,
          visible: taskAdminData.title,
        },
        {
          label: 'DESCRIPTION',
          value: taskAdminData.description,
          visible: taskAdminData.description,
        },
        {
          label: 'LINK NAME',
          value: taskAdminData.linkName,
          visible: taskAdminData.linkName,
        },
        {
          label: 'LINK ENTITY TYPE',
          value: taskAdminData.linkEntityType,
          visible: taskAdminData.linkEntityType,
        },
        {
          label: 'LINK ENTITY IDENTIFIER',
          value: taskAdminData.linkEntityIdentifier,
          visible: taskAdminData.linkEntityIdentifier,
        },
        {
          label: 'RELATED EVENT TEMPLATE IDENTIFIER',
          value: taskAdminData.relatedEventTemplateIdentifier,
          visible: taskAdminData.relatedEventTemplateIdentifier,
        },
        {
          label: 'UNIQUE IDENTIFIER',
          value: taskAdminData.uniqueIdentifier,
          visible: taskAdminData.uniqueIdentifier,
        },
      ],
    },
    {
      title: 'TASK CONTEXT',
      editStep: 2,
      columnsData: [
        {
          label: 'DEPARTMENT',
          value: taskAdminData.department?.label,
          visible: !!taskAdminData.department?.value,
        },
        {
          label: 'PROCEDURE',
          value: taskAdminData.procedure?.label,
          visible: !!taskAdminData.procedure?.value,
        },
        {
          label: 'EVENT KIND',
          value: taskAdminData.eventKind?.label,
          visible: taskAdminData.eventKind?.value !== null,
        },
        {
          label: 'EXPIRY EVENT KIND',
          value: taskAdminData.expiryEventKind?.label,
          visible: !!taskAdminData.expiryEventKind?.value,
        },
        {
          label: 'IMPORTANCE LEVEL',
          value: taskAdminData.importanceLevel?.label,
          visible: !!taskAdminData.importanceLevel?.value,
        },
      ],
    },
    {
      title: 'TASK TIMINGS',
      editStep: 3,
      columnsData: [
        {
          label: 'OFFSET IN DAYS',
          value: taskAdminData.offsetInDays,
          visible: true,
        },
        {
          label: 'TIMINGS',
          value: timeInMeridianFormat(taskAdminData.timings),
          visible: taskAdminData.timings,
        },
        {
          label: 'EXPIRY OFFSET IN DAYS',
          value: taskAdminData.expiryOffsetInDays,
          visible: true,
        },
        {
          label: 'EXPIRY TIMINGS',
          value: timeInMeridianFormat(taskAdminData.expiryTimings),
          visible: taskAdminData.expiryTimings,
        },
        {
          label: 'TIME OFFSET',
          value: taskAdminData.timeOffset,
          visible: true,
        },
      ],
    },
  ];

  return (
    <FormDataPreview dataToShow={dataToShow} {...props} onSubmit={onSubmit} />
  );
};

AddTaskFormPreview.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default AddTaskFormPreview;

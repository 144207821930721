import axios1 from 'axios';
import axios, { nonMasqueradingInstance } from './base';
import { AUTHORIZE_URL, GET_DOWNLOAD_LINK } from './constants';

export const getDocumentAuthorizedURL = (
  fileName,
  fileSize,
  fromType,
  mediaType,
  duration,
  useOriginalHeaders = true
) => {
  const params = {
    file: {
      filename: fileName,
      filesize: fileSize,
    },
    is_from: fromType,
    media_type: mediaType,
    duration,
  };

  return useOriginalHeaders
    ? nonMasqueradingInstance.post(AUTHORIZE_URL, params)
    : axios.post(AUTHORIZE_URL, params);
};

export const uploadDocumentToS3 = (
  url,
  fields,
  file,
  onUploadProgress = null
) => {
  const formData = new FormData();
  formData.append('key', fields.key);
  formData.append('policy', fields.policy);
  formData.append('acl', fields.acl);
  formData.append(
    'X-Amz-Server-Side-Encryption',
    fields.xAmzServerSideEncryption
  );
  formData.append(
    'X-Amz-Meta-Original-Filename',
    fields.xAmzMetaOriginalFilename
  );
  formData.append('X-Amz-Credential', fields.xAmzCredential);
  formData.append('X-Amz-Algorithm', fields.xAmzAlgorithm);
  formData.append('X-Amz-Date', fields.xAmzDate);
  formData.append('X-Amz-Signature', fields.xAmzSignature);
  formData.append('file', file);

  const config = {
    timeout: 60000,
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress,
  };
  delete axios1.defaults.headers.common.Authorization;
  delete axios1.defaults.headers.common.authorization;
  return axios1.post(url, formData, config);
};

export const doGetDownloadLink = documentId =>
  axios.get(`${GET_DOWNLOAD_LINK}${documentId}`);
// .then((response) => ({
//   response,
// }));

import {
  FETCH_TASKS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
  SAVE_ADD_TASK_FORM_DATA,
  UPDATE_CREATE_TASK_ADMIN_VALUE_IN_STORE,
  SET_SELECTED_TASK_ID,
  RESET_ADD_TASK_DATA,
} from './constants';

const paginationIntialData = {
  total: 0,
  page: 1,
  total_pages: 1,
  last_page: false,
  first_page: true,
  prev_page: null,
  next_page: 2,
  start_number: 1,
  end_number: 10,
};

const taskAdminFormData = {
  show: false,
  department: null,
  procedure: null,
  eventKind: null,
  offsetInDays: 0,
  timings: null,
  expiryEventKind: null,
  expiryOffsetInDays: 0,
  expiryTimings: '',
  title: '',
  description: '',
  importanceLevel: null,
  linkName: '',
  linkEntityType: '',
  linkEntityIdentifier: '',
  relatedEventTemplateIdentifier: '',
  uniqueIdentifier: '',
  timeOffset: 0,
  selectedTaskId: null,
};

const taskAdminInitialData = {
  tasksData: [],
  tasksHeaders: [],
  pagination: paginationIntialData,
  ...taskAdminFormData,
};

export const taskAdminReducer = (state = taskAdminInitialData, action) => {
  switch (action.type) {
    case FETCH_TASKS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED:
      return {
        ...state,
        tasksHeaders: action.data.tasksHeaders,
        tasksData: action.data.tasksData,
        pagination: action.data.pagination,
      };

    case SAVE_ADD_TASK_FORM_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }

    case UPDATE_CREATE_TASK_ADMIN_VALUE_IN_STORE: {
      return {
        ...state,
        [action?.key]: action?.value,
      };
    }

    case SET_SELECTED_TASK_ID:
      return {
        ...state,
        ...action.data.taskData,
        selectedTaskId: action.data.id,
      };

    case RESET_ADD_TASK_DATA: {
      return {
        ...state,
        ...taskAdminFormData,
        ...action.data,
      };
    }

    default:
      return state;
  }
};

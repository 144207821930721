import React from 'react';
import type { FC } from 'react';
import EmptyView from 'components/Emptyview';
import AuditTrailTable from 'containers/AuditTrail/AuditTrailTable';
import useAdminAuditTrailViewController from './ViewController';
import './index.scss';
import { PropsType } from './types';

const AuditTrailAdmin: FC<PropsType> = (props: PropsType) => {
  const { isActive } = props;
  const { auditTrailData, shouldShowEmptyViewModel } =
    useAdminAuditTrailViewController({ isActive });

  return (
    <div className='admin-audit-trail-container'>
      {shouldShowEmptyViewModel() ? (
        <EmptyView
          className='icon icon-featured_play_list'
          message='No Audit Trails'
        />
      ) : (
        <AuditTrailTable
          data={auditTrailData.data}
          headers={auditTrailData.headers}
        />
      )}
    </div>
  );
};

export default AuditTrailAdmin;

/*
 *
 * PatientDetailsPage reducer
 *
 */
import filter from 'lodash/filter';
import * as Constants from './constants';
import { RESET_GLOBAL_REDUCERS } from '../../constants';

const initialState = {
  isLoading: false,
  isEventRequestInProgress: false,
  events: [],
  procedureId: 0,
  procedureName: '',
  departmentName: '',
  userProcedureId: 0,
  total: 0,
  eventQuestions: [],
  hasError: false,
  isDeleted: false,
  isSkipped: false,
  hasPendingConsent: false,
  pendingSurveysCount: 0,
};

function patientAppointmentsReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.GET_APPOINTMENTS_REQUEST_SENT:
    case Constants.UPDATE_APPOINTMENT_REQUEST_SENT:
    case Constants.CREATE_APPOINTMENT_REQUEST_SENT:
    case Constants.DELETE_APPOINTMENT_REQUEST_SENT:
    case Constants.SKIP_APPOINTMENT_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        eventQuestions: [],
        isDeleted: false,
        isSkipped: false,
      };
    case Constants.GET_APPOINTMENTS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.GET_EVENT_QUESTIONS_REQUEST_SENT:
      return {
        ...state,
        isEventRequestInProgress: true,
        eventQuestions: [],
      };

    case Constants.GET_APPOINTMENTS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        events: [...action.appointments.events],
        total: action.appointments.total,
        procedureName: action.appointments.procedureName,
        departmentName: action.appointments.departmentName,
        userProcedureId: action.appointments.userProcedureId,
        hasPendingConsent: action.appointments.hasPendingConsent,
        pendingSurveysCount: action.appointments.pendingSurveysCount,
      };
    case Constants.GET_EVENT_QUESTIONS_REQUEST_FAILED:
      return {
        ...state,
        isEventRequestInProgress: false,
      };
    case Constants.GET_EVENT_QUESTIONS_REQUEST_SUCCEED:
      return {
        ...state,
        isEventRequestInProgress: false,
        eventQuestions: [...action.data.questions],
      };
    case Constants.CREATE_APPOINTMENT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        events: [...state.events, action.data.event],
      };
    case Constants.UPDATE_APPOINTMENT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
      };
    case Constants.CREATE_APPOINTMENT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.UPDATE_APPOINTMENT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case Constants.DELETE_APPOINTMENT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        events: filter(state.events, event => event.id !== action.eventId),
        isDeleted: true,
      };
    case Constants.DELETE_APPOINTMENT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
      };
    case Constants.SKIP_APPOINTMENT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        isSkipped: true,
      };
    case Constants.SKIP_APPOINTMENT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isSkipped: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return initialState;
    case Constants.POST_TELEMEDICINECALL_REMINDER_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case Constants.POST_TELEMEDICINECALL_REMINDER_SUCCEED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.POST_TELEMEDICINECALL_REMINDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default patientAppointmentsReducer;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import camelcaseKeys from 'change-case-object';
import validate, { clearErrorsForField } from 'common/validator';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { getValidationsObject } from 'utils/stringUtils';
import PropTypes from 'prop-types';
import { saveAddUserFormData, updateUserAdminValue } from '../action';
import { canHavePrimaryAdvocates, canHavePrimaryPhysicians } from '../services';

const AddUserStepTwoForm = props => {
  const dispatch = useDispatch();

  const userAdminData = useSelector(store => store.userAdminReducer);
  const adminData = useSelector(store => store.AdminReducer);

  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState({
    userDepartment: null,
    type: null,
    userType: null,
    userRole: null,
    primaryPhysicians: [],
    primaryAdvocates: [],
  });

  useEffect(() => {
    if (userAdminData.allowSsoLogin && !userAdminData.selectedUserId) {
      dispatch(
        updateUserAdminValue(
          'userType',
          adminData.userTypes.find(userType => userType.label === 'master')
        )
      );
      dispatch(
        updateUserAdminValue(
          'type',
          adminData.types.find(type => type.label === 'PatientAdvocate')
        )
      );
      dispatch(
        updateUserAdminValue(
          'userRole',
          adminData.userRoleOptions.patientAdvocate.find(
            role => role.label === 'Rep'
          )
        )
      );
    }
  }, []);

  useEffect(() => {
    const data = {
      userDepartment: userAdminData.userDepartment,
      type: userAdminData.type,
      userType: userAdminData.userType,
      userRole: userAdminData.userRole,
      primaryPhysicians: userAdminData.primaryPhysicians,
      primaryAdvocates: userAdminData.primaryAdvocates,
    };
    setUserData(data);
  }, [
    userAdminData.userDepartment,
    userAdminData.type,
    userAdminData.userType,
    userAdminData.userRole,
    userAdminData.primaryPhysicians,
    userAdminData.primaryAdvocates,
  ]);

  const config = [
    {
      id: 1,
      input_type: 'single_select',
      placeholder: 'Select Department',
      label: 'Select Department',
      name: 'userDepartment',
      value: userData.userDepartment,
      options: adminData.departments,
      validation: [{ rule: 'isRequired', message: 'Department is required' }],
      visible: true,
      disabled: userAdminData.selectedUserId,
      required: true,
    },
    {
      id: 2,
      input_type: 'single_select',
      placeholder: 'Select User Type',
      label: 'Select User Type',
      value: userData.type,
      name: 'type',
      options: adminData.types,
      validation: [{ rule: 'isRequired', message: 'Type is required' }],
      visible: true,
      disabled: userAdminData.selectedUserId || userAdminData.allowSsoLogin,
      required: true,
    },
    {
      id: 3,
      input_type: 'single_select',
      placeholder: 'Select User Category',
      label: 'Select User Category',
      name: 'userType',
      value: userData.userType,
      options: adminData.userTypes,
      validation: [{ rule: 'isRequired', message: 'User Type is required' }],
      visible: true,
      disabled: userAdminData.selectedUserId || userAdminData.allowSsoLogin,
      required: true,
    },
    {
      id: 4,
      input_type: 'single_select',
      label: 'Select User Role',
      placeholder: 'Select User Role',
      name: 'userRole',
      value: userData.userRole,
      options:
        adminData.userRoleOptions[
          camelcaseKeys.camelCase(userData.type?.value)
        ],
      validation: [{ rule: 'isRequired', message: 'Role is required' }],
      visible: true,
      disabled: userAdminData.selectedUserId || userAdminData.allowSsoLogin,
      required: true,
    },
    {
      id: 5,
      input_type: 'multi_select',
      placeholder: 'Select Primary Physicians',
      label: 'Select Primary Physicians',
      name: 'primaryPhysicians',
      visible: canHavePrimaryPhysicians(
        userData.type?.value,
        userData.userType?.label,
        adminData.deidentifiedAccessUserRoles,
        userData.userRole?.value
      ),
      isSearchable: true,
      isClearable: true,
      value: userData.primaryPhysicians,
      options: adminData.allProviders,
      disabled: false,
      required: false,
    },
    {
      id: 6,
      input_type: 'multi_select',
      placeholder: 'Select Primary Advocates',
      label: 'Select Primary Advocates',
      name: 'primaryAdvocates',
      visible: canHavePrimaryAdvocates(
        userData.type?.value,
        userData.userType?.label,
        adminData.deidentifiedAccessUserRoles,
        userData.userRole?.value
      ),
      isSearchable: true,
      isClearable: true,
      value: userData.primaryAdvocates,
      options: adminData.advocates,
      disabled: false,
      required: false,
    },
  ];

  const { onContinue, onBack, step } = props;

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      userData,
      errorsInParams => {
        setErrors(errorsInParams);
      },
      () => {
        dispatch(saveAddUserFormData(userData));
        onContinue(step + 1);
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      id='addUserStepTwoForm'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        const data = {};
        data[key] = value;
        if (key === 'type') data.userRole = null;
        clearErrorsForField(errors, key);
        setUserData({ ...userData, ...data });
      }}
      onSubmit={() => {
        onSubmit();
      }}
      buttons={[
        <SecondaryButton
          style={{ width: '176px' }}
          onClick={() => {
            dispatch(saveAddUserFormData(userData));
            onBack(step);
          }}
          key='secondaryButton'
        >
          <span>Back</span>
        </SecondaryButton>,
        <PrimaryButton
          form='addUserStepTwoForm'
          type='submit'
          style={{ width: '176px' }}
          onClick={() => {
            onSubmit();
          }}
          key='primaryButton'
        >
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

AddUserStepTwoForm.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

export default AddUserStepTwoForm;

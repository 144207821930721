import {
  GET_CONVERSATION_MESSAGES_REQUEST_FAILED,
  GET_CONVERSATION_MESSAGES_REQUEST_SENT,
  GET_CONVERSATION_MESSAGES_REQUEST_SUCCEED,
  SEND_MESSAGE_REQUEST_FAILED,
  SEND_MESSAGE_REQUEST_SENT,
  SEND_MESSAGE_REQUEST_SUCCEED,
} from './constants';

const initialState = {
  isLoading: false,
  conversationId: null,
  redirect: false,
  messages: [],
};

function conversationMessagesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONVERSATION_MESSAGES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        redirect: false,
      };
    case GET_CONVERSATION_MESSAGES_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        redirect: false,
        conversation: action.conversation,
        messages: [...action.messages],
      };
    case GET_CONVERSATION_MESSAGES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        redirect: false,
      };
    case SEND_MESSAGE_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        redirect: false,
      };
    case SEND_MESSAGE_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        redirect: false,
        messages: [action.message].concat(state.messages),
      };
    case SEND_MESSAGE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        redirect: false,
      };
    default:
      return state;
  }
}

export default conversationMessagesReducer;

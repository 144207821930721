import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import AddTaskFormPreview from './AddTaskFormPreview';
import AddTaskStepOneForm from './AddTaskStepOneForm';
import AddTaskStepTwoForm from './AddTaskStepTwoForm';
import { resetAddTasksData } from '../action';
import AddTaskStepThreeForm from './AddTaskStepThreeForm';

const AddEditTaskModal = props => {
  const dispatch = useDispatch();

  const taskAdminData = useSelector(store => store.taskAdminReducer);

  const [step, setStep] = useState(1);

  const handleModalHide = () => {
    dispatch(
      resetAddTasksData({
        page: taskAdminData.page,
        filterName: taskAdminData.filterName,
      })
    );
    setStep(1);
    props.updateSelectedTaskId(null);
    props.onHide();
  };

  const getFormBasedOnStep = step => {
    switch (step) {
      case 1:
        return (
          <AddTaskStepOneForm
            step={1}
            totalSteps={4}
            onStepChange={step => setStep(step)}
            onClose={() => handleModalHide()}
          />
        );
      case 2:
        return (
          <AddTaskStepTwoForm
            step={2}
            totalSteps={4}
            onStepChange={step => setStep(step)}
            onClose={() => handleModalHide()}
          />
        );

      case 3:
        return (
          <AddTaskStepThreeForm
            step={3}
            totalSteps={4}
            onStepChange={step => setStep(step)}
            onClose={() => handleModalHide()}
          />
        );
      case 4:
        return (
          <AddTaskFormPreview
            step={4}
            totalSteps={4}
            onStepChange={step => setStep(step)}
            onClose={() => handleModalHide()}
          />
        );
    }
  };

  const getFormSubheader = () => {
    switch (step) {
      case 1:
        return 'Personal Information';
      case 2:
        return 'Position Information';
      case 3:
        return 'Role Information';
      default:
        return 'Add New User';
    }
  };

  return (
    <FormModal
      show={props.show}
      onHide={handleModalHide}
      title={{
        text: props.selectedTaskId ? 'Edit Task Info' : 'Add New Task',
      }}
      subheader={
        <div className='modal-subheader-content'>
          <div>{getFormSubheader()}</div>
          <div className='text-right'>
            <span className='steps-count active-step-count'>{`${step} of 4`}</span>
          </div>
        </div>
      }
      modalStyle={{
        width: '800px',
        height: '800px',
      }}
      form={{
        form: getFormBasedOnStep(step),
      }}
    />
  );
};

export default AddEditTaskModal;

import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TabContentWrapper from 'containers/PatientDetails/Components/TabContentWrapper';
import EmptyView from 'components/Emptyview';
import { SecondaryButton } from 'components/StyleGuideComponents/common/buttons';
import { sortData } from 'containers/PatientDetails/configs/services';
import { PropTypes } from 'prop-types';
import {
  getAllDiaryEntriesRequest,
  markAsReadPatientAllDiariesRequest,
} from './action';
import DiaryCardBucket from './Components/diaryCardBucket';
import './diary.scss';

const Diaries = props => {
  const dispatch = useDispatch();
  const total = useSelector(store => store.patientDiaryReducer.total);
  const diaries = useSelector(store => store.patientDiaryReducer.diaryLogs);

  const { patientId, procedureId, isActive, count } = props;

  const onMarkAllReadClick = () => {
    dispatch(markAsReadPatientAllDiariesRequest(patientId, procedureId));
  };

  useEffect(() => {
    if (isActive) dispatch(getAllDiaryEntriesRequest(patientId));
  }, [isActive]);

  const diaryLogs = useMemo(
    () => sortData(dispatch, diaries, 'date', 'diaries'),
    [diaries]
  );

  return (
    <TabContentWrapper
      header={
        <div className='header'>
          {total > 0 ? (
            <>
              <div className='header-left-section diary-count'>
                {total === 1 ? '1 Entry' : `${total} Entries`}
              </div>
              <div className='header-right-section'>
                <div>
                  <SecondaryButton
                    style={{ width: '138px' }}
                    onClick={onMarkAllReadClick}
                    disabled={!count}
                  >
                    Mark all as read
                  </SecondaryButton>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      }
      body={
        <div className='diary-list'>
          {diaries.length ? (
            Object.keys(diaryLogs).map(key => (
              <DiaryCardBucket
                key={key}
                date={key}
                diaryEntries={diaryLogs[key]}
              />
            ))
          ) : (
            <EmptyView className='icon-menu_book' message='No Diary Entries' />
          )}
        </div>
      }
    />
  );
};

Diaries.propTypes = {
  isActive: PropTypes.bool.isRequired,
  patientId: PropTypes.number.isRequired,
  procedureId: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};
export default Diaries;

import {
  FetchPaymentHistoriesParamsType,
  fetchPaymentHistories,
  RefreshPaymentHistoryParamsType,
  refreshPaymentHistory,
  exportCsv,
} from 'services/paymentHistory';
import { APIResponse } from 'types';
import { AppDispatch } from 'store';
import * as Constants from './constants';
import { ResponseDataType, PaymentsDataObjectType } from './reducer';

type FetchPaymentHistoriesAPIResponse = APIResponse & {
  data: ResponseDataType;
};

type RefreshPaymentHistoryAPIResponse = APIResponse & {
  data: PaymentsDataObjectType;
};

type ExportCsvAPIResponse = APIResponse & {
  data: {
    fileUrl: string;
    message: string;
  };
};

export const fetchPaymentHistoriesRequestSucceeded = (
  data: ResponseDataType
) => ({ type: Constants.FETCH_PAYMENT_HISTORIES_REQUEST_SUCCEED, data });

export const refreshPaymentHistoryRequestSucceeded = (
  data: PaymentsDataObjectType
) => ({ type: Constants.REFRESH_PAYMENT_HISTORY_REQUEST_SUCCEED, data });

// redux thunk actions
export const fetchPaymentHistoriesRequest =
  (params: FetchPaymentHistoriesParamsType) => (dispatch: AppDispatch) => {
    fetchPaymentHistories(params)
      .then((response: FetchPaymentHistoriesAPIResponse) => {
        dispatch(fetchPaymentHistoriesRequestSucceeded(response.data));
      })
      .catch(() => {});
  };

export const refreshPaymentHistoryRequest =
  (params: RefreshPaymentHistoryParamsType) => (dispatch: AppDispatch) => {
    refreshPaymentHistory(params)
      .then((response: RefreshPaymentHistoryAPIResponse) => {
        dispatch(refreshPaymentHistoryRequestSucceeded(response.data));
      })
      .catch(() => {});
  };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const exportCsvRequest = () => (dispatch: AppDispatch) => {
  exportCsv()
    .then((response: ExportCsvAPIResponse) => {
      window.open(response.data.fileUrl);
    })
    .catch(() => {});
};

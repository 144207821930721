import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { snakeCase } from 'change-case-object';
import { dispatchActionWithCallBack } from 'common/actions';
import FormDataPreview from 'containers/AdminPage/FormDataPreview';
import PropTypes from 'prop-types';
import {
  fetchResourcesListOnAdminPageRequest,
  createResourceRequest,
  updateResourceRequest,
} from '../action';
import { secureActionWithDoubleAuthentication } from '../../../../components/MultiAuthenticatedRequest/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';

const AddResourceFormPreview = props => {
  const dispatch = useDispatch();

  const resourceAdminData = useSelector(store => store.resourceAdminReducer);

  const makeParamsReady = data => {
    const params = {};
    Object.keys(data).map(key => {
      if (
        key === 'department' ||
        key === 'procedure' ||
        key === 'category' ||
        key === 'action' ||
        key === 'location'
      ) {
        if (key === 'action')
          params.action_type = resourceAdminData[key]?.value ?? null;
        else params[snakeCase(key)] = resourceAdminData[key]?.value ?? null;
      } else if (
        key === 'title' ||
        key === 'description' ||
        key === 'order' ||
        key === 'headerOrder' ||
        key === 'hasDetails' ||
        key === 'isValueDisplayed' ||
        key === 'displayValue' ||
        key === 'resourceURL' ||
        key === 'categoryOrder' ||
        key === 'value'
      )
        params[snakeCase(key)] = resourceAdminData[key];
      return params;
    });
    params.needs_reason = true;
    if (resourceAdminData.selectedResourceId)
      params.secure_action_code = SecureCodes.EDIT_RESOURCE;
    else
      params.secure_action_code = SecureCodes.ADD_RESOURCE;
    return params;
  };

  const onSubmit = () => {
    const { handleModalHide } = props;
    const userData = makeParamsReady(resourceAdminData);
    const action = resourceAdminData.selectedResourceId
      ? updateResourceRequest
      : createResourceRequest;
    dispatch(
      dispatchActionWithCallBack(
        secureActionWithDoubleAuthentication,
        {
          action,
          params: { id: resourceAdminData.selectedResourceId, ...userData },
        },
        () => {
          handleModalHide();
          dispatch(
            fetchResourcesListOnAdminPageRequest({
              name: resourceAdminData.filterName,
              page: resourceAdminData.page,
            })
          );
        }
      )
    );
  };

  const dataToShow = [
    {
      title: 'Basic Information',
      editStep: 1,
      columnsData: [
        {
          label: 'DEPARTMENT',
          value: resourceAdminData.department?.label,
          visible: resourceAdminData.department,
          editable: false,
        },
        {
          label: 'PROCEDURE',
          value: resourceAdminData.procedure?.label,
          visible: resourceAdminData.procedure,
          editable: false,
        },
        {
          label: 'LOCATION',
          value: resourceAdminData.location?.label,
          visible: resourceAdminData.location,
          editable: false,
        },
        {
          label: 'TITLE',
          value: resourceAdminData.title,
          visible: resourceAdminData.title,
          editable: true,
        },
        {
          label: 'DESCRIPTION',
          value: resourceAdminData.description,
          visible: resourceAdminData.description,
          editable: true,
        },
      ],
    },
    {
      title: 'Advance Details',
      editStep: 2,
      columnsData: [
        {
          label: 'VALUE',
          value: resourceAdminData.value,
          visible: resourceAdminData.value,
          editable: true,
        },
        {
          label: 'DISPLAY VALUE',
          value: resourceAdminData.displayValue,
          visible: resourceAdminData.displayValue,
          editable: true,
        },
        {
          label: 'ACTION',
          value: resourceAdminData.action.label,
          visible: resourceAdminData.action,
          editable: true,
        },
        {
          label: 'IS VALUE DISPLAYED?',
          value: resourceAdminData.isValueDisplayed ? 'Yes' : 'No',
          visible: true,
        },
      ],
    },
  ];

  return (
    <FormDataPreview dataToShow={dataToShow} {...props} onSubmit={onSubmit} />
  );
};

AddResourceFormPreview.propTypes = {
  handleModalHide: PropTypes.func.isRequired,
};

export default AddResourceFormPreview;

/*
 *
 * Connect reducer
 *
 */
import {
  GET_NOTES_REQUEST_SENT,
  GET_NOTES_REQUEST_SUCCEED,
  GET_NOTES_REQUEST_FAILED,
  POST_CREATE_NOTES_REQUEST_SENT,
  POST_CREATE_NOTES_REQUEST_SUCCEED,
  POST_CREATE_NOTES_REQUEST_FAILED,
  IS_NOTE_DETAIL_VIEW,
  SELECTED_NOTE_ID,
  MARK_AS_READ_ALL_NOTES_REQUEST_SENT,
  MARK_AS_READ_ALL_NOTES_REQUEST_SUCCEED,
  MARK_AS_READ_ALL_NOTES_REQUEST_FAILED,
} from './constants';

const initialState = {
  isLoading: false,
  isCreateNoteRequestInProgress: false,
  isNoteDetailView: false,
  selectedNoteId: 0,
  notes: [],
  isAllNotesMarkedRead: false,
};

export const patientNotesReducer = (state = initialState, action) => {
  const newNotes = null;
  switch (action.type) {
    case IS_NOTE_DETAIL_VIEW:
      return {
        ...state,
        isNoteDetailView: action.data,
      };

    case SELECTED_NOTE_ID:
      return {
        ...state,
        selectedNoteId: action.data,
      };
    case GET_NOTES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case POST_CREATE_NOTES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreateNoteRequestInProgress: false,
      };
    case POST_CREATE_NOTES_REQUEST_SENT:
      return {
        ...state,
        isCreateNoteRequestInProgress: true,
      };
    case GET_NOTES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_NOTES_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        notes: [...action.data.notes],
      };
    case POST_CREATE_NOTES_REQUEST_SUCCEED:
      return {
        ...state,
        isCreateNoteRequestInProgress: false,
      };
    case MARK_AS_READ_ALL_NOTES_REQUEST_SENT:
      return {
        ...state,
        isMarkAllNotesRequestInProgress: true,
      };
    case MARK_AS_READ_ALL_NOTES_REQUEST_SUCCEED:
      return {
        ...state,
        isMarkAllNotesRequestInProgress: false,
        isAllNotesMarkedRead: true,
      };
    case MARK_AS_READ_ALL_NOTES_REQUEST_FAILED:
      return {
        ...state,
        isMarkAllNotesRequestInProgress: false,
      };
    default:
      return state;
  }
};

export default patientNotesReducer;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Pagination from 'components/Pagination';
import {
  fetchAllLocationsRequest,
  fetchResourceActionsRequest,
} from 'containers/AdminPage/action';
import AddEditResourceModal from './Components/AddEditResourceModal';
import AdminTabWrapper from '../AdminTabWrapper';
import ResourceFilter from './Components/ResourceFilter';
import ResourceData from './Components/ResourceData';
import {
  fetchResourcesListOnAdminPageRequest,
  setSelectedResourceIdRequest,
  updateResourceAdminValue,
} from './action';

const TeamResource = props => {
  const dispatch = useDispatch();

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedResourceId, setSelectedResourceId] = useState(null);

  const resourceAdminData = useSelector(store => store.resourceAdminReducer);

  useEffect(() => {
    if (props.isActive) {
      dispatch(
        fetchResourcesListOnAdminPageRequest({
          page: resourceAdminData.page,
          name: resourceAdminData.filterName,
        })
      );
      dispatch(fetchAllLocationsRequest());
      dispatch(fetchResourceActionsRequest());
    } else {
      dispatch(updateResourceAdminValue('filterName', ''));
    }
  }, [props.isActive, resourceAdminData.page, resourceAdminData.filterName]);

  return (
    <AdminTabWrapper
      filterSection={<ResourceFilter />}
      dataSection={{
        header: (
          <div style={{ width: 'fit-content' }}>
            <TertiaryButton onClick={() => setShowAddEditModal(true)}>
              <AddCircleOutlineIcon sx={{ fontSize: '32px' }} />
              <span style={{ fontSize: '16px' }}> Add New Resource </span>
            </TertiaryButton>
          </div>
        ),
        content: (
          <>
            <ResourceData
              selectedResourceId={selectedResourceId}
              data={{
                resourcesHeaders: resourceAdminData.resourcesHeaders,
                resourcesData: resourceAdminData.resourcesData,
              }}
              updateSelectedResourceId={data => {
                setSelectedResourceId(data);
                dispatch(
                  setSelectedResourceIdRequest(
                    data,
                    resourceAdminData.resourcesData.find(
                      resource => resource.id === data
                    )
                  )
                );
                setShowAddEditModal(true);
              }}
            />
            <AddEditResourceModal
              show={showAddEditModal}
              onHide={() => setShowAddEditModal(false)}
              updateSelectedResourceId={id => setSelectedResourceId(id)}
            />
          </>
        ),
        footer: (
          <Pagination
            pagination={resourceAdminData.pagination}
            title='Users'
            handlePagination={page => {
              dispatch(updateResourceAdminValue('page', page));
            }}
          />
        ),
      }}
    />
  );
};

export default TeamResource;

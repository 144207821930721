import {
  FETCH_LOCATIONS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
  UPDATE_CREATE_LOCATION_ADMIN_VALUE_IN_STORE,
  SET_SELECTED_LOCATION_ID,
  SAVE_ADD_LOCATION_FORM_DATA,
  RESET_ADD_LOCATION_DATA,
} from './constants';

const paginationIntialData = {
  total: 0,
  page: 1,
  total_pages: 1,
  last_page: false,
  first_page: true,
  prev_page: null,
  next_page: 2,
  start_number: 1,
  end_number: 10,
};

const locationAdminFormData = {
  name: null,
  title: null,
  latitude: null,
  longitude: null,
  code: null,
  timezone: null,
  locationPi: null,
  removeAsInvestigator: false,
  locationProviders: [],
  locationAdvocates: [],
  locationDepartment: null,
  selectedLocationId: null,
  filterName: null,
  previosStep: 0,
  step: 1,
};

const locationAdminInitialData = {
  locationsData: [],
  locationsHeaders: [],
  pagination: paginationIntialData,
  ...locationAdminFormData,
};

export const locationAdminReducer = (
  state = locationAdminInitialData,
  action
) => {
  switch (action.type) {
    case FETCH_LOCATIONS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED:
      return {
        ...state,
        locationsHeaders: action.data.locationsHeaders,
        locationsData: action.data.locationsData,
        pagination: action.data.pagination,
      };
    case SAVE_ADD_LOCATION_FORM_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }
    case UPDATE_CREATE_LOCATION_ADMIN_VALUE_IN_STORE: {
      return {
        ...state,
        [action?.key]: action?.value,
      };
    }

    case SET_SELECTED_LOCATION_ID:
      return {
        ...state,
        ...action.data.locationData,
        selectedLocationId: action.data.id,
        timezone: {
          label: action.data.locationData.timezone,
          value: action.data.locationData.timezone,
        },
        locationPi: action.data.locationData.pi,
      };

    case RESET_ADD_LOCATION_DATA: {
      return {
        ...state,
        ...locationAdminFormData,
      };
    }
    default:
      return state;
  }
};

/**
 *
 * Confirmation Card
 *
 */

import variables from './index.scss';
import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { SecondaryModal } from 'components/StyleGuideComponents/common/modals';
import './index.scss';
import { IconWithRectangularBorder } from '../../components/UtilityComponents';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import SingleSelect from 'components/SelectV2/SingleSelect';
import HelpBlock from 'components/HelpBlock';
import { useSelector, useDispatch } from 'react-redux';
import { getNotMovingForwardReasons } from 'containers/PatientDetails/actions.js';
import { useParams } from 'react-router-dom';
import Password from '../../components/StyleGuideComponents/Password';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { fetchDropdownOptionsForMultiAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { OTHER_REASON_CHARACTERS_LIMIT } from 'constants';
export default function ConfirmationCard(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const studyExitOptions = useSelector(
    store => store.notMovingForwardReducer.reasons
  );
  const stageTransitionOptions = useSelector(
    store => store.MultiAuthenticatedRequestReducer.reasonOptions
  );
  const [selectedReasonToQuit, setSelectedReasonToQuit] = useState('');
  const confirmationType = props.confirmationType;
  const [additionalComments, setAdditionalComments] = useState('');
  const [password, setPassword] = useState('');
  const optionsList =
    confirmationType === 'Exit Study'
      ? studyExitOptions.length
        ? studyExitOptions.map(item => ({
            label: item.value,
            value: item.id,
            isAdditionalCommentRequired: item.isAdditionalCommentRequired,
          }))
        : []
      : stageTransitionOptions;

  useEffect(() => {
    if (confirmationType === 'Exit Study' && props.show) {
      dispatch(
        getNotMovingForwardReasons({
          user_procedure_id: parseInt(params.procedureId, 10) || -1,
        })
      );
    } else if (props.show) {
      dispatch(
        fetchDropdownOptionsForMultiAuthentication(SecureCodes.STAGE_TRANSITION)
      );
    }
    if (!props.show) {
      setSelectedReasonToQuit('');
    }
  }, [confirmationType, props.show]);

  const alertIcon = () => (
    <IconWithRectangularBorder
      backgroundColor={variables.colorAlertNeutralDark}
      icon={
        <ErrorOutlineTwoToneIcon
          style={{
            fontSize: '30px',
            color: variables.colorWhite,
          }}
        />
      }
    />
  );

  const onAdditionalCommentChange = event => {
    setAdditionalComments(event.target.value);
  };

  const onPasswordChange = (name, value) => {
    setPassword(value);
  };

  const selectReasonToQuit = selectedValue => {
    setSelectedReasonToQuit(selectedValue);
  };

  const showModalTitle = () => {
    let value = '';
    switch (props.confirmationType) {
      case 'Exit Study':
        value = (
          <>
            <span>
              Are you sure the subject will
              <span className='highlighted-text'>&nbsp;Exit the Study</span>?
            </span>
          </>
        );
        break;

      case '':
        value = (
          <>
            <span>Please provide with the correct Confirmation Type</span>
          </>
        );
        break;

      default:
        value = (
          <>
            <span>
              Are you sure you want to mark this subject
              <span className='highlighted-text'>
                &nbsp; {props.confirmationType}
              </span>
              ?
            </span>
          </>
        );
        break;
    }
    return value;
  };

  const showModalBody = () => {
    let value = '';
    switch (props.confirmationType) {
      case 'Exit Study':
        value = (
          <span style={{ color: variables.colorInactiveDarkGrey }}>
            Please designate the reason this subject will be removed from the
            study. Their status will be changed to “STUDY EXIT”.
          </span>
        );
        break;

      case '':
        value = (
          <>
            <span>Please provide with the correct Confirmation Type</span>
          </>
        );
        break;

      default:
        value = (
          <>
            <span>
              By marking this subject as eligible, you are agreeing that they
              have met all inclusion and exclusion criteria to qualify for this
              study.
            </span>
          </>
        );
        break;
    }
    return value;
  };

  return (
    <div className='confirmation-modal col-xs-12'>
      <div className='page-content col-xs-8 col-xs-offset-2'>
        <SecondaryModal
          show={props.show}
          onHide={() => {
            props.onHide();
            setSelectedReasonToQuit('');
          }}
          bodyIcon={{
            icon: alertIcon(),
          }}
          bodyHeader={{
            text: showModalTitle(),
            align: 'start',
          }}
          bodyContent={{
            text: showModalBody(),
            align: 'start',
          }}
          form={{
            form: (
              <form
                id='exit-study-form'
                onSubmit={props.onConfirmPasswordRequest}
              >
                <div className='confirmation-modal-body'>
                  {
                    <>
                      <div
                        className={`${
                          props.errors.selectedReasonToExit
                            ? 'form-group has-error'
                            : ''
                        }`}
                      >
                        <SingleSelect
                          id='select-reason'
                          placeholder='Select a reason'
                          className={`select-reason`}
                          onChange={selectReasonToQuit}
                          options={optionsList}
                        />
                        <HelpBlock value={props.errors.selectedReasonToExit} />
                      </div>
                      {selectedReasonToQuit.isAdditionalCommentRequired ||
                      selectedReasonToQuit?.isCommentAllowed ? (
                        <div
                          className={`form-group ${
                            props.errors.additionalComments ? 'has-error' : ''
                          }`}
                        >
                          <label htmlFor='title'>Additional Comment</label>
                          <input
                            type='text'
                            // value={additionalComments}
                            placeholder='Additional Comment'
                            onChange={onAdditionalCommentChange}
                            className='form-control has-error'
                            id='additional-comment-for-exit'
                            maxLength={OTHER_REASON_CHARACTERS_LIMIT}
                          />
                          <HelpBlock value={props.errors.additionalComments} />
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  }
                  <div className={`confirmation-modal-password-wrapper`}>
                    <label
                      style={{
                        color: variables.colorPrimary,
                        marginBottom: '12px',
                      }}
                    >
                      CONFIRM BY ENTERING YOUR PASSWORD
                    </label>
                    <Password
                      id='password'
                      label='Enter Password'
                      name='password'
                      onChange={(name, value) => onPasswordChange(name, value)}
                      required
                      error={
                        props.errors && 'password' in props.errors
                          ? true
                          : false
                      }
                      errorText={
                        props.errors &&
                        ('password' in props.errors
                          ? props.errors.password
                          : '')
                      }
                      size='small'
                    />
                  </div>
                </div>
              </form>
            ),
          }}
          buttons={[
            <SecondaryButton
              onClick={() => {
                props.onHide();
                setSelectedReasonToQuit('');
              }}
            >
              No, cancel
            </SecondaryButton>,
            <PrimaryButton
              form='exit-study-form'
              type='submit'
              onClick={e => {
                e.preventDefault();
                props.onConfirmingStageChange(
                  selectedReasonToQuit,
                  additionalComments,
                  password
                );
              }}
            >
              Yes, confirm
            </PrimaryButton>,
          ]}
        />
      </div>
    </div>
  );
}

ConfirmationCard.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  isLoading: PropTypes.bool,
  errors: PropTypes.object,
};

import { AppDispatch } from 'store';
import {
  fetchSiteClosureRequestData,
  FetchSiteClosureRequestDataParamsType,
  resendPinForSiteClosureRequest,
  ResendPinForSiteClosureRequestDataParamsType,
} from 'services/siteClosureRequest';
import * as StorageService from 'services/storage';
import { setAuthHeaders } from 'services/base';
import { showToaster } from 'common/toasterActions';
import {
  FetchSiteClosureRequestDataAPIResponseType,
  ResendPinForSiteClosureRequestDataAPIResponseType,
  SiteClosureRequestDataAPIResponse,
} from './types';
import * as Constants from './constants';

export const fetchSiteClosureRequestDataSucceed = (
  data: SiteClosureRequestDataAPIResponse
) => ({
  type: Constants.FETCH_SITE_CLOSURE_REQUEST_DATA_SUCCEED,
  data,
});

// redux thunk actions

export const fetchSiteClosureRequestDataAction =
  (params: FetchSiteClosureRequestDataParamsType) =>
  (dispatch: AppDispatch) => {
    fetchSiteClosureRequestData(params)
      .then((response: FetchSiteClosureRequestDataAPIResponseType) => {
        dispatch(fetchSiteClosureRequestDataSucceed(response.data));
        StorageService.deleteAuthHeadersFromDeviceStorage();
        StorageService.clearDefaultStorage();
        setAuthHeaders(response.headers);
        StorageService.persistAuthHeadersInDeviceStorage(response.headers);
      })
      .catch(() => {});
  };

export const resendPinForSiteClosureRequestAction =
  (params: ResendPinForSiteClosureRequestDataParamsType) =>
  (dispatch: AppDispatch) => {
    resendPinForSiteClosureRequest(params)
      .then((response: ResendPinForSiteClosureRequestDataAPIResponseType) => {
        console.log(response.data);
        dispatch(
          showToaster({ type: 'success', message: response.data.message })
        );
      })
      .catch(() => {});
  };

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DeleteModal } from 'components/StyleGuideComponents/common/modals';
import { AlertButton } from 'components/StyleGuideComponents/common/buttons';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { deleteAppointment } from 'containers/Appointments/action';
import { resetDeleteAppointmentStarted } from 'common/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';

const DeleteAppointment = props => {
  const dispatch = useDispatch();

  const deleteAppointmentStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.deleteAppointmentStarted
  );

  useEffect(() => {
    if (deleteAppointmentStarted) {
      props.closeShowDeleteModal();
      dispatch(resetDeleteAppointmentStarted);
    }
  }, [deleteAppointmentStarted]);

  const onDeleteAppointmentRequest = () => {
    const params = {
      appointment_id: props.item.id,
      secure_action_code: SecureCodes.DELETE_APPOINTMENT,
      needs_reason: true,
    };
    dispatch(secureActionWithDoubleAuthentication(deleteAppointment, params));
  };

  return (
    <DeleteModal
      show={props.showDeleteModal}
      onHide={props.closeShowDeleteModal}
      bodyHeader={{
        text: 'Delete Appointment',
      }}
      bodyContent={{
        text: 'Are you sure you want to delete this appointment?',
      }}
      buttons={[
        <AlertButton onClick={onDeleteAppointmentRequest}>Delete</AlertButton>,
      ]}
    />
  );
};

export default DeleteAppointment;

import React from 'react';
import { components } from 'react-select';

const ValueContainer = ({ children, getValue, ...props }) => {
  const values = getValue();
  const { placeholder: pillFor, isPill } = props.selectProps;
  let valueLabel;

  {
    isPill
      ? (valueLabel = values.length > 0 && (
          <div className='value-container--value-outer'>
            <span className='value-container--value-label'>
              {pillFor} ({values.length})
            </span>
          </div>
        ))
      : (valueLabel = ` ${values.length} selected`);
  }

  // if (values.length > 0) { valueLabel = ` ${values.length} selected`; }

  // Keep standard placeholder and input from react-select
  const childsToRender = React.Children.toArray(children).filter(
    child =>
      ['Input', 'DummyInput', 'Placeholder'].indexOf(child.type.name) >= 0
  );

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && valueLabel}
      {childsToRender}
    </components.ValueContainer>
  );
};

export default ValueContainer;

export const getNativeAuthTokens = () => {
  let tokens = {};
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim().replace(/Bearer Bearer/, 'Bearer');
    if (cookie.startsWith('Authorization=Bearer ')) {
      tokens["Authorization"] = cookie.substring('Authorization=Bearer '.length, cookie.length);
    }
  }
  return tokens;
};

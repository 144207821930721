import { createNotification } from 'services/admin';
import { showToaster } from 'common/toasterActions';
import {
  SAVE_ADD_NOTIFICATION_FORM_DATA,
  UPDATE_CREATE_NOTIFICATION_ADMIN_VALUE_IN_STORE,
  RESET_ADD_NOTIFICATION_DATA,
} from './constants';

export const saveAddNotificationFormData = data => {
  return {
    type: SAVE_ADD_NOTIFICATION_FORM_DATA,
    data,
  };
};

export const updateNotificationAdminValue = (key, value) => {
  return {
    type: UPDATE_CREATE_NOTIFICATION_ADMIN_VALUE_IN_STORE,
    key,
    value,
  };
};

export const resetAddNotificationData = () => {
  return {
    type: RESET_ADD_NOTIFICATION_DATA,
  };
};

export const createNotificationRequest = params => dispatch => {
  createNotification(params)
    .then(response => {
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {});
};

/*
 *
 * Ediary constants
 *
 */

export const GET_DIARY_ENTRIES_REQUEST_SENT =
  'app/ediary/GET_DIARY_ENTRIES_REQUEST_SENT';
export const GET_DIARY_ENTRIES_REQUEST_SUCCEED =
  'app/ediary/GET_DIARY_ENTRIES_REQUEST_SUCCEED';
export const GET_DIARY_ENTRIES_REQUEST_FAILED =
  'app/ediary/GET_DIARY_ENTRIES_REQUEST_FAILED';

import {
  FETCH_PAYMENT_HISTORIES,
  PAYMENT_HISTORY_EXPORT_CSV,
  REFRESH_PAYMENT_HISTORY,
} from './constants';
import axios from './base';

export type FetchPaymentHistoriesParamsType = {
  term?: string | null;
  page: number;
  limit?: number | null;
};

export type RefreshPaymentHistoryParamsType = {
  id: number;
};

export const fetchPaymentHistories = (
  params: FetchPaymentHistoriesParamsType
) => axios.get(FETCH_PAYMENT_HISTORIES, { params });

export const refreshPaymentHistory = (
  params: RefreshPaymentHistoryParamsType
) => axios.get(REFRESH_PAYMENT_HISTORY.replace(':id', params.id.toString()));

export const exportCsv = () => axios.get(PAYMENT_HISTORY_EXPORT_CSV);

import {
  fetchAllTasksOfTheApplication,
  createTask,
  updateTask,
} from 'services/admin';
import { showToaster } from 'common/toasterActions';
import {
  FETCH_TASKS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
  SAVE_ADD_TASK_FORM_DATA,
  UPDATE_CREATE_TASK_ADMIN_VALUE_IN_STORE,
  SET_SELECTED_TASK_ID,
  RESET_ADD_TASK_DATA,
} from './constants';

const fetchTasksListOnAdminPageRequestSucceeded = data => {
  return {
    type: FETCH_TASKS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
    data: {
      tasksHeaders: data.taskAttributes,
      tasksData: data.tasks,
      pagination: data.pagination,
    },
  };
};

export const saveAddTaskFormData = data => {
  return {
    type: SAVE_ADD_TASK_FORM_DATA,
    data,
  };
};

export const updateTaskAdminValue = (key, value) => {
  return {
    type: UPDATE_CREATE_TASK_ADMIN_VALUE_IN_STORE,
    key,
    value,
  };
};

export const setSelectedTaskIdRequest = (id, taskData) => {
  return {
    type: SET_SELECTED_TASK_ID,
    data: { id, taskData },
  };
};

export const resetAddTasksData = data => {
  return {
    data,
    type: RESET_ADD_TASK_DATA,
  };
};

export const createTaskRequest = params => dispatch => {
  createTask(params)
    .then(response => {
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {});
};

export const updateTaskRequest = params => dispatch => {
  updateTask(params)
    .then(response => {
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {});
};

export const fetchTasksListOnAdminPageRequest = params => dispatch => {
  fetchAllTasksOfTheApplication(params)
    .then(response => {
      dispatch(fetchTasksListOnAdminPageRequestSucceeded(response.data));
    })
    .catch(() => {});
};

/* eslint-disable react/forbid-prop-types */
/**
 *
 * NavigationBar
 *
 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getItemFromStorage } from 'services/storage';
import { compose, combineReducers } from 'redux';
import isEmpty from 'lodash/isEmpty';
import injectReducer from 'utils/injectReducer';
import { defaultLandingPageRouteGenerator } from 'common/authGuard';
import { getDepartmentProvidersReducer } from 'common/reducer';
import Lookup from 'components/Lookup';
import { USER_TYPE_PATIENT } from '../../constants';
import './navigation.scss';

import ProfileDropdown from './ProfileDropdown';
import { navBarSelectedPerformerReducer } from './reducer';
import Logo from '../../assets/images/insightproh.png';
import NotificationDropdown from './NotificationDropdown';

class NavigationBar extends React.PureComponent {
  render() {
    const { userDetails, userType, isLookup, isSignedIn, history, location } =
      this.props;
    const isPasswordChangeNeeded = !!(
      userDetails !== null && userDetails.needsPasswordChange
    );
    const isViewDataDownloadRequestComponent = location.pathname.includes(
      'view-data-download-request'
    );
    const isViewSiteClosureRequestComponent = location.pathname.includes(
      'view-site-closure-request'
    );

    return (
      <div className='navigation-bar'>
        <span
          onClick={() => history.replace(defaultLandingPageRouteGenerator())}
          role='button'
          onKeyDown={() => {}}
          tabIndex={0}
        >
          <div className='navigation-bar-logo cursor-pointer'>
            <img src={Logo} className='cgp-logo' alt='InsightPro™' />
          </div>
        </span>
        {isSignedIn &&
          !isViewDataDownloadRequestComponent &&
          !isViewSiteClosureRequestComponent && (
            <>
              <span className='navigation-bar-lookup'>
                <span id='flex-item-2'>
                  {isSignedIn &&
                    !isLookup &&
                    userType !== USER_TYPE_PATIENT &&
                    !isPasswordChangeNeeded && (
                      <Lookup
                        history={history}
                        isSignedIn={isSignedIn}
                        isLookup={!isLookup}
                        userType={userType}
                      />
                    )}
                </span>
              </span>
              <span className='navigation-bar-profile-notifications'>
                {!isPasswordChangeNeeded ? <NotificationDropdown /> : ''}
                <ProfileDropdown />
              </span>
            </>
          )}
      </div>
    );
  }
}

NavigationBar.propTypes = {
  userDetails: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  isLookup: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  userDetails: isEmpty(JSON.parse(getItemFromStorage('currentUser')))
    ? state.currentUser.attributes
    : JSON.parse(getItemFromStorage('currentUser')),
  userType:
    state.currentUser.attributes.type ||
    (getItemFromStorage('token') !== null && getItemFromStorage('currentUser')
      ? JSON.parse(getItemFromStorage('currentUser')).type
      : ''),
  isSignedIn:
    state.currentUser.isSignedIn || !isEmpty(getItemFromStorage('token')),
  isLookup: ownProps.history.location.pathname.indexOf('/lookup/') > -1,
});

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = dispatch => ({});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'navBar',
  reducer: combineReducers({
    pro: getDepartmentProvidersReducer,
    per: navBarSelectedPerformerReducer,
  }),
});

export default withRouter(compose(withReducer, withConnect)(NavigationBar));

import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'components/StyleGuideComponents/DataTable';
import React, { useMemo } from 'react';
import { dispatchActionWithCallBack } from 'common/actions';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import {
  activateOrDeactivateUserRequest,
  fetchUsersListOnAdminPageRequest,
} from '../action';

const UserData = props => {
  const dispatch = useDispatch();
  const data = props.data.usersData;
  const filterNameOrEmail = useSelector(
    store => store.userAdminReducer.filterNameOrEmail
  );
  const page = useSelector(store => store.userAdminReducer.page);

  const getDataToShow = useMemo(() => {
    const dataToShow = data.map(user => {
      const newObj = { ...user };
      const primaryMasters = newObj.primaryPhysicians.concat(
        newObj.primaryAdvocates
      );
      newObj.primaryPhysicians = primaryMasters.map(phy => phy.label);
      newObj.userLocations = newObj.userLocations.map(loc => loc.label);
      newObj.type = newObj.type.label;
      newObj.userType = newObj.userType.label;
      newObj.userRole = newObj.userRole.label;
      newObj.department = newObj.department.label;
      newObj.isSuperUser = newObj.isSuperUser ? 'Yes' : 'No';
      newObj.isPi = newObj.isPi ? 'Yes' : 'No';
      newObj.isActive = {
        onClick: () => {
          dispatch(
            dispatchActionWithCallBack(
              secureActionWithDoubleAuthentication,
              {
                activateOrDeactivateUserRequest,
                params: {
                  id: newObj.id,
                  is_active: user.isActive,
                  needs_reason: true,
                  secure_action_code: user.isActive
                    ? SecureCodes.DEACTIVATE_USER
                    : SecureCodes.ACTIVATE_USER,
                },
              },
              () =>
                dispatch(
                  fetchUsersListOnAdminPageRequest({
                    name: filterNameOrEmail,
                    page,
                  })
                )
            )
          );
        },
        value: newObj.isActive ? 'Deactivate User' : 'Activate User',
      };
      return newObj;
    });
    return dataToShow;
  }, [props.data.usersData]);

  return (
    <DataTable
      tableHeaders={props.data.usersHeaders}
      tableData={getDataToShow}
      tableDataCellStyle={{ textAlign: 'center' }}
      tableHeaderCellStyle={{ textAlign: 'center' }}
      onRowClick={id => props.updateSelectedUserId(id)}
      title='User'
    />
  );
};

export default UserData;

import LightboxModal from 'components/LightboxModal';
import React from 'react';
import { useSelector } from 'react-redux';

const LoaderDisplay = () => {
  const apiCallsInProgress = useSelector(
    store => store.LoadingReducer.apiCallsCount
  );
  return apiCallsInProgress > 0 ? <LightboxModal show /> : <></>;
};
export default LoaderDisplay;

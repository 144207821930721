export const FETCH_CONSENT_REQUEST_SENT =
  'src/Consent/FETCH_CONSENT_REQUEST_SENT';
export const FETCH_CONSENT_REQUEST_SUCCEED =
  'src/Consent/FETCH_CONSENT_REQUEST_SUCCEED';
export const FETCH_CONSENT_REQUEST_FAILED =
  'src/Consent/FETCH_CONSENT_REQUEST_FAILED';

export const DOWNLOAD_SIGNED_CONSENT_REQUEST_SENT =
  'src/Consent/DOWNLOAD_SIGNED_CONSENT_REQUEST_SENT';
export const DOWNLOAD_SIGNED_CONSENT_REQUEST_SUCCEED =
  'src/Consent/DOWNLOAD_SIGNED_CONSENT_REQUEST_SUCCEED';
export const DOWNLOAD_SIGNED_CONSENT_REQUEST_FAILED =
  'src/Consent/DOWNLOAD_SIGNED_CONSENT_REQUEST_FAILED';

export const CONSENT_RESEND_INVITE_REQUEST_SENT =
  'src/Consent/CONSENT_RESEND_INVITE_REQUEST_SENT';
export const CONSENT_RESEND_INVITE_REQUEST_SUCCEED =
  'src/Consent/CONSENT_RESEND_INVITE_REQUEST_SUCCEED';
export const CONSENT_RESEND_INVITE_REQUEST_FAILED =
  'src/Consent/CONSENT_RESEND_INVITE_REQUEST_FAILED';

export const UPLOAD_PAPER_CONSENT_REQUEST_SENT =
  'src/Consent/UPLOAD_PAPER_CONSENT_REQUEST_SENT';
export const UPLOAD_PAPER_CONSENT_REQUEST_SUCCEED =
  'src/Consent/UPLOAD_PAPER_CONSENT_SUCCEED';
export const UPLOAD_PAPER_CONSENT_REQUEST_FAILED =
  'src/Consent/UPLOAD_PAPER_CONSENT_REQUEST_FAILED';

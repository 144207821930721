import React from 'react';
import ConsentCard from './ConsentCard';

const ConsentCardBucket = props => {
  return (
    <>
      <div className='consent-bucket-title'>
        {props.title === 'completed' ? 'Completed' : 'Incomplete'}
      </div>
      <div>
        {props.consents.map(consent => (
          <ConsentCard key={consent.patientConsentId} consent={consent} />
        ))}
      </div>
    </>
  );
};

export default ConsentCardBucket;

import * as Constants from './constants';
import { ResponseDataType, ActionType } from './types';

const initialAdminAuditTrails = <ResponseDataType>{
  auditTrailsHeaders: [],
  auditTrails: [],
};

// eslint-disable-next-line import/prefer-default-export
export const adminAuditTrailReducer = (
  state = initialAdminAuditTrails,
  action: ActionType
) => {
  switch (action.type) {
    case Constants.FETCH_ADMIN_AUDIT_TRAIL:
      return {
        ...state,
        auditTrailsHeaders: action.data.auditTrailsHeaders,
        auditTrails: action.data.auditTrails,
      };
    case Constants.RESET_ADMIN_AUDIT_TRAIL:
      return initialAdminAuditTrails;
    default:
      return state;
  }
};

import React from 'react';
import BarChart from './Barchart';
import './styles.scss';
import { getSurveyActionText, getDisclaimerText } from '../utils';

export default function TrialPainScore(props) {
  const {
    data,
    getChartData,
    getChartColors,
    getXLabels,
    appendProcedureDataToBaselineData,
    generateXAxisLegend,
    generateChartLegend
  } = props;

  const trialData = data?.pain_score_data?.trial_pain_score_data ?? [];
  const baselineData = data?.pain_score_data?.baseline_pain_score_data ?? [];
  const actionText = getSurveyActionText() ?? null;

  const TRIAL_LEGEND_HASH = {
    BL: 'Baseline',
    D: 'Day',
    LD: 'Last Day',
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          autoSkip: false,
        },
        categorySpacing: 0.1,
      },
    },
  };

  const chartData = {
    labels: appendProcedureDataToBaselineData(
      trialData,
      baselineData,
      getXLabels
    ),
    datasets: [
      {
        data: appendProcedureDataToBaselineData(
          trialData,
          baselineData,
          getChartData
        ),
        backgroundColor: appendProcedureDataToBaselineData(
          trialData,
          baselineData,
          getChartColors
        ),
        datalabels: {
          display: true,
        },
      },
    ],
  };

  return (
    <>
      <div className='summary-page-divider' />
      <div className='trial-chart-wrapper'>
        <BarChart
          options={options}
          data={chartData}
          chartTitle={'Trial Pain Scores'}
          generateChartLegend={generateChartLegend}
          titleClassNames='uppercase'
          getActionText={actionText}
        />
      </div>
      {generateXAxisLegend(TRIAL_LEGEND_HASH)}
      {getDisclaimerText()}
    </>
  );
}

import React from 'react';
import {
  getChartData,
  getChartColors,
  getXLabels,
  appendProcedureDataToBaselineData,
} from '../utils';
import BarChart from './Barchart';
import './styles.scss';
import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import { red } from '@mui/material/colors';

export default function OutcomesCardChart(props) {
  const { data, seeMoreClicked } = props;

  const phaseData = data?.phase_pain_score_data ?? [];
  const baselineData = data?.baseline_pain_score_data ?? [];

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          autoSkip: false,
        },
        categorySpacing: 0.1,
      },
    },
  };

  const chartData = {
    labels: appendProcedureDataToBaselineData(
      phaseData,
      baselineData,
      getXLabels
    ),
    datasets: [
      {
        data: appendProcedureDataToBaselineData(
          phaseData,
          baselineData,
          getChartData
        ),
        backgroundColor: appendProcedureDataToBaselineData(
          phaseData,
          baselineData,
          getChartColors
        ),
        datalabels: {
          display: true,
        },
      },
    ],
  };
  return (
    <>
      <div className='outcomes-card-chart-wrapper' style={{height: "224px", maxWidth: "100%", background: red}}>
        <div className='outcomes-card-header'>
          <p className='chart-title'>Your Progress</p>
          <TertiaryButton onClick={seeMoreClicked} className={'fit-content'}>
            See More
          </TertiaryButton>
        </div>
        <div className='outcomes-card-chart'>
          <BarChart options={options} data={chartData} chartTitle={data?.is_trial ? 'Trial pain scores' : 'Implant pain scores'}/>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import validate, { clearErrorsForField } from 'common/validator';
import HelpBlock from 'components/HelpBlock';
import AddAttachmentModal from 'containers/AddAttachmentModal';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { resetSendMessageStarted } from 'common/actions';
import { PropTypes } from 'prop-types';
import { sendMessage as sendMessageAction } from './MessagesList/actions';

const validationConfig = {
  fields: ['newMessage'],
  rules: {
    newMessage: [{ rule: 'isRequired', message: 'Message is required' }],
  },
};

const MessageResponse = props => {
  const dispatch = useDispatch();
  const params = useParams();

  const { conversationId } = props;

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  const [errors, setErrors] = useState({});
  const [newMessage, setNewMessage] = useState('');
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const sendMessageStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.sendMessageStarted
  );

  const sendMessage = () => {
    const data = {
      conversation_id: conversationId,
      body: newMessage,
      media_resources: [...attachments],
      patientId,
      userProcedureId: procedureId,
      secure_action_code: SecureCodes.SEND_MESSAGE,
      needs_reason: true,
    };
    dispatch(secureActionWithDoubleAuthentication(sendMessageAction, data));
  };

  const resetState = () => {
    setErrors({});
    setNewMessage('');
    setShowAttachments(false);
    setAttachments([]);
  };

  useEffect(() => {
    if (sendMessageStarted) {
      resetState();
      dispatch(resetSendMessageStarted());
    }
  }, [sendMessageStarted]);

  const onSendMessageValidationSuccess = () => {
    sendMessage();
  };

  const onSendMessage = () => {
    validate(
      validationConfig,
      { newMessage },
      errorsInForm => setErrors(errorsInForm),
      onSendMessageValidationSuccess
    );
  };

  const onAddAttachment = () => {
    setShowAttachmentModal(true);
    setShowAttachments(true);
  };

  return (
    <div className='' style={{ width: '70%' }}>
      <span>Reply</span>
      <div
        className={`form-group text-reply ${
          errors.newMessage !== undefined ? 'has-error' : ''
        }`}
      >
        <textarea
          name='newMessage'
          id='newMessage'
          className='form-control'
          placeholder='Type your answer here...'
          value={newMessage}
          onChange={e => {
            clearErrorsForField(errors, 'newMessage');
            setNewMessage(e.target.value);
          }}
        />
        <HelpBlock value={errors.newMessage} />
        <AddAttachmentModal
          onUpdateAttachments={attachmentsInForm =>
            setAttachments(attachmentsInForm)
          }
          showModal={showAttachmentModal}
          onModalClose={() => setShowAttachmentModal(false)}
          showAddedAttachments={showAttachments}
          mediaResources={attachments}
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        <span className='float-right'>
          <PrimaryButton onClick={onSendMessage}>Send Answer</PrimaryButton>
        </span>
        <span
          className='float-right btn-attachment add-attachment-button'
          style={{ marginRight: '10px' }}
        >
          <SecondaryButton onClick={onAddAttachment}>
            Add Attachment
          </SecondaryButton>
        </span>
      </div>
      <div className='clearfix' />
    </div>
  );
};

MessageResponse.propTypes = {
  conversationId: PropTypes.string.isRequired,
};

export default MessageResponse;

import React from 'react';
import NoteCard from '../NoteCard';
import './noteDateBucket.scss';

const NoteDateBucket = props => {
  return (
    <div className='notes-date-bucket'>
      <div className='notes-header-date'>{props.date}</div>
      {props.notes.map(item => {
        return (
          <NoteCard
            key={item.id}
            onNoteClick={() => props.onNoteClick(item.id)}
            item={item}
            currentUserId={props.currentUserId}
          />
        );
      })}
    </div>
  );
};

export default NoteDateBucket;

import axios from 'axios';
import camelcaseKeys from 'change-case-object';
import { showToaster } from 'common/toasterActions';
import isEmpty from 'lodash/isEmpty';
import { resetDataForDoubleAuthetication } from 'components/MultiAuthenticatedRequest/actions';
import {
  clearDefaultStorage,
  getDefaultStorage,
  getItemFromStorage,
  persistTokenAuthHeaderInDeviceStorage,
  persistProxyTokenAuthHeaderInDeviceStorage,
} from 'services/storage';

import actions from 'common/authActions';

import { isMasquerading } from 'utils/common';

import {
  increaseAPICallsInProgressCount,
  decreaseAPICallsInProgressCount,
  removeRequstAndCallBackFromStore,
  clearStudyInformation,
  clearSubjectInformation,
} from 'common/actions';
import { resetAddLocationsData } from 'containers/AdminPage/LocationsAdmin/action';
import { resetAddUsersData } from 'containers/AdminPage/UsersAdmin/action';
import { resetAddConsentData } from 'containers/AdminPage/ConsentAdmin/action';
import { resetAddResourcesData } from 'containers/AdminPage/TeamResource/action';

import {
  GET_UNREAD_USER_NOTIFICATIONS_COUNT,
  GET_USER_NOTIFICATIONS_FOR_DROPDOWN,
  POST_READ_NOTIFICATIONS,
  DEFAULT_URL,
  authHeaderKeys,
  proxyAuthHeaderKeys,
} from './constants';

const apiRoutesToExcludeFromLoader = [
  GET_UNREAD_USER_NOTIFICATIONS_COUNT,
  GET_USER_NOTIFICATIONS_FOR_DROPDOWN,
  POST_READ_NOTIFICATIONS,
];

const startLoaderBeforeAPICall = (config, store) => {
  if (!apiRoutesToExcludeFromLoader.includes(config.url))
    store.dispatch(increaseAPICallsInProgressCount());
};

const stopLoaderAfterAPICall = (config, store) => {
  if (!apiRoutesToExcludeFromLoader.includes(config.url))
    store.dispatch(decreaseAPICallsInProgressCount());
};

const addUniqueIdToRequestIfCallBackIsRequired = configInParams => {
  const config = configInParams;
  if (Object.keys(config.data || {}).includes('uniqueRequestId')) {
    config.uniqueRequestId = config.data.uniqueRequestId;
    delete config.data.uniqueRequestId;
  }
  return config;
};

const executeCallBackIfRequestedhasOne = (config, store) => {
  if (config.uniqueRequestId) {
    const requestIdAndCallBack = store
      .getState()
      .requestsAndCallBackReducer.requests.find(
        req => req.id === config.uniqueRequestId
      );
    requestIdAndCallBack.callBack();
    store.dispatch(removeRequstAndCallBackFromStore(config.uniqueRequestId));
  }
  return config;
};

export const setAuthHeaders = (headers, accessToken = null) => {
  authHeaderKeys.forEach(key => {
    if (key === 'authorization' && accessToken) {
      axios.defaults.headers.common[key] = `Bearer ${accessToken}`;
    } else if (!isEmpty(headers[key])) {
      axios.defaults.headers.common[key] = headers[key];
    }
  });
};

export const deleteAuthHeaders = () => {
  authHeaderKeys.forEach(key => {
    delete axios.defaults.headers.common[key];
  });
};

export const setAuthHeadersFromDefaultStorage = () => {
  if (getDefaultStorage.length) {
    authHeaderKeys.forEach(key => {
      if (key === 'authorization') {
        axios.defaults.headers.common[key] = `Bearer ${getItemFromStorage(
          'token'
        )}`;
      } else if (getItemFromStorage(key)) {
        axios.defaults.headers.common[key] = getItemFromStorage(key);
      }
    });
  }
};

axios.defaults.baseURL = DEFAULT_URL;
axios.defaults.timeout = 120000;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common.Platform = 'Web';
axios.defaults.withCredentials = true;
axios.defaults.validateStatus = status => status >= 200 && status < 300;

const instance = axios.create();

export const defaultAxios = axios.create();

export const basicInstance = axios.create();

// this instance should be used when urls with original headers(non patient) is called.
export const nonMasqueradingInstance = axios.create();

const handleErrors = (store, error) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Error Logging :: ', error.message, JSON.stringify(error));
  }
  if (error.message) {
    if (error.message === 'Network Error') {
      store.dispatch(
        showToaster({
          message:
            'No internet connection. Please check your internet settings',
          type: 'error',
        })
      );
      return Promise.reject(error);
    }
  }
  if (!error.response) {
    if (error.code === 'ECONNABORTED') {
      store.dispatch(
        showToaster({ type: 'error', message: 'Request Timeout' })
      );
      const timeoutError = error;
      timeoutError.response = {
        data: {},
      };
      return Promise.reject(timeoutError);
    }
    store.dispatch(showToaster({ type: 'error', message: error.message }));
    return Promise.reject(error);
  }
  if (error.response) {
    let errorMessage = 'Something went wrong.';
    switch (error.response.status) {
      case 400:
        if (error.response.data.errors) {
          if (
            error.response.data.errors.full_messages &&
            error.response.data.errors.full_messages.length
          ) {
            errorMessage = error.response.data.errors.full_messages[0]; //eslint-disable-line
          }
          if (error.response.data.errors.length) {
            errorMessage = error.response.data.errors[0]; //eslint-disable-line
          }
        } else if (error.response.data.error) {
          errorMessage = error.response.data.error;
        }
        store.dispatch(showToaster({ message: errorMessage, type: 'error' }));
        break;
      case 401:
        store.dispatch(resetDataForDoubleAuthetication());
        store.dispatch(clearStudyInformation());
        store.dispatch(clearSubjectInformation());
        store.dispatch(actions.signOutRequestSucceeded());
        store.dispatch(resetAddUsersData());
        store.dispatch(resetAddLocationsData());
        store.dispatch(resetAddConsentData());
        store.dispatch(resetAddResourcesData());
        clearDefaultStorage();
        deleteAuthHeaders();
        store.dispatch(
          showToaster({ message: 'Session Expired. Please login again.' })
        );
        if (error.response.data.errors.length > 0)
          store.dispatch(
            showToaster({
              message: error.response.data.errors[0],
              type: 'error',
              timeout: 1500,
            })
          );
        break;
      case 422:
        if (error.response.data.errors) {
          if (
            error.response.data.errors.full_messages &&
            error.response.data.errors.full_messages.length
          ) {
            errorMessage = error.response.data.errors.full_messages[0]; //eslint-disable-line
          }
        } else if (error.response.data.error) {
          errorMessage = error.response.data.error;
        }
        // REMOVE HardCoded check
        if (errorMessage !== 'Not a valid zipcode') {
          store.dispatch(showToaster({ message: errorMessage, type: 'error' }));
        }
        break;
      case 500:
        store.dispatch(
          showToaster({
            type: 'error',
            message: error.message || 'Something went wrong.',
          })
        );
        break;
      case 404:
        if (error.response.data?.error) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage =
            'Information you requested, either does not exist or is not authorized for access.';
        }

        store.dispatch(
          showToaster({
            type: 'error',
            message: errorMessage,
          })
        );
        break;
      default:
        store.dispatch(showToaster({ type: 'error', message: error.message }));
        break;
    }
  }
  return Promise.reject(error);
};

const setNonMasqueradingAuthHeadersInInterceptors = configInParams => {
  const config = configInParams;
  if (!isEmpty(getItemFromStorage('token'))) {
    authHeaderKeys.forEach(key => {
      if (key === 'authorization') {
        config.headers.common[key] = `Bearer ${getItemFromStorage('token')}`;
      } else if (getItemFromStorage(key)) {
        config.headers.common[key] = getItemFromStorage(key);
      }
    });
  }
  return addUniqueIdToRequestIfCallBackIsRequired(config);
};

const setAuthHeadersInInterceptors = configInParams => {
  const config = configInParams;
  const isMasqueradingSession = isMasquerading();
  if (!isEmpty(getItemFromStorage('token'))) {
    if (isMasqueradingSession) {
      proxyAuthHeaderKeys.forEach((key, index) => {
        if (key === 'proxyAuthorization') {
          config.headers.common[
            authHeaderKeys[index]
          ] = `Bearer ${getItemFromStorage('proxyToken')}`;
        } else if (getItemFromStorage(key)) {
          config.headers.common[authHeaderKeys[index]] =
            getItemFromStorage(key);
        }
      });
    } else {
      authHeaderKeys.forEach(key => {
        if (key === 'authorization') {
          config.headers.common[key] = `Bearer ${getItemFromStorage('token')}`;
        } else if (getItemFromStorage(key)) {
          config.headers.common[key] = getItemFromStorage(key);
        }
      });
    }
  }
  return addUniqueIdToRequestIfCallBackIsRequired(config);
};

const setNewAuthorizationHeader = headers => {
  if (headers.authorization) {
    axios.defaults.headers.common.Authorization = headers.authorization;
    const accessToken = headers.authorization.split(' ')[1];
    persistTokenAuthHeaderInDeviceStorage(accessToken);
  } else if (headers['proxy-authorization']) {
    axios.defaults.headers.common.Authorization = `Bearer ${headers['proxy-authorization']}`;
    persistProxyTokenAuthHeaderInDeviceStorage(headers['proxy-authorization']);
  }
};

export const setupInterceptor = store => {
  instance.interceptors.response.use(
    response => {
      stopLoaderAfterAPICall(response.config, store);
      setNewAuthorizationHeader(response.headers);
      executeCallBackIfRequestedhasOne(response.config, store);
      return camelcaseKeys.camelCase(response);
    },
    error => {
      stopLoaderAfterAPICall(error.config, store);
      return handleErrors(store, error);
    }
  );

  instance.interceptors.request.use(
    config => {
      startLoaderBeforeAPICall(config, store);
      return setAuthHeadersInInterceptors(config);
    },
    error => {
      stopLoaderAfterAPICall(error.config, store);
      return Promise.reject(error);
    }
  );
};

export const setupBasicInterceptor = store => {
  basicInstance.interceptors.response.use(
    response => {
      stopLoaderAfterAPICall(response.config, store);
      executeCallBackIfRequestedhasOne(response.config, store);
      return Promise.resolve(response);
    },
    error => {
      stopLoaderAfterAPICall(error.config, store);
      return handleErrors(store, error);
    }
  );

  basicInstance.interceptors.request.use(
    config => {
      startLoaderBeforeAPICall(config, store);
      return setAuthHeadersInInterceptors(config);
    },
    error => {
      stopLoaderAfterAPICall(error.config, store);
      return Promise.reject(error);
    }
  );
};

export const setupNonMasqueradingInterceptor = store => {
  nonMasqueradingInstance.interceptors.response.use(
    response => {
      stopLoaderAfterAPICall(response.config, store);
      setNewAuthorizationHeader(response.headers);
      executeCallBackIfRequestedhasOne(response.config, store);
      return camelcaseKeys.camelCase(response);
    },
    error => {
      stopLoaderAfterAPICall(error.config, store);
      return handleErrors(store, error);
    }
  );

  nonMasqueradingInstance.interceptors.request.use(
    config => {
      startLoaderBeforeAPICall(config, store);
      return setNonMasqueradingAuthHeadersInInterceptors(config);
    },
    error => Promise.reject(error)
  );
};

export const setupDefaultAxiosInterceptor = store => {
  defaultAxios.interceptors.response.use(
    response => {
      stopLoaderAfterAPICall(response.config, store);
      executeCallBackIfRequestedhasOne(response.config, store);
      return response;
    },
    error => {
      stopLoaderAfterAPICall(error.config, store);
      return handleErrors(store, error);
    }
  );

  defaultAxios.interceptors.request.use(config => {
    startLoaderBeforeAPICall(config, store);
    return addUniqueIdToRequestIfCallBackIsRequired(config);
  });
};

export default instance;

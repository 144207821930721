import axios from 'axios';

import { AUTH_SAML_CALLBACK } from './constants';

const authSamlCallback = code => {
  const params = {
    code,
  };
  return axios.get(`${AUTH_SAML_CALLBACK}`, { params });
};

export default authSamlCallback;

/*
 *
 * Appointments constants
 *
 */

export const GET_APPOINTMENTS_REQUEST_SENT =
  'app/Appointments/GET_APPOINTMENTS_REQUEST_SENT';
export const GET_APPOINTMENTS_REQUEST_SUCCEED =
  'app/Appointments/GET_APPOINTMENTS_REQUEST_SUCCEED';
export const GET_APPOINTMENTS_REQUEST_FAILED =
  'app/Appointments/GET_APPOINTMENTS_REQUEST_FAILED';

export const GET_EVENT_QUESTIONS_REQUEST_SENT =
  'app/Appointments/GET_EVENT_QUESTIONS_REQUEST_SENT';
export const GET_EVENT_QUESTIONS_REQUEST_SUCCEED =
  'app/Appointments/GET_EVENT_QUESTIONS_REQUEST_SUCCEED';
export const GET_EVENT_QUESTIONS_REQUEST_FAILED =
  'app/Appointments/GET_EVENT_QUESTIONS_REQUEST_FAILED';

export const UPDATE_APPOINTMENT_REQUEST_SENT =
  'app/Appointments/UPDATE_APPOINTMENT_REQUEST_SENT';
export const UPDATE_APPOINTMENT_REQUEST_SUCCEED =
  'app/Appointments/UPDATE_APPOINTMENT_REQUEST_SUCCEED';
export const UPDATE_APPOINTMENT_REQUEST_FAILED =
  'app/Appointments/UPDATE_APPOINTMENT_REQUEST_FAILED';

export const CREATE_APPOINTMENT_REQUEST_SENT =
  'app/Appointments/CREATE_APPOINTMENT_REQUEST_SENT';
export const CREATE_APPOINTMENT_REQUEST_SUCCEED =
  'app/Appointments/CREATE_APPOINTMENT_REQUEST_SUCCEED';
export const CREATE_APPOINTMENT_REQUEST_FAILED =
  'app/Appointments/CREATE_APPOINTMENT_REQUEST_FAILED';

export const DELETE_APPOINTMENT_REQUEST_SENT =
  'app/Appointments/DELETE_APPOINTMENT_REQUEST_SENT';
export const DELETE_APPOINTMENT_REQUEST_SUCCEED =
  'app/Appointments/DELETE_APPOINTMENT_REQUEST_SUCCEED';
export const DELETE_APPOINTMENT_REQUEST_FAILED =
  'app/Appointments/DELETE_APPOINTMENT_REQUEST_FAILED';

export const SKIP_APPOINTMENT_REQUEST_SENT =
  'app/Appointments/SKIP_APPOINTMENT_REQUEST_SENT';
export const SKIP_APPOINTMENT_REQUEST_SUCCEED =
  'app/Appointments/SKIP_APPOINTMENT_REQUEST_SUCCEED';
export const SKIP_APPOINTMENT_REQUEST_FAILED =
  'app/Appointments/SKIP_APPOINTMENT_REQUEST_FAILED';

export const POST_TELEMEDICINECALL_REMINDER_SENT =
  'app/Tasks/POST_TELEMEDICINECALL_REMINDER_SENT';
export const POST_TELEMEDICINECALL_REMINDER_SUCCEED =
  'app/Tasks/POST_TELEMEDICINECALL_REMINDER_SUCCEED';
export const POST_TELEMEDICINECALL_REMINDER_FAILED =
  'app/Tasks/POST_TELEMEDICINECALL_REMINDER_FAILED';

import Strings from '../../strings';
import * as Sentry from '@sentry/react';

export const getChartData = painScores => {
  const values = painScores?.map(el => {
    if (el.value === -1 || el.value === null) {
      return el.survey_status;
    }
    return el.value;
  });
  return values;
};

export const getChartColors = painScores => {
  const colors = painScores?.map(el => {
    if (Object.keys(el).length > 0 && el.hasOwnProperty('color')) {
      return el.color;
    }
    return 'red';
  });
  return colors;
};

export const getXLabels = painScoreData => {
  const labels = painScoreData?.map(el => {
    if (Object.keys(el).length > 0 && el.hasOwnProperty('x_label')) {
      return el.x_label;
    }
    return 'NL';
  });
  return labels;
};

export const appendProcedureDataToBaselineData = (procedureData, baselineData, functionName) => {
  let formattedProcedureData = functionName(procedureData) ?? [];
  let formattedBaselineData = functionName(baselineData) ?? [];
  return formattedBaselineData.concat(formattedProcedureData);
};

export const generateChartLegend = () => {
  return (
    <div className='symbol-legend-outer-wrapper fit-content'>
      <p className='symbol-legend-wrapper'>
        <span className='legend-symbol legend-symbol-due'>
          <span className='legend-symbol-text'>D</span>
        </span>
        <span className='legend-value'>Due Survey</span>
      </p>
      <p className='symbol-legend-wrapper'>
        <span className='legend-symbol legend-symbol-missing'>
          <span className='legend-symbol-text'>M</span>
        </span>
        <span className='legend-value'>Missing survey</span>
      </p>
      <p className='symbol-legend-wrapper'>
        <span className='legend-symbol legend-symbol-upcoming'>
          <span className='legend-symbol-text'>C</span>
        </span>
        <span className='legend-value'>Upcoming survey</span>
      </p>
      <p className='symbol-legend-wrapper'>
        <span className='legend-symbol legend-symbol-no-pain'>
          <span className='legend-symbol-text legend-symbol-no-pain-text'>
            NP
          </span>
        </span>
        <span className='legend-value'>No Pain</span>
      </p>
    </div>
  );
};

export const getSurveyActionText = () => {
  return (
    <p className='survey-action-text'>
      <span>{Strings.SURVEY_ACTION_TEXT}</span>
    </p>
  );
};

export const getDisclaimerText = () => {
  return (
    <p className='fill-survey-disclaimer-text'>
      <span>{Strings.TRIAL_SURVEY_DISCLAIMER}</span>
    </p>
  );
};

export const fallbackUI = () => {
  return (
    <div className='share-report-mobile-fallback-wrapper'>
      <div className='share-report-mobile-fallback-wrapper__error-text'>
        <h4>Error</h4>
        <h4>Something Went Wrong...</h4>
      </div>
    </div>
  );
};

export const logError = e => {
  console.error(JSON.stringify(e));
  Sentry.captureMessage(e, { level: 'debug' });
};

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import PropTypes from 'prop-types';
import { saveAddLocationFormData } from '../action';

const AddLocationStepTwoForm = props => {
  const dispatch = useDispatch();

  const adminData = useSelector(store => store.AdminReducer);
  const locationAdminData = useSelector(store => store.locationAdminReducer);

  const [errors, setErrors] = useState({});
  const [locationData, setLocationData] = useState({
    locationDepartment: null,
    timzone: null,
    locationPi: null,
    removeAsInvestigator: false,
    locationProviders: [],
    locationAdvocates: [],
  });

  useEffect(() => {
    setLocationData({
      ...locationData,
      locationDepartment: locationAdminData.locationDepartment,
      timezone: locationAdminData.timezone,
      locationPi: locationAdminData.locationPi,
      locationProviders: locationAdminData.locationProviders,
      locationAdvocates: locationAdminData.locationAdvocates,
      removeAsInvestigator: locationAdminData.removeAsInvestigator,
    });
  }, [
    locationAdminData.locationDepartment,
    locationAdminData.timezone,
    locationAdminData.locationPi,
    locationAdminData.locationProviders,
    locationAdminData.locationAdvocates,
    locationAdminData.removeAsInvestigator,
  ]);

  const config = [
    {
      id: 1,
      input_type: 'single_select',
      placeholder: 'Select Department',
      label: 'Select Department',
      name: 'locationDepartment',
      value: locationData.locationDepartment,
      options: adminData.departments,
      disabled: !!locationAdminData.selectedLocationId,
      validation: [{ rule: 'isRequired', message: 'Department is required' }],
      visible: true,
      required: true,
    },
    {
      id: 2,
      input_type: 'single_select',
      placeholder: 'Select Timezone',
      label: 'Select TimeZone',
      name: 'timezone',
      value: locationData.timezone,
      options: adminData.timezones,
      validation: [{ rule: 'isRequired', message: 'TimeZone is required' }],
      visible: true,
      disabled: locationAdminData.selectedLocationId,
      required: true,
    },
    {
      id: 3,
      input_type: 'single_select',
      name: 'locationPi',
      label: 'Select PI',
      placeholder: 'Select PI',
      value: locationData.locationPi,
      options: adminData.allProviders,
      disabled: false,
      validation: [{ rule: 'isRequired', message: 'Location PI is required' }],
      visible: true,
      required: true,
      onChangeCallBack: value => {
        if (locationAdminData.locationPi?.value === value?.value)
          return { removeAsInvestigator: false };
      },
    },
    {
      id: 4,
      input_type: 'checkbox',
      name: 'removeAsInvestigator',
      label:
        'Revoke the investigator status of old PI and assign new PI as investigator for the patients?',
      value: locationData.removeAsInvestigator,
      disabled:
        locationAdminData.locationPi?.value === locationData.locationPi?.value,
      default: false,
      visible: locationAdminData.selectedLocationId,
    },
    {
      id: 5,
      input_type: 'multi_select',
      name: 'locationProviders',
      label: 'Select Providers ( optional )',
      placeholder: 'Select Providers',
      value: locationData.locationProviders,
      options: adminData.allProviders,
      visible: true,
    },
    {
      id: 6,
      input_type: 'multi_select',
      name: 'locationAdvocates',
      label: 'Select Advocates ( optional )',
      placeholder: 'Select Advocates',
      options: adminData.advocates,
      value: locationData.locationAdvocates,
      visible: true,
    },
  ];

  const { onContinue, onBack, step } = props;

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      locationData,
      errorsInParams => {
        setErrors(errorsInParams);
      },
      () => {
        dispatch(saveAddLocationFormData(locationData));
        onContinue(step + 1);
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      formConfig={config}
      id='addLocationStepTwoForm'
      errors={errors}
      onChange={(key, value, onChangeCallBack) => {
        clearErrorsForField(errors, key);
        const data = onChangeCallBack ? onChangeCallBack(value) : {};
        setLocationData({ ...locationData, [key]: value, ...data });
      }}
      buttons={[
        <SecondaryButton
          style={{ width: '176px' }}
          onClick={() => {
            dispatch(saveAddLocationFormData(locationData));
            onBack(step);
          }}
          key='secondaryButton'
        >
          <span>Back</span>
        </SecondaryButton>,
        <PrimaryButton
          form='addLocationStepTwoForm'
          type='submit'
          style={{ width: '176px' }}
          onClick={() => {
            onSubmit();
          }}
          key='primaryButton'
        >
          <span>Save</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

AddLocationStepTwoForm.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

export default AddLocationStepTwoForm;

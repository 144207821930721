import { dateStringToCustomDate } from 'utils/dateUtils';
import cloneDeep from 'lodash/cloneDeep';
import {
  decreaseAPICallsInProgressCount,
  increaseAPICallsInProgressCount,
} from 'common/actions';

export const sortData = (dispatch, dataToSort, dateKey, dataKey) => {
  dispatch(increaseAPICallsInProgressCount());
  const data = cloneDeep(dataToSort);
  const dataDates = data.map(row => row[dateKey]);
  const updatedData = {};
  dataDates.forEach(date => {
    data.forEach(rowInParam => {
      const row = rowInParam;
      if (
        dateStringToCustomDate(row[dateKey]) === dateStringToCustomDate(date)
      ) {
        if (!updatedData[dateStringToCustomDate(row[dateKey])]) {
          updatedData[dateStringToCustomDate(row[dateKey])] = [...row[dataKey]];
          row[dataKey] = [];
        } else {
          updatedData[dateStringToCustomDate(row[dateKey])] = [
            ...updatedData[dateStringToCustomDate(row[dateKey])],
            ...row[dataKey],
          ];
          row[dataKey] = [];
        }
      }
    });
  });
  dispatch(decreaseAPICallsInProgressCount());
  return updatedData;
};

export const renderPatientDetailsTab = (history, tabName, patient, tabs) => {
  let hasRedirected = false;
  if (patient.userProcedures && patient.userProcedures.length > 0) {
    tabs.every(tab => {
      if (tab.name === tabName && !tab.disabled) {
        history.push(
          `/patients/${patient?.id}/procedures/${patient?.userProcedures[0]?.id}/tab/${tab.id}`
        );
        hasRedirected = true;
        return false;
      }
      return true;
    });

    if (!hasRedirected) {
      tabs.every(tab => {
        if (!tab.disabled) {
          history.push(
            `/patients/${patient?.id}/procedures/${patient?.userProcedures[0]?.id}/tab/${tab.id}`
          );
          return false;
        }
        return true;
      });
    }
  }
};

export const getEnabledTabs = tabsInProps => {
  return tabsInProps.filter(tab => tab.disabled === false);
};

import { DATE_FORMAT } from 'constants';

export const patientInfoValidationConfig = {
  fields: [
    'firstName',
    'lastName',
    'email',
    'contactNumber',
    'addressLine1',
    'city',
    'state',
    'zipcode',
    'DOB',
  ],
  rules: {
    firstName: [{ rule: 'isRequired', message: 'First Name is required' }],
    lastName: [{ rule: 'isRequired', message: 'Last Name is required' }],
    email: [
      { rule: 'isEmail', message: 'Email Address is invalid' },
      { rule: 'isRequired', message: 'Email Address is required' },
    ],
    contactNumber: [
      { rule: 'isPhone', message: 'Phone Number is invalid' },
      { rule: 'isRequired', message: 'Phone Number is required' },
    ],
    addressLine1: [
      { rule: 'isRequired', message: 'Address Line 1 is required' },
      {
        rule: 'isValidAddressLength',
        message:
          'Address Line 1 is too short. Please enter a valid Address Line 1 with at least 5 characters',
      },
    ],
    city: [{ rule: 'isRequired', message: 'City is required' }],
    state: [{ rule: 'isRequired', message: 'State is required' }],
    zipcode: [
      { rule: 'isRequired', message: 'ZIP Code is required' },
      { rule: 'isZipCode', message: 'Invalid ZIP Code is provided' },
    ],
    DOB: [
      {
        rule: 'isValidDate',
        message: `Invalid date provided. Accepted Format is ${DATE_FORMAT}`,
      },
      { rule: 'isFutureDate', message: `DOB can not be in future` },
    ],
  },
};

export const patientInfoValidationConfigForEditPatient = {
  fields: [...patientInfoValidationConfig.fields, 'cohort'],
  rules: {
    ...patientInfoValidationConfig.rules,
    cohort: [{ rule: 'isRequired', message: 'Cohort is required' }],
  },
};

export const procedureValidationConfig = {
  fields: ['studyNameId', 'locationId', 'masterProviderId', 'counterSigneId'],
  rules: {
    studyNameId: [{ rule: 'isRequired', message: 'Study Name is required' }],
    locationId: [{ rule: 'isRequired', message: 'Study Site is required' }],
    masterProviderId: [
      { rule: 'isRequired', message: 'Invesitgator is required' },
    ],
    counterSigneId: [
      { rule: 'isRequired', message: 'Counter Signee is required' },
    ],
  },
};

/*
 *
 * Notifications actions
 *
 */
import {
  getUserNotifications,
  getUnreadUserNotificationsCount,
  markUnreadUserNotificationsRead,
} from 'services/users';

import {
  GET_NOTIFICATIONS_REQUEST_SENT,
  GET_NOTIFICATIONS_REQUEST_SUCCEED,
  GET_NOTIFICATIONS_REQUEST_FAILED,
  GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SENT,
  GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SUCCEED,
  GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_FAILED,
  MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_SENT,
  MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_SUCCEED,
  MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_FAILED,
} from './constants';

//
// Fetch Notifications
//
export const getNotificationsRequestSent = () => ({
  type: GET_NOTIFICATIONS_REQUEST_SENT,
});

export const getNotificationsRequestSucceeded = data => ({
  type: GET_NOTIFICATIONS_REQUEST_SUCCEED,
  data,
});

export const getNotificationsRequestFailed = () => ({
  type: GET_NOTIFICATIONS_REQUEST_FAILED,
});

// Redux Thunk action:
export const getNotifications =
  (params, isDropdown = false) =>
  dispatch => {
    dispatch(getNotificationsRequestSent());
    getUserNotifications(params, isDropdown)
      .then(response => {
        // Marking notifications as read
        if (response.data.notifications.length > 0) {
          const unreadNotifications = response.data.notifications.filter(
            n => n.readOn == null
          );
          if (unreadNotifications.length > 0) {
            params = { notifications: [] };
            unreadNotifications.forEach(n => {
              params.notifications.push({ notification_id: n.id });
            });
            dispatch(markUnreadNotificationsRead(params));
          }
        }
        dispatch(getNotificationsRequestSucceeded(response.data));
      })
      .catch(() => {
        dispatch(getNotificationsRequestFailed());
      });
  };

//
// Fetch Unread Notifications Count
//
export const getUnreadNotificationsCountRequestSent = () => ({
  type: GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SENT,
});

export const getUnreadNotificationsCountRequestSucceeded = data => ({
  type: GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_SUCCEED,
  data,
});

export const getUnreadNotificationsCountRequestFailed = () => ({
  type: GET_UNREAD_NOTIFICATIONS_COUNT_REQUEST_FAILED,
});

// Redux Thunk action:
export const getUnreadNotificationsCount = () => dispatch => {
  dispatch(getUnreadNotificationsCountRequestSent());
  getUnreadUserNotificationsCount()
    .then(response => {
      dispatch(getUnreadNotificationsCountRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getUnreadNotificationsCountRequestFailed());
    });
};

//
// Mark Unread Notifications as Read
//
export const markUnreadNotificationsReadRequestSent = () => ({
  type: MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_SENT,
});

export const markUnreadNotificationsReadRequestSucceeded = () => ({
  type: MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_SUCCEED,
});

export const markUnreadNotificationsReadRequestFailed = () => ({
  type: MARK_UNREAD_NOTIFICATIONS_READ_REQUEST_FAILED,
});

// Redux Thunk action:
export const markUnreadNotificationsRead = params => dispatch => {
  dispatch(markUnreadNotificationsReadRequestSent());
  markUnreadUserNotificationsRead(params)
    .then(response => {
      dispatch(getUnreadNotificationsCount());
      dispatch(markUnreadNotificationsReadRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(markUnreadNotificationsReadRequestFailed());
    });
};

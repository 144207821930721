import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import DefaultPatientAvatarImage from '../../assets/images/defaultpatient.png';
import DefaultProviderAvatarImage from '../../assets/images/defaultprovider.png';
import DefaultPatientAdvocateAvatarImage from '../../assets/images/defaultpatientadvocate.png';
import './ProfileDropdown.scss';
import {
  USER_TYPE_PATIENT_ADVOCATE,
  USER_TYPE_PROVIDER,
} from '../../constants';
import { getDateDifference } from '../../utils/dateUtils';

const ProfileDropdown = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };
  const userDetails = useSelector(store => store.currentUser.attributes);
  const isPasswordChangeNeeded =
    userDetails !== null && userDetails.needsPasswordChange;
  const currentUserName = `${userDetails.firstName} ${userDetails.lastName}`;
  const pwdExpiryDate = useSelector(
    store => store.currentUser.passwordExpiryInfo.pwdExpiryDate
  );
  const pwdExpiryReminderPeriod = useSelector(
    store => store.currentUser.passwordExpiryInfo.pwdExpiryReminderPeriod
  );
  const daysToExpirePassword = getDateDifference(
    new Date(),
    new Date(pwdExpiryDate),
    'days'
  );
  const showPasswordExpiryWarning =
    daysToExpirePassword <= pwdExpiryReminderPeriod;

  const getAvatarImage = () => {
    if (userDetails.type === USER_TYPE_PATIENT_ADVOCATE)
      return DefaultPatientAdvocateAvatarImage;
    if (userDetails.type === USER_TYPE_PROVIDER)
      return DefaultProviderAvatarImage;
    return DefaultPatientAvatarImage;
  };

  const avatarImage = getAvatarImage();

  const profilePhoto = userDetails.profilePhotoUrl || avatarImage;

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        className='profile-drop-down'
      >
        <IconButton
          sx={{ padding: '0' }}
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          disableRipple
        >
          <div className='profile-icon'>
            {showPasswordExpiryWarning ? (
              <span className='password-expiry-red-dot' />
            ) : (
              ''
            )}
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={profilePhoto} id='profile-icon-image' />
            <div id='profile-icon-username'>{currentUserName}</div>
          </div>
        </IconButton>
      </Box>
      <Menu
        className='profile-dropdown-menu'
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            width: '380px',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              paddingBottom: 0,
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        sx={{
          paddingBottom: 0,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {!isPasswordChangeNeeded ? (
          <MenuItem className='user-info-container'>
            <div>
              <img
                src={profilePhoto}
                alt={currentUserName}
                className='user-image'
              />
              <TertiaryButton onClick={() => history.replace('/profile')}>
                <span>Edit Profile</span>
              </TertiaryButton>
            </div>
            <div className='user-information'>
              <span className='user-name'>{currentUserName}</span>
              <span className='user-email'>{userDetails.email}</span>
              <span className='user-type'>
                {userDetails.type === 'Provider'
                  ? 'Site Team'
                  : 'Representative'}
              </span>
            </div>
          </MenuItem>
        ) : (
          ''
        )}

        <div className='change-password-and-logout'>
          {!isPasswordChangeNeeded ? (
            <MenuItem
              className={`change-password ${
                showPasswordExpiryWarning ? 'expiry-warning' : ''
              }`}
              onClick={() => history.replace('/change-password')}
            >
              <span style={{ lineHeight: '14px' }}>Change Password</span>
              {showPasswordExpiryWarning ? (
                <p className='password-expiry-string'>
                  {daysToExpirePassword > 0
                    ? `Your password will expire in the next ${daysToExpirePassword} day${
                        daysToExpirePassword > 1 ? 's' : ''
                      }`
                    : 'Your password will expire today'}
                </p>
              ) : (
                ''
              )}
            </MenuItem>
          ) : (
            ''
          )}

          <MenuItem
            onClick={() => history.replace('/logout')}
            className='logout'
          >
            <span>Log Out</span>
          </MenuItem>
        </div>
      </Menu>
    </>
  );
};

export default ProfileDropdown;

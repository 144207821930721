import React from 'react';
import './empty_view.scss';

const EmptyView = props => {
  return (
    <div className='empty-container'>
      <div className='img'>
        {props.imgSrc && props.imgSrc !== '' ? (
          <img src={props.imgSrc} alt='' />
        ) : (
          <span className={`icon ${props.className}`} />
        )}
      </div>
      <div className='text'>{props.message}</div>
    </div>
  );
};

export default EmptyView;

/**
 * Validates provided react state with given configuration.
 *
 *
 * @param {object} config
 *   Validation configuration settings.
 * @param {object} state
 *   State to run validation against.
 * @param {function} failureCallback
 *   Callback function on validation errors.
 * @param {function} successCallback
 *   Callback function on successful validation.
 *
 * @returns {(undefined|Object)} Success callback if called without params, error callback is called with errors object
 *
 *
 * Supported Rules:
 *    isRequired:
 *        value: String, Number, Array, null, undefined
 *        message: String
 *    isRequiredWith:
 *        value: String, Array, null, undefined
 *        withKey: String
 *        withValue: String, Array
 *        message: String
 *    shouldExcludeChars:
 *        value: String, Array
 *        chars: Array
 *        message: String
 *    isPhone:
 *        value: String
 *        message: String
 *    isEmail:
 *        value: String
 *        message: String
 *    requiredArrayLength:
 *        value: Array
 *        min: Integer [OPTIONAL]
 *        max: Integer [OPTIONAL]
 *        message: String
 *    isDependentRequired:
 *        value: Array
 *        dependOnKey: String
 *        message: String
 *    requiredDifferentValue:
 *        firstFieldValue: String
 *        secondFieldValue: String
 *        message: String
 *    isDependentRequiredWith:
 *        value: String, Array, Boolean, null, undefined
 *        withValue: String, Array, Boolean, null, undefined
 *        dependOnKey: String
 *        message: String
 *

  Configuration Example:

  var config = {
    fields: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j'],
    rules: {
      a: [
        {rule: 'isRequired', message: 'A is required'},
      ],
      b: [
        {rule: 'isRequiredWith', withKey: 'a', withValue: 'Value1', message: 'B is required when a==Value1'},
      ],
      c: [
        {rule: 'isRequiredWith', withKey: 'a', withValue: ['Value1', 'Value5'], message: 'B is required when a==Value1 or a==Value5'},
      ],
      d: [
        {rule: 'shouldExcludeChars', chars: ['%', '#'], message: 'D cannot have "%, #" characters'},
      ],
      e: [
        {rule: 'isPhone', message: 'E is supposed to be phone number, its invalid'},
      ],
      f: [
        {rule: 'requiredArrayLength', min: 10, message: 'Minimum 10 Fs are required'},
      ],
      g: [
        {rule: 'isEmail', message: 'Email is invalid'},
      ],
      h: [
        {rule: 'isDependentRequired', dependOnKey: 'procedure_time', message: 'Date is required'},
      ],
      i: [
        {rule: 'hasDifferentValueWith', fieldsName: ['Field1', 'Field2'], message: "Field1 should be different from Field2"}
      ],
      j: [
        {rule: 'isDependentRequiredWith', dependOnKey: 'a', withValue: 'abc', message: 'J is required when a===abc'}
      ],
    }
  }
 *
 */

import { DATE_FORMAT } from 'constants';
import moment from 'moment';

const validate = (config, state, failureCallback, successCallback) => {
  const errors = {};

  // Loop on all fields from the configuration
  config.fields.forEach(field => {
    // Loop on all rules of the field
    config.rules[field].forEach(ruleConf => {
      let value = null;
      let keyValue = null;
      let minLen = 0;
      let maxLen = 10000;
      let firstFieldValue = null;
      let secondFieldValue = null;

      switch (ruleConf.rule) {
        // Validate Required Case
        case 'isRequired':
          value = state[field];
          if (testRequired(value)) {
            errors[field] = ruleConf.message;
          }
          break;

        case 'isValidDate':
          value = state[field];
          const dateVal = moment(value, DATE_FORMAT, true);
          if (value && !dateVal.isValid()) {
            errors[field] = ruleConf.message;
          }
          break;

        case 'isFutureDate':
          value = state[field];
          const date = moment(value, DATE_FORMAT, true);
          const today = moment();
          if (value && date.isValid() && !date.isBefore(today)) {
            errors[field] = ruleConf.message;
          }
          break;
        // Validate Required case with other value from state
        // {rule: 'isRequiredWith', withKey: 'a', withValue: ['Value1', 'Value5'], message: 'B is required when a==Value1 or a==Value5'},
        case 'isRequiredWith':
          keyValue = state[ruleConf.withKey].value;
          value = state[field];

          if (
            ruleConf.withValue.constructor === Array &&
            ruleConf.withValue.includes(keyValue)
          ) {
            if (testRequired(value)) {
              errors[field] = ruleConf.message;
            }
          } else if (keyValue === ruleConf.withValue) {
            if (testRequired(value)) {
              errors[field] = ruleConf.message;
            }
          }
          break;

        // Validate Required dependent on other fields value.
        case 'isDependentRequired':
          keyValue = state[ruleConf.dependOnKey];
          value = state[field];

          if (!testRequired(keyValue) && testRequired(value)) {
            errors[field] = ruleConf.message;
          }
          break;

        // Validate Required dependent on other field with specific value.
        case 'isDependentRequiredWith':
          keyValue = state[ruleConf.dependOnKey];
          value = state[field];

          if (keyValue === ruleConf.withValue) {
            if (testRequired(value)) {
              errors[field] = ruleConf.message;
            }
          }
          break;

        // Validate that given characters should not be present
        case 'shouldExcludeChars':
          value = state[field];
          ruleConf.chars.forEach(excldChar => {
            if (value.includes(excldChar)) {
              errors[field] = ruleConf.message;
            }
          });
          break;

        // Validate email
        case 'isEmail':
          value = state[field];
          if (
            value != null &&
            value !== '' &&
            !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
          ) {
            errors[field] = ruleConf.message;
          }
          break;

        // Validate password
        case 'isPassword':
          value = state[field];
          if (
            value !== '' &&
            !value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.{10,}).+$/)
          ) {
            errors[field] = ruleConf.message;
          }
          break;

        // Validate phone number
        case 'isPhone':
          value = state[field] !== null ? state[field].trim() : null;
          if (value !== '' && value !== null) {
            if (value.includes('+1')) {
              if (
                !value.match(
                  /^(\+1\s|1|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/
                )
              ) {
                errors[field] = ruleConf.message;
              }
            } else if (value.includes('+44')) {
              if (
                !value.match(
                  /^(\+44\s|1|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/
                )
              ) {
                errors[field] = ruleConf.message;
              }
            } else {
              value = value.replace(/[^a-zA-z0-9]/g, '');
              if (value.length !== 10) {
                errors[field] = ruleConf.message;
              }
            }
          }
          break;

        case 'isAuPhone':
          value = state[field].trim();
          if (value !== '') {
            if (value.includes('+61')) {
              if (
                !value.match(
                  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
                )
              ) {
                errors[field] = ruleConf.message;
              }
            } else {
              value = value.replace(/[^a-zA-z0-9]/g, '');
              if (value.length !== 10) {
                errors[field] = ruleConf.message;
              }
            }
          }
          break;

        // Validate if value is equal to other fields value
        case 'isEqualToKey':
          keyValue = state[ruleConf.key];
          value = state[field];

          if (keyValue !== value) {
            errors[field] = ruleConf.message;
          }
          break;

        case 'isEmailOrMobileNumber':
          value = state[field].trim();
          if (
            value !== '' &&
            !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
          ) {
            if (value.length !== 10 || !/\d{10}$/.test(value)) {
              errors[field] = ruleConf.message;
            }
          }
          break;

        // Validate array length;
        // defaults
        //    min: 0,
        //    max: 10000
        case 'requiredArrayLength':
          value = state[field];

          minLen = ruleConf.min || 0;
          maxLen = ruleConf.max || 10000;
          if (value.length < minLen || value.length > maxLen) {
            errors[field] = ruleConf.message;
          }
          break;

        // Validate checkbox
        case 'isCheckboxRequired':
          if (!state[field]) {
            errors[field] = ruleConf.message;
          }
          break;

        case 'isVerified':
          value = state[field];
          if (value !== true) {
            errors[field] = ruleConf.message;
          }
          break;
        case 'isZipCode':
          value = state[field];
          if (value !== '' && !value.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/)) {
            errors[field] = ruleConf.message;
          }
          break;

        // Validate one value check case with other value from state
        // {rule: 'requiredDifferentValue', fieldsName: ['Field1', 'Field2'], message: "Field1 should be different from Field2"},
        case 'requiredDifferentValue':
          firstFieldValue = state[ruleConf.fieldsName[0]];
          secondFieldValue = state[ruleConf.fieldsName[1]];

          if (
            firstFieldValue !== '' &&
            secondFieldValue !== '' &&
            firstFieldValue === secondFieldValue
          ) {
            errors[field] = ruleConf.message;
          }
          break;
        case 'isValidAddressLength':
          value = state[field];
          if (value.length > 0 && value.length < 5) {
            errors[field] = ruleConf.message;
          }
          break;
        case 'isValidURL':
          value = state[field];
          if (
            value !== '' &&
            !value.match(
              /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
            )
          ) {
            errors[field] = ruleConf.message;
          }
          break;

        case 'isIntegerOnly':
          value = state[field];

          if (!Number.isInteger(parseFloat(value)))
            errors[field] = ruleConf.message;
        default:
          break;
      }
    });
  });

  if (Object.keys(errors).length > 0) {
    failureCallback(errors);
  } else {
    successCallback();
  }
};

export const clearErrorsForField = (errors, field) => {
  if (errors[field] !== undefined) {
    delete errors[field];
  }

  return errors;
};

// Private functions
const testRequired = value => {
  if (value == null || value === undefined) {
    return true;
  }
  if (value.constructor === String) {
    return value.trim() === '';
  }
  if (value.constructor === Number) {
    return value === 0 || value === -1;
  }
  if (value.constructor === Array) {
    return value.length === 0;
  }
  if (value.constructor === Object) return value.value === null;
  return false;
};

export default validate;

import DataTable from 'components/StyleGuideComponents/DataTable';
import { useDispatch } from 'react-redux';
import React from 'react';
import { downloadTemplateOfConsentRequest } from '../action';

const ConsentData = props => {
  const dispatch = useDispatch();

  const getDataToShow = data => {
    const dataToShow = data.map(consent => {
      const newObj = { ...consent };
      newObj.location = newObj.location.label;
      newObj.task = newObj.task;
      newObj.procedure = newObj.procedure.label;
      if (consent.template) {
        newObj.template = {
          onClick: () => {
            dispatch(
              downloadTemplateOfConsentRequest({ identifier: consent.template })
            );
          },
          value: 'Download Template',
        };
      } else {
        newObj.template = 'NA';
      }

      return newObj;
    });
    return dataToShow;
  };

  return (
    <DataTable
      tableHeaders={props.data.consentsHeaders}
      tableData={getDataToShow(props.data.consentsData)}
      onRowClick={id => props.updateSelectedConsentId(id)}
      tableDataCellStyle={{ textAlign: 'center' }}
      tableHeaderCellStyle={{ textAlign: 'center' }}
      title='Consent'
    />
  );
};

export default ConsentData;

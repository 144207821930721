/** \n * @desc This file contains various button components with different styles and themes.
 * @author Dilip Joshi
 * The following exportable components are:
 *
 * 1. PrimaryButton: A primary button with a regular size.
 * 2. PrimaryButtonLarge: A primary button with a larger size.
 * 3. SecondaryButton: A secondary button with a regular size.
 * 4. SecondaryButtonLarge: A secondary button with a large size.
 * 5. SecondaryAlphaButton: A secondary alpha button with a regular size.
 * 6. TertiaryButton: A tertiary button with a regular size.
 * 7. TertiaryAlphaButton: A tertiary alpha button with a regular size.
 * 8. AlertButton: An alert button with a regular size.
 * 9. DefaultButton: A default button with a regular size.
 * 10. DangerButton: A danger button with a regular size.
 *
 * @requires React
 * @requires Button component from 'components/StyleGuideComponents/Button'
 *
 * @example
 * import {
 *  PrimaryButton,
 *  SecondaryButton,
 *  AlertButton,
 *  DangerButton
 * } from 'components/ButtonComponents'
 *
 * const MyComponent = () => {
 *  return (
 *    <>
 *      <PrimaryButton onClick={() => console.log('clicked')}>Primary button</PrimaryButton>
 *      <SecondaryButton>Secondary button</SecondaryButton>
 *      <AlertButton disabled>Alert button</AlertButton>
 *      <DangerButton>Button with danger theme</DangerButton>
 *    </>
 *  )
 * }
 */

import React from 'react';
import Button from 'components/StyleGuideComponents/Button';

export const PrimaryButton = props => <Button category='PRIMARY' {...props} />;
export const PrimaryButtonLarge = props => (
  <Button category='PRIMARY_LARGE' {...props} />
);

export const SecondaryButton = props => (
  <Button category='SECONDARY' {...props} />
);
export const SecondaryButtonLarge = props => (
  <Button category='SECONDARY_LARGE' {...props} />
);

export const SecondaryAlphaButton = props => (
  <Button category='SECONDARY_ALPHA' {...props} />
);

export const TertiaryButton = props => (
  <Button textButton category='TERTIARY' {...props} />
);
export const TertiaryAlphaButton = props => (
  <Button textButton category='TERTIARY_ALPHA' {...props} />
);

export const AlertButton = props => <Button category='ALERT' {...props} />;

export const DefaultButton = props => <Button category='DEFAULT' {...props} />;
export const DangerButton = props => <Button category='DANGER' {...props} />;

import React from 'react';
import './radioButton.scss';
import { PropTypes } from 'prop-types';

const RadioButton = props => {
  const { disabled, value, onToggle } = props;

  return (
    <label className={`switch ${disabled ? 'disabled' : ''}`}>
      <input
        type='checkbox'
        checked={value}
        onChange={e => onToggle(e.target.checked)}
        disabled={disabled}
      />
      <span className={`slider round ${disabled ? 'disabled' : ''}`} />
    </label>
  );
};

RadioButton.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  disabled: false,
};

export default RadioButton;

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import merge from 'lodash/merge';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './styles.scss';
import "../../../assets/stylesheets/_mixins.scss";
import { SURVEY_STATUS } from 'enums';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const surveyDueColor = '#FFF4CC';
const surveyMissedColor = '#FFE1E1';
const surveyUpComingColor = '#D5F5E8';
const surveyNoPainColor = '#007946';

const commonOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      grid: {
        display: true,
      },
      border: {
        display: false,
      },
      beginAtZero: true,
      min: 0,
      max: 10,
      ticks: {
        stepSize: 2,
        padding: 6,
        autoSkip: false,
        font: {
          size: 10,
        },
      }
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      align: 'end',
      anchor: 'end',
      offset: 2,
      font: {
        size: 8,
      },
      color: context => {
        const value = context.dataset.data[context.dataIndex];
        if (value < 0) {
          return '#000';
        } 
        else if(value === 0) {
          return '#FFF';
        } 
        else {
          return '';
        }
      },
      formatter: value => {
        if (value == null) {
          return 'N/A';
        } else if (value === SURVEY_STATUS.FUTURE) {
          return 'C';
        } else if (value === SURVEY_STATUS.DUE) {
          return 'D';
        } else if (value === SURVEY_STATUS.EXPIRED) {
          return 'M';
        } else if (value === 0) {
          return 'NP';
        } else {
          return ''; // Return an empty string to hide the label
        }
      },
      backgroundColor: context => {
        const value = context.dataset.data[context.dataIndex];
        if (value === SURVEY_STATUS.FUTURE) {
          return surveyUpComingColor;
        } else if (value === SURVEY_STATUS.DUE) {
          return surveyDueColor;
        } else if (value === SURVEY_STATUS.EXPIRED) {
          return surveyMissedColor;
        } else if (value === 0) {
          return surveyNoPainColor;
        } else {
          return ''; // Return an empty string to hide the label
        }
      },
      borderRadius: 50,
      padding: 6,
    },
  },
  animation: {
    duration: 0,
  },
};

function BarChart(props) {
  const data = props.data;
  const includeCommonOptions = props.includeCommonOptions ?? true;
  const optionProps = props.options ?? {};
  const options = includeCommonOptions
    ? merge(commonOptions, optionProps)
    : { ...optionProps };
  const title = props.chartTitle;
  const generateChartLegend = props?.generateChartLegend;
  const actionText= props?.getActionText ?? null;
  
  return (
    <>
      {
        title && (<p className={`title-font-regular chart-title ${props.titleClassNames ?? ''}`}>{title}</p>)
      }
      {
        generateChartLegend && generateChartLegend()
      }
      {actionText && actionText}
      <div className='chart-wrapper'>
        <Bar options={options} data={data} />
      </div>
    </>
  );
}

export default BarChart;

export const SecureCodes = {
  CLEAR_SURVEY: 'CLEAR_SURVEY',
  CREATE_APPOINTMENT: 'CREATE_APPOINTMENT',
  CREATE_CONVERSATION: 'CREATE_CONVERSATION',
  CREATE_DIARY: 'CREATE_DIARY',
  CREATE_NOTE: 'CREATE_NOTE',
  CREATE_PATIENT: 'CREATE_PATIENT',
  DATA_DOWNLOAD_REQUEST_SUBMIT: 'DATA_DOWNLOAD_REQUEST_SUBMIT',
  DELETE_APPOINTMENT: 'DELETE_APPOINTMENT',
  DELETE_PATIENT: 'DELETE_PATIENT',
  EDIT_APPOINTMENT: 'EDIT_APPOINTMENT',
  EDIT_APPOINTMENT_NOTE: 'EDIT_APPOINTMENT_NOTE',
  EDIT_PATIENT: 'EDIT_PATIENT',
  MARK_TASK_AS_COMPLETE: 'MARK_TASK_AS_COMPLETE',
  MARK_TASK_AS_INCOMPLETE: 'MARK_TASK_AS_INCOMPLETE',
  NOTE_ADD_COMMENT: 'NOTE_ADD_COMMENT',
  REMIND_TASK: 'REMIND_TASK',
  RESEND_CONSENT: 'RESEND_CONSENT',
  RESEND_INVITE: 'RESEND_INVITE',
  RESOLVE_CONVERSATION: 'RESOLVE_CONVERSATION',
  SEND_CONSENT: 'SEND_CONSENT',
  SEND_MESSAGE: 'SEND_MESSAGE',
  STAGE_TRANSITION: 'STAGE_TRANSITION',
  SURVEY_SUBMIT: 'SURVEY_SUBMIT',
  UPDATE_NEEDS_ATTENTION: 'UPDATE_NEEDS_ATTENTION',
  UPLOAD_PAPER_CONSENT: 'UPLOAD_PAPER_CONSENT',
  DELETE_PROFILE_IMAGE: 'DELETE_PROFILE_IMAGE',
  UPDATE_PROFILE_IMAGE: 'UPDATE_PROFILE_IMAGE',
  REVOKE_ACCOUNT_DELETION: 'CANCEL_DELETE_ACCOUNT',
  EDIT_DIARY: 'EDIT_DIARY',
  ADD_USER: 'ADD_USER',
  EDIT_USER: 'EDIT_USER',
  ACTIVATE_USER: 'ACTIVATE_USER',
  DEACTIVATE_USER: 'DEACTIVATE_USER',
  ADD_LOCATION: 'ADD_LOCATION',
  EDIT_LOCATION: 'EDIT_LOCATION',
  ADD_CONSENT: 'ADD_CONSENT',
  EDIT_CONSENT: 'EDIT_CONSENT',
  ADD_RESOURCE: 'ADD_RESOURCE',
  EDIT_RESOURCE: 'EDIT_RESOURCE',
};

export const SecureActionFlowType = {
  [SecureCodes.CREATE_CONVERSATION]: 'create_conversation',
  [SecureCodes.SEND_MESSAGE]: 'send_message',
  [SecureCodes.CREATE_NOTE]: 'create_note',
  [SecureCodes.NOTE_ADD_COMMENT]: 'note_add_comment',
  [SecureCodes.UPDATE_NEEDS_ATTENTION]: 'update_needs_attention',
  [SecureCodes.RESOLVE_CONVERSATION]: 'resolve_conversation',
  [SecureCodes.REMIND_TASK]: 'remind_task',
};

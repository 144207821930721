import React from 'react';
import Select from './SingleSelect';
import SingleSelectNoBorder from './SingleSelectNoBorder';
import MultiSelectComponent from './MultiSelect';
import CustomizedMultiSelect from './CustomizedMultiSelect';
import MultiSelectPill from './MultiSelectPill';
import SingleSelect from './SingleSelect';
import './style.scss';

export const SingleSelectWithoutBorder = SingleSelectNoBorder;

export const MultiSelect = MultiSelectComponent;

export const CustomMultiSelect = CustomizedMultiSelect;

export const MultiSelectForPill = MultiSelectPill;

export const CustomizedSingleSelect = props => <SingleSelect {...props} />;

export default Select;

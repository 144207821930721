import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddEditNotificationModal from './Components/AddEditNotificationModal';
import {
  updateNotificationAdminValue,
  resetAddNotificationData,
} from './action';

const NotificationsAdmin = props => {
  const dispatch = useDispatch();
  const show = useSelector(store => store.notificationAdminReducer.show);

  const [selectedNotificationId, setSelectedNotificationId] = useState(null);

  return (
    <AddEditNotificationModal
      show={show}
      onHide={() => {
        dispatch(updateNotificationAdminValue('show', false));
        dispatch(resetAddNotificationData());
      }}
      updateSelectedNotificationId={id => setSelectedNotificationId(id)}
    />
  );
};

export default NotificationsAdmin;

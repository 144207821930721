import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import PhoneInput from 'components/PhoneInput';
import validate, { clearErrorsForField } from 'common/validator';
import HelpBlock from 'components/HelpBlock';
import { onShareOutcomesReport } from 'common/shareOutcomesReportActions';
import startCase from 'lodash/startCase';
import validationConfig from './validator';
import {
  PrimaryButton,
  PrimaryButtonLarge,
} from '../StyleGuideComponents/common/buttons';
import SecondaryButton from '../StyleGuideComponents/SecondaryButton';
import { PrimaryModal } from '../StyleGuideComponents/common/modals';

class ShareOutcomesReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleContentView: 0,
      outcomesReportType: '',
      errors: {},
      shareWithUser: {
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        message: '',
      },
    };
  }

  resetModalStates = () => {
    this.setState({
      visibleContentView: 0,
      outcomesReportType: '',
      errors: {},
      shareWithUser: {
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        message: '',
      },
    });
  };

  onNavigateOutcomeSharing = (step, outcomesReportType) => {
    this.setState({
      visibleContentView: step,
      outcomesReportType,
    });
  };

  changeNameFieldHandler = e => {
    const obj = this.state.shareWithUser;
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState({ shareWithUser: obj });
  };

  changeEmailFieldHandler = e => {
    const obj = this.state.shareWithUser;
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, 'email');
    obj.hasDisclaimerError = false;
    this.setState({ shareWithUser: obj });
  };

  changeContactNumberHandler = e => {
    const obj = this.state.shareWithUser;
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, 'contactNumber');
    obj.hasDisclaimerError = false;
    this.setState({ shareWithUser: obj });
  };

  onMessageChange = e => {
    const obj = this.state.shareWithUser;
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, 'message');
    this.setState({ shareWithUser: obj });
  };

  onshareOutcomesReportSubmit = e => {
    if (this.props.country === 'AU') {
      delete validationConfig.rules.contactNumber;
      validationConfig.rules.contactNumber = [
        { rule: 'isAuPhone', message: 'Contact number is invalid' },
      ];
    }
    validate(
      validationConfig,
      this.state.shareWithUser,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  onFormValidationFailure = errors => {
    this.setState({ errors });
  };

  onFormValidationSuccess = () => {
    const { firstName, lastName, email, contactNumber, message } =
      this.state.shareWithUser;

    if (email === '' && contactNumber === '') {
      this.setState({
        hasDisclaimerError: true,
      });
    } else {
      this.setState({ errors: {}, hasDisclaimerError: false });
      const data = {
        user_procedure_id: this.props.procedureId || '',
        outcome_procedure_group_id: this.props.outcomeProcedureGroupId || '',
        performer_id: this.props.selectedPerformerId || null,
        chart_type: this.props.chartType || null,
        outcomes_report_type: this.state.outcomesReportType || 'all_charts',
        aggregation_type: this.props.aggregationType,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email,
        contact_number: contactNumber,
        message,
      };

      this.props.onShareOutcomesReport(data);
      this.resetModalStates();
    }
  };

  onShareOutcomeModalClose = () => {
    this.resetModalStates();
    this.props.onModalClose();
  };

  noShareOutcomesModalBody = step => {
    if (step === 0) {
      return (
        <div className='navigate-button-container'>
          <PrimaryButton
            onClick={() =>
              this.onNavigateOutcomeSharing(1, 'executive_summary')
            }
          >
            Share Executive Summary PDF
          </PrimaryButton>
          <div className='button-explanation'>
            We suggest sharing the executive summary.
          </div>
          <div className='button-explanation text-center'>
            Note: Any 'summary comments' will not be included in the shared
            version.
          </div>
          <div className='button-separator'>or</div>
          <PrimaryButton
            onClick={() => this.onNavigateOutcomeSharing(1, 'all_charts')}
          >
            Share All Tables and Charts
          </PrimaryButton>
        </div>
      );
    }
    if (step === 1) {
      return this.props.fromDepartmentSurveys ? (
        this.props.selectedPerformerName !== '' ? (
          <span className='modal-info text-block'>
            Please specify the recipient of
            {this.props.chartType} Survey PDF for
            {this.props.outcomeProcedureGroupName} Dr.
            {this.props.selectedPerformerName}
          </span>
        ) : (
          <span className='modal-info text-block'>
            Please specify the recipient of
            {this.props.chartType} Survey PDF for
            {this.props.outcomeProcedureGroupName}
          </span>
        )
      ) : (
        <span className='modal-info text-block'>
          Please specify the recipient of
          {this.props.patient.firstName} {this.props.patient.lastName}
          's {startCase(this.state.outcomesReportType)} PDF
        </span>
      );
    }
  };

  noShareOutcomesModalForm = () => {
    return (
      <form id='no-share-outcomes-form'>
        <div
          className={`form-group ${
            this.state.errors.firstName !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='firstName'>First Name</label>
          <input
            type='text'
            className='form-control'
            value={this.state.shareWithUser.firstName}
            placeholder='First Name'
            onChange={this.changeNameFieldHandler}
            name='firstName'
          />
          <HelpBlock value={this.state.errors.firstName} />
        </div>
        <div
          className={`form-group ${
            this.state.errors.lastName !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='lastName'>Last Name</label>
          <input
            type='text'
            className='form-control'
            value={this.state.shareWithUser.lastName}
            placeholder='Last Name'
            onChange={this.changeNameFieldHandler}
            name='lastName'
          />
          <HelpBlock value={this.state.errors.lastName} />
        </div>
        <div
          className={`email-contactnumber-disclaimer ${
            this.state.hasDisclaimerError ? 'has-error' : ''
          }`}
        >
          Note: Specify email or mobile phone number
        </div>
        <div
          className={`form-group  ${
            this.state.errors.email !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='email'>Email</label>
          <input
            type='text'
            className='form-control'
            value={this.state.shareWithUser.email}
            placeholder='Email'
            onChange={this.changeEmailFieldHandler}
            name='email'
          />
        </div>
        <div
          className={`form-group ${
            this.state.errors.contactNumber !== undefined ? 'has-error' : ''
          }`}
        >
          <label htmlFor='phone'>Mobile Phone Number</label>
          <PhoneInput
            className='form-control'
            id='phone'
            name='contactNumber'
            placeholder={
              this.props.country === 'AU'
                ? '+61 02 3456 7890'
                : '+1 123 456 7890'
            }
            value={this.state.shareWithUser.contactNumber}
            onChange={this.changeContactNumberHandler}
            country={this.props.country}
          />
        </div>

        <div className='form-group'>
          <label htmlFor='phone'>Message to Recipient (optional)</label>
          <textarea
            type='text'
            name='message'
            className='form-control'
            value={this.state.shareWithUser.message}
            placeholder='Let the recipient know why you are sharing this report with them'
            onChange={this.onMessageChange}
          />
        </div>
      </form>
    );
  };

  render() {
    const { show, showExecutiveSummary, patientFirstName } = this.props;
    const step = showExecutiveSummary ? this.state.visibleContentView : 1;

    return (
      <PrimaryModal
        show={show}
        onHide={this.onShareOutcomeModalClose}
        bodyHeader={{
          text: this.props.patient
            ? `Share ${this.props.patient.firstName}'s Outcomes Report`
            : 'Share Outcomes Report',
        }}
        bodyContent={{
          text: this.noShareOutcomesModalBody(),
        }}
        form={{
          form: this.noShareOutcomesModalForm(),
        }}
        buttons={[
          showExecutiveSummary && (
            <SecondaryButton onClick={() => this.resetModalStates()}>
              Back
            </SecondaryButton>
          ),
          <PrimaryButton
            form='no-share-outcomes-form'
            onClick={() => this.onshareOutcomesReportSubmit()}
          >
            Share Outcomes Report
          </PrimaryButton>,
        ]}
      />
    );
  }
}

ShareOutcomesReportModal.propTypes = {
  patient: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  showExecutiveSummary: PropTypes.bool,
  aggregationType: PropTypes.string,
  procedureId: PropTypes.number,
  outcomeProcedureGroupId: PropTypes.number,
  outcomeProcedureGroupName: PropTypes.string,
  selectedPerformerId: PropTypes.number,
  selectedPerformerName: PropTypes.string,
  chartType: PropTypes.string,
  hasError: PropTypes.bool,
  isRequestInProgress: PropTypes.bool,
  onShareOutcomesReport: PropTypes.func,
  country: PropTypes.string,
};

const mapStateToProps = state => ({
  hasError: state.shareOutcomesReport.hasError,
  isRequestInProgress: state.shareOutcomesReport.isRequestInProgress,
  country: state.currentUser.attributes.country,
});

const mapDispatchToProps = dispatch => ({
  onShareOutcomesReport: params => dispatch(onShareOutcomesReport(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareOutcomesReportModal);

import React from 'react';
import { PrimaryModal } from 'components/StyleGuideComponents/common/modals';
import { dateStringToCustomDateTime } from 'utils/dateUtils';

const SharedOutcomesHistoryModal = props => {
  return (
    <PrimaryModal
      show={props.show}
      onHide={props.onModalClose}
      bodyHeader={{
        text: 'Surveys Sharing History',
      }}
      bodyContent={{
        text: (
          <div className='outcomes-sharing-modal'>
            {props.sharedOutcomesReportsHistory.length > 0 ? (
              props.sharedOutcomesReportsHistory.map(item => (
                <div className='history-item' key={item.id}>
                  <span className='person'>
                    {item.firstName} {item.lastName}
                  </span>
                  <span className='text-small text-dark-gray'>
                    {item.email}
                  </span>
                  {item.email && item.contactNumber ? (
                    <span className='vertical-pipe'>|</span>
                  ) : (
                    ''
                  )}
                  <span className='text-small text-dark-gray'>
                    {item.contactNumber}
                  </span>

                  <p className='outcomes-report-message'>{item.message}</p>

                  <span className='text-small text-dark-gray text-block outcomes-report-type'>
                    {item.outcomeProcedureGroup
                      ? item.performer
                        ? `${item.outcomeProcedureGroup} > ${item.chartType} > Dr. ${item.performer} Report PDF`
                        : `${item.outcomeProcedureGroup} > ${item.chartType} > Report PDF`
                      : item.outcomesReportType === 'executive_summary'
                      ? `Executive Summary PDF`
                      : `Report PDF`}
                  </span>
                  <span className='text-small text-blue shared-by'>
                    Shared by {item.sharedBy}
                  </span>
                  <span className='text-small text-gray'>
                    {dateStringToCustomDateTime(item.createdAt)}
                  </span>
                </div>
              ))
            ) : (
              <span className='text-small text-center text-dark-gray text-block'>
                Nothing to show
              </span>
            )}
          </div>
        ),
      }}
    />
  );
};

export default SharedOutcomesHistoryModal;

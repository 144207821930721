import {
  FETCH_TASKS_REMINDER_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
  SAVE_ADD_TASK_REMINDER_FORM_DATA,
  UPDATE_CREATE_TASK_REMINDER_ADMIN_VALUE_IN_STORE,
  SET_SELECTED_TASK_REMINDER_ID,
  RESET_ADD_TASK_REMINDER_DATA,
} from './constant';

const paginationIntialData = {
  total: 0,
  page: 1,
  total_pages: 1,
  last_page: false,
  first_page: true,
  prev_page: null,
  next_page: 2,
  start_number: 1,
  end_number: 10,
};

const taskReminderAdminFormData = {
  page: 0,
  filterName: '',
  title: '',
  newReminderText: '',
  body: '',
  department: null,
  procedure: null,
  offsetInDays: 0,
  timings: null,
  reminderType: null,
  type: null,
  relatedTaskIdentifier: null,
  uniqueIdentifier: '',
  showAddTaskReminderInfoModal: false,
};

const taskReminderAdminInitialData = {
  tasksReminderData: [],
  tasksReminderHeaders: [],
  pagination: paginationIntialData,
  ...taskReminderAdminFormData,
};

export const taskReminderAdminReducer = (
  state = taskReminderAdminInitialData,
  action
) => {
  switch (action.type) {
    case FETCH_TASKS_REMINDER_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED:
      return {
        ...state,
        tasksReminderHeaders: action.data.tasksReminderHeaders,
        tasksReminderData: action.data.tasksReminderData,
        pagination: action.data.pagination,
      };

    case SAVE_ADD_TASK_REMINDER_FORM_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }

    case UPDATE_CREATE_TASK_REMINDER_ADMIN_VALUE_IN_STORE: {
      return {
        ...state,
        [action?.key]: action?.value,
      };
    }

    case SET_SELECTED_TASK_REMINDER_ID:
      return {
        ...state,
        ...action.data.taskReminderData,
        selectedTaskReminderId: action.data.id,
      };

    case RESET_ADD_TASK_REMINDER_DATA: {
      return {
        ...state,
        ...taskReminderAdminFormData,
        ...action.data,
      };
    }

    default:
      return state;
  }
};

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import LightboxModal from 'components/LightboxModal';
import smoothscroll from 'smoothscroll-polyfill';
import { showToaster } from 'common/toasterActions';
import ShareOutcomesReportModal from 'components/ShareOutcomesReportModal';
import SharedOutcomesHistoryModal from 'components/SharedOutcomesHistoryModal';
import { onGetSharedOutcomesReportsHistory } from 'common/shareOutcomesReportActions';
import TabContentWrapper from 'containers/PatientDetails/Components/TabContentWrapper';
import NoOutcomes from '../../assets/images/nooutcomes.png';
import axios from '../../services/base';

// kick off the polyfill!
smoothscroll.polyfill();

const Outcomes = props => {
  const params = useParams();
  const dispatch = useDispatch();

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  const hasError = useSelector(store => store.shareOutcomesReport.hasError);
  const patient = useSelector(store => store.currentPatientInfo.patient);
  const sharedOutcomesReportsHistory = useSelector(
    store => store.shareOutcomesReport.sharedOutcomesReportsHistory
  );
  const showExecutiveSummary = useSelector(
    store =>
      store.currentPatientInfo.departmentConfiguration.showExecutiveSummary
  );
  const hasSurveys = useSelector(store => store.currentPatientInfo.hasSurveys);

  const aggregation_type = 'per_patient';

  const [outcomesUrl, setOutcomesUrl] = useState(null);
  const [isSummaryUpdating, setIsSummaryUpdating] = useState(false);
  const [iFrameLoaded, setIFrameLoaded] = useState(false);
  const [random, setRandom] = useState(0);
  const [showShareOutcomeModal, setShowShareOutcomeModal] = useState(false);
  const [showSharedOutcomesHistoryModal, setShowSharedOutcomesHistoryModal] =
    useState(false);

  const onPostMessage = event => {
    if (
      typeof event.data === 'object' &&
      Object.keys(event.data).includes('text', 'url_uuid', 'user_procedure_id')
    ) {
      showUpdateSummaryContainer(event);
    } else if (
      typeof event.data === 'string' &&
      event.data === 'show share outcomes modal'
    ) {
      setShowShareOutcomeModal(true);
    } else if (
      typeof event.data === 'string' &&
      event.data === 'show share outcomes history modal'
    ) {
      callShowSharedOutcomesHistoryModal();
    }
  };

  const loadOutcomeURL = () => {
    const params = {
      aggregation_type,
      user_procedure_id: procedureId,
      patient_id: patientId,
    };

    axios
      .post('/outcomes_url', params)
      .then(res => {
        const { outcomesUrl } = res.data;
        setOutcomesUrl(outcomesUrl);
        setIsSummaryUpdating(false);
      })
      .catch(() => {});
  };

  const onIframeLoaded = () => {
    setIFrameLoaded(true);
    window.scroll({
      top: 360,
      behavior: 'smooth',
    });
  };

  const showUpdateSummaryContainer = e => {
    setIsSummaryUpdating(true);
    axios
      .post('/outcomes/update_executive_summary', e.data)
      .then(res => {
        if (res.data) {
          dispatch(showToaster('success', res.data.message));
          setRandom(random + 1);
          setIsSummaryUpdating(false);
        }
      })
      .catch(() => {
        dispatch(showToaster('error', 'Summary not updated. Try again!'));
        setIsSummaryUpdating(false);
      });
  };

  const callShowSharedOutcomesHistoryModal = () => {
    const data = {
      user_procedure_id: procedureId,
      aggregation_type,
    };
    dispatch(onGetSharedOutcomesReportsHistory(data));
    setShowSharedOutcomesHistoryModal(true);
  };

  useEffect(() => {
    if (props.isActive) {
      loadOutcomeURL();
      window.addEventListener('message', onPostMessage);
    }
    return () => {
      window.removeEventListener('message', onPostMessage);
      setIFrameLoaded(false);
      setOutcomesUrl(null);
    };
  }, [props.isActive]);

  useEffect(() => {
    if (hasError) setShowShareOutcomeModal(true);
    else setShowShareOutcomeModal(false);
  }, [hasError]);

  return (
    <TabContentWrapper
      body={
        hasSurveys ? (
          <div>
            <div className='tab-details-title hidden-lg hidden-md visible-sm visible-xs'>
              Outcomes Reports
            </div>
            {outcomesUrl != null && (
              <iframe
                src={outcomesUrl}
                className='charts-frame'
                title='Aggregate Outcomes'
                key={random}
                onLoad={onIframeLoaded}
              />
            )}
            {showShareOutcomeModal && (
              <ShareOutcomesReportModal
                show={showShareOutcomeModal}
                onModalClose={() => setShowShareOutcomeModal(false)}
                showExecutiveSummary={showExecutiveSummary}
                aggregationType={aggregation_type}
                procedureId={procedureId}
                patient={patient}
              />
            )}
            <SharedOutcomesHistoryModal
              show={showSharedOutcomesHistoryModal}
              onModalClose={() => setShowSharedOutcomesHistoryModal(false)}
              sharedOutcomesReportsHistory={sharedOutcomesReportsHistory}
            />
          </div>
        ) : (
          <div>
            <div className='tab-details-title hidden-lg hidden-md visible-sm visible-xs'>
              Outcomes Reports
            </div>
            <div className='outcome-reports-empty-state'>
              <img src={NoOutcomes} alt='No Outcomes' />
              <br />
              <br />
              Outcomes Report can only be seen when patient submits at least one
              survey.
            </div>
          </div>
        )
      }
    />
  );
};

export default Outcomes;

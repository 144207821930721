import { getValidationsObject } from 'utils/stringUtils';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useState } from 'react';
import validate from 'common/validator';
import {
  fetchSiteClosureRequestDataAction,
  resendPinForSiteClosureRequestAction,
} from './actions';

const useSiteClosureRequestViewController = () => {
  const initialFormData = {
    pin: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const params = useParams<{ key: string }>();
  const dispatch = useAppDispatch();

  const siteClosureRequestData = useAppSelector(
    store => store.siteClosureRequestReducer
  );

  const config = [
    {
      id: 'pin',
      input_type: 'input_text_field_without_label',
      name: 'pin',
      autoComplete: false,
      validation: [{ rule: 'isRequired', message: 'pin is required' }],
      value: formData.pin,
      label: 'PIN',
      isInlineLabelNeeded: true,
      required: true,
      visible: true,
    },
  ];

  const onValidationSuccess = () => {
    setErrors({});
    const data = {
      request_key: params.key,
      request_pin: formData.pin,
    };
    dispatch(fetchSiteClosureRequestDataAction(data));
  };

  const submitPin = (e: Event) => {
    e.preventDefault();
    validate(
      getValidationsObject(config),
      formData,
      (errorsInForm: object) => setErrors(errorsInForm),
      onValidationSuccess
    );
  };

  const resendPin = () => {
    dispatch(resendPinForSiteClosureRequestAction({ request_key: params.key }));
  };

  return {
    config,
    formData,
    setFormData,
    errors,
    submitPin,
    siteClosureRequestData,
    resendPin,
  };
};

export default useSiteClosureRequestViewController;

import React from 'react';
import { components } from 'react-select';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownOutlinedIcon className='dropdown-icon' />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;

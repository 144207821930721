/** \n
 * @author Dilip Joshi
 * @description
 * * Component: New Patient Study Information Form
 * Props:
 * - nextPage: The next page to navigate to after data is successfully saved
 * - onModalClose: Function to close any open modal
 * Description: This component is used to capture study information for new patient creation. It includes multiple select inputs
 * for study name, study site, investigator and consent form countersigner. It implements validation on all fields before data is saved
 * in the redux store. It also provides buttons to move to the next page, edit subject information, save all data and
 * cancel the form without saving.
 * Dependencies: React, react-redux, common/validator, components/SelectV2, validators.js, ../StyleGuideComponents/common/buttons, components/HelpBlock,
 * common/actions.
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validate, { clearErrorsForField } from 'common/validator';
import SingleSelectWithBorder from 'components/SelectV2';
import HelpBlock from 'components/HelpBlock';
import {
  getProcedureLocationsRequest,
  getProcedureMasterProvidersRequest,
  getCounterSigneRequest,
  getDepartmentProceduresRequest,
  saveStudyInformation,
  clearStudyInformation,
  clearSubjectInformation,
  showLoaderForAddPatient,
  stopLoaderForAddPatient,
} from 'common/actions';
import { procedureValidationConfig } from './validators';
import {
  PrimaryButton,
  SecondaryButton,
} from '../StyleGuideComponents/common/buttons';

const StepTwoForm = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDepartmentProceduresRequest());
  }, []);

  const studyInformation = useSelector(store => store.addPatientReducer);

  const [errors, setErrors] = useState({});
  const [studyNameId, setStudyNameId] = useState(
    studyInformation.studyNameId.value || ''
  );
  const [locationId, setLocationId] =
    useState(studyInformation.locationId.value) || '';
  const [masterProviderId, selectMasterProviderId] = useState(
    studyInformation.masterProviderId.value || ''
  );
  const [counterSigneId, setCounterSigneId] = useState(
    studyInformation.counterSigneId.value || ''
  );

  const fetchedLocations = useSelector(store => store.loc.locations);
  const masterProviders = useSelector(
    store => store.procedureMasterProvider.masterProviders
  );
  const counterSigne = useSelector(
    store => store.counterSigneReducer.counterSigne
  );
  const studyNames = useSelector(
    store => store.getDepartmentProceduresReducer.procedures
  );

  const selectStudy = ({ value }) => {
    setErrors(clearErrorsForField(errors, 'studyNameId'));
    setStudyNameId(value);
    if (studyNameId != value) {
      setLocationId('');
      selectMasterProviderId(null);
      setCounterSigneId(null);
    }
    dispatch(getProcedureLocationsRequest({ id: value }));
  };

  const selectSite = ({ value }) => {
    setErrors(clearErrorsForField(errors, 'locationId'));
    setLocationId(value);
    if (locationId != value) {
      selectMasterProviderId(null);
      setCounterSigneId(null);
    }
    dispatch(
      getProcedureMasterProvidersRequest({
        id: studyNameId,
        location_id: value,
      })
    );
  };

  const selectMasterProvider = ({ value }) => {
    setErrors(clearErrorsForField(errors, 'masterProviderId'));
    selectMasterProviderId(value);
    if (masterProviderId != value) {
      setCounterSigneId(null);
    }
    dispatch(
      getCounterSigneRequest({
        id: studyNameId,
        provider_id: value,
        location_id: locationId,
      })
    );
  };

  const selectCounterSigne = ({ value }) => {
    setErrors(clearErrorsForField(errors, 'counterSigneId'));
    setCounterSigneId(value);
  };

  const onNextValidationSuccess = () => {
    dispatch(
      saveStudyInformation({
        studyNameId: getValueForDropDown(studyNames, studyNameId),
        locationId: getValueForDropDown(fetchedLocations, locationId),
        masterProviderId: getValueForDropDown(
          masterProviders,
          masterProviderId,
          ['firstName', 'lastName']
        ),
        counterSigneId: getValueForDropDown(counterSigne, counterSigneId, [
          'firstName',
          'lastName',
        ]),
      })
    );
    dispatch(stopLoaderForAddPatient());
    props.moveToPage(props.nextPage);
  };

  const onNextValidationFailure = errors => {
    setErrors(errors);
    dispatch(stopLoaderForAddPatient());
  };

  const saveAllData = () => {
    dispatch(showLoaderForAddPatient());
    validate(
      procedureValidationConfig,
      { studyNameId, locationId, masterProviderId, counterSigneId },
      onNextValidationFailure,
      onNextValidationSuccess
    );
  };

  const saveFilledData = () => {
    dispatch(showLoaderForAddPatient());
    const dataToValidate = {};
    if (studyNameId)
      dataToValidate.studyNameId = getValueForDropDown(studyNames, studyNameId);
    if (locationId)
      dataToValidate.locationId = getValueForDropDown(
        fetchedLocations,
        locationId
      );
    if (masterProviderId)
      dataToValidate.masterProviderId = getValueForDropDown(
        masterProviders,
        masterProviderId,
        ['firstName', 'lastName']
      );
    if (counterSigneId)
      dataToValidate.counterSigneId = getValueForDropDown(
        counterSigne,
        counterSigneId,
        ['firstName', 'lastName']
      );
    dispatch(saveStudyInformation(dataToValidate));
    dispatch(stopLoaderForAddPatient());
  };

  const onSubmit = e => {
    e.preventDefault();
    saveAllData();
  };

  const editSubjectInfo = () => {
    saveFilledData();
    props.moveToPage(1);
  };

  const getValueForDropDown = (options, valueId, fields = null) => {
    const option = options.filter(item => {
      return item.id === valueId;
    });
    if (option.length) {
      let fieldValue = '';
      if (fields) {
        fields.forEach(field => {
          fieldValue = `${fieldValue + option[0][field]} `;
        });
        return { value: option[0].id, label: fieldValue };
      }
      return { value: option[0].id, label: option[0].name.trim() };
    }
    return '';
  };

  const cancelFormWithoutSaving = () => {
    dispatch(clearSubjectInformation());
    dispatch(clearStudyInformation());
    props.onModalClose();
  };

  const getOptionsForDropDown = (options, fields = null) => {
    return options.map(option => {
      let fieldValue = '';
      if (fields) {
        fields.forEach(field => {
          fieldValue = `${fieldValue + option[field]} `;
        });
      } else {
        fieldValue = option.name;
      }
      return { value: option.id, label: fieldValue.trim() };
    });
  };

  const options =
    fetchedLocations &&
    fetchedLocations.map(item => ({
      value: item.id,
      label: item.name,
    }));

  return (
    <form className='form-body form-two-body' id='add-patient-step-two-form'>
      <div className='step-two-form'>
        <div
          className={`form-group ${
            errors.studyNameId !== undefined ? 'has-error' : ''
          }`}
        >
          <label className='form-label required' htmlFor='studyName'>
            Study Name
          </label>
          <SingleSelectWithBorder
            id='select-study-name'
            placeholder='Enter Study Name'
            onChange={selectStudy}
            value={getValueForDropDown(studyNames, studyNameId)}
            options={getOptionsForDropDown(studyNames)}
          />
          <HelpBlock value={errors.studyNameId} />
        </div>

        <div
          className={`form-group ${
            errors.locationId !== undefined ? 'has-error' : ''
          }`}
        >
          <label className='form-label required' htmlFor='location'>
            Study Site
          </label>
          <SingleSelectWithBorder
            id='select-location'
            placeholder='Enter Study Site'
            value={getValueForDropDown(fetchedLocations, locationId)}
            onChange={selectSite}
            options={getOptionsForDropDown(fetchedLocations)}
          />
          <HelpBlock value={errors.locationId} />
        </div>

        <div
          className={`form-group ${
            errors.masterProviderId !== undefined ? 'has-error' : ''
          }`}
        >
          <label className='form-label required' htmlFor='masterProviderId'>
            INVESTIGATOR
          </label>
          <SingleSelectWithBorder
            id='select-pi-hcp'
            placeholder='Enter Investigator'
            onChange={selectMasterProvider}
            value={getValueForDropDown(masterProviders, masterProviderId, [
              'firstName',
              'lastName',
            ])}
            options={getOptionsForDropDown(masterProviders, [
              'firstName',
              'lastName',
            ])}
          />
          <HelpBlock value={errors.masterProviderId} />
        </div>

        <div
          className={`form-group ${
            errors.counterSigneId !== undefined ? 'has-error' : ''
          }`}
        >
          <label className='form-label required' htmlFor='counterSigneId'>
            Consent Form Countersigner
          </label>
          <SingleSelectWithBorder
            id='select-counter-signe'
            placeholder='Select Consent Form Countersigner'
            onChange={selectCounterSigne}
            value={getValueForDropDown(counterSigne, counterSigneId, [
              'firstName',
              'lastName',
            ])}
            options={getOptionsForDropDown(counterSigne, [
              'firstName',
              'lastName',
            ])}
          />
          <HelpBlock value={errors.counterSigneId} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '16px',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}
      >
        <span>
          <SecondaryButton onClick={cancelFormWithoutSaving}>
            <span className='add-patient-form-button cancel-button '>
              Cancel without saving
            </span>
          </SecondaryButton>
        </span>
        <span style={{ width: '180px' }}>
          <SecondaryButton onClick={() => editSubjectInfo()}>
            <span className='add-patient-form-button'>
              Edit Subject Information
            </span>
          </SecondaryButton>
        </span>
        <span style={{ width: '260px' }}>
          <PrimaryButton
            onClick={onSubmit}
            form='add-patient-step-two-form'
            type='submit'
          >
            <span className='add-patient-form-button'>Review and Finish</span>
          </PrimaryButton>
        </span>
      </div>
    </form>
  );
};

export default StepTwoForm;

import {
  FETCH_CONSENT_REQUEST_SENT,
  FETCH_CONSENT_REQUEST_SUCCEED,
  FETCH_CONSENT_REQUEST_FAILED,
  DOWNLOAD_SIGNED_CONSENT_REQUEST_SENT,
  DOWNLOAD_SIGNED_CONSENT_REQUEST_SUCCEED,
  DOWNLOAD_SIGNED_CONSENT_REQUEST_FAILED,
  CONSENT_RESEND_INVITE_REQUEST_SENT,
  CONSENT_RESEND_INVITE_REQUEST_SUCCEED,
  CONSENT_RESEND_INVITE_REQUEST_FAILED,
  UPLOAD_PAPER_CONSENT_REQUEST_SUCCEED,
  UPLOAD_PAPER_CONSENT_REQUEST_SENT,
  UPLOAD_PAPER_CONSENT_REQUEST_FAILED,
} from './constants';

const initialState = {
  data: {
    completed: [],
    incomplete: [],
  },
  isLoading: false,
};

function consentReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONSENT_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_CONSENT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };

    case FETCH_CONSENT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case UPLOAD_PAPER_CONSENT_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };

    case UPLOAD_PAPER_CONSENT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          completed: [...state.data.completed, action.data],
          incomplete: state.data.incomplete.filter(
            consent => consent.consentFormId != action.data.consentFormId
          ),
        },
      };

    case UPLOAD_PAPER_CONSENT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}

const downloadSignedConsentInitialState = {
  isLoading: false,
  hasError: false,
};

export const downloadSignedConsentReducer = (
  state = downloadSignedConsentInitialState,
  action
) => {
  switch (action.type) {
    case DOWNLOAD_SIGNED_CONSENT_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case DOWNLOAD_SIGNED_CONSENT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
      };

    case DOWNLOAD_SIGNED_CONSENT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };

    default:
      return state;
  }
};

const consentResendInviteInitialState = {
  isLoading: false,
  hasError: false,
};

export const consentResendInviteReducer = (
  state = consentResendInviteInitialState,
  action
) => {
  switch (action.type) {
    case CONSENT_RESEND_INVITE_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };

    case CONSENT_RESEND_INVITE_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
      };

    case CONSENT_RESEND_INVITE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };

    default:
      return state;
  }
};

export default consentReducer;

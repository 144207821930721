/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import moment from 'moment-timezone';
import './appointmentCard.scss';
import '../../assets/stylesheets/_variables.scss';
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  DISPLAY_TO_BE_DECIDED_STRING,
} from 'constants';
import PropTypes from 'prop-types';
import { isTBD } from '../../utils/dateUtils';
import { TertiaryButton } from '../StyleGuideComponents/common/buttons';
import EditAppointmentNote from './Components/EditAppointmentNote';
import EditAppointment from './Components/EditAppointment';
import DeleteAppointment from './Components/DeleteAppointment';

const AppointmentCard = props => {
  const {
    item,
    appFeatures,
    patientId,
    procedureId,
    compositeProcedure,
    isUserCreatedAppointment,
  } = props;

  const canEditVisit = appFeatures && appFeatures?.appointments?.canEdit;
  const canDeleteVisit =
    appFeatures &&
    appFeatures?.appointments?.canDelete &&
    item.eventKindName?.toLowerCase() === 'User Created'.toLowerCase();
  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { isEditable } = item;

  const getDateTimeString = (event, format) => {
    if (event.location) {
      if (isTBD(event.time)) return DISPLAY_TO_BE_DECIDED_STRING;
      return moment(event.time).tz(event.location.timezone).format(format);
    }
    const tz = moment.tz.guess();
    return moment(event.time).tz(tz).format(format);
  };

  const displayDateString = getDateTimeString(item, DISPLAY_DATE_FORMAT);
  const displayTimeString = getDateTimeString(item, DISPLAY_TIME_FORMAT);

  return (
    <div
      className={`event-container ${item.deletedAt == null ? '' : 'skipped'} ${
        isUserCreatedAppointment ? 'user-created' : 'system-created'
      }`}
      data-id={item.id}
    >
      <div className='visit-details'>
        <div className='visit-column'>
          <div className='visit-title'>VISIT NAME</div>
          <div className='visit-data'>{item.title}</div>
        </div>
        <div className='visit-column'>
          <div className='visit-title'>DATE</div>
          <div className='visit-data'>
            <span className='icon icon-font-a-calendar' /> &nbsp;
            {item.dateKnown ? displayDateString : DISPLAY_TO_BE_DECIDED_STRING}
          </div>
        </div>
        <div className='visit-column'>
          <div className='visit-title'>TIME</div>
          <div className='visit-data'>
            <span className='icon icon-schedule' />
            &nbsp;
            {item.timeKnown ? displayTimeString : DISPLAY_TO_BE_DECIDED_STRING}
          </div>
        </div>

        <span className='modify-visit'>
          {canDeleteVisit ? (
            <TertiaryButton
              onClick={
                item.deletedAt == null ? () => setShowDeleteModal(true) : null
              }
              style={{ height: '14px' }}
            >
              <span className='icon icon-delete visit-icon' />
            </TertiaryButton>
          ) : (
            ''
          )}
          {canEditVisit ? (
            <TertiaryButton
              onClick={
                item.deletedAt == null ? () => setShowEditModal(true) : null
              }
              style={{ height: '14px', justifyContent: 'flex-end' }}
              disabled={!isEditable}
            >
              <span className='icon icon-edit visit-icon' />
              EDIT
            </TertiaryButton>
          ) : (
            ''
          )}
        </span>
      </div>
      <div className='visit-notes'>
        <div className='visit-column'>
          <span className='visit-title'>NOTES</span>
          <span className='visit-data'>
            {item.additionalNote ? (
              item.additionalNote
            ) : (
              <span className='no-data-label'>No Notes Available</span>
            )}
          </span>
        </div>
        {canEditVisit ? (
          <div className='visit-column edit-visit-note'>
            <TertiaryButton
              style={{ width: 'fit-content', height: '14px' }}
              disabled={!isEditable}
              onClick={() => setShowEditNoteModal(true)}
            >
              <span className='icon icon-edit visit-icon' />
              EDIT
            </TertiaryButton>
          </div>
        ) : (
          ''
        )}
      </div>
      <EditAppointmentNote
        showEditNoteModal={showEditNoteModal}
        closeShowEditNoteModal={() => {
          setShowEditNoteModal(false);
        }}
        appointmentId={item.id}
        note={item.additionalNote ? item.additionalNote : ''}
        patientId={patientId}
        procedureId={procedureId}
        compositeProcedure={compositeProcedure}
      />
      <EditAppointment
        showEditModal={showEditModal}
        closeShowEditModal={() => setShowEditModal(false)}
        item={item}
        patientId={patientId}
        procedureId={procedureId}
        compositeProcedure={compositeProcedure}
      />
      <DeleteAppointment
        showDeleteModal={showDeleteModal}
        closeShowDeleteModal={() => setShowDeleteModal(false)}
        item={item}
      />
    </div>
  );
};

AppointmentCard.propTypes = {
  item: PropTypes.object.isRequired,
  appFeatures: PropTypes.object.isRequired,
  patientId: PropTypes.number.isRequired,
  procedureId: PropTypes.number.isRequired,
  compositeProcedure: PropTypes.object.isRequired,
  isUserCreatedAppointment: PropTypes.bool.isRequired,
};
export default AppointmentCard;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Tabs, Tab } from 'react-bootstrap/lib';
import {
  fetchAllDepartmentsRequest,
  fetchAllProceduresOfTheDepartmentRequest,
  fetchAllProvidersRequest,
  fetchAdvocatesRequest,
} from './action';
import './user_admin.scss';
import { userAdminTabsConfig, defaultTab } from './config';


// eslint-disable-next-line no-unused-vars
const UserAdmin = props => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const tabId = parseInt(params.tabId, 10);
  const [selectedTabId, setSelectedTabId] = useState(tabId || defaultTab);

  const getTitle = title => {
    return (
      <div className='tab-header'>
        {title?.icon}
        <span className='tab-menu-title'>{title?.text}</span>
        {(title.keyForMissedData && title.count) > 0 ? (
          <span className='count-container'>{title.count}</span>
        ) : (
          ''
        )}
      </div>
    );
  };

  useEffect(() => {
    const tab = userAdminTabsConfig.find(
      tabInParams => tabInParams.id === selectedTabId
    );
    history.push(`/admin/tab/${tab.id}/${tab.href}`);
  }, [selectedTabId]);

  useEffect(() => {
    dispatch(fetchAllDepartmentsRequest());
    dispatch(fetchAllProceduresOfTheDepartmentRequest());
    dispatch(fetchAllProvidersRequest());
    dispatch(fetchAdvocatesRequest());
  }, []);

  return (
    <div className='user-admin-wrapper'>
      <Tabs
        activeKey={selectedTabId}
        onSelect={tabIdInParams => {
          setSelectedTabId(tabIdInParams);
        }}
      >
        {userAdminTabsConfig.map(tab =>
          !tab.disabled ? (
            <Tab
              eventKey={tab.id}
              href={tab.href}
              title={getTitle(tab.title)}
              key={tab.id}
            >
              <tab.component isActive={tab.id === selectedTabId} />
            </Tab>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )
        )}
      </Tabs>
    </div>
  );
};

export default UserAdmin;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TertiaryButton,
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Pagination from 'components/Pagination';
import { PrimaryModal } from 'components/StyleGuideComponents/common/modals';
import { getLocationCodesRequest } from 'containers/AdminPage/action';
import AddEditConsentModal from './Components/AddEditConsentModal';
import AdminTabWrapper from '../AdminTabWrapper';
import ConsentFilter from './Components/ConsentFilter';
import ConsentData from './Components/ConsentData';
import {
  setSelectedConsentIdRequest,
  fetchConsentsListOnAdminPageRequest,
  updateConsentAdminValue,
} from './action';

import { updateTaskAdminValue } from '../TaskAdmin/action';
import TaskAdmin from '../TaskAdmin/TaskAdmin';
import TaskReminderAdmin from '../TaskReminderAdmin/TaskReminderAdmin';
import { updateTaskReminderAdminValue } from '../TaskReminderAdmin/action';
import NotificationsAdmin from '../NotificationsAdmin/NotificationsAdmin';
import { updateNotificationAdminValue } from '../NotificationsAdmin/action';

const ConsentAdmin = props => {
  const dispatch = useDispatch();

  const [showAddEditModa, setShowAddEditModal] = useState(false);
  const [selectedConsentId, setSelectedConsentId] = useState(null);

  const consentAdminData = useSelector(store => store.consentAdminReducer);
  const showAddTaskReminderInfoModal = useSelector(
    store => store.taskReminderAdminReducer.showAddTaskReminderInfoModal
  );
  const showAddNotificationsInfoModal = useSelector(
    store => store.notificationAdminReducer.showAddNotificationsInfoModal
  );

  useEffect(() => {
    if (props.isActive) {
      dispatch(
        fetchConsentsListOnAdminPageRequest({
          page: consentAdminData.page,
          name: consentAdminData.filterName,
        })
      );
      dispatch(getLocationCodesRequest());
    } else {
      dispatch(updateConsentAdminValue('filterName', ''));
    }
  }, [consentAdminData.page, props.isActive, consentAdminData.filterName]);

  const getEditData = data => {
    const consentData = consentAdminData.consentsData.find(
      consent => consent.id === data
    );
    return { ...consentData, template: [], taskIdentifier: consentData.task };
  };

  return (
    <AdminTabWrapper
      filterSection={<ConsentFilter />}
      dataSection={{
        header: (
          <div style={{ width: 'fit-content' }}>
            <TertiaryButton onClick={() => setShowAddEditModal(true)}>
              <AddCircleOutlineIcon sx={{ fontSize: '32px' }} />
              <span style={{ fontSize: '16px' }}> Add New Consent </span>
            </TertiaryButton>
          </div>
        ),
        content: (
          <>
            <ConsentData
              selectedConsentId={selectedConsentId}
              data={{
                consentsHeaders: consentAdminData.consentsHeaders,
                consentsData: consentAdminData.consentsData,
              }}
              updateSelectedConsentId={data => {
                setSelectedConsentId(data);
                dispatch(setSelectedConsentIdRequest(data, getEditData(data)));
                setShowAddEditModal(true);
              }}
            />
            <AddEditConsentModal
              show={showAddEditModa}
              onHide={() => {
                setSelectedConsentId(null);
                setShowAddEditModal(false);
              }}
            />
            <TaskAdmin />
            <TaskReminderAdmin />
            <NotificationsAdmin />
            <PrimaryModal
              show={showAddTaskReminderInfoModal}
              onHide={dispatch(
                updateTaskAdminValue('showAddTaskReminderInfoModal', false)
              )}
              bodyTitle={{
                text: 'Add Task Reminder',
              }}
              bodyContent={{
                text: ' Do You want to add the Task Reminder For this Task ?',
              }}
              buttons={[
                <PrimaryButton
                  onClick={() =>
                    dispatch(updateTaskReminderAdminValue('show', true))
                  }
                >
                  Yes
                </PrimaryButton>,
                <SecondaryButton
                  onClick={() =>
                    dispatch(
                      updateTaskReminderAdminValue(
                        'showAddTaskReminderInfoModal',
                        false
                      )
                    )
                  }
                >
                  No
                </SecondaryButton>,
              ]}
            />
            <PrimaryModal
              show={showAddNotificationsInfoModal}
              onHide={dispatch(
                updateTaskAdminValue('showAddTaskReminderInfoModal', false)
              )}
              bodyTitle={{
                text: 'Add Notification',
              }}
              bodyContent={{
                text: ' Do You want to add the Notification For this Task ?',
              }}
              buttons={[
                <PrimaryButton
                  onClick={() =>
                    dispatch(updateNotificationAdminValue('show', true))
                  }
                >
                  Yes
                </PrimaryButton>,
                <SecondaryButton
                  onClick={() =>
                    dispatch(
                      updateNotificationAdminValue(
                        'showAddNotificationsInfoModal',
                        false
                      )
                    )
                  }
                >
                  No
                </SecondaryButton>,
              ]}
            />
          </>
        ),
        footer: (
          <Pagination
            pagination={consentAdminData.pagination}
            title='Consents'
            handlePagination={page => {
              dispatch(updateConsentAdminValue('page', page));
            }}
          />
        ),
      }}
    />
  );
};

export default ConsentAdmin;

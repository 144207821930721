import axios from './base';
import {
  GET_SURVEYS,
  GET_DEPARTMENT_SURVEYS,
  CREATE_OUTCOMES_URL,
  CLEAR_SURVEYS,
} from './constants';

export const getSurveys = (patientId, userProcedureId, procedurePhaseId) => {
  const params = {
    patient_id: patientId,
    user_procedure_id: userProcedureId,
    procedure_phase_id: procedurePhaseId,
  };

  return axios.get(GET_SURVEYS, {
    params,
  });
};

export const clearSurveyResponse = params => {
  return axios.post(CLEAR_SURVEYS, params);
};

export const getDepartmentSurveysRequest = params =>
  axios.get(GET_DEPARTMENT_SURVEYS, { params });

export const createSurveyUrlRequest = params =>
  axios.post(CREATE_OUTCOMES_URL, params);

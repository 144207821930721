import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './styles.scss';
import useClientListener from '../../../utils/hooks/useClientListener';

export default function Header(props) {
  const { onBackClick } = props;

  return (
    <div className='pain-chart-header-wrapper'>
      <ArrowBackIosIcon className='pain-chart-header-wrapper__back-icon' sx={{ fontSize: '18px' }} onClick={onBackClick}/>
      <p className='native-header'>Your Progress</p>
    </div>
  );
}

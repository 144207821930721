import React, { useState } from 'react';
import { dateStringToCustomDateTime } from 'utils/dateUtils';
import './diaryCard.scss';
import PropTypes from 'prop-types';
import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import { useSelector } from 'react-redux';
import { ACCESS_CONTROL_KEYS } from 'constants';
import CircleIcon from '@mui/icons-material/Circle';
import EditDiary from './EditDiary';

const DiaryCard = props => {
  const { diary } = props;
  const canEditDiary = useSelector(
    store => store.currentUser.appFeatures[ACCESS_CONTROL_KEYS.DIARY].canEdit
  );

  const [showEditDiaryModal, setShowEditDiaryModal] = useState(false);

  const diaryReadStatus =
    diary.readOn === null
      ? {
          diaryReadStatusIconClassName: 'diary-unread-icon',
          diaryReadStatusMessage: 'UNREAD',
        }
      : {
          diaryReadStatusIconClassName: 'diary-read-icon',
          diaryReadStatusMessage: 'READ',
        };

  return (
    <>
      <EditDiary
        diary={{ id: diary.id, activity: diary.activity }}
        showEditDiaryModal={showEditDiaryModal}
        setShowEditDiaryModal={setShowEditDiaryModal}
      />
      <div className='diary-card' data-id={diary.id}>
        <div className='diary-body'>
          <span className='diary-title'>INTENSITY</span>
          <span className='diary-content'>
            {diary.answers.length ? diary.answers[0].intensity : ''}
          </span>
          <span>
            <CircleIcon
              fontSize='small'
              className={`${diaryReadStatus.diaryReadStatusIconClassName}`}
            />
            &nbsp;
            <span className='diary-title'>
              {diaryReadStatus.diaryReadStatusMessage}
            </span>
          </span>
        </div>
        <div className='diary-activity'>
          <span className='diary-title'>ACTIVITY</span>
          <span className='diary-content'>{diary.activity}</span>
          <span className='diary-validity-text'>
            {diary.editedOn ? (
              <>
                <span>Edited by: {diary.editedBy}</span> |{' '}
                <span>
                  Edited on: {dateStringToCustomDateTime(diary.editedOn)}
                </span>{' '}
                |{' '}
              </>
            ) : (
              ''
            )}
            <span>
              Submitted on: {dateStringToCustomDateTime(diary.submittedAt)}
            </span>
          </span>
        </div>
        <div className='diary-card-buttons'>
          {canEditDiary ? (
            <TertiaryButton onClick={() => setShowEditDiaryModal(true)}>
              <span className='icon icon-edit' />
              EDIT
            </TertiaryButton>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

DiaryCard.propTypes = {
  diary: PropTypes.shape({
    id: PropTypes.number,
    answers: PropTypes.arrayOf(PropTypes.object),
    activity: PropTypes.string,
    submittedAt: PropTypes.string,
    editedOn: PropTypes.string,
    editedBy: PropTypes.string,
    readOn: PropTypes.string,
  }).isRequired,
};

export default DiaryCard;

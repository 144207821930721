import React from 'react';
import * as Sentry from '@sentry/react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import "core-js/es";
import 'sanitize.css/sanitize.css';

import { getCurrentUserInfo } from 'common/authThunkActions';
import App from 'containers/App';
import { createBrowserHistory } from 'history';
import {
  setupBasicInterceptor,
  setupInterceptor,
  setupNonMasqueradingInterceptor,
  setupDefaultAxiosInterceptor,
} from 'services/base';
import { store as AppStore } from 'store';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/stylesheets/bootstrap.scss';
import './assets/stylesheets/datetime.scss';
import './assets/stylesheets/mms_common.scss';
import 'assets/stylesheets/_fonts.scss';
import './assets/stylesheets/_font_icons.scss';
import './assets/stylesheets/_font_icons_b.scss';
import './assets/stylesheets/_font_icons_z.scss';
import './assets/stylesheets/_font_icon_multiselect.scss';
import './assets/stylesheets/_star_fonts.scss';
import './assets/stylesheets/resend-invite.css';
import './assets/stylesheets/_download_fonts.scss';
import './assets/stylesheets/smart_banner.scss';
import './assets/stylesheets/_notes_fonts.scss';
import './assets/stylesheets/task-icon.css';
import './assets/stylesheets/hourglass.css';
import './assets/stylesheets/not-logged-in.css';
import './assets/stylesheets/ediaryIcon.scss';
import './assets/stylesheets/audit_trail_icon.scss';

// eslint-disable-next-line import/prefer-default-export
export const localHistory = createBrowserHistory();
const store = AppStore;
const container = document.getElementById('root');
const root = createRoot(container);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

setupBasicInterceptor(store);
setupInterceptor(store);
setupNonMasqueradingInterceptor(store);
setupDefaultAxiosInterceptor(store);

getCurrentUserInfo(store);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

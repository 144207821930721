import React, { useEffect, useState } from 'react';
import { getQuickViewSummary } from '../service';
import { changeSearchParams } from '../../../utils/changeSearchParams';
import TrialPainScore from './TrialPainScore';
import { STATE, ERRORS } from 'enums';
import ImplantPainScore from './ImplantPainScore';
import Strings from '../../../strings';
import './styles.scss';
import useClientListener from '../../../utils/hooks/useClientListener';
import useBackHandler from '../../../utils/hooks/useBackHandler';
import Header from './Header';
import PatientGoals from './PatientGoals';
import { generateChartLegend } from '../utils';
import MobileErrorBoundary from 'containers/MobileAppErrorBoundary/MobileErrorBoundary';
import { fallbackUI } from '../utils';
import { logError } from '../utils';

export default function ProcedureSummaryQuickView() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const clientListener = useClientListener();
  useBackHandler('Procedure Summary Quick Overview', clientListener);

  useEffect(() => {
    (async function () {
      const searchParams = new URLSearchParams(window.location.search);
      const userProcedureId = searchParams.get('UserProcedureId') ?? '0';
      if (userProcedureId == '0' || userProcedureId === null) {
        throw new Error('Invalid User Procedure');
      }
      try {
        setIsLoading(true);
        const { data } = await getQuickViewSummary(userProcedureId);
        setData(data);
      } catch (e) {
        logError(e);
        changeSearchParams(STATE.ERROR, e.message || null);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  // TODO: Use this function from utils
  const getChartData = painScores => {
    const values = painScores?.map(el => {
      if (el.value === -1 || el.value === null) {
        return el.survey_status;
      }
      return el.value;
    });
    return values;
  };

  // TODO: Use this function from utils
  const getChartColors = painScores => {
    const colors = painScores?.map(el => {
      if (Object.keys(el).length > 0 && el.hasOwnProperty('color')) {
        return el.color;
      }
      return 'red';
    });
    return colors;
  };

  // TODO: Use this function from utils
  const getXLabels = painScoreData => {
    const labels = painScoreData?.map(el => {
      if (Object.keys(el).length > 0 && el.hasOwnProperty('x_label')) {
        return el.x_label;
      }
      return 'NL';
    });
    return labels;
  };

  // TODO: Use this function from utils
  const appendProcedureDataToBaselineData = (
    procedureData,
    baselineData,
    functionName
  ) => {
    let formattedProcedureData = functionName(procedureData) ?? [];
    let formattedBaselineData = functionName(baselineData) ?? [];
    return formattedBaselineData.concat(formattedProcedureData);
  };

  const onBack = () => {
    clientListener.onCancelled();
  };

  const generateXAxisLegend = keyValueMap => {
    return (
      <p className='x-axis-legend-wrapper'>
        {Object.keys(keyValueMap).map(key => {
          let value = keyValueMap[key];
          return (
            <>
              <span className='x-axis-legend'>
                <span>{key}-&nbsp;</span>
                <span>{value}</span>
              </span>
            </>
          );
        })}
      </p>
    );
  };

  return (
    <>
    <MobileErrorBoundary
      fallback={fallbackUI()}
      isLoading={isLoading}
      loaderWrapperClass='pain-score-chart-loader pain-score-chart-loader-wrapper'
      loaderImgStyle={{ position: 'relative', top: '8px' }}
    >
        <div className='pain-score-chart-wrapper'>
          <Header onBackClick={onBack} />

          {data?.patient_goals != null && <PatientGoals patient_goals={data?.patient_goals} />}

          {data?.pain_score_data?.implant_pain_score_first_page_data != null &&
            data?.pain_score_data?.implant_pain_score_first_page_data.length >
              0 && (
              <ImplantPainScore
                data={data}
                getChartData={getChartData}
                getChartColors={getChartColors}
                getXLabels={getXLabels}
                appendProcedureDataToBaselineData={
                  appendProcedureDataToBaselineData
                }
                generateXAxisLegend={generateXAxisLegend}
                generateChartLegend={generateChartLegend}
              />
            )}

          {data?.pain_score_data?.trial_pain_score_data != null && (
            <TrialPainScore
              data={data}
              getChartData={getChartData}
              getChartColors={getChartColors}
              getXLabels={getXLabels}
              appendProcedureDataToBaselineData={
                appendProcedureDataToBaselineData
              }
              generateXAxisLegend={generateXAxisLegend}
              generateChartLegend={generateChartLegend}
            />
          )}
        </div>
        </MobileErrorBoundary>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import { getOutcomesCardSummary } from '../service';
import { changeSearchParams } from 'utils/changeSearchParams';
import OutcomesCardChart from './OutcomesCardChart';
import MobileErrorBoundary from 'containers/MobileAppErrorBoundary/MobileErrorBoundary';
import './styles.scss';
import useClientListener from "../../../utils/hooks/useClientListener";
import { STATE, ERRORS } from 'enums';
import { logError } from '../utils';

export default function OutcomesCardSummary() {
  const [isLoading, setIsLoading] = useState(true);
  const [cardData, setCardData] = useState();

  const searchParams = new URLSearchParams(window.location.search);

  const fallbackUI = () => {
    return (
      <div className='share-report-mobile-fallback-wrapper'>
        <div className='share-report-mobile-fallback-wrapper__error-text'>
          <h4>Error</h4>
          <h4>Something Went Wrong...</h4>
        </div>
      </div>
    );
  };

  const clientListener = useClientListener();

  const seeMoreClicked = () => {
    const userProcedureId = searchParams.get("UserProcedureId") || "0";
    clientListener.openInFullWebContainer(
      `summary/quickview?UserProcedureId=${userProcedureId}`
    );
  };

  useEffect(() => {
    (async function () {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        const userProcedureId = searchParams.get('UserProcedureId') || '0';
        if (userProcedureId == '0' || userProcedureId === null) {
          throw new Error('Invalid User Procedure');
        }
        setIsLoading(true);
        const { data } = await getOutcomesCardSummary(userProcedureId);
        setCardData(data);
      } 
      catch (e) {
        logError(e);
        changeSearchParams(STATE.ERROR, e.message || null);
      } 
      finally {
        setIsLoading(false);
      }
    })();
  }, []);
  return (
    <MobileErrorBoundary
      fallback={fallbackUI()}
      isLoading={isLoading}
      loaderWrapperClass='outcomes-card-wrapper outcomes-card-loader'
      loaderImgStyle={{ position: 'relative', top: '8px' }}
    >
      <OutcomesCardChart data={cardData} seeMoreClicked={seeMoreClicked}/>
    </MobileErrorBoundary>
  );
}

import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import CreatableSelect from 'react-select/creatable';
import customStyles from './DefaultStyle';

const SingleSelect = props => {
  const [myIcon, setMyIcon] = useState(props.placeholderIcon);

  useEffect(() => {
    setMyIcon(props.placeholderIcon);
  }, [props.placeholderIcon]);

  const ValueContainer = ({ children, ...props }) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          {children && <>{myIcon}</>}
          {children}
        </components.ValueContainer>
      )
    );
  };

  const { className: classes } = props;
  const iconComponent = {};
  if (props.placeholderIcon) iconComponent.ValueContainer = ValueContainer;
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownOutlinedIcon className='dropdown-icon' />
      </components.DropdownIndicator>
    );
  };

  const selectInputProps = {
    ...props,
    isDisabled: props.disabled,
    styles: customStyles,
    placeholder: props.placeholder,
    classNamePrefix: 'single-select',
    className: `single-select-container ${classes || ''}`,
    isClearable: props.isClearable,
    components: {
      ...props.components,
      iconComponent,
      DropdownIndicator,
      IndicatorSeparator: () => null,
    },
  };

  return props.isCreatable ? (
    <CreatableSelect {...selectInputProps} isClearable />
  ) : (
    <Select {...selectInputProps} />
  );
};

export default SingleSelect;

import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { IconWithCircularBorder } from 'components/UtilityComponents';
import {
  dateStringToCustomDateTime,
  isTBD,
  isDueToday,
  isOverDue,
  isOverDueByDate,
} from 'utils/dateUtils';
import DOMPurify from 'dompurify';
import Attachments from 'containers/Attachments';
import {
  PrimaryButton,
  SecondaryButton,
  AlertButton,
} from 'components/StyleGuideComponents/common/buttons';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import {
  markTaskCompleteRequest,
  sendPatientTaskReminder,
} from 'containers/Tasks/actions';
import './taskCard.scss';
import strings from 'strings';
import { PrimaryModal } from 'components/StyleGuideComponents/common/modals';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { ACCESS_CONTROL_KEYS } from 'constants';
import { PropTypes } from 'prop-types';
import DeleteTask from './DeleteTask';

const TaskCard = props => {
  const params = useParams();
  const dispatch = useDispatch();

  const { task } = props;

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRemaindTaskInfo, setShowRemaindTaskInfo] = useState(false);

  const currentUser = useSelector(store => store.currentUser);
  const appFeatures = useSelector(store => store.currentUser.appFeatures);
  const patientHasSignedIn = useSelector(
    store => store.currentPatientInfo.patient.signInCount > 0
  );

  const checkIfExpired = localTask =>
    localTask.expiredAt !== null &&
    isOverDue(localTask.expiredAt, localTask.timezone);
  const checkIfFutureTask = localTask =>
    !isOverDue(
      localTask.graceTime ? localTask.graceTime : localTask.startDate,
      localTask.timezone
    );

  const isMarkTaskDisabled =
    isTBD(task.startDate) || checkIfFutureTask(task) || checkIfExpired(task);

  const renderTaskText = text => DOMPurify.sanitize(text);

  const getIconClassForTask = localTask => {
    let iconClass =
      localTask.completedOn !== null ? 'background-app-green' : null;
    if (!iconClass) {
      if (checkIfExpired(localTask)) {
        iconClass = 'background-app-light-grey expiring-task';
      } else if (
        isTBD(localTask.graceTime ? localTask.graceTime : localTask.startDate)
      ) {
        iconClass = 'background-app-grey';
      } else if (
        isOverDueByDate(
          localTask.graceTime ? localTask.graceTime : localTask.startDate,
          localTask.timezone
        )
      ) {
        iconClass = 'background-app-red';
      } else if (
        isDueToday(
          localTask.graceTime ? localTask.graceTime : localTask.startDate,
          localTask.timezone
        )
      ) {
        iconClass = 'background-app-orange';
      } else {
        iconClass = 'background-app-orange';
      }
    }
    return iconClass;
  };

  const canDeleteTask = taskItem => {
    if (
      currentUser === null ||
      currentUser === undefined ||
      taskItem.task.type === 'ProcedureTask'
    ) {
      return false;
    }
    if (appFeatures[ACCESS_CONTROL_KEYS.TASKS].canDelete)
      return (
        taskItem.task.createdById !== null &&
        (taskItem.task.createdById === currentUser.attributes.id ||
          currentUser.attributes.hasSuperUserPrivileges)
      );
    return false;
  };

  const onRemindClick = taskId => {
    const data = {
      taskId,
      secure_action_code: SecureCodes.REMIND_TASK,
      needs_reason: true,
    };
    if (patientHasSignedIn) {
      dispatch(
        secureActionWithDoubleAuthentication(sendPatientTaskReminder, data)
      );
    } else {
      setShowRemaindTaskInfo(true);
    }
  };

  const onDeleteTaskClicked = () => {
    setShowDeleteModal(true);
  };

  const markTaskAsComplete = localTask => {
    const data = {
      id: localTask.id,
      patient_id: patientId,
      procedureId,
      phaseId: null,
      showAllTasks: true,
      completed_on:
        localTask.completedOn !== null ? '' : new Date().toISOString(),
      secure_action_code:
        localTask.completedOn !== null
          ? SecureCodes.MARK_TASK_AS_INCOMPLETE
          : SecureCodes.MARK_TASK_AS_COMPLETE,
      needs_reason: true,
    };
    dispatch(
      secureActionWithDoubleAuthentication(markTaskCompleteRequest, data)
    );
  };

  return (
    <div className='task-card'>
      <div className='task-content' data-id={task.id}>
        <div className='task-card-pane'>
          <IconWithCircularBorder
            className={`${getIconClassForTask(task)}`}
            icon={
              task.completedOn == null ? (
                <span className='icon icon-hourglass' />
              ) : (
                <span className='icon icon-font-a-tick' />
              )
            }
          />

          <div className='task-body'>
            <div className='task-title'>
              {task.task.title}
              {task.task.importanceLevel === 'critical' ? (
                <span className='critical-task-label'>
                  &nbsp;(critical task)
                </span>
              ) : (
                ''
              )}
            </div>
            <div
              className='task-name'
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: renderTaskText(task.task.name),
              }}
            />
            {task.completedOn !== null ? (
              <span className='task-validity-text'>
                Completed on: &nbsp;
                {dateStringToCustomDateTime(task.completedOn)}
              </span>
            ) : (
              <>
                {task.expiredAt !== null && (
                  <div className='task-validity-text'>
                    {checkIfExpired(task) ? (
                      <>
                        Expired on: &nbsp;
                        {dateStringToCustomDateTime(task.expiredAt)}
                      </>
                    ) : null}
                  </div>
                )}
                {task.remindedOn && (
                  <div className='task-validity-text'>
                    Last Reminded at: &nbsp;
                    {dateStringToCustomDateTime(task.remindedOn)}
                    <span className='dot-container'>
                      <span className='dot' />
                    </span>
                  </div>
                )}
              </>
            )}
            {task.taskExpired && (
              <div className='task-validity-text'>
                Expired at: &nbsp;
                {dateStringToCustomDateTime(task.expiredAt)}
              </div>
            )}
            <Attachments mediaResources={task.mediaResources} />
          </div>
        </div>
        <div className='task-card-buttons'>
          {appFeatures[ACCESS_CONTROL_KEYS.TASKS].canRemind && (
            <SecondaryButton
              disabled={!task.isRemindable}
              onClick={() => onRemindClick(task.id)}
              style={{ width: '120px' }}
            >
              Remind
            </SecondaryButton>
          )}

          {appFeatures[ACCESS_CONTROL_KEYS.TASKS].canMarkComplete && (
            <SecondaryButton
              disabled={isMarkTaskDisabled}
              style={{ width: '120px' }}
              onClick={() => markTaskAsComplete(task)}
            >
              {`Mark ${task.completedOn !== null ? 'Incomplete' : 'Complete'}`}
            </SecondaryButton>
          )}

          {canDeleteTask(task) && (
            <div className='text-center float-right'>
              <AlertButton onClick={() => onDeleteTaskClicked()}>
                Delete
              </AlertButton>
            </div>
          )}
        </div>
      </div>
      <DeleteTask
        task={task}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />

      <PrimaryModal
        show={showRemaindTaskInfo}
        onHide={() => setShowRemaindTaskInfo(false)}
        bodyHeader={{
          text: 'Alert!',
        }}
        bodyContent={{
          text: strings.REMAIND_TASK_PATIENT_NOT_LOGGED_IN_MESSAGE,
        }}
        buttons={[
          <PrimaryButton
            key='close'
            onClick={() => setShowRemaindTaskInfo(false)}
          >
            Close
          </PrimaryButton>,
        ]}
      />
    </div>
  );
};

TaskCard.propTypes = {
  task: PropTypes.object.isRequired,
};

export default TaskCard;

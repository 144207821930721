import {
  SAVE_ADD_CONSENT_FORM_DATA,
  FETCH_CONSENTS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
  UPDATE_CREATE_CONSENT_ADMIN_VALUE_IN_STORE,
  RESET_ADD_CONSENT_DATA,
  SET_SELECTED_CONSENT_ID,
} from './constants';

const paginationIntialData = {
  total: 0,
  page: 1,
  total_pages: 1,
  last_page: false,
  first_page: true,
  prev_page: null,
  next_page: 2,
  start_number: 1,
  end_number: 10,
};

const consentAdminFormData = {
  locationCode: null,
  title: null,
  body: null,
  templateId: null,
  procedure: null,
  filterName: null,
  template: [],
  step: 1,
  previousStep: 0,
  selectedConsentId: null,
};

const consentAdminInitialData = {
  consentsData: [],
  consentsHeaders: [],
  pagination: paginationIntialData,
  ...consentAdminFormData,
};

const consentAdminReducer = (state = consentAdminInitialData, action) => {
  switch (action.type) {
    case SAVE_ADD_CONSENT_FORM_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }

    case UPDATE_CREATE_CONSENT_ADMIN_VALUE_IN_STORE: {
      return {
        ...state,
        [action?.key]: action?.value,
      };
    }
    case FETCH_CONSENTS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED:
      return {
        ...state,
        consentsHeaders: action.data.consentsHeaders,
        consentsData: action.data.consentsData,
        pagination: action.data.pagination,
      };
    case SET_SELECTED_CONSENT_ID:
      return {
        ...state,
        ...action.data.consentData,
        selectedConsentId: action.data.id,
      };

    case RESET_ADD_CONSENT_DATA:
      return {
        ...state,
        ...consentAdminFormData,
        ...action.data,
      };
    default:
      return state;
  }
};

export default consentAdminReducer;

export const FETCH_CONSENTS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED =
  'src/containers/admin_page/FETCH_CONSENTS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED';

export const RESET_ADD_CONSENT_DATA =
  'src/containers/admin_page/RESET_ADD_CONSENT_DATA';

export const SET_SELECTED_CONSENT_ID =
  'src/containers/admin_page/SET_SELECTED_CONSENT_ID';

export const UPDATE_CREATE_CONSENT_ADMIN_VALUE_IN_STORE =
  'src/containers/admin_page/UPDATE_CREATE_CONSENT_ADMIN_VALUE_IN_STORE';

export const SAVE_ADD_CONSENT_FORM_DATA =
  'src/containers/admin_page/SAVE_ADD_CONSENT_FORM_DATA';

export const ADMIN_COMMON_CONSTANT = 'src/containers/admin_page/';

const constantGenerator = constant => ADMIN_COMMON_CONSTANT + constant;

export const CREATE_USER_SUCCEED =
  'src/container/admin_page/CREATE_USER_SUCCEED';
export const FETCH_LOCATIONS_SUCCEED =
  'src/container/admin_page/FETCH_LOCATIONS_SUCCEED';
export const UPDATE_ROLES_IN_STORE =
  'src/container/admin_page/UPDATE_ROLES_IN_STORE';
export const UPDATE_VALUE_IN_STORE =
  'src/container/admin_page/UPDATE_VALUE_IN_STORE';

export const FETCH_LOCATIONS_OF_ADMIN_SUCCEED =
  'src/containers/admin_page/FETCH_LOCATIONS_OF_ADMIN_SUCCEED';

export const FETCH_PROVIDERS_NOT_PI_SUCCEED =
  'src/containers/admin_page/FETCH_PROVIDERS_NOT_PI_SUCCEED';
export const FETCH_ADVOCATES_SUCCEED =
  'src/containers/admin_page/FETCH_ADVOCATES_SUCCEED';

export const GET_EVENT_KINDS_REQUEST_SUCCEED =
  'src/containers/admin_page/GET_EVENT_KINDS_REQUEST_SUCCEED';

export const GET_LOCATION_CODES_REQUEST_SUCCEED =
  'src/containers/admin_page/GET_LOCATION_CODES_REQUEST_SUCCEED';
export const GET_TASK_IDENTIFIERS_REQUEST_SUCCEED =
  'src/containers/admin_page/GET_TASK_IDENTIFIERS_REQUEST_SUCCEED';

export const FETCH_PROCEDURES_REQUEST_SUCCEED = constantGenerator(
  'FETCH_PROCEDURES_ON_ADMIN_PAGE_REQUEST_SUCCEED'
);
export const FETCH_ALL_PROVIDERS_SUCCEED = constantGenerator(
  'FETCH_PROVIDERS_ON_ADMIN_PAGE_REQUEST_SUCCEED'
);
export const FETCH_DEPARTMENTS_SUCCEED = constantGenerator(
  'FETCH_DEPARTMENTS_ON_ADMIN_PAGE_REQUEST_SUCCEED'
);
export const FETCH_CREATE_USER_DROPDOWN_OPTIONS = constantGenerator(
  'FETCH_CREATE_USER_OPTIONS_ON_ADMIN_PAGE_REQUEST_SUCCEED'
);
export const FETCH_TIMEZONES_SUCCEED = constantGenerator(
  'FETCH_TIMEZONES_ON_ADMIN_PAGE_REQUEST_SUCCEED'
);

export const FETCH_USERS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED = constantGenerator(
  'FETCH_USERS_ON_ADMIN_PAGE_REQUEST_SUCCEED'
);

export const UPDATE_CREATE_USER_ADMIN_VALUE_IN_STORE =
  'src/container/admin_page/UPDATE_CREATE_USER_ADMIN_VALUE_IN_STORE';
export const SAVE_ADD_USER_FORM_DATA =
  'src/container/admin_page/SAVE_ADD_USER_FORM_DATA';
export const RESET_ADD_USER_DATA =
  'src/containers/admin_page/RESET_ADD_USER_DATA';
export const SET_SELECTED_USER_ID =
  'src/containers/admin_page/SET_SELECTED_USER_ID';
export const UPDATE_USERS_DATA_AFTER_SORTING =
  'src/containers/admin_page/UPDATE_USERS_DATA_AFTER_SORTING';

export const FETCH_RESOURCE_CATEGORIES_SUCCEED = constantGenerator(
  'FETCH_RESOURCE_CATEGORIES_SUCCEED'
);

export const FETCH_RESOURCE_ACTIONS_SUCCEED = constantGenerator(
  'FETCH_RESOURCE_ACTIONS_SUCCEED'
);

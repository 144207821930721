import * as Constants from './constants';
import {
  ActionType,
  ReducerStateType,
  SiteClosureRequestDataAPIResponse,
} from './types';

const initialState = <ReducerStateType>{
  requestKey: null,
  requestedAt: null,
  preparedAt: null,
  expiredAt: null,
  statusText: null,
  userEmail: null,
  locationName: null,
  isRequestCompleted: false,
  downloadLink: null,
};

const siteClosureRequestReducer = (
  state = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case Constants.FETCH_SITE_CLOSURE_REQUEST_DATA_SUCCEED: {
      const data = <SiteClosureRequestDataAPIResponse>action.data;
      return {
        ...state,
        ...data,
      };
    }
    default:
      return state;
  }
};

export default siteClosureRequestReducer;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import { PropTypes } from 'prop-types';
import { saveAddUserFormData } from '../action';

const AddUserStepOneForm = props => {
  const { onContinue, step } = props;
  const dispatch = useDispatch();

  const data = useSelector(store => store.userAdminReducer);

  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    allowSsoLogin: false,
    type: null,
    userRole: null,
  });

  useEffect(() => {
    setUserData({
      ...userData,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      contactNumber: data.contactNumber,
      allowSsoLogin: data.allowSsoLogin,
      type: data.type,
      userRole: data.userRole,
    });
  }, [data]);

  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter First Name',
      validation: [{ rule: 'isRequired', message: 'First Name is required' }],
      value: userData.firstName,
      visible: true,
      required: true,
    },
    {
      id: 2,
      input_type: 'input',
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter Last Name',
      value: userData.lastName,
      validation: [{ rule: 'isRequired', message: 'Last Name is required' }],
      visible: true,
      required: true,
    },
    {
      id: 3,
      input_type: 'input',
      name: 'email',
      label: 'Email Address',
      type: 'email',
      placeholder: 'Enter Email Address',
      value: userData.email,
      validation: [
        { rule: 'isRequired', message: 'Email Address is required' },
        { rule: 'isEmail', message: 'Email Address is Invalid' },
      ],
      visible: true,
      required: true,
    },
    {
      id: 4,
      input_type: 'checkbox',
      label: 'should allow SSO Login?',
      name: 'allowSsoLogin',
      value: userData.allowSsoLogin,
      visible: true,
      disabled:
        userData.type?.label === 'Provider' ||
        (userData.type?.label === 'PatientAdvocate' &&
          userData.userRole?.label === 'FCE'),
    },
    {
      id: 5,
      input_type: 'phone',
      name: 'contactNumber',
      label: 'Phone Number',
      value: userData.contactNumber,
      placeholder: '+1 999 999 9999',
      type: 'phone',
      validation: [
        { rule: 'isRequired', message: 'Phone Number is required' },
        { rule: 'isPhone', message: 'Phone Number is Invalid' },
      ],
      visible: true,
      disabled: false,
      required: true,
    },
  ];

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      userData,
      errorsInForm => {
        setErrors(errorsInForm);
      },
      () => {
        dispatch(saveAddUserFormData(userData));
        onContinue(step + 1);
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      id='addUserStepOneForm'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        setUserData({ ...userData, [key]: value });
        clearErrorsForField(errors, key);
      }}
      buttons={[
        <PrimaryButton
          form='addUserStepOneForm'
          type='submit'
          style={{ width: '176px' }}
          onClick={() => onSubmit()}
          key='submitButton'
        >
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

AddUserStepOneForm.propTypes = {
  onContinue: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

export default AddUserStepOneForm;

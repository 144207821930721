import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import { saveAddTaskReminderFormData } from '../action';

const AddTaskReminderStepOneForm = props => {
  const dispatch = useDispatch();

  const data = useSelector(store => store.taskReminderAdminReducer);

  const [errors, setErrors] = useState({});
  const [taskReminderData, setTaskReminderData] = useState({
    title: '',
    newReminderText: '',
    body: '',
  });

  useEffect(() => {
    const updatedData = {};
    Object.keys(taskReminderData).forEach(
      key => (updatedData[key] = data[key])
    );
    setTaskReminderData({ ...taskReminderData, ...updatedData });
  }, [data]);

  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'title',
      label: 'Title',
      type: 'text',
      placeholder: 'Title',
      validation: [{ rule: 'isRequired', message: 'Title is required' }],
      value: taskReminderData.title,
      visible: true,
    },
    {
      id: 2,
      input_type: 'input',
      name: 'newReminderText',
      label: 'New Reminder Text',
      type: 'text',
      placeholder: 'New Reminder Text',
      validation: [
        { rule: 'isRequired', message: 'New Reminder Text is required' },
      ],
      value: taskReminderData.newReminderText,
      visible: true,
    },
    {
      id: 3,
      input_type: 'textarea',
      name: 'body',
      label: 'Body',
      type: 'text',
      placeholder: 'Body',
      validation: [{ rule: 'isRequired', message: 'Body is required' }],
      value: taskReminderData.body,
      visible: true,
    },
  ];

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      taskReminderData,
      errors => {
        setErrors(errors);
      },
      () => {
        dispatch(saveAddTaskReminderFormData(taskReminderData));
        props.onStepChange(props.step + 1);
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        setTaskReminderData({ ...taskReminderData, [key]: value });
      }}
      buttons={[
        <PrimaryButton style={{ width: '176px' }} onClick={() => onSubmit()}>
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

export default AddTaskReminderStepOneForm;

import React from 'react';
import { dateTimeStringToMMSDateFormatWithTimzoneAbbreviation } from 'utils/dateUtils';
import Attachments from 'containers/Attachments';
import './messageListCard.scss';

const MessagesListCard = props => {
  return (
    <div className='message-list-row col-xs-10'>
      <div
        className={`row content ${
          props.item.type === 'Patient' ? 'patient' : 'provider'
        }`}
      >
        <div className='font-weight-300'>{props.item.body}</div>
        <Attachments
          mediaResources={props.item.mediaResources}
          isAdded={false}
          notShowAttachmentsCount
        />
        <div className='margin-top-5 font-size-sm'>
          <span className='font-weight-300'>{props.item.sentBy.fullName}</span>
          <span className='dot' />
          <span className='sent-on'>Sent On: </span>
          <span className='date'>
            {dateTimeStringToMMSDateFormatWithTimzoneAbbreviation(
              props.item.createdAt
            )}
          </span>
        </div>
      </div>
      <br />
    </div>
  );
};

export default MessagesListCard;

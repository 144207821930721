/* eslint-disable react/prop-types */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import '../../assets/stylesheets/_variables.scss';
import { IconButton } from '@material-ui/core';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InputMask from 'react-input-mask';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { InputBoxStyleGuide } from './StyleGuideCSS';
import { InputTextFieldTheme } from './common/Themes';

import 'components/StyleGuideComponents/styles/inputTextField.scss';

const InputTextField = props => {
  const {
    value: valueInProps,
    error: errorInProps,
    disabled,
    clearIconDisabled,
    onChange,
    type,
    name,
  } = props;
  const [value, setValue] = useState(valueInProps || '');
  const [error, setError] = useState(errorInProps || false);

  const phoneMask =
    process.env.REACT_APP_COUNTRY_OF_APPLICATION === 'USA'
      ? '+1 999 999 9999'
      : '+44 099 999 9999';

  useEffect(() => {
    setValue(valueInProps);
  }, [valueInProps]);

  useEffect(() => {
    setError(errorInProps);
  }, [errorInProps]);

  useEffect(() => {
    if (!disabled && onChange) onChange(name, value);
  }, [value]);

  const handleChange = e => {
    if (error) {
      setError(false);
    }
    setValue(e.target.value);
  };

  const errorIcon = {
    endAdornment: (
      <InputAdornment position='end'>
        <ErrorOutlineIcon
          sx={{ color: `${InputBoxStyleGuide.colorAlertNegative}` }}
        />
      </InputAdornment>
    ),
  };

  const clearButton = {
    endAdornment: (
      <IconButton
        aria-label='toggle password visibility'
        onClick={() => {
          if (!clearIconDisabled) setValue('');
        }}
      >
        <CancelOutlinedIcon
          fontSize='small'
          sx={{ color: '#575558', height: '20px', width: '20px' }}
        />
      </IconButton>
    ),
  };

  const {
    id,
    label,
    variant,
    autoComplete,
    sx,
    maxLength,
    required,
    className,
    errorText,
    placeholder,
  } = props;

  const getInputProps = () => {
    if (!disabled) {
      if (error) return errorIcon;
      if (valueInProps) return clearButton;
    }
    return {};
  };

  return (
    <ThemeProvider theme={InputTextFieldTheme}>
      <FormControl sx={{ width: '100%' }}>
        {type === 'phone' ? (
          <InputMask
            mask={phoneMask}
            maskChar=''
            value={value}
            onChange={handleChange}
          >
            {() => (
              <TextField
                sx={{
                  ...sx,
                  fontSize: '16px',
                }}
                error={error}
                id={id || 'outlined-basic'}
                label={label || ''}
                variant={variant || 'outlined'}
                name={name || 'text'}
                color='primary'
                value={value || ''}
                autoComplete={autoComplete || 'on'}
                fullWidth
                InputProps={getInputProps()}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: maxLength || 150,
                }}
                required={required}
                onChange={handleChange}
                size='normal'
                className={className}
                placeholder={placeholder}
              />
            )}
          </InputMask>
        ) : (
          <TextField
            sx={{
              ...sx,
              fontSize: '16px',
            }}
            error={error}
            id={id || 'outlined-basic'}
            label={label || ''}
            variant={variant || 'outlined'}
            name={name || 'text'}
            autoComplete={autoComplete || 'on'}
            color='primary'
            value={value || ''}
            fullWidth
            InputProps={getInputProps()}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ maxLength: maxLength || 150 }}
            required={required}
            onChange={handleChange}
            size='normal'
            disabled={disabled}
            className={className}
            placeholder={placeholder}
          />
        )}
        <FormHelperText id='outlined-weight-helper-text' className='no-margin'>
          <p
            style={{
              color: `${InputBoxStyleGuide.colorAlertNegative}`,
              fontSize: '12px',
            }}
          >
            {error && errorText ? errorText : ''}
          </p>
        </FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
};

export default InputTextField;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientAppointments } from 'containers/Appointments/action';
import AppointmentCard from 'components/AppointmentCard';
import EmptyView from 'components/Emptyview';
import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import NoAppointments from 'assets/images/noappointments.png';
import TabContentWrapper from 'containers/PatientDetails/Components/TabContentWrapper';
import { DISABLE_ADD_VISIT, ACCESS_CONTROL_KEYS } from 'constants';
import PropTypes from 'prop-types';
import CreateAppointment from './Components/CreateAppointment';
import './appointment.scss';

const Appointments = props => {
  const dispatch = useDispatch();

  const { patientId, procedureId, compositeProcedure, isActive } = props;

  useEffect(() => {
    if (patientId && Object.keys(compositeProcedure).length && isActive) {
      dispatch(
        getPatientAppointments(
          patientId,
          compositeProcedure?.compositeProcedureId
        )
      );
    }
  }, [patientId, compositeProcedure, isActive]);

  const [showCreateAppointmentModal, setShowCreateAppointmentModal] =
    useState(false);

  const events = useSelector(store => store.appointments.events);
  const appFeatures = useSelector(store => store.currentUser.appFeatures);
  const canAddVisit = appFeatures[ACCESS_CONTROL_KEYS.VISITS].canCreate;

  const onCreateAppointmentClick = () => {
    setShowCreateAppointmentModal(true);
  };

  return (
    <TabContentWrapper
      header={
        <div className='header'>
          {events.length ? (
            <span className='header-left-section'>{events.length} Visits</span>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}

          {canAddVisit && (
            <span className='header-right-section'>
              <TertiaryButton
                onClick={onCreateAppointmentClick}
                disabled={DISABLE_ADD_VISIT}
              >
                <span className='icon icon-add-circle add-button' /> &nbsp;
                <span className='appointment-label'>ADD VISIT</span>
              </TertiaryButton>
            </span>
          )}
        </div>
      }
      body={
        <>
          {events.length ? (
            <div className='appointment-list'>
              {events.map(item => (
                <div key={item.id}>
                  <AppointmentCard
                    patientId={patientId}
                    procedureId={procedureId}
                    compositeProcedure={compositeProcedure}
                    item={item}
                    isUserCreatedAppointment={
                      item.eventKindName === 'User Created'
                    }
                    appFeatures={appFeatures}
                  />
                </div>
              ))}
            </div>
          ) : (
            <EmptyView
              imgSrc={NoAppointments}
              className=''
              message='No Appointments'
            />
          )}
          <CreateAppointment
            showCreateAppointmentModal={showCreateAppointmentModal}
            setShowCreateAppointmentModal={setShowCreateAppointmentModal}
            patientId={patientId}
            procedureId={procedureId}
            compositeProcedure={compositeProcedure}
          />
        </>
      }
    />
  );
};

Appointments.propTypes = {
  patientId: PropTypes.number.isRequired,
  procedureId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  compositeProcedure: PropTypes.object,
  isActive: PropTypes.bool.isRequired,
};

Appointments.defaultProps = {
  compositeProcedure: {},
};
export default Appointments;

//
/** \n
 * @author Dilip Joshi
 * @desc
 * InputTextField component is a customizable textfield component that accepts props to allow the user to modify
 * a few features such as 'type', 'label', 'variant', 'name', 'autoComplete', 'maxLength', 'required', 'size', and
 *  'disabled'.
 *
 * Usage:
 * ```
 * import InputTextField from 'components/InputTextField';
 *
 * function ExampleComponent() {
 *   const handleSubmit = (name, value) => {
 *     console.log('Submitted value:', value);
 *   };
 *
 *   return (
 *     <InputTextField
 *       variant="outlined"
 *       label="Username"
 *       type="text"
 *       onChange={handleSubmit}
 *     />
 *   );
 * }
 * ```
 *
 * @param {Object} props Object containing the following properties;
 *  @param {string} [props.value=""] Sets the initial value of the input field
 *  @param {boolean} [props.error=false] A boolean flag that shows/hides error message
 *  @param {Function} [props.onChange] A callback function that is called when the user types in the input field.
 *  @param {string} [props.id="outlined-basic"] Sets the id of the input element
 *  @param {string} [props.label="Password"] The label for the input field
 *  @param {string} [props.variant="outlined"] The variant type for the input field - 'standard' | 'filled' | 'outlined'
 *  @param {string} [props.name="text"] The name of the input field
 *  @param {string} [props.autoComplete="new-password"] The autocomplete attribute of the input element
 *  @param {number} [props.maxLength] Sets the maximum length of the input field
 *  @param {boolean} [props.required=false] A boolean flag that denotes whether field input is required or not
 *  @param {string} [props.size="normal"] The size of the input field - 'small' | 'medium' | 'large'
 *  @param {boolean} [props.disabled] A boolean flag that sets the input field to disabled
 *  @param {Function} [props.onFocus] A callback function that is called when the input field receives focus
 *  @param {Function} [props.onBlur] A callback function that is called when the input field loses focus
 *  @param {string} [props.errorText=""] The error text message to be displayed when an error is present
 *
 * @returns {JSX.Element} Customizable textfield component based on Material-UI's TextField
 *
 * @example
 *
 * import InputTextField from 'components/InputTextField';
 *
 * function ExampleComponent() {
 *   const handleSubmit = (name, value) => {
 *     console.log('Submitted value:', value);
 *   };
 *
 *   return (
 *     <InputTextField
 *       variant="outlined"
 *       label="Username"
 *       type="text"
 *       onChange={handleSubmit}
 *     />
 *   );
 * }
 * */

import * as React from 'react';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import '../../assets/stylesheets/_variables.scss';
import { IconButton } from '@material-ui/core';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PhoneInput from 'components/PhoneInput';
import InputMask from 'react-input-mask';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { InputBoxStyleGuide, passwordVisibilityIcon } from './StyleGuideCSS';
import { InputTextFieldTheme } from './common/Themes';

import 'components/StyleGuideComponents/styles/inputTextField.scss';

export default function InputTextField(props) {
  const [value, setValue] = useState(props.value || '');
  const [error, setError] = useState(props.error || false);
  const [isInlineLabelNeeded, setIsInlineLabelNeeded] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setIsInlineLabelNeeded(
      props.isInlineLabelNeeded === undefined
        ? true
        : props.isInlineLabelNeededrue
    );
  }, [props.isInlineLabelNeeded]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  useEffect(() => {
    if (!props.disabled && props.onChange) props.onChange(props.name, value);
  }, [value]);

  const handleChange = e => {
    if (error) {
      setError(false);
    }
    setValue(e.target.value);
  };

  const visibilityOnIcon = {
    endAdornment: (
      <IconButton
        aria-label='toggle password visibility'
        onClick={() => handleClickShowPassword()}
      >
        <VisibilityOff
          fontSize='small'
          sx={{
            height: passwordVisibilityIcon.height,
            width: passwordVisibilityIcon.width,
            color: passwordVisibilityIcon.color,
          }}
        />
      </IconButton>
    ),
  };

  const visibilityOffIcon = {
    endAdornment: (
      <IconButton
        aria-label='toggle password visibility'
        onClick={() => handleClickShowPassword()}
      >
        <Visibility
          sx={{
            height: passwordVisibilityIcon.height,
            width: passwordVisibilityIcon.width,
            color: passwordVisibilityIcon.color,
          }}
        />
      </IconButton>
    ),
  };

  return (
    <ThemeProvider theme={InputTextFieldTheme}>
      <FormControl sx={{ width: '100%' }}>
        <TextField
          type={showPassword ? 'type' : 'password'}
          sx={{
            ...props.sx,
            fontSize: '16px',
          }}
          error={error}
          id={props.id || 'outlined-basic'}
          label={isInlineLabelNeeded ? props.label : null}
          variant={props.variant || 'outlined'}
          name={props.name || 'text'}
          placeholder={props.placeholder}
          color='primary'
          value={value}
          autoComplete={props.autoComplete || 'new-password'}
          fullWidth
          InputProps={showPassword ? visibilityOnIcon : visibilityOffIcon}
          inputProps={{ maxLength: props.maxLength ? props.maxLength : 150 }}
          required={props.required}
          onChange={handleChange}
          onFocus={
            props.onFocus
              ? () => {
                  props.onFocus();
                }
              : null
          }
          onBlur={
            props.onBlur
              ? () => {
                  props.onBlur();
                }
              : null
          }
          size={props.size || 'normal'}
          disabled={props.disabled}
        />
        <FormHelperText id='outlined-weight-helper-text' className='no-margin'>
          <p
            style={{
              color: `${InputBoxStyleGuide.colorAlertNegative}`,
              fontSize: '12px',
            }}
          >
            {props.errorText ? props.errorText : ''}
          </p>
        </FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
}

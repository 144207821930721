import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import { CONSENT_SUPPORTED_FILE_FORMATS } from 'constants';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import PropTypes from 'prop-types';
import { saveAddConsentFormData } from '../action';
import { getTaskIdentifiersRequest } from '../../action';

const AddConsentStepTwoForm = props => {
  const dispatch = useDispatch();

  const data = useSelector(store => store.consentAdminReducer);
  const adminData = useSelector(store => store.AdminReducer);
  const consentAdminData = useSelector(store => store.consentAdminReducer);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(
      getTaskIdentifiersRequest({
        location_code: consentAdminData.locationCode?.value,
      })
    );
  }, []);

  const [consentData, setConsentData] = useState({
    locationCode: null,
    templateId: null,
    procedure: null,
    template: [],
  });

  useEffect(() => {
    setConsentData({
      ...consentData,
      locationCode: data.locationCode,
      templateId: data.templateId,
      procedure: data.procedure,
      template: data.template,
    });
  }, [data]);

  const config = [
    {
      id: 2,
      input_type: 'single_select',
      name: 'procedure',
      label: 'Procedure',
      value: consentData.procedure,
      placeholder: 'Select Procedure',
      validation: [{ rule: 'isRequired', message: 'Procedure is Invalid' }],
      options: adminData.procedures,
      visible: true,
      required: true,
    },
    {
      id: 5,
      input_type: 'input',
      type: 'text',
      name: 'templateId',
      label: 'Template ID',
      value: consentData.templateId,
      placeholder: 'Enter Template ID',
      validation: [{ rule: 'isRequired', message: 'Template ID is Required' }],
      visible: true,
      required: true,
    },
    {
      id: 7,
      input_type: 'attachment',
      name: 'template',
      label: 'Template',
      value: consentData.template,
      visible: true,
      isBindedWithModal: true,
      onAttachmentClose: () => {},
      fileFormats: CONSENT_SUPPORTED_FILE_FORMATS,
      maxAttachmentCount: 1,
      isFrom: 'consent',
      validateOnlyIf: !consentAdminData.selectedConsentId,
      validation: [{ rule: 'isRequired', message: 'Template is Required' }],
    },
  ];

  const { onContinue, onBack, step } = props;

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      consentData,
      errorsInForm => {
        setErrors(errorsInForm);
      },
      () => {
        dispatch(saveAddConsentFormData(consentData));
        onContinue(step + 1);
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        setConsentData({ ...consentData, [key]: value });
      }}
      buttons={[
        <SecondaryButton
          style={{ width: '176px' }}
          onClick={() => {
            onBack(step);
            dispatch(saveAddConsentFormData(consentData));
          }}
          key='secondaryButton'
        >
          <span>Back</span>
        </SecondaryButton>,
        <PrimaryButton
          style={{ width: '176px' }}
          onClick={() => onSubmit()}
          key='primaryButton'
        >
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

AddConsentStepTwoForm.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

export default AddConsentStepTwoForm;

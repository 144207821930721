export const STATE = {
  COMPLETED: 'completed',
  DEFERRED: 'deferred',
  ERROR: 'error',
};

export const ERRORS = {
  SOMETHING_WENT_WRONG: 'Something went wrong',
};

// export const SURVEY_STATUS = {
//     DUE: "Due",
//     EXPIRED: "Expired",
//     FUTURE: "Future",
//     SUBMITTED: "Submitted"
// }

export const SURVEY_STATUS = {
  EXPIRED: -2,
  DUE: -3,
  FUTURE: -4,
  SUBMITTED: -5
}

export const PLATFORM = {
  ANDROID: 'android',
  IOS: 'ios',
}

export const FETCH_USERS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED =
  'src/containers/admin_page/FETCH_USERS_ON_ADMIN_PAGE_REQUEST_SUCCEED';
export const UPDATE_CREATE_USER_ADMIN_VALUE_IN_STORE =
  'src/container/admin_page/UPDATE_CREATE_USER_ADMIN_VALUE_IN_STORE';
export const SAVE_ADD_USER_FORM_DATA =
  'src/container/admin_page/SAVE_ADD_USER_FORM_DATA';
export const RESET_ADD_USER_DATA =
  'src/containers/admin_page/RESET_ADD_USER_DATA';
export const SET_SELECTED_USER_ID =
  'src/containers/admin_page/SET_SELECTED_USER_ID';
export const UPDATE_USERS_DATA_AFTER_SORTING =
  'src/containers/admin_page/UPDATE_USERS_DATA_AFTER_SORTING';

import * as Constants from './constants';

const initialAuditTrails = {
  auditTrails: [],
  auditTrailsHeaders: [],
  pagination: {
    endNumber: null,
    firstPage: null,
    lastPage: null,
    page: null,
    prevPage: null,
    startNumber: null,
    total: null,
    totalPages: null,
  },
};

export const auditTrailsReducer = (state = initialAuditTrails, action) => {
  switch (action.type) {
    case Constants.FETCH_AUDIT_TRAILS_REQUEST_SUCCEED:
      return {
        ...state,
        auditTrails: action.data.auditTrails,
        auditTrailsHeaders: action.data.auditTrailsHeaders,
        pagination: { ...action.data.pagination, avoidScroll: true },
      };

    default:
      return state;
  }
};

const initialState = {
  exportConsentAuditTrailAllowed: false,
  consents: [],
  headers: [],
};

export const consentsAuditTrailReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.FETCH_CONSENT_FOR_AUDIT_TRAIL_REQUEST_SUCCEED:
      return {
        ...state,
        exportConsentAuditTrailAllowed: action.data.econsentAuditTrailAllowed,
        consents: action.data.consentData,
        headers: action.data.consentHeaders,
      };
    default:
      return state;
  }
};

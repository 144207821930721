/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import findIndex from 'lodash/findIndex';
import Tooltip from '@mui/material/Tooltip';
import { colors } from 'components/StyleGuideComponents/StyleGuideCSS';
import ConfirmationModal from 'components/ConfirmationModal';
import { resolveConversation } from 'containers/Conversations/action';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { dateTimeStringToMMSDateFormat } from 'utils/dateUtils';
import { IconWithCircularBorder } from 'components/UtilityComponents';
import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import PropTypes from 'prop-types';
import { resetResolveConversationStarted } from 'common/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import './conversationListCard.scss';

import head from 'lodash/head';

const ConversationCard = props => {
  const params = useParams();
  const dispatch = useDispatch();

  const resolveConversationStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.resolveConversationStarted
  );

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  const [showConfirmResolutionModal, setShowConfirmResolutionModal] =
    useState(false);

  const {
    item,
    showHeader,
    startDateDisplay,
    onConversationClicked,
    currentUserId,
  } = props;

  useEffect(() => {
    if (resolveConversationStarted) {
      dispatch(resetResolveConversationStarted());
      setShowConfirmResolutionModal(false);
    }
  }, [resolveConversationStarted]);

  const onConfirmResolveConversation = () => {
    const paramsToAPI = {
      patientId,
      procedureId,
      resolveId: item.id,
      secure_action_code: SecureCodes.RESOLVE_CONVERSATION,
      needs_reason: true,
    };
    dispatch(
      secureActionWithDoubleAuthentication(resolveConversation, paramsToAPI)
    );
  };

  const getConversationResolutionTooltipContent = conversation => {
    if (conversation.resolvedAt) {
      return (
        <span style={{ fontSize: '12px' }}>{`The conversation is answered ${
          conversation.resolutionMethod === 'manual' ? ' Manually' : ''
        } by ${
          conversation.resolvedBy.fullName
        } at ${dateTimeStringToMMSDateFormat(conversation.resolvedAt)} `}</span>
      );
    }
    return (
      <span style={{ fontSize: '12px' }}>
        The conversation is not yet answered.
      </span>
    );
  };

  const getConversationIcon = conversation => {
    return (
      <IconWithCircularBorder
        className={`${
          conversation.resolvedAt
            ? 'resolved-conversation'
            : 'unresolved-conversation'
        }`}
        icon={
          conversation.resolvedAt ? (
            <span className='icon icon-font-a-tick' />
          ) : (
            <span className='icon icon-hourglass' />
          )
        }
      />
    );
  };

  const resolveConversationClicked = () => {
    setShowConfirmResolutionModal(true);
  };

  const getLastRepResponse = messages =>
    messages[findIndex(messages, message => message.sentBy.type === 'Rep')];

  const getLastPatientResponse = messages =>
    messages[findIndex(messages, message => message.sentBy.type === 'Patient')];

  const sentBy = getLastRepResponse(item.messages)
    ? getLastRepResponse(item.messages)?.sentBy
    : '';

  const getSentByTextToDisplay = () => {
    if (sentBy) {
      if (sentBy.id === currentUserId) {
        return 'You';
      }
      return sentBy.fullName;
    }
    return '';
  };
  const displaySentName = getSentByTextToDisplay();
  const cardLabel = sentBy
    ? `Last response by ${displaySentName} ${
        displaySentName === 'You' ? '' : `the ${sentBy.subTypeDisplayName}`
      }`
    : '';

  const getConversationCardFooter = () => {
    if (item.messages.length > 1) {
      if (
        getLastPatientResponse(item.messages)?.createdAt &&
        getLastRepResponse(item.messages)?.createdAt &&
        getLastPatientResponse(item.messages)?.createdAt >
          getLastRepResponse(item.messages)?.createdAt
      ) {
        return <span>Sent by Subject</span>;
      }
      return <span>{cardLabel}</span>;
    }
    if (getLastPatientResponse(item.messages)) {
      return <span>Sent by Subject</span>;
    }
    return <span>{cardLabel}</span>;
  };

  return (
    <>
      <div className='conversation-list-row' key={item.id}>
        <div className={`conversation-card-date${!showHeader ? '-hide' : ''}`}>
          {startDateDisplay}
        </div>
        <div
          className={`conversation ${
            !showHeader ? 'message-separator-show' : 'message-separator-hide'
          }`}
        >
          <div className='conversation-metadata cursor-pointer'>
            <span
              className='conversation-list-status cursor-pointer float-left'
              onClick={() =>
                item.resolvedAt
                  ? onConversationClicked(item.id, patientId)
                  : resolveConversationClicked(item)
              }
              role='button'
              tabIndex={0}
              onKeyDown={() => {}}
            >
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: colors.colorPrimary,
                    },
                  },
                }}
                title={getConversationResolutionTooltipContent(item)}
              >
                <span>{getConversationIcon(item)}</span>
              </Tooltip>
            </span>
            <div
              className='conversation-body'
              onClick={() => onConversationClicked(item.id, patientId)}
              role='button'
              tabIndex={0}
              onKeyDown={() => {}}
            >
              <div className='conversation-title'>{item.subject}</div>
              <div className='conversation-message-body'>
                {head(item.messages).body}
              </div>
              <div className='conversation-message-footer'>
                {getConversationCardFooter()}
              </div>
            </div>
          </div>
          <div className='app-link'>
            <TertiaryButton
              onClick={() => onConversationClicked(item.id, patientId)}
              style={{ padding: '0px' }}
            >
              <span>
                {item.resolvedAt ? (
                  <>Thread ({item.messages.length})</>
                ) : (
                  <>Reply</>
                )}
              </span>
              &nbsp;
              <span className='icon icon-font-a-right-chevron' />
            </TertiaryButton>
          </div>
        </div>
      </div>
      <ConfirmationModal
        show={showConfirmResolutionModal}
        text='Marking this thread as answered will remove it from your dashboard as well as the dashboards of any other Medtronic InsightPro users who are assigned to this patient.'
        title='Mark as Answered'
        onModalClose={() => setShowConfirmResolutionModal(false)}
        onConfirmed={onConfirmResolveConversation}
        cancelBtnText='Cancel'
        okBtnText='Mark as Answered'
      />
    </>
  );
};

ConversationCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  showHeader: PropTypes.bool.isRequired,
  startDateDisplay: PropTypes.string.isRequired,
  onConversationClicked: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
};

export default ConversationCard;

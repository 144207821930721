import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './styleGuideComponents.scss';
import { colors } from './StyleGuideCSS';

const reasonsToAvoidModalHide = ['backdropClick', 'escapeKeyDown'];

const modalDefaultStyle = {
  position: 'absolute',
  outline: 'none',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: `1px solid ${colors.colorDisabledGrey}`,
  boxShadow: '8px 16px 24px 16px',
  padding: '16px',
  boxShadow:
    '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)',
  borderRadius: '4px',
  height: 'auto',
  width: 'auto',
  // minHeight: '800px',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
};

export default function CustomModal(props) {
  useEffect(() => {
    if (Object.keys(props).includes('form')) {
      if (props.form.form) {
        if (props.form.form.props ? !props.form.form.props.id : false) {
          console.warn(
            'Form Object In Modal Should have id attribute to have Button to submit the form'
          );
        } else if (props.buttons) {
          const isButtonHasFormAttribute = props.buttons.some(
            button => button.props.form
          );
          if (!isButtonHasFormAttribute) {
            console.warn(
              'Atleast one button in modal should have a form attribute when there is a form in modal'
            );
          } else {
            const buttonFormId = props.buttons.some(button => {
              if (button.props.form) {
                if (props.form.form.props)
                  return button.props.form === props.form.form.props.id;
              }
            });
            if (!buttonFormId) {
              console.warn(
                'form attribute in button should have same value as id attribute to the form'
              );
            }
          }
        }
      }
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason && reasonsToAvoidModalHide.includes(reason)) return;
    props.onHide();
  };

  const getInlineStyleForForm = () => ({
    width: props.form.width ? props.form.width : '100%',
    alignSelf: props.formPosition ? props.formPosition : 'flex-start',
  });
  return (
    <div className='formmodal-box'>
      <Modal
        open={props.show}
        onClose={handleClose}
        className={props.className}
      >
        <Box
          sx={
            props.modalStyle
              ? { ...modalDefaultStyle, ...props.modalStyle }
              : { ...modalDefaultStyle }
          }
        >
          {/* modal Header */}
          <div className='formmodal-header-content'>
            {props.title && (
              <span className='formmodal-content-title'>
                {props.title.text}
              </span>
            )}
            {props.closeIcon && (
              <span id='modal-close-button' className='modal-close-button'>
                <IconButton onClick={props.onHide}>
                  <CloseIcon />
                </IconButton>
              </span>
            )}
          </div>
          {/* modal content */}
          <div className='formmodal-details'>
            {/* modal image and content */}
            <div
              className='formmodal-content'
              style={
                props.loaderModal
                  ? { boxShadow: 'none', background: 'transparent' }
                  : {}
              }
            >
              {/* modal content subheader */}
              {props.subheader && (
                <span
                  className='formmodal-subheader'
                  style={
                    props.subheaderStyle ? { ...props.subheaderStyle } : {}
                  }
                >
                  {props.subheader}
                </span>
              )}
            </div>
            {props.form && (
              <div className='formmodal-form'>
                {props.form && props.form.form}
              </div>
            )}
            {props.body && (
              <div className='formmodal-form'>{props.body && props.body}</div>
            )}
          </div>
          {/* modal content buttons */}
          {props.buttons && (
            <div
              id='modal-buttons'
              className='formmodal-buttons'
              style={{
                justifyContent:
                  props.buttons.length == 1 ? 'center' : 'flex-end',
              }}
            >
              {props.buttons &&
                props.buttons.map(button => (
                  <span className='formmodal-button'>{button}</span>
                ))}
            </div>
          )}
          <div className='formmodal-href'>{props.href}</div>
        </Box>
      </Modal>
    </div>
  );
}

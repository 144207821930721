import axios, { basicInstance } from './base';
import {
  GET_PROVIDER_FILTERS,
  ADD_NEW_PATIENT,
  GET_PROCEDURE_LOCATIONS,
  GET_PROCEDURE_PROVIDERS,
  GET_PROCEDURE_MEDTRONIC_MENTORS,
  GET_EHR_ACTIVITIES,
  GET_APPOINTMENTS_WITHIN_RANGE,
  GET_APPOINTMENTS_FOR_DATE,
  GET_PROCEDURE_MODIFIERS,
  UPDATE_PROCEDURE_MODIFIERS,
} from './constants';

export const getAppointmentsSearchResults = paramsInArgs => {
  const params = paramsInArgs;
  params.limit = 1000;
  return axios.get(GET_APPOINTMENTS_FOR_DATE, { params });
};

export const getAppointmentsSearchWithinRangeResults = params => {
  return basicInstance.get(GET_APPOINTMENTS_WITHIN_RANGE, { params });
};

export const getProviderFacets = params =>
  axios.get(GET_PROVIDER_FILTERS, { params });

export const addNewPatient = params => axios.post(ADD_NEW_PATIENT, params);

export const getProcedureLocations = params =>
  axios.get(GET_PROCEDURE_LOCATIONS, { params });

export const getProcedureModifiers = params =>
  axios.get(GET_PROCEDURE_MODIFIERS, { params });

export const updateProcedureModifiers = params => {
  const url = UPDATE_PROCEDURE_MODIFIERS.replace(
    ':id',
    params.user_procedure_id
  );
  return axios.put(url, params);
};

export const getProcedureProviders = (params, userType = 'Provider') => {
  if (userType === 'Provider') {
    return axios.get(GET_PROCEDURE_PROVIDERS, { params });
  }
  return axios.get(GET_PROCEDURE_MEDTRONIC_MENTORS, { params });
};

export const getEhrActivitiesCount = () => axios.get(GET_EHR_ACTIVITIES);

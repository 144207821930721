import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import AddUserFormPreview from './AddUserFormPreview';
import AddUserStepOneForm from './AddUserStepOneForm';
import AddUserStepTwoForm from './AddUserStepTwoForm';
import AddUserStepThreeForm from './AddUserStepThreeForm';
import { resetAddUsersData, updateUserAdminValue } from '../action';

const AddEditUserModal = props => {
  const dispatch = useDispatch();

  const LAST_STEP = 4;

  const userAdminData = useSelector(store => store.userAdminReducer);

  const step = useSelector(store => store.userAdminReducer.step);

  const previousStep = useSelector(
    store => store.userAdminReducer.previousStep
  );

  const handleModalHide = () => {
    dispatch(
      resetAddUsersData({
        page: userAdminData.page,
        filterNameOrEmail: userAdminData.filterNameOrEmail,
      })
    );
    props.updateSelectedUserId(null);
    props.onHide();
  };

  const onStepChange = newStep => {
    dispatch(updateUserAdminValue('step', newStep));
    dispatch(updateUserAdminValue('previousStep', step));
  };

  const onBack = step => {
    onStepChange(step - 1);
  };

  const onContinue = newStep => {
    if (previousStep === LAST_STEP) {
      dispatch(updateUserAdminValue('step', LAST_STEP));
    } else {
      dispatch(updateUserAdminValue('step', newStep));
    }
    dispatch(updateUserAdminValue('previousStep', step));
  };

  const getFormBasedOnStep = step => {
    switch (step) {
      case 1:
        return (
          <AddUserStepOneForm
            step={1}
            totalSteps={4}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onClose={() => handleModalHide()}
          />
        );
      case 2:
        return (
          <AddUserStepTwoForm
            step={2}
            totalSteps={4}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onClose={() => handleModalHide()}
          />
        );

      case 3:
        return (
          <AddUserStepThreeForm
            step={3}
            totalSteps={4}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onClose={() => handleModalHide()}
          />
        );
      case 4:
        return (
          <AddUserFormPreview
            step={4}
            onBack={step => onBack(step)}
            onContinue={step => onContinue(step)}
            onStepChange={step => onStepChange(step)}
            handleModalHide={handleModalHide}
            onClose={() => handleModalHide()}
          />
        );
    }
  };

  const getFormSubheader = () => {
    switch (step) {
      case 1:
        return 'Personal Information';
        break;
      case 2:
        return 'Position Information';
        break;
      case 3:
        return 'Role Information';
        break;
      case 4:
        return 'Review and Finish';
      default:
        return 'Add New User';
    }
  };

  return (
    <FormModal
      show={props.show}
      onHide={handleModalHide}
      title={{
        text: userAdminData.selectedUserId ? 'Edit User Info' : 'Add New User',
      }}
      subheader={
        <div className='modal-subheader-content'>
          <div>{getFormSubheader()}</div>
          <div className='text-right'>
            <span className='steps-count active-step-count'>{`${step} of 4`}</span>
          </div>
        </div>
      }
      modalStyle={{
        width: '700px',
        height: '700px',
      }}
      form={{
        form: getFormBasedOnStep(step),
      }}
    />
  );
};

export default AddEditUserModal;

import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { ResponseDataType, PropsType } from './types';
import {
  fetchAdminAuditTrailRequest,
  resetAdminAuditTrailData,
} from './action';

const useAdminAuditTrailViewController = (props: PropsType) => {
  const { isActive } = props;
  const { auditTrails, auditTrailsHeaders } = <ResponseDataType>(
    useAppSelector(store => store.adminAuditTrailReducer)
  );
  const shouldShowEmptyViewModel = (): boolean => !auditTrails.length;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isActive) {
      dispatch(fetchAdminAuditTrailRequest());
    }

    return () => {
      dispatch(resetAdminAuditTrailData());
    };
  }, [isActive]);

  return {
    auditTrailData: { data: auditTrails, headers: auditTrailsHeaders },
    shouldShowEmptyViewModel,
  };
};

export default useAdminAuditTrailViewController;

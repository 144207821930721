import React from 'react';
import InputMask from 'react-input-mask';
import { PHONE_INPUT_MASK } from 'constants';

const MaskedInput = props => {
  const formatChars = {
    '*': '[ A-Za-z0-9_@./#&+-:]',
  };

  const maxLength = props.maxLength || 100;
  const inputLength = '*'.repeat(maxLength);
  return (
    <InputMask
      {...props}
      formatChars={formatChars}
      mask={props.mask + inputLength}
      maskChar={null}
      type='text'
    />
  );
};

export default MaskedInput;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  PrimaryButton,
  AlertButton,
} from 'components/StyleGuideComponents/common/buttons';
import { DeleteModal } from 'components/StyleGuideComponents/common/modals';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { deleteCreatedTask } from 'containers/Tasks/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';

const DeleteTask = props => {
  const dispatch = useDispatch();
  const params = useParams();

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  const onDeleteConfirmed = () => {
    const params = {
      taskId: props.task.id,
      patientId,
      procedureId,
      phaseId: null,
      showAllTasks: true,
      secure_action_code: SecureCodes.DELETE_TASK,
      needs_reason: true,
    };
    dispatch(secureActionWithDoubleAuthentication(deleteCreatedTask, params));
  };

  return (
    <DeleteModal
      show={props.showDeleteModal}
      onHide={() => props.setShowDeleteModal(false)}
      bodyHeader={{
        text: 'Delete',
      }}
      bodyContent={{
        text: <div>Are you sure you want to delete this task?</div>,
      }}
      buttons={[
        <AlertButton onClick={() => props.setShowDeleteModal(false)}>
          Cancel
        </AlertButton>,
        <PrimaryButton onClick={onDeleteConfirmed}>Delete</PrimaryButton>,
      ]}
    />
  );
};

export default DeleteTask;

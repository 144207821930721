import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Pagination from 'components/Pagination';
import { fetchTimezonesRequest } from 'containers/AdminPage/action';
import PropTypes from 'prop-types';
import AdminTabWrapper from '../AdminTabWrapper';
import {
  setSelectedLocationIdRequest,
  fetchLocationsListOnAdminPageRequest,
  updateLocationAdminValue,
} from './action';
import LocationsFilter from './Components/LocationsFilter';
import LocationsData from './Components/LocationsData';
import AddEditLocationModal from './Components/AddEditLocationModal';

const LocationsAdmin = props => {
  const dispatch = useDispatch();

  const [showAddEditModa, setShowAddEditModal] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  const locationAdminData = useSelector(store => store.locationAdminReducer);
  const { isActive } = props;

  useEffect(() => {
    if (isActive) {
      dispatch(
        fetchLocationsListOnAdminPageRequest({
          page: locationAdminData.page,
          name: locationAdminData.filterName,
        })
      );
      dispatch(fetchTimezonesRequest());
    } else {
      dispatch(updateLocationAdminValue('filterName', ''));
    }
  }, [locationAdminData.page, isActive, locationAdminData.filterName]);

  return (
    <>
      <AdminTabWrapper
        filterSection={<LocationsFilter />}
        dataSection={{
          header: (
            <div style={{ width: 'fit-content' }}>
              <TertiaryButton onClick={() => setShowAddEditModal(true)}>
                <AddCircleOutlineIcon sx={{ fontSize: '32px' }} />
                <span style={{ fontSize: '16px' }}> Add New Location </span>
              </TertiaryButton>
            </div>
          ),
          content: (
            <LocationsData
              selectedLocationId={selectedLocationId}
              data={{
                locationHeaders: locationAdminData.locationsHeaders,
                locationsData: locationAdminData.locationsData,
              }}
              updateSelectedLocationId={data => {
                setSelectedLocationId(data);
                dispatch(
                  setSelectedLocationIdRequest(
                    data,
                    locationAdminData.locationsData.find(
                      location => location.id === data
                    )
                  )
                );
                setShowAddEditModal(true);
              }}
            />
          ),
          footer: (
            <Pagination
              pagination={locationAdminData.pagination}
              title='Locations'
              handlePagination={pageInParams => {
                dispatch(updateLocationAdminValue('page', pageInParams));
              }}
            />
          ),
        }}
      />
      <AddEditLocationModal
        show={showAddEditModa}
        onHide={() => setShowAddEditModal(false)}
        updateSelectedLocationId={id => setSelectedLocationId(id)}
      />
    </>
  );
};

LocationsAdmin.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default LocationsAdmin;

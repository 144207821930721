import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import { getValidationsObject } from 'utils/stringUtils';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import { saveAddNotificationFormData } from '../action';

const AddNotificationStepOneForm = props => {
  const dispatch = useDispatch();

  const data = useSelector(store => store.notificationAdminReducer);

  const [errors, setErrors] = useState({});
  const [notificationData, setNotificationData] = useState({
    title: '',
    body: '',
    offsetInDays: 0,
    timings: null,
    dueOffsetHours: 0,
  });

  useEffect(() => {
    const updatedData = {};
    Object.keys(notificationData).forEach(
      key => (updatedData[key] = data[key])
    );
    setNotificationData({ ...notificationData, ...updatedData });
  }, [data]);

  const config = [
    {
      id: 1,
      input_type: 'input',
      name: 'title',
      label: 'Title',
      type: 'text',
      placeholder: 'Title',
      validation: [{ rule: 'isRequired', message: 'Title is required' }],
      value: notificationData.title,
      visible: true,
    },
    {
      id: 2,
      input_type: 'textarea',
      name: 'body',
      label: 'Body',
      type: 'text',
      placeholder: 'Body',
      validation: [{ rule: 'isRequired', message: 'Body is required' }],
      value: notificationData.body,
      visible: true,
    },
    {
      id: 3,
      input_type: 'input',
      name: 'offsetInDays',
      label: 'Offset In Days',
      type: 'number',
      placeholder: 'Offset In Days',
      value: notificationData.offsetInDays,
      visible: true,
    },
    {
      id: 4,
      input_type: 'input',
      name: 'timings',
      label: 'Timings',
      type: 'time',
      placeholder: 'Timings',
      value: notificationData.timings,
      visible: true,
    },
    {
      id: 5,
      input_type: 'input',
      name: 'dueOffsetHours',
      label: 'Due Offset Hours',
      type: 'number',
      placeholder: 'Due Offset Hours',
      value: notificationData.dueOffsetHours,
      visible: true,
    },
  ];

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      notificationData,
      errors => {
        setErrors(errors);
      },
      () => {
        dispatch(saveAddNotificationFormData(notificationData));
        props.onStepChange(props.step + 1);
      }
    );
  };

  return (
    <FormGenerator
      className='add-edit-form'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        setNotificationData({ ...notificationData, [key]: value });
      }}
      buttons={[
        <PrimaryButton style={{ width: '176px' }} onClick={() => onSubmit()}>
          <span>Continue</span>
        </PrimaryButton>,
      ]}
      {...props}
    />
  );
};

export default AddNotificationStepOneForm;

import * as React from 'react';
import Button from '@mui/material/Button';
import { SecondaryButtonStyleGuide } from './StyleGuideCSS';

export default function SecondaryButton(props) {
  const SecondaryButtonStyle = {
    maxWidth: '100%',
    height: '56px',
    width:
      props.device === 'phone'
        ? props.size === 'large'
          ? SecondaryButtonStyleGuide.phone.large.width
          : SecondaryButtonStyleGuide.phone.medium.width
        : props.size === 'large'
        ? SecondaryButtonStyleGuide.web.large.width
        : SecondaryButtonStyleGuide.web.medium.width,

    background: SecondaryButtonStyleGuide.background,
    borderRadius: SecondaryButtonStyleGuide.borderRadius,
    fontFamily: SecondaryButtonStyleGuide.fontFamily,
    fontWeight: SecondaryButtonStyleGuide.fontWeight,
    fontSize: SecondaryButtonStyleGuide.fontSize,
    lineHeight: SecondaryButtonStyleGuide.lineHeight,
    letterSpacing: SecondaryButtonStyleGuide.letterSpacing,
    textAlign: SecondaryButtonStyleGuide.align,
    color: SecondaryButtonStyleGuide.color,
    border: '1px solid red',
    textTransform: 'none',
    '&:hover': {
      boxShadow: SecondaryButtonStyleGuide.boxShadow,
    },
    '&:active': {
      background: SecondaryButtonStyleGuide.backgroundActive,
      color: SecondaryButtonStyleGuide.colorActive,
    },

    '&:disabled': {
      border: SecondaryButtonStyleGuide.backgroundDisabled,
      color: SecondaryButtonStyleGuide.colorDisabled,
    },
  };

  return (
    <Button
      className='secondary-button'
      disabled={props.disabled}
      disableRipple
      sx={SecondaryButtonStyle}
      onClick={props.onClick}
    >
      {props.text || 'secondary button'}
    </Button>
  );
}

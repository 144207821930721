import React from 'react';
import { useHistory, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import LandingPageWrapper from 'components/LandingPageWrapper';
import { defaultLandingPageRouteGenerator } from 'common/authGuard';
import { PrimaryButtonLarge } from 'components/StyleGuideComponents/common/buttons';
import './landingPage.scss';

const LandingPage = props => {
  const history = useHistory();

  const isSignedIn = useSelector(store => store.currentUser.isSignedIn);

  const signInUser = () => {
    history.push({
      pathname: '/sign-in-with-password',
      search: history.location.search,
    });
  };

  const signInUserWithSAML = () => {
    window.open(process.env.REACT_APP_MDT_SAML_URL, '_self');
  };

  if (isSignedIn) {
    return <Redirect to={defaultLandingPageRouteGenerator()} replace />;
  }
  return (
    <div>
      <LandingPageWrapper>
        <div className='landing-page-buttons'>
          <PrimaryButtonLarge
            style={{ maxWidth: '700px', height: '48px' }}
            onClick={() => signInUser()}
          >
            Sign in with Email ID or Phone Number
          </PrimaryButtonLarge>
          <span className='buttons-seperator'>
            <span className='line'>
              <hr />
            </span>
            <span className='or-text'>OR</span>
            <span className='line'>
              <hr />
            </span>
          </span>
          <PrimaryButtonLarge
            style={{ maxWidth: '700px', height: '48px' }}
            onClick={() => signInUserWithSAML()}
          >
            Log in with Medtronic
          </PrimaryButtonLarge>
        </div>
      </LandingPageWrapper>
    </div>
  );
};

export default LandingPage;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import RadioButton from 'components/RadioButton';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import CloseIcon from '@mui/icons-material/Close';

import {
  updateNeedsAttentionAction,
  revokeAccountDeletionRequest,
  resendInviteAction,
  getPatientInfo,
} from 'containers/PatientDetails/actions';

import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';

import UserId from 'components/UserId';
import { IconWithCircularBorder } from 'components/UtilityComponents';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import Tooltip from 'components/StyleGuideComponents/ToolTip';
import { dateStringToCustomDateTime } from 'utils/dateUtils';
import { ACCESS_CONTROL_KEYS } from 'constants';
import { colors } from '../StyleGuideComponents/StyleGuideCSS';
import { TertiaryButton } from '../StyleGuideComponents/common/buttons';
import AvatarImage from '../../assets/images/defaultpatient.png';
import { FormModal } from '../StyleGuideComponents/common/modals';
import EditPatientForm from './EditPatientForm';
import PatientInfoCard from './ReusableFragments/PatientInfoCard';

const SubjectInfoCard = () => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(
      getPatientInfo(
        parseInt(params.patientId, 10) || -1,
        parseInt(params.procedureId, 10) || -1
      )
    );
  }, [params.patientId, params.procedureId]);

  const subjectInformation = useSelector(
    store => store.currentPatientInfo.patient
  );
  const appFeatures = useSelector(store => store.currentUser.appFeatures);

  const [showEditPatientInfoModal, setShowEditPatientInfoModal] =
    useState(false);
  const [formEditDisabled, setFormEditDisabled] = useState(true);

  const userProcedure = subjectInformation.userProcedures.length
    ? subjectInformation.userProcedures.find(
        item => item.id === params.procedureId
      ) || subjectInformation.userProcedures[0]
    : null;

  const canRevokeAccountDeletionRequest =
    appFeatures[ACCESS_CONTROL_KEYS.REVOKE_ACCOUNT_DELETION]?.canClear;

  useEffect(() => {
    setFormEditDisabled(true);
  }, [showEditPatientInfoModal]);

  const needsAttentionChange = currentState => {
    const paramsToAPI = {
      user_procedure_id: userProcedure.id,
      needs_attention: currentState,
      secure_action_code: SecureCodes.UPDATE_NEEDS_ATTENTION,
      needs_reason: true,
    };
    dispatch(
      secureActionWithDoubleAuthentication(
        updateNeedsAttentionAction,
        paramsToAPI
      )
    );
  };

  const revokeDeletionRequest = () => {
    const data = {
      id: params.patientId,
      user_procedure_id: userProcedure.id,
      secure_action_code: SecureCodes.REVOKE_ACCOUNT_DELETION,
      needs_reason: true,
    };
    dispatch(
      secureActionWithDoubleAuthentication(revokeAccountDeletionRequest, data)
    );
  };

  const handleSendInvites = () => {
    const data = {
      id: parseInt(params.patientId, 10),
      user_procedure_id: parseInt(params.procedureId, 10),
      secure_action_code: SecureCodes.RESEND_INVITE,
      needs_reason: true,
    };
    dispatch(secureActionWithDoubleAuthentication(resendInviteAction, data));
  };

  const subjectInformationbody = (
    <>
      <div className='subject-info-body'>
        <div className='patient-image'>
          <img
            alt='Patient'
            src={subjectInformation.profilePhotoUrl || AvatarImage}
            id='profile-image'
          />
        </div>
        <div className='patient-info-content'>
          {/* use UserId global componenet here */}
          <span className='patient-id'>
            <UserId
              id={userProcedure?.subjectStudyId || subjectInformation?.id}
              mrn={subjectInformation.mrn}
            />
          </span>
          <span className='subject-details'>
            {appFeatures[ACCESS_CONTROL_KEYS.PATIENT_DEMOGRAPHICS].canView && (
              <TertiaryButton onClick={() => setShowEditPatientInfoModal(true)}>
                <span className='subject-details-icon'>
                  <TextSnippetOutlinedIcon
                    fontSize='small'
                    sx={{
                      color: colors.colorPrimary,
                      height: '20px',
                      width: '20px',
                    }}
                  />
                </span>
                <span className='subject-details-text'>
                  View Subject Details
                </span>
              </TertiaryButton>
            )}
          </span>
          <span>
            {canRevokeAccountDeletionRequest &&
            subjectInformation.isAccountDeleteRequested ? (
              <div className='alert-container'>
                <TertiaryButton
                  onClick={revokeDeletionRequest}
                  className='alert-text'
                >
                  <span className='subject-details-icon'>
                    <CloseIcon
                      fontSize='small'
                      sx={{
                        color: colors.colorPrimary,
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  </span>
                  Cancel Account Deletion Request
                </TertiaryButton>
              </div>
            ) : (
              ''
            )}
          </span>
          <span>
            {userProcedure && (
              <div className='switch-container'>
                Tag for Follow-up &nbsp;&nbsp;
                <RadioButton
                  onToggle={needsAttentionChange}
                  value={userProcedure.needsAttention}
                  disabled={
                    !appFeatures[ACCESS_CONTROL_KEYS.TAG_FOR_FOLLOW_UP].canEdit
                  }
                />
              </div>
            )}
          </span>
        </div>
      </div>
      {/* <LoadingIndicator /> */}
    </>
  );

  const getPatientLoginStatus = (
    signInCount,
    notificationPreference,
    hasPendingInvite
  ) => {
    if (
      appFeatures[ACCESS_CONTROL_KEYS.PATIENT_DEMOGRAPHICS].canEdit &&
      hasPendingInvite
    ) {
      return (
        <TertiaryButton
          onClick={() => handleSendInvites()}
          style={{ padding: '0px' }}
        >
          <IconWithCircularBorder
            className='pending-invite-icon login-status-icon'
            icon={
              <div className='icon icon-invite-phone-iphone-fill0-wght400-grad0-opsz48-1-2 phone-icon-send-invite' />
            }
          />
          <span className='action-text'>
            {subjectInformation.isEhrPendingInvite
              ? 'Send Invite'
              : 'Resend Invite'}
          </span>
        </TertiaryButton>
      );
    }
    if (!hasPendingInvite && signInCount === 0) {
      return (
        <>
          <IconWithCircularBorder
            className='not-logged-in-icon'
            icon={<div className='icon icon-not-logged-in' />}
          />
          <span className='action-text'>Not Logged in</span>
        </>
      );
    }
    if (signInCount > 0) {
      if (notificationPreference === 'PushNotification') {
        return (
          <Tooltip
            content={`last login status : ${dateStringToCustomDateTime(
              subjectInformation.lastLoginStatus
            )}`}
            toolTipStyle={{ top: '60px' }}
            tipStyle={{ top: '-2px', left: '-41px', position: 'sticky' }}
          >
            <span className='action'>
              <IconWithCircularBorder
                className='logged-in-icon login-status-icon'
                icon={
                  <div className='icon icon-invite-logged-in phone-icon-logged' />
                }
              />
              <span className='action-text'>Logged In</span>
            </span>
          </Tooltip>
        );
      }
      return (
        <>
          <IconWithCircularBorder
            className='notification-off-icon login-status-icon'
            icon={
              <div className='icon icon-invite-notifications-off phone-icon-notifications' />
            }
          />
          <span className='action-text'>Notifications off</span>
        </>
      );
    }
  };

  const subjectInformationHeader = (
    <>
      <div className='card-icon'>
        <AccountCircleOutlinedIcon
          fontSize='small'
          sx={{ color: colors.colorPrimaryDark, height: '24px', width: '24px' }}
        />
      </div>
      <div className='card-title'>Subject Information</div>

      <span className='action'>
        {getPatientLoginStatus(
          subjectInformation.signInCount,
          subjectInformation.notificationPreference,
          subjectInformation.hasPendingInvite
        )}
      </span>
    </>
  );

  return (
    <PatientInfoCard id='subject' className='subject' cardType='subject-card'>
      <div className='header'>{subjectInformationHeader}</div>
      <div className='body-content'>{subjectInformationbody}</div>

      <FormModal
        show={showEditPatientInfoModal}
        onHide={() => {
          setShowEditPatientInfoModal(false);
          setFormEditDisabled(true);
        }}
        modalStyle={{
          height: '90%',
          width: '750px',
        }}
        title={{
          text: formEditDisabled ? 'Subject Details' : 'Edit Subject Details',
        }}
        form={{
          form: (
            <EditPatientForm
              onModalClose={() => setShowEditPatientInfoModal(false)}
              setFormEditDisabled={value => setFormEditDisabled(value)}
              formEditDisabled={formEditDisabled}
            />
          ),
        }}
      />
    </PatientInfoCard>
  );
};
export default SubjectInfoCard;

import { showToaster } from 'common/toasterActions';

import { createTaskReminder } from 'services/admin';
import {
  SAVE_ADD_TASK_REMINDER_FORM_DATA,
  UPDATE_CREATE_TASK_REMINDER_ADMIN_VALUE_IN_STORE,
  RESET_ADD_TASK_REMINDER_DATA,
} from './constant';

export const saveAddTaskReminderFormData = data => {
  return {
    type: SAVE_ADD_TASK_REMINDER_FORM_DATA,
    data,
  };
};

export const updateTaskReminderAdminValue = (key, value) => {
  return {
    type: UPDATE_CREATE_TASK_REMINDER_ADMIN_VALUE_IN_STORE,
    key,
    value,
  };
};

export const resetAddTasksReminderData = data => {
  return {
    data,
    type: RESET_ADD_TASK_REMINDER_DATA,
  };
};

export const createTaskReminderRequest = params => dispatch => {
  createTaskReminder(params).then(response => {
    dispatch(showToaster({ message: response.data.message }));
  });
};

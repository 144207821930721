import axios from './base';
import {
  SHARE_OUTCOMES_REPORT,
  GET_SHARED_OUTCOMES_REPORTS_HISTORY,
} from './constants';

export const shareOutcomesReport = params => {
  return axios.post(SHARE_OUTCOMES_REPORT, params);
};

export const getSharedOutcomesReportsHistory = params => {
  return axios.get(GET_SHARED_OUTCOMES_REPORTS_HISTORY, { params });
};

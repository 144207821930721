/*
 *
 * ediaryreducer
 *
 */

import * as Constants from './constants';

const initialState = {
  isLoading: false,
  diaryLogs: [],
  total: 0,
  hasError: false,
  isRequestInProgress: false,
};

export const patientDiaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.GET_DIARY_ENTRIES_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case Constants.GET_DIARY_ENTRIES_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        total: action.diaryLogs.total,
        diaryLogs: [...action.diaryLogs.diaryLogs],
      };
    case Constants.GET_DIARY_ENTRIES_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default: {
      return state;
    }
  }
};
